import { ChainId } from 'sdk'

export const listedTokensList = {
  [ChainId.FANTOM]: [
    {
      symbol: 'L3USD',
      chainId: 250,
      name: 'L3USD',
      address: '0x5f0456f728e2d59028b4f5b8ad8c604100724c6a',
      decimals: 18,
      logoURI: 'https://ftmscan.com/token/images/lif3usd_32.png',
      rating: 7,
      eip2612: false,
    },
    {
      symbol: 'LIF3',
      chainId: ChainId.FANTOM,
      name: 'LIF3',
      address: '0x5e074c28149cc35c1e5fbc79d8a6ea688dba2314',
      decimals: 18,
      logoURI: './assets/svg/lif3.svg',
      eip2612: false,
    },
    {
      symbol: 'LSHARE',
      chainId: ChainId.FANTOM,
      name: 'LSHARE',
      address: '0x8ff27b58e1d969befc9c8aeae93a6eb7e2250c8f',
      decimals: 18,
      logoURI: 'https://ftmscan.com/token/images/lif3lshare_32.png',
      eip2612: false,
    },
    {
      symbol: 'LSHARE (old)',
      chainId: ChainId.FANTOM,
      name: 'LSHARE (old)',
      address: '0xcbe0ca46399af916784cadf5bcc3aed2052d6c45',
      decimals: 18,
      logoURI: 'https://ftmscan.com/token/images/lif3lshare_32.png',
      eip2612: false,
    },
    {
      address: '0xa23c4e69e5eaf4500f2f9301717f12b578b948fb',
      chainId: ChainId.FANTOM,
      name: 'Protofi Token',
      symbol: 'PROTO',
      decimals: 18,
      logoURI: 'https://tokens.1inch.io/0xa23c4e69e5eaf4500f2f9301717f12b578b948fb.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x1d1764f04de29da6b90ffbef372d1a45596c4855',
      symbol: 'MIMO',
      decimals: 18,
      name: 'MIMO Parallel Governance Token',
      logoURI: 'https://tokens.1inch.io/0x1d1764f04de29da6b90ffbef372d1a45596c4855.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x15b7c0c907e4c6b9adaaaabc300c08991d6cea05',
      symbol: 'GEL',
      decimals: 18,
      name: 'Gelato Network Token',
      logoURI: 'https://tokens.1inch.io/0x15b7c0c907e4c6b9adaaaabc300c08991d6cea05.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x01e77288b38b416f972428d562454fb329350bac',
      symbol: 'MMY',
      decimals: 18,
      name: 'MUMMY',
      logoURI: 'https://tokens.1inch.io/0x01e77288b38b416f972428d562454fb329350bac.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x3fd3a0c85b70754efc07ac9ac0cbbdce664865a6',
      symbol: 'EQUAL',
      decimals: 18,
      name: 'Equalizer',
      logoURI: 'https://tokens.1inch.io/0x3fd3a0c85b70754efc07ac9ac0cbbdce664865a6.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x34d33dc8ac6f1650d94a7e9a972b47044217600b',
      symbol: 'SMART',
      decimals: 18,
      name: 'Smart Token',
      logoURI: 'https://tokens.1inch.io/0x34d33dc8ac6f1650d94a7e9a972b47044217600b.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xe0654c8e6fd4d733349ac7e09f6f23da256bf475',
      symbol: 'SCREAM',
      decimals: 18,
      name: 'Scream',
      logoURI: 'https://tokens.1inch.io/0xe0654c8e6fd4d733349ac7e09f6f23da256bf475.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xbbc4a8d076f4b1888fec42581b6fc58d242cf2d5',
      symbol: 'FONT',
      decimals: 18,
      name: 'Font',
      logoURI: 'https://tokens.1inch.io/0x4c25bdf026ea05f32713f00f73ca55857fbf6342.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x0def844ed26409c5c46dda124ec28fb064d90d27',
      symbol: 'CoUSD',
      decimals: 18,
      name: 'CoffinDollar',
      logoURI: 'https://tokens.1inch.io/0x0def844ed26409c5c46dda124ec28fb064d90d27.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x1d43697d67cb5d0436cc38d583ca473a1bfebc7a',
      symbol: 'RIP',
      decimals: 9,
      name: 'Fantom Doge',
      logoURI: 'https://tokens.1inch.io/0x1d43697d67cb5d0436cc38d583ca473a1bfebc7a.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xd3c325848d7c6e29b574cb0789998b2ff901f17e',
      symbol: '1ART',
      decimals: 18,
      name: 'ArtWallet',
      logoURI: 'https://tokens.1inch.io/0xd3c325848d7c6e29b574cb0789998b2ff901f17e.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x9f47f313acfd4bdc52f4373b493eae7d5ac5b765',
      symbol: 'JOE',
      decimals: 18,
      name: 'JoeToken',
      logoURI: 'https://tokens.1inch.io/0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x230576a0455d7ae33c6defe64182c0bb68bafaf3',
      symbol: 'BRO',
      decimals: 18,
      name: 'DexBrowser',
      logoURI: 'https://tokens.1inch.io/0x230576a0455d7ae33c6defe64182c0bb68bafaf3.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x10010078a54396f62c96df8532dc2b4847d47ed3',
      symbol: 'HND',
      decimals: 18,
      name: 'Hundred Finance',
      logoURI: 'https://tokens.1inch.io/0x10010078a54396f62c96df8532dc2b4847d47ed3.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xe1e6b01ae86ad82b1f1b4eb413b219ac32e17bf6',
      symbol: 'XRUNE',
      decimals: 18,
      name: 'Thorstarter Token',
      logoURI: 'https://tokens.1inch.io/0xe1e6b01ae86ad82b1f1b4eb413b219ac32e17bf6.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xc54a1684fd1bef1f077a336e6be4bd9a3096a6ca',
      symbol: '2SHARES',
      decimals: 18,
      name: '2SHARE Token',
      logoURI: 'https://tokens.1inch.io/0xc54a1684fd1bef1f077a336e6be4bd9a3096a6ca.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x00a35fd824c717879bf370e70ac6868b95870dfb',
      symbol: 'IB',
      decimals: 18,
      name: 'IronBank',
      logoURI: 'https://tokens.1inch.io/0x00a35fd824c717879bf370e70ac6868b95870dfb.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x582423c10c9e83387a96d00a69ba3d11ee47b7b5',
      symbol: 'RING',
      decimals: 18,
      name: 'OneRing',
      logoURI: 'https://tokens.1inch.io/0x9469d013805bffb7d3debe5e7839237e535ec483.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xe83dfaaafd3310474d917583ae9633b4f68fb036',
      symbol: 'RAINI',
      decimals: 18,
      name: 'Raini',
      logoURI: 'https://tokens.1inch.io/0xe83dfaaafd3310474d917583ae9633b4f68fb036.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xeeeeeb57642040be42185f49c52f7e9b38f8eeee',
      symbol: 'ELK',
      decimals: 18,
      name: 'Elk',
      logoURI: 'https://tokens.1inch.io/0xeeeeeb57642040be42185f49c52f7e9b38f8eeee.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xe83ce6bfb580583bd6a62b4be7b34fc25f02910d',
      symbol: 'FABBC',
      decimals: 8,
      name: 'Fantom ABBC',
      logoURI: 'https://tokens.1inch.io/0xe83ce6bfb580583bd6a62b4be7b34fc25f02910d.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x82f0b8b456c1a451378467398982d4834b6829c1',
      symbol: 'MIM',
      decimals: 18,
      name: 'Magic Internet Money',
      logoURI: 'https://tokens.1inch.io/0x130966628846bfd36ff31a822705796e8cb8c18d.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
      symbol: 'TAROT',
      decimals: 18,
      name: 'Tarot',
      logoURI: 'https://tokens.1inch.io/0xc5e2b037d30a390e62180970b3aa4e91868764cd.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9',
      symbol: 'LQDR',
      decimals: 18,
      name: 'Liquid Driver',
      logoURI: 'https://tokens.1inch.io/0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xd8321aa83fb0a4ecd6348d4577431310a6e0814d',
      symbol: 'GEIST',
      decimals: 18,
      name: 'Geist.Finance Protocol Token',
      logoURI: 'https://tokens.1inch.io/0xd8321aa83fb0a4ecd6348d4577431310a6e0814d.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x90e892fed501ae00596448aecf998c88816e5c0f',
      symbol: 'DMD',
      decimals: 18,
      name: 'DarkMatter',
      logoURI: 'https://tokens.1inch.io/0x90e892fed501ae00596448aecf998c88816e5c0f.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xeff6fcfbc2383857dd66ddf57efffc00d58b7d9d',
      symbol: 'JulD',
      decimals: 18,
      name: 'JulSwap',
      logoURI: 'https://tokens.1inch.io/0x5a41f637c3f7553dba6ddc2d3ca92641096577ea.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xfa1fbb8ef55a4855e5688c0ee13ac3f202486286',
      symbol: 'FHM',
      decimals: 9,
      name: 'Fantohm',
      logoURI: 'https://tokens.1inch.io/0xfa1fbb8ef55a4855e5688c0ee13ac3f202486286.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x69d17c151ef62421ec338a0c92ca1c1202a427ec',
      symbol: 'SNT',
      decimals: 18,
      name: 'Supernova Token',
      logoURI: 'https://tokens.1inch.io/0x744d70fdbe2ba4cf95131626614a1763df805b9e.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x85dec8c4b2680793661bca91a8f129607571863d',
      symbol: 'BRUSH',
      decimals: 18,
      name: 'PaintSwap Token',
      logoURI: 'https://tokens.1inch.io/0x85dec8c4b2680793661bca91a8f129607571863d.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x59d07a115fe3ffe5db3d52029d43cc0ef5e9ba08',
      symbol: 'SUPA',
      decimals: 18,
      name: 'SUPA Foundation',
      logoURI: 'https://tokens.1inch.io/0x59d07a115fe3ffe5db3d52029d43cc0ef5e9ba08.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xc165d941481e68696f43ee6e99bfb2b23e0e3114',
      symbol: 'OXD',
      decimals: 18,
      name: '0xDAO',
      logoURI: 'https://tokens.1inch.io/0xc165d941481e68696f43ee6e99bfb2b23e0e3114.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x112df7e3b4b7ab424f07319d4e92f41e6608c48b',
      symbol: 'pFTM',
      decimals: 18,
      name: 'pFTM',
      logoURI: 'https://tokens.1inch.io/0x112df7e3b4b7ab424f07319d4e92f41e6608c48b.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x91fa20244fb509e8289ca630e5db3e9166233fdc',
      symbol: 'gOHM',
      decimals: 18,
      name: 'Governance OHM',
      logoURI: 'https://tokens.1inch.io/0x91fa20244fb509e8289ca630e5db3e9166233fdc.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454',
      symbol: 'BNB',
      decimals: 18,
      name: 'BNB',
      logoURI: 'https://tokens.1inch.io/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c_1.png',
      tags: ['PEG:BNB', 'tokens'],
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x5cc61a78f164885776aa610fb0fe1257df78e59b',
      symbol: 'SPIRIT',
      decimals: 18,
      name: 'SpiritSwap Token',
      logoURI: 'https://tokens.1inch.io/0x5cc61a78f164885776aa610fb0fe1257df78e59b.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xd7028092c830b5c8fce061af2e593413ebbc1fc1',
      symbol: 'sFTMX',
      decimals: 18,
      name: 'sFTMX',
      logoURI: 'https://tokens.1inch.io/0xd7028092c830b5c8fce061af2e593413ebbc1fc1.png',
      tags: ['PEG:FTM', 'tokens'],
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590',
      symbol: 'STG',
      decimals: 18,
      name: 'StargateToken',
      logoURI: 'https://tokens.1inch.io/0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xddcb3ffd12750b45d32e084887fdf1aabab34239',
      symbol: 'ANY',
      decimals: 18,
      name: 'Anyswap',
      logoURI: 'https://tokens.1inch.io/0xf99d58e463a2e07e5692127302c20a191861b4d6.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xdc301622e621166bd8e82f2ca0a26c13ad0be355',
      symbol: 'FRAX',
      decimals: 18,
      name: 'Frax',
      logoURI: 'https://tokens.1inch.io/0xd24c2ad096400b6fbcd2ad8b24e7acbc21a1da64.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xd3b71117e6c1558c1553305b44988cd944e97300',
      symbol: 'YEL',
      decimals: 18,
      name: 'YEL Token',
      logoURI: 'https://tokens.1inch.io/0xd3b71117e6c1558c1553305b44988cd944e97300.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x6626c47c00f1d87902fc13eecfac3ed06d5e8d8a',
      symbol: 'WOO',
      decimals: 18,
      name: 'Wootrade Network',
      logoURI: 'https://tokens.1inch.io/0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x593ab53baffaf1e821845cf7080428366f030a9c',
      symbol: 'COFFIN',
      decimals: 18,
      name: 'CoffinToken',
      logoURI: 'https://tokens.1inch.io/0x593ab53baffaf1e821845cf7080428366f030a9c.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xc758295cd1a564cdb020a78a681a838cf8e0627d',
      symbol: 'FS',
      decimals: 18,
      name: 'FantomStarter',
      logoURI: 'https://tokens.1inch.io/0xc758295cd1a564cdb020a78a681a838cf8e0627d.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x5602df4a94eb6c680190accfa2a475621e0ddbdc',
      symbol: 'SPA',
      decimals: 9,
      name: 'Spartacus',
      logoURI: 'https://tokens.1inch.io/0x5602df4a94eb6c680190accfa2a475621e0ddbdc.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x248cb87dda803028dfead98101c9465a2fbda0d4',
      symbol: 'CHARM',
      decimals: 18,
      name: 'Charm Token',
      logoURI: 'https://tokens.1inch.io/0x248cb87dda803028dfead98101c9465a2fbda0d4.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xc3118248e7a3b2c103d87392fca5eb6ed8daa754',
      symbol: 'DUCAT',
      decimals: 18,
      name: 'Ducat',
      logoURI: 'https://tokens.1inch.io/0xc3118248e7a3b2c103d87392fca5eb6ed8daa754.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x40df1ae6074c35047bff66675488aa2f9f6384f3',
      symbol: 'MATIC',
      decimals: 18,
      name: 'MATIC',
      logoURI: 'https://tokens.1inch.io/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png',
      eip2612: true,
      tags: ['PEG:MATIC', 'tokens'],
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x33333ee26a7d02e41c33828b42fb1e0889143477',
      symbol: 'LIQR',
      decimals: 18,
      name: 'LIQR',
      logoURI: 'https://tokens.1inch.io/0x33333ee26a7d02e41c33828b42fb1e0889143477.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x7a6e4e3cc2ac9924605dca4ba31d1831c84b44ae',
      symbol: '2OMB',
      decimals: 18,
      name: '2omb Token',
      logoURI: 'https://tokens.1inch.io/0x7a6e4e3cc2ac9924605dca4ba31d1831c84b44ae.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xf24bcf4d1e507740041c9cfd2dddb29585adce1e',
      symbol: 'BEETS',
      decimals: 18,
      name: 'BeethovenxToken',
      logoURI: 'https://tokens.1inch.io/0xf24bcf4d1e507740041c9cfd2dddb29585adce1e.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x74e23df9110aa9ea0b6ff2faee01e740ca1c642e',
      symbol: 'TOR',
      decimals: 18,
      name: 'TOR',
      logoURI: 'https://tokens.1inch.io/0x74e23df9110aa9ea0b6ff2faee01e740ca1c642e.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x13082681e8ce9bd0af505912d306403592490fc7',
      symbol: 'PAR',
      decimals: 18,
      name: 'PAR Stablecoin',
      logoURI: 'https://tokens.1inch.io/0x13082681e8ce9bd0af505912d306403592490fc7.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
      symbol: 'WFTM',
      decimals: 18,
      name: 'Wrapped Fantom',
      logoURI: 'https://tokens.1inch.io/0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83.png',
      tags: ['PEG:FTM', 'tokens'],
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
      symbol: 'TOMB',
      decimals: 18,
      name: 'TOMB',
      logoURI: 'https://tokens.1inch.io/0x6c021ae822bea943b2e66552bde1d2696a53fbb7.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x9bd0611610a0f5133e4dd1bfdd71c5479ee77f37',
      symbol: 'FTMO',
      decimals: 18,
      name: 'Fantom Oasis Token',
      logoURI: 'https://tokens.1inch.io/0x9bd0611610a0f5133e4dd1bfdd71c5479ee77f37.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x49894fcc07233957c35462cfc3418ef0cc26129f',
      symbol: 'FANG',
      decimals: 18,
      name: 'FANG Token',
      logoURI: 'https://tokens.1inch.io/0x49894fcc07233957c35462cfc3418ef0cc26129f.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x468003b688943977e6130f4f68f23aad939a1040',
      symbol: 'SPELL',
      decimals: 18,
      name: 'Spell Token',
      logoURI: 'https://tokens.1inch.io/0xce1bffbd5374dac86a2893119683f4911a2f7814.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x3dc57b391262e3aae37a08d91241f9ba9d58b570',
      symbol: 'YOSHI',
      decimals: 18,
      name: 'Yoshi.exchange',
      logoURI: 'https://tokens.1inch.io/0x3dc57b391262e3aae37a08d91241f9ba9d58b570.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xfb98b335551a418cd0737375a2ea0ded62ea213b',
      symbol: 'miMATIC',
      decimals: 18,
      name: 'miMATIC',
      logoURI: 'https://tokens.1inch.io/0xa3fa99a148fa48d14ed51d610c367c61876997f1.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x152888854378201e173490956085c711f1ded565',
      symbol: 'MST',
      decimals: 18,
      name: 'Monster',
      logoURI: 'https://tokens.1inch.io/0x152888854378201e173490956085c711f1ded565.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x511d35c52a3c244e7b8bd92c0c297755fbd89212',
      symbol: 'AVAX',
      decimals: 18,
      name: 'Avalanche',
      logoURI: 'https://tokens.1inch.io/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x77128dfdd0ac859b33f44050c6fa272f34872b5e',
      symbol: 'CREDIT',
      decimals: 18,
      name: 'Creditum',
      logoURI: 'https://tokens.1inch.io/0x77128dfdd0ac859b33f44050c6fa272f34872b5e.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xe64b9fd040d1f9d4715c645e0d567ef69958d3d9',
      symbol: 'MOD',
      decimals: 18,
      name: 'Modefi',
      logoURI: 'https://tokens.1inch.io/0xd4fbc57b6233f268e7fba3b66e62719d74deecbc.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x131c7afb4e5f5c94a27611f7210dfec2215e85ae',
      symbol: 'POWER',
      decimals: 18,
      name: 'Power',
      logoURI: 'https://tokens.1inch.io/0xf2f9a7e93f845b3ce154efbeb64fb9346fcce509.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x59c6606ed2af925f270733e378d6af7829b5b3cf',
      symbol: 'WBOND',
      decimals: 18,
      name: 'War Bond Token',
      logoURI: 'https://tokens.1inch.io/0x59c6606ed2af925f270733e378d6af7829b5b3cf.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xfb4d42bed5618fb1a377ddb64eb56b92a6d117f2',
      symbol: 'NOKU',
      decimals: 18,
      name: 'NOKU v2',
      logoURI: 'https://tokens.1inch.io/0xfb4d42bed5618fb1a377ddb64eb56b92a6d117f2.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x49c290ff692149a4e16611c694fded42c954ab7a',
      symbol: 'BSHARE',
      decimals: 18,
      name: 'BSHARE',
      logoURI: 'https://tokens.1inch.io/0x531780face85306877d7e1f05d713d1b50a37f7a.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xad996a45fd2373ed0b10efa4a8ecb9de445a4302',
      symbol: 'ALPACA',
      decimals: 18,
      name: 'AlpacaToken',
      logoURI: 'https://tokens.1inch.io/0x8f0528ce5ef7b51152a59745befdd91d97091d2f.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x1e4f97b9f9f913c46f1632781732927b9019c68b',
      symbol: 'CRV',
      decimals: 18,
      name: 'Curve DAO',
      logoURI: 'https://tokens.1inch.io/0xd533a949740bb3306d119cc777fa900ba034cd52.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xae75a438b2e0cb8bb01ec1e1e376de11d44477cc',
      symbol: 'SUSHI',
      decimals: 18,
      name: 'Sushi',
      logoURI: 'https://tokens.1inch.io/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37',
      symbol: 'TSHARE',
      decimals: 18,
      name: 'TSHARE',
      logoURI: 'https://tokens.1inch.io/0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xc60d7067dfbc6f2caf30523a064f416a5af52963',
      symbol: 'TREEB',
      decimals: 18,
      name: 'Treeb',
      logoURI: 'https://tokens.1inch.io/0xc60d7067dfbc6f2caf30523a064f416a5af52963.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x5c4fdfc5233f935f20d2adba572f770c2e377ab0',
      symbol: 'HEC',
      decimals: 9,
      name: 'Hector',
      logoURI: 'https://tokens.1inch.io/0x5c4fdfc5233f935f20d2adba572f770c2e377ab0.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x9879abdea01a879644185341f7af7d8343556b7a',
      symbol: 'TUSD',
      decimals: 18,
      name: 'TrueUSD',
      logoURI: 'https://tokens.1inch.io/0x1c20e891bab6b1727d14da358fae2984ed9b59eb.png',
      eip2612: true,
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x65def5029a0e7591e46b38742bfedd1fb7b24436',
      symbol: 'KAE',
      decimals: 18,
      name: 'Kanpeki',
      logoURI: 'https://tokens.1inch.io/0x65def5029a0e7591e46b38742bfedd1fb7b24436.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x8a41f13a4fae75ca88b1ee726ee9d52b148b0498',
      symbol: 'PAE',
      decimals: 18,
      name: 'Ripae',
      logoURI: 'https://tokens.1inch.io/0x8a41f13a4fae75ca88b1ee726ee9d52b148b0498.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
      symbol: 'BOO',
      decimals: 18,
      name: 'SpookyToken',
      logoURI: 'https://tokens.1inch.io/0xbd83010eb60f12112908774998f65761cf9f6f9a.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x6a07a792ab2965c72a5b8088d3a069a7ac3a993b',
      symbol: 'AAVE',
      decimals: 18,
      name: 'Aave',
      logoURI: 'https://tokens.1inch.io/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      symbol: 'FTM',
      decimals: 18,
      name: 'Fantom Token',
      logoURI: 'https://tokens.1inch.io/0x4e15361fd6b4bb609fa63c81a2be19d873717870.png',
      tags: ['native'],
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xf16e81dce15b08f326220742020379b855b87df9',
      symbol: 'ICE',
      decimals: 18,
      name: 'IceToken',
      logoURI: 'https://tokens.1inch.io/0xe0ce60af0850bf54072635e66e79df17082a1109.png',
      eip2612: true,
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x66eed5ff1701e6ed8470dc391f05e27b1d0657eb',
      symbol: 'MPX',
      decimals: 18,
      name: 'MPX',
      logoURI: 'https://tokens.1inch.io/0x66eed5ff1701e6ed8470dc391f05e27b1d0657eb.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xcfc785741dc0e98ad4c9f6394bb9d43cd1ef5179',
      symbol: 'ankrFTM',
      decimals: 18,
      name: 'Ankr Staked FTM',
      logoURI: 'https://tokens.1inch.io/0xcfc785741dc0e98ad4c9f6394bb9d43cd1ef5179.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x1b6382dbdea11d97f24495c9a90b7c88469134a4',
      symbol: 'axlUSDC',
      decimals: 6,
      name: 'Axelar USDC',
      logoURI: 'https://assets.spooky.fi/tokens/USDC.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xd5d5350f42cb484036a1c1af5f2df77eafadcaff',
      symbol: 'axlDAI',
      decimals: 18,
      name: 'Axelar DAI',
      logoURI: 'https://assets.spooky.fi/tokens/DAI.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xd226392c23fb3476274ed6759d4a478db3197d82',
      symbol: 'axlUSDT',
      decimals: 6,
      name: 'Axelar USDT',
      logoURI: 'https://assets.spooky.fi/tokens/USDT.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x28a92dde19d9989f39a49905d7c9c2fac7799bdf',
      symbol: 'lzUSDC',
      decimals: 6,
      name: 'LayerZero USDC',
      logoURI: 'https://assets.spooky.fi/tokens/USDC.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xcc1b99ddac1a33c201a742a1851662e87bc7f22c',
      symbol: 'lzUSDT',
      decimals: 6,
      name: 'LayerZero USDT',
      logoURI: 'https://assets.spooky.fi/tokens/USDT.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0xfe7eda5f2c56160d406869a8aa4b2f365d544c7b',
      symbol: 'axlETH',
      decimals: 18,
      name: 'Axelar wETH',
      logoURI: 'https://assets.spooky.fi/tokens/wETH.png',
    },
    {
      chainId: ChainId.FANTOM,
      address: '0x448d59b4302ab5d2dadf9611bed9457491926c8e',
      symbol: 'axlWBTC',
      decimals: 8,
      name: 'Axelar wBTC',
      logoURI: 'https://assets.spooky.fi/tokens/wBTC.png',
    },
  ],
  [ChainId.BASE]: [
    {
      address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      chainId: ChainId.BASE,
      symbol: 'ETH',
      decimals: 18,
      name: 'Ether',
      logoURI: 'https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
      tags: ['native'],
    },
    {
      chainId: ChainId.BASE,
      address: '0x4200000000000000000000000000000000000006',
      symbol: 'WETH',
      decimals: 18,
      name: 'Wrapped Ether',
      logoURI: 'https://tokens.1inch.io/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png',
      tags: ['PEG:ETH', 'tokens'],
    },
    {
      chainId: ChainId.BASE,
      address: '0xbcf19453badf7c7ac0630b11dcb75d52b7eb8930',
      symbol: 'Dbase',
      decimals: 18,
      name: 'Doge Base',
      logoURI: 'https://tokens.1inch.io/0xbcf19453badf7c7ac0630b11dcb75d52b7eb8930.png',
    },
    {
      chainId: ChainId.BASE,
      address: '0xeb466342c4d449bc9f53a865d5cb90586f405215',
      symbol: 'axlUSDC',
      decimals: 6,
      name: 'Axelar Wrapped USDC',
      logoURI: 'https://tokens.1inch.io/0xeb466342c4d449bc9f53a865d5cb90586f405215.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.BASE,
      address: '0x23ee2343b892b1bb63503a4fabc840e0e2c6810f',
      symbol: 'AXL',
      decimals: 6,
      name: 'Axelar',
      logoURI: 'https://tokens.1inch.io/0x23ee2343b892b1bb63503a4fabc840e0e2c6810f.png',
    },
    {
      chainId: ChainId.BASE,
      address: '0x7f5373ae26c3e8ffc4c77b7255df7ec1a9af52a6',
      symbol: 'axlUSDT',
      decimals: 6,
      name: 'Axelar Wrapped USDT',
      logoURI: 'https://tokens.1inch.io/0x7f5373ae26c3e8ffc4c77b7255df7ec1a9af52a6.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.BASE,
      address: '0x081ad949defe648774c3b8debe0e4f28a80716dc',
      symbol: 'HZN',
      decimals: 18,
      name: 'Horizon',
      logoURI: 'https://tokens.1inch.io/0x081ad949defe648774c3b8debe0e4f28a80716dc.png',
    },
    {
      chainId: ChainId.BASE,
      address: '0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca',
      symbol: 'USDbC',
      decimals: 6,
      name: 'USD Base Coin',
      logoURI: 'https://tokens.1inch.io/0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.BASE,
      address: '0x27d2decb4bfc9c76f0309b8e88dec3a601fe25a8',
      symbol: 'BALD',
      decimals: 18,
      name: 'Bald',
      logoURI: 'https://tokens.1inch.io/0x27d2decb4bfc9c76f0309b8e88dec3a601fe25a8.png',
    },
    {
      chainId: ChainId.BASE,
      address: '0x63e71271719f03d7233f4fa306b6ea868d0f52ff',
      symbol: 'BINU',
      decimals: 18,
      name: 'BaseInu',
      logoURI: 'https://tokens.1inch.io/0x63e71271719f03d7233f4fa306b6ea868d0f52ff.png',
    },
    {
      chainId: ChainId.BASE,
      address: '0x6653dd4b92a0e5bf8ae570a98906d9d6fd2eec09',
      symbol: 'RCKT',
      decimals: 18,
      name: 'RocketSwap',
      logoURI: 'https://tokens.1inch.io/0x6653dd4b92a0e5bf8ae570a98906d9d6fd2eec09.png',
    },
    {
      chainId: ChainId.BASE,
      address: '0x0bf0ba3962a189d56f358143f38b7ffd26b8d48f',
      symbol: 'BASE',
      decimals: 18,
      name: 'BaseTools',
      logoURI: 'https://tokens.1inch.io/0x0bf0ba3962a189d56f358143f38b7ffd26b8d48f.png',
    },
    {
      chainId: ChainId.BASE,
      address: '0x50c5725949a6f0c72e6c4a641f24049a917db0cb',
      symbol: 'DAI',
      decimals: 18,
      name: 'Dai Stablecoin',
      logoURI: 'https://tokens.1inch.io/0x50c5725949a6f0c72e6c4a641f24049a917db0cb.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.BASE,
      address: '0xbd2dbb8ecea9743ca5b16423b4eaa26bdcfe5ed2',
      symbol: 'SYNTH',
      decimals: 18,
      name: 'Synth Token',
      logoURI: 'https://tokens.1inch.io/0xbd2dbb8ecea9743ca5b16423b4eaa26bdcfe5ed2.png',
    },
    {
      chainId: ChainId.BASE,
      address: '0x2ae3f1ec7f1f5012cfeab0185bfc7aa3cf0dec22',
      symbol: 'cbETH',
      decimals: 18,
      name: 'Coinbase Wrapped Staked ETH',
      logoURI: 'https://tokens.1inch.io/0x2ae3f1ec7f1f5012cfeab0185bfc7aa3cf0dec22.png',
      tags: ['PEG:ETH', 'tokens'],
    },
    {
      chainId: ChainId.BASE,
      address: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
      symbol: 'USDC',
      decimals: 6,
      name: 'USD Coin',
      logoURI: 'https://tokens.1inch.io/0x833589fcd6edb6e08f4c7c32d4f71b54bda02913.png',
    },
    {
      chainId: ChainId.BASE,
      address: '0xac1bd2486aaf3b5c0fc3fd868558b082a531b2b4',
      symbol: 'TOSHI',
      decimals: 18,
      name: 'Toshi',
      logoURI: 'https://tokens.1inch.io/0xac1bd2486aaf3b5c0fc3fd868558b082a531b2b4.png',
    },
  ],
  [ChainId.LIF3CHAIN_TESTNET]: [
    {
      address: '0x6883d461FE9FB40D3Ff3393fDE5147A6617b3E30',
      chainId: ChainId.LIF3CHAIN_TESTNET,
      name: 'Wrapped Lif3',
      symbol: 'WLIF3',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    {
      address: '0x621A936084BBb73F947C827a92C93Ef6A3612c3A',
      chainId: ChainId.LIF3CHAIN_TESTNET,
      name: 'USDT',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDT.svg',
    },
    {
      address: '0x6aAaa67227D8D29D26115a433130D882A876507d',
      chainId: ChainId.LIF3CHAIN_TESTNET,
      name: 'LSHARE',
      symbol: 'LSHARE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0xe97EF94FF6df13d769f1F1ac23C2419eFF501ad8',
      chainId: ChainId.LIF3CHAIN_TESTNET,
      name: 'L3USD',
      symbol: 'L3USD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
    },
  ],
  [ChainId.BSC]: [
    {
      symbol: 'L3USD',
      chainId: 56,
      name: 'L3USD',
      address: '0xdea12c8c23994ea2d88ed99ee1bdc0ff56f7f9d1',
      decimals: 18,
      logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21496.png',
      rating: 12,
      eip2612: false,
    },
    {
      symbol: 'LIF3',
      chainId: ChainId.BSC,
      name: 'LIF3',
      address: '0x336f374198c872ba760e85af9c6331c3c5a535d3',
      decimals: 18,
      logoURI: './assets/svg/lif3.svg',
      eip2612: false,
    },
    {
      symbol: 'LSHARE',
      chainId: ChainId.BSC,
      name: 'LSHARE',
      address: '0x0c08638473cafbca3beb113616a1871f4bfad4f9',
      decimals: 18,
      logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20728.png',
      eip2612: false,
    },
    {
      chainId: ChainId.BSC,
      address: '0x53e562b9b7e5e94b81f10e96ee70ad06df3d2657',
      symbol: 'BABY',
      decimals: 18,
      name: 'BabySwap Token',
      logoURI: 'https://tokens.1inch.io/0x53e562b9b7e5e94b81f10e96ee70ad06df3d2657.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x9c65ab58d8d978db963e63f2bfb7121627e3a739',
      symbol: 'MDX',
      decimals: 18,
      name: 'MDX Token',
      logoURI: 'https://tokens.1inch.io/0x9c65ab58d8d978db963e63f2bfb7121627e3a739.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x9ac983826058b8a9c7aa1c9171441191232e8404',
      symbol: 'SNX',
      decimals: 18,
      name: 'Synthetix',
      logoURI: 'https://tokens.1inch.io/0x9ac983826058b8a9c7aa1c9171441191232e8404.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xa865197a84e780957422237b5d152772654341f3',
      symbol: 'OLE',
      decimals: 18,
      name: 'OpenLeverage',
      logoURI: 'https://tokens.1inch.io/0xa865197a84e780957422237b5d152772654341f3.png',
      eip2612: true,
    },
    {
      chainId: ChainId.BSC,
      address: '0xa64455a4553c9034236734faddaddbb64ace4cc7',
      symbol: 'SANTOS',
      decimals: 8,
      name: 'FC Santos Fan Token',
      logoURI: 'https://tokens.1inch.io/0xa64455a4553c9034236734faddaddbb64ace4cc7.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4ba0057f784858a48fe351445c672ff2a3d43515',
      symbol: 'KALM',
      decimals: 18,
      name: 'Kalmar Token',
      logoURI: 'https://tokens.1inch.io/0x4ba0057f784858a48fe351445c672ff2a3d43515.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x6bff4fb161347ad7de4a625ae5aa3a1ca7077819',
      symbol: 'ADX',
      decimals: 18,
      name: 'AdEx Network',
      logoURI: 'https://tokens.1inch.io/0x6bff4fb161347ad7de4a625ae5aa3a1ca7077819.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x68e374f856bf25468d365e539b700b648bf94b67',
      symbol: 'MIST',
      decimals: 18,
      name: 'Mist',
      logoURI: 'https://tokens.1inch.io/0x68e374f856bf25468d365e539b700b648bf94b67.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xa2120b9e674d3fc3875f415a7df52e382f141225',
      symbol: 'ATA',
      decimals: 18,
      name: 'Automata',
      logoURI: 'https://tokens.1inch.io/0xa2120b9e674d3fc3875f415a7df52e382f141225.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x5f4bde007dc06b867f86ebfe4802e34a1ffeed63',
      symbol: 'HIGH',
      decimals: 18,
      name: 'Highstreet Token',
      logoURI: 'https://tokens.1inch.io/0x5f4bde007dc06b867f86ebfe4802e34a1ffeed63.png',
      eip2612: true,
    },
    {
      chainId: ChainId.BSC,
      address: '0x8263cd1601fe73c066bf49cc09841f35348e3be0',
      symbol: 'ALU',
      decimals: 18,
      name: 'Altura',
      logoURI: 'https://tokens.1inch.io/0x8263cd1601fe73c066bf49cc09841f35348e3be0.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xad86d0e9764ba90ddd68747d64bffbd79879a238',
      symbol: 'PAID',
      decimals: 18,
      name: 'PAID Network',
      logoURI: 'https://tokens.1inch.io/0xad86d0e9764ba90ddd68747d64bffbd79879a238.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xac51066d7bec65dc4589368da368b212745d63e8',
      symbol: 'ALICE',
      decimals: 6,
      name: 'ALICE',
      logoURI: 'https://tokens.1inch.io/0xac51066d7bec65dc4589368da368b212745d63e8.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x60322971a672b81bcce5947706d22c19daecf6fb',
      symbol: 'MDAO',
      decimals: 18,
      name: 'MarsDAO',
      logoURI: 'https://tokens.1inch.io/0x60322971a672b81bcce5947706d22c19daecf6fb.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x90ed8f1dc86388f14b64ba8fb4bbd23099f18240',
      symbol: 'SDAO',
      decimals: 18,
      name: 'Singularity Dao',
      logoURI: 'https://tokens.1inch.io/0x90ed8f1dc86388f14b64ba8fb4bbd23099f18240.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x88d7e9b65dc24cf54f5edef929225fc3e1580c25',
      symbol: 'JMPT',
      decimals: 18,
      name: 'JumpToken',
      logoURI: 'https://tokens.1inch.io/0x88d7e9b65dc24cf54f5edef929225fc3e1580c25.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf275e1ac303a4c9d987a2c48b8e555a77fec3f1c',
      symbol: 'DPS',
      decimals: 9,
      name: 'DEEPSPACE',
      logoURI: 'https://tokens.1inch.io/0xf275e1ac303a4c9d987a2c48b8e555a77fec3f1c.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x9fd87aefe02441b123c3c32466cd9db4c578618f',
      symbol: 'THG',
      decimals: 18,
      name: 'Thetan Gem',
      logoURI: 'https://tokens.1inch.io/0x9fd87aefe02441b123c3c32466cd9db4c578618f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x398f7827dccbefe6990478876bbf3612d93baf05',
      symbol: 'MIX',
      decimals: 18,
      name: 'MixMarvel Token',
      logoURI: 'https://tokens.1inch.io/0x398f7827dccbefe6990478876bbf3612d93baf05.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd6fdde76b8c1c45b33790cc8751d5b88984c44ec',
      symbol: 'STRX',
      decimals: 18,
      name: 'StrikeX',
      logoURI: 'https://tokens.1inch.io/0xd6fdde76b8c1c45b33790cc8751d5b88984c44ec.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x76a797a59ba2c17726896976b7b3747bfd1d220f',
      symbol: 'TONCOIN',
      decimals: 9,
      name: 'Wrapped TON Coin',
      logoURI: 'https://tokens.1inch.io/0x76a797a59ba2c17726896976b7b3747bfd1d220f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x89af13a10b32f1b2f8d1588f93027f69b6f4e27e',
      symbol: 'GAFI',
      decimals: 18,
      name: 'GameFi',
      logoURI: 'https://tokens.1inch.io/0x89af13a10b32f1b2f8d1588f93027f69b6f4e27e.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x256d1fce1b1221e8398f65f9b36033ce50b2d497',
      symbol: 'wALV',
      decimals: 18,
      name: 'Alvey Chain',
      logoURI: 'https://tokens.1inch.io/0x256d1fce1b1221e8398f65f9b36033ce50b2d497.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x1bdd3cf7f79cfb8edbb955f20ad99211551ba275',
      symbol: 'BNBx',
      decimals: 18,
      name: 'Liquid Staking BNB',
      logoURI: 'https://tokens.1inch.io/0x1bdd3cf7f79cfb8edbb955f20ad99211551ba275.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4d2d32d8652058bf98c772953e1df5c5c85d9f45',
      symbol: 'DAO',
      decimals: 18,
      name: 'DAO Maker',
      logoURI: 'https://tokens.1inch.io/0x4d2d32d8652058bf98c772953e1df5c5c85d9f45.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x6d23970ce32cb0f1929bece7c56d71319e1b4f01',
      symbol: 'MFET',
      decimals: 8,
      name: 'Multi Functional Environmental Token',
      logoURI: 'https://tokens.1inch.io/0x6d23970ce32cb0f1929bece7c56d71319e1b4f01.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x6fefd97f328342a8a840546a55fdcfee7542f9a8',
      symbol: 'BTBS',
      decimals: 18,
      name: 'BitBase',
      logoURI: 'https://tokens.1inch.io/0x6fefd97f328342a8a840546a55fdcfee7542f9a8.png',
      eip2612: true,
    },
    {
      chainId: ChainId.BSC,
      address: '0x48077400faf11183c043feb5184a13ea628bb0db',
      symbol: 'ZIX',
      decimals: 18,
      name: 'Coinzix Token',
      logoURI: 'https://tokens.1inch.io/0x48077400faf11183c043feb5184a13ea628bb0db.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x12f31b73d812c6bb0d735a218c086d44d5fe5f89',
      symbol: 'agEUR',
      decimals: 18,
      name: 'agEUR',
      logoURI: 'https://tokens.1inch.io/0x12f31b73d812c6bb0d735a218c086d44d5fe5f89.png',
      eip2612: true,
    },
    {
      chainId: ChainId.BSC,
      address: '0xb6b91269413b6b99242b1c0bc611031529999999',
      symbol: 'CALO',
      decimals: 18,
      name: 'CALO',
      logoURI: 'https://tokens.1inch.io/0xb6b91269413b6b99242b1c0bc611031529999999.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xb3cb6d2f8f2fde203a022201c81a96c167607f15',
      symbol: 'GAMMA',
      decimals: 18,
      name: 'GAMMA',
      logoURI: 'https://tokens.1inch.io/0xb3cb6d2f8f2fde203a022201c81a96c167607f15.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xb3a6381070b1a15169dea646166ec0699fdaea79',
      symbol: 'Gold',
      decimals: 18,
      name: 'CyberDragon Gold',
      logoURI: 'https://tokens.1inch.io/0xb3a6381070b1a15169dea646166ec0699fdaea79.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xb346c52874c7023df183068c39478c3b7b2515bc',
      symbol: 'TruePNL',
      decimals: 18,
      name: 'PNL',
      logoURI: 'https://tokens.1inch.io/0xb346c52874c7023df183068c39478c3b7b2515bc.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xa83bfcf9e252adf1f39937984a4e113eda6e445b',
      symbol: 'PEFI',
      decimals: 18,
      name: 'Plant Empires Token',
      logoURI: 'https://tokens.1inch.io/0xa83bfcf9e252adf1f39937984a4e113eda6e445b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xb001f1e7c8bda414ac7cf7ecba5469fe8d24b6de',
      symbol: 'UCO',
      decimals: 18,
      name: 'UnirisToken',
      logoURI: 'https://tokens.1inch.io/0xb001f1e7c8bda414ac7cf7ecba5469fe8d24b6de.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xb09fe1613fe03e7361319d2a43edc17422f36b09',
      symbol: 'BOG',
      decimals: 18,
      name: 'Bogged Finance',
      logoURI: 'https://tokens.1inch.io/0xb09fe1613fe03e7361319d2a43edc17422f36b09.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xad29abb318791d579433d831ed122afeaf29dcfe',
      symbol: 'FTM',
      decimals: 18,
      name: 'Fantom',
      logoURI: 'https://tokens.1inch.io/0xad29abb318791d579433d831ed122afeaf29dcfe.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xac83271abb4ec95386f08ad2b904a46c61777cef',
      symbol: 'NFTD',
      decimals: 18,
      name: 'NFTrade Token [via ChainPort.io]',
      logoURI: 'https://tokens.1inch.io/0xac83271abb4ec95386f08ad2b904a46c61777cef.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe0f7c8682f865b417aeb80bf237025b4cb5ebaef',
      symbol: 'SWAP',
      decimals: 18,
      name: 'SatoshiSwap',
      logoURI: 'https://tokens.1inch.io/0xe0f7c8682f865b417aeb80bf237025b4cb5ebaef.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x52f24a5e03aee338da5fd9df68d2b6fae1178827',
      symbol: 'ankrBNB',
      decimals: 18,
      name: 'Ankr Staked BNB',
      logoURI: 'https://tokens.1inch.io/0x52f24a5e03aee338da5fd9df68d2b6fae1178827.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x2406dce4da5ab125a18295f4fb9fd36a0f7879a2',
      symbol: 'CPD',
      decimals: 18,
      name: 'Coinspaid',
      logoURI: 'https://tokens.1inch.io/0x2406dce4da5ab125a18295f4fb9fd36a0f7879a2.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xaec945e04baf28b135fa7c640f624f8d90f1c3a6',
      symbol: 'C98',
      decimals: 18,
      name: 'Coin98',
      logoURI: 'https://tokens.1inch.io/0xaec945e04baf28b135fa7c640f624f8d90f1c3a6.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x6ae9701b9c423f40d54556c9a443409d79ce170a',
      symbol: 'POLC',
      decimals: 18,
      name: 'Polka City',
      logoURI: 'https://tokens.1inch.io/0x6ae9701b9c423f40d54556c9a443409d79ce170a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xc632f90affec7121120275610bf17df9963f181c',
      symbol: 'DEBT',
      decimals: 8,
      name: 'DEBT',
      logoURI: 'https://tokens.1inch.io/0xc632f90affec7121120275610bf17df9963f181c.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991',
      symbol: 'AQUA',
      decimals: 18,
      name: 'AQUA',
      logoURI: 'https://tokens.1inch.io/0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xc1a59a17f87ba6651eb8e8f707db7672647c45bd',
      symbol: 'LNR',
      decimals: 18,
      name: 'Lunar',
      logoURI: 'https://tokens.1inch.io/0xc1a59a17f87ba6651eb8e8f707db7672647c45bd.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xc029a12e4a002c6858878fd9d3cc74e227cc2dda',
      symbol: 'VEX',
      decimals: 9,
      name: 'Velorex',
      logoURI: 'https://tokens.1inch.io/0xc029a12e4a002c6858878fd9d3cc74e227cc2dda.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xb6c53431608e626ac81a9776ac3e999c5556717c',
      symbol: 'TLOS',
      decimals: 18,
      name: 'pTokens TLOS',
      logoURI: 'https://tokens.1inch.io/0xb6c53431608e626ac81a9776ac3e999c5556717c.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xbe1a001fe942f96eea22ba08783140b9dcc09d28',
      symbol: 'BETA',
      decimals: 18,
      name: 'Beta Token',
      logoURI: 'https://tokens.1inch.io/0xbe1a001fe942f96eea22ba08783140b9dcc09d28.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xbd2949f67dcdc549c6ebe98696449fa79d988a9f',
      symbol: 'MTRG',
      decimals: 18,
      name: 'Wrapped MTRG on BSC by Meter.io',
      logoURI: 'https://tokens.1inch.io/0xbd2949f67dcdc549c6ebe98696449fa79d988a9f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xbcbdecf8e76a5c32dba69de16985882ace1678c6',
      symbol: 'RVC',
      decimals: 18,
      name: 'Revenue Coin',
      logoURI: 'https://tokens.1inch.io/0xbcbdecf8e76a5c32dba69de16985882ace1678c6.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x872a34ebb2d54af86827810eebc7b9dc6b2144aa',
      symbol: 'RVF',
      decimals: 18,
      name: 'RocketVaultRocketX',
      logoURI: 'https://tokens.1inch.io/0x872a34ebb2d54af86827810eebc7b9dc6b2144aa.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xdb021b1b247fe2f1fa57e0a87c748cc1e321f07f',
      symbol: 'AMPL',
      decimals: 9,
      name: 'AMPL secured by Meter Passport',
      logoURI: 'https://tokens.1inch.io/0xdb021b1b247fe2f1fa57e0a87c748cc1e321f07f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xdacc0417add48b63cbefb77efbe4a3801aad51ba',
      symbol: 'BZEN',
      decimals: 9,
      name: 'BITZEN',
      logoURI: 'https://tokens.1inch.io/0xdacc0417add48b63cbefb77efbe4a3801aad51ba.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd983ab71a284d6371908420d8ac6407ca943f810',
      symbol: 'ULX',
      decimals: 18,
      name: 'Ultron',
      logoURI: 'https://tokens.1inch.io/0xd983ab71a284d6371908420d8ac6407ca943f810.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd9de2b1973e57dc9dba90c35d6cd940ae4a3cbe1',
      symbol: 'MILO',
      decimals: 9,
      name: 'Milo Inu',
      logoURI: 'https://tokens.1inch.io/0xd9de2b1973e57dc9dba90c35d6cd940ae4a3cbe1.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd306c124282880858a634e7396383ae58d37c79c',
      symbol: 'WAL',
      decimals: 18,
      name: 'WastedLands',
      logoURI: 'https://tokens.1inch.io/0xd306c124282880858a634e7396383ae58d37c79c.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xce186ad6430e2fe494a22c9edbd4c68794a28b35',
      symbol: 'LOOP',
      decimals: 18,
      name: 'LoopNetwork',
      logoURI: 'https://tokens.1inch.io/0xce186ad6430e2fe494a22c9edbd4c68794a28b35.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
      symbol: 'MATIC',
      decimals: 18,
      name: 'Matic Token',
      logoURI: 'https://tokens.1inch.io/0xcc42724c6683b7e57334c4e856f4c9965ed682bd.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xc9882def23bc42d53895b8361d0b1edc7570bc6a',
      symbol: 'FIST',
      decimals: 6,
      name: 'FistToken',
      logoURI: 'https://tokens.1inch.io/0xc9882def23bc42d53895b8361d0b1edc7570bc6a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x66109633715d2110dda791e64a7b2afadb517abb',
      symbol: 'GAME',
      decimals: 5,
      name: 'Game Coin [via ChainPort.io]',
      logoURI: 'https://tokens.1inch.io/0x66109633715d2110dda791e64a7b2afadb517abb.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x799e1cf88a236e42b4a87c544a22a94ae95a6910',
      symbol: 'MCONTENT',
      decimals: 6,
      name: 'MContent',
      logoURI: 'https://tokens.1inch.io/0x799e1cf88a236e42b4a87c544a22a94ae95a6910.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xcd1faff6e578fa5cac469d2418c95671ba1a62fe',
      symbol: 'XTM',
      decimals: 18,
      name: 'Torum',
      logoURI: 'https://tokens.1inch.io/0xcd1faff6e578fa5cac469d2418c95671ba1a62fe.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
      symbol: 'LIQ',
      decimals: 18,
      name: 'Liquidus',
      logoURI: 'https://tokens.1inch.io/0xc7981767f644c7f8e483dabdc413e8a371b83079.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xc748673057861a797275cd8a068abb95a902e8de',
      symbol: 'BabyDoge',
      decimals: 9,
      name: 'Baby Doge Coin',
      logoURI: 'https://tokens.1inch.io/0xc748673057861a797275cd8a068abb95a902e8de.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x83b79f74f225e8f9a29fc67cb1678e7909d7d73d',
      symbol: 'AVA',
      decimals: 18,
      name: 'Avatly',
      logoURI: 'https://tokens.1inch.io/0x83b79f74f225e8f9a29fc67cb1678e7909d7d73d.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xbd83010eb60f12112908774998f65761cf9f6f9a',
      symbol: 'STARS',
      decimals: 18,
      name: 'Mogul Stars',
      logoURI: 'https://tokens.1inch.io/0xbd83010eb60f12112908774998f65761cf9f6f9a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x77087ab5df23cfb52449a188e80e9096201c2097',
      symbol: 'HI',
      decimals: 18,
      name: 'hi Dollar',
      logoURI: 'https://tokens.1inch.io/0x77087ab5df23cfb52449a188e80e9096201c2097.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xc17c30e98541188614df99239cabd40280810ca3',
      symbol: 'RISE',
      decimals: 18,
      name: 'EverRise',
      logoURI: 'https://tokens.1inch.io/0xc17c30e98541188614df99239cabd40280810ca3.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x361c60b7c2828fcab80988d00d1d542c83387b50',
      symbol: 'DFI',
      decimals: 18,
      name: 'DFI (DefiChain)',
      logoURI: 'https://tokens.1inch.io/0x361c60b7c2828fcab80988d00d1d542c83387b50.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xa4838122c683f732289805fc3c207febd55babdd',
      symbol: 'TRIAS',
      decimals: 18,
      name: 'Trias Token',
      logoURI: 'https://tokens.1inch.io/0xa4838122c683f732289805fc3c207febd55babdd.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x9c67638c4fa06fd47fb8900fc7f932f7eab589de',
      symbol: 'ARKER',
      decimals: 18,
      name: 'ARKER',
      logoURI: 'https://tokens.1inch.io/0x9c67638c4fa06fd47fb8900fc7f932f7eab589de.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x9a3321e1acd3b9f6debee5e042dd2411a1742002',
      symbol: 'AFP',
      decimals: 18,
      name: 'PIGS Token',
      logoURI: 'https://tokens.1inch.io/0x9a3321e1acd3b9f6debee5e042dd2411a1742002.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xde3dbbe30cfa9f437b293294d1fd64b26045c71a',
      symbol: 'NFTB',
      decimals: 18,
      name: 'NFTB',
      logoURI: 'https://tokens.1inch.io/0xde3dbbe30cfa9f437b293294d1fd64b26045c71a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd40bedb44c081d2935eeba6ef5a3c8a31a1bbe13',
      symbol: 'HERO',
      decimals: 18,
      name: 'Metahero',
      logoURI: 'https://tokens.1inch.io/0xd40bedb44c081d2935eeba6ef5a3c8a31a1bbe13.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xbc7370641ddcf16a27eea11230af4a9f247b61f9',
      symbol: 'XETA',
      decimals: 18,
      name: 'XANA',
      logoURI: 'https://tokens.1inch.io/0xbc7370641ddcf16a27eea11230af4a9f247b61f9.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x949d48eca67b17269629c7194f4b727d4ef9e5d6',
      symbol: 'MC',
      decimals: 18,
      name: 'Merit Circle',
      logoURI: 'https://tokens.1inch.io/0x949d48eca67b17269629c7194f4b727d4ef9e5d6.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x8db1d28ee0d822367af8d220c0dc7cb6fe9dc442',
      symbol: 'ETHPAD',
      decimals: 18,
      name: 'ETHPAD.network',
      logoURI: 'https://tokens.1inch.io/0x8db1d28ee0d822367af8d220c0dc7cb6fe9dc442.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe60eaf5a997dfae83739e035b005a33afdcc6df5',
      symbol: 'DERI',
      decimals: 18,
      name: 'Deri',
      logoURI: 'https://tokens.1inch.io/0xe60eaf5a997dfae83739e035b005a33afdcc6df5.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe4cc45bb5dbda06db6183e8bf016569f40497aa5',
      symbol: 'GAL',
      decimals: 18,
      name: 'Project Galaxy',
      logoURI: 'https://tokens.1inch.io/0xe4cc45bb5dbda06db6183e8bf016569f40497aa5.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe4c797d43631f4d660ec67b5cb0b78ef5c902532',
      symbol: 'MONS',
      decimals: 18,
      name: 'Monsters Clan Token',
      logoURI: 'https://tokens.1inch.io/0xe4c797d43631f4d660ec67b5cb0b78ef5c902532.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe38950f71e2d2fc4ca9dc9c3625d82560b0a5d8f',
      symbol: 'NELO',
      decimals: 9,
      name: 'NELO Metaverse',
      logoURI: 'https://tokens.1inch.io/0xe38950f71e2d2fc4ca9dc9c3625d82560b0a5d8f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe369fec23380f9f14ffd07a1dc4b7c1a9fdd81c9',
      symbol: 'Froyo',
      decimals: 18,
      name: 'Froyo',
      logoURI: 'https://tokens.1inch.io/0xe369fec23380f9f14ffd07a1dc4b7c1a9fdd81c9.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe0f94ac5462997d2bc57287ac3a3ae4c31345d66',
      symbol: 'CEEK',
      decimals: 18,
      name: 'CEEK',
      logoURI: 'https://tokens.1inch.io/0xe0f94ac5462997d2bc57287ac3a3ae4c31345d66.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x570a5d26f7765ecb712c0924e4de545b89fd43df',
      symbol: 'SOL',
      decimals: 18,
      name: 'SOLANA',
      logoURI: 'https://tokens.1inch.io/0x570a5d26f7765ecb712c0924e4de545b89fd43df.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xbbca42c60b5290f2c48871a596492f93ff0ddc82',
      symbol: 'DOMI',
      decimals: 18,
      name: 'Domi',
      logoURI: 'https://tokens.1inch.io/0xbbca42c60b5290f2c48871a596492f93ff0ddc82.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4b8285ab433d8f69cb48d5ad62b415ed1a221e4f',
      symbol: 'MCRT',
      decimals: 9,
      name: 'MagicCraft',
      logoURI: 'https://tokens.1inch.io/0x4b8285ab433d8f69cb48d5ad62b415ed1a221e4f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xdfd7b0dd7bf1012dfdf3307a964c36b972300ac8',
      symbol: 'WNDR',
      decimals: 8,
      name: 'Wonderman Token',
      logoURI: 'https://tokens.1inch.io/0xdfd7b0dd7bf1012dfdf3307a964c36b972300ac8.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd8a2ae43fd061d24acd538e3866ffc2c05151b53',
      symbol: 'AIR',
      decimals: 18,
      name: 'AIR',
      logoURI: 'https://tokens.1inch.io/0xd8a2ae43fd061d24acd538e3866ffc2c05151b53.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd74b782e05aa25c50e7330af541d46e18f36661c',
      symbol: 'QUACK',
      decimals: 9,
      name: 'RichQUACK.com',
      logoURI: 'https://tokens.1inch.io/0xd74b782e05aa25c50e7330af541d46e18f36661c.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xc6dddb5bc6e61e0841c54f3e723ae1f3a807260b',
      symbol: 'URUS',
      decimals: 18,
      name: 'Aurox Token',
      logoURI: 'https://tokens.1inch.io/0xc6dddb5bc6e61e0841c54f3e723ae1f3a807260b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd44fd09d74cd13838f137b590497595d6b3feea4',
      symbol: 'ETERNAL',
      decimals: 18,
      name: 'CryptoMines Eternal',
      logoURI: 'https://tokens.1inch.io/0xd44fd09d74cd13838f137b590497595d6b3feea4.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd41c4805a9a3128f9f7a7074da25965371ba50d5',
      symbol: 'COINSCOPE',
      decimals: 18,
      name: 'Coinscope',
      logoURI: 'https://tokens.1inch.io/0xd41c4805a9a3128f9f7a7074da25965371ba50d5.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x7ddc52c4de30e94be3a6a0a2b259b2850f421989',
      symbol: 'GMT_1',
      decimals: 18,
      name: 'GoMining Token',
      logoURI: 'https://tokens.1inch.io/0x7ddc52c4de30e94be3a6a0a2b259b2850f421989.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xb64e280e9d1b5dbec4accedb2257a87b400db149',
      symbol: 'LVL',
      decimals: 18,
      name: 'Level Token',
      logoURI: 'https://tokens.1inch.io/0xb64e280e9d1b5dbec4accedb2257a87b400db149.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x9fb9a33956351cf4fa040f65a13b835a3c8764e3',
      symbol: 'MULTI',
      decimals: 18,
      name: 'Multichain',
      logoURI: 'https://tokens.1inch.io/0x9fb9a33956351cf4fa040f65a13b835a3c8764e3.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf98b660adf2ed7d9d9d9daacc2fb0cace4f21835',
      symbol: 'SIS',
      decimals: 18,
      name: 'Symbiosis',
      logoURI: 'https://tokens.1inch.io/0xf98b660adf2ed7d9d9d9daacc2fb0cace4f21835.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf48f91df403976060cc05dbbf8a0901b09fdefd4',
      symbol: 'MINU',
      decimals: 18,
      name: 'Minu',
      logoURI: 'https://tokens.1inch.io/0xf48f91df403976060cc05dbbf8a0901b09fdefd4.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x9085b4d52c3e0b8b6f9af6213e85a433c7d76f19',
      symbol: 'OWL',
      decimals: 18,
      name: 'OwlDAO token',
      logoURI: 'https://tokens.1inch.io/0x9085b4d52c3e0b8b6f9af6213e85a433c7d76f19.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x1613957159e9b0ac6c80e824f7eea748a32a0ae2',
      symbol: 'CGG',
      decimals: 18,
      name: 'pTokens CGG',
      logoURI: 'https://tokens.1inch.io/0x1613957159e9b0ac6c80e824f7eea748a32a0ae2.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x9131066022b909c65edd1aaf7ff213dacf4e86d0',
      symbol: 'LAND_2',
      decimals: 18,
      name: 'META-UTOPIA LAND',
      logoURI: 'https://tokens.1inch.io/0x9131066022b909c65edd1aaf7ff213dacf4e86d0.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xb0d502e938ed5f4df2e681fe6e419ff29631d62b',
      symbol: 'STG',
      decimals: 18,
      name: 'StargateToken',
      logoURI: 'https://tokens.1inch.io/0xb0d502e938ed5f4df2e681fe6e419ff29631d62b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x602ba546a7b06e0fc7f58fd27eb6996ecc824689',
      symbol: 'PINKSALE',
      decimals: 18,
      name: 'PinkSale',
      logoURI: 'https://tokens.1inch.io/0x602ba546a7b06e0fc7f58fd27eb6996ecc824689.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xffba7529ac181c2ee1844548e6d7061c9a597df4',
      symbol: 'CAPS',
      decimals: 18,
      name: 'Capsule Coin',
      logoURI: 'https://tokens.1inch.io/0xffba7529ac181c2ee1844548e6d7061c9a597df4.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xfe19f0b51438fd612f6fd59c1dbb3ea319f433ba',
      symbol: 'MIM',
      decimals: 18,
      name: 'Magic Internet Money',
      logoURI: 'https://tokens.1inch.io/0xfe19f0b51438fd612f6fd59c1dbb3ea319f433ba.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xfdd2374be7ae7a71138b9f6b93d9ef034a49edb6',
      symbol: 'VRGW',
      decimals: 18,
      name: 'Virtual Reality Game World',
      logoURI: 'https://tokens.1inch.io/0xfdd2374be7ae7a71138b9f6b93d9ef034a49edb6.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      symbol: 'BNB',
      decimals: 18,
      name: 'BNB',
      logoURI: 'https://tokens.1inch.io/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c.png',
      tags: ['native'],
    },
    {
      chainId: ChainId.BSC,
      address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      symbol: 'WBNB',
      decimals: 18,
      name: 'Wrapped BNB',
      logoURI: 'https://tokens.1inch.io/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c.png',
      tags: ['PEG:BNB', 'tokens'],
    },
    {
      chainId: ChainId.BSC,
      address: '0x55d398326f99059ff775485246999027b3197955',
      symbol: 'USDT',
      decimals: 18,
      name: 'Tether USD',
      logoURI: 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.BSC,
      address: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
      symbol: 'CAKE',
      decimals: 18,
      name: 'PancakeSwap Token',
      logoURI: 'https://tokens.1inch.io/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      symbol: 'BUSD',
      decimals: 18,
      name: 'BUSD Token',
      logoURI: 'https://tokens.1inch.io/0x4fabb145d64652a948d72533023f6e7a623c7c53.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.BSC,
      address: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
      symbol: 'ETH',
      decimals: 18,
      name: 'Ethereum Token',
      logoURI: 'https://tokens.1inch.io/0x2170ed0880ac9a755fd29b2688956bd959f933f8.png',
      tags: ['PEG:ETH', 'tokens'],
    },
    {
      chainId: ChainId.BSC,
      address: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
      symbol: 'BTCB',
      decimals: 18,
      name: 'BTCB Token',
      logoURI: 'https://tokens.1inch.io/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xa184088a740c695e156f91f5cc086a06bb78b827',
      symbol: 'AUTO',
      decimals: 18,
      name: 'AUTOv2',
      logoURI: 'https://tokens.1inch.io/0xa184088a740c695e156f91f5cc086a06bb78b827.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x190b589cf9fb8ddeabbfeae36a813ffb2a702454',
      symbol: 'BDO',
      decimals: 18,
      name: 'bDollar',
      logoURI: 'https://tokens.1inch.io/0x190b589cf9fb8ddeabbfeae36a813ffb2a702454.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
      symbol: 'DOT',
      decimals: 18,
      name: 'DOT',
      logoURI: 'https://tokens.1inch.io/0x7083609fce4d1d8dc0c979aab8c869ea2c873402.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
      symbol: 'UST',
      decimals: 18,
      name: 'Wrapped UST Token',
      logoURI: 'https://tokens.1inch.io/0xa47c8bf37f92abed4a126bda807a7b7498661acd.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4bd17003473389a42daf6a0a729f6fdb328bbbd7',
      symbol: 'VAI',
      decimals: 18,
      name: 'VAI Stablecoin',
      logoURI: 'https://tokens.1inch.io/0x4bd17003473389a42daf6a0a729f6fdb328bbbd7.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xbf5140a22578168fd562dccf235e5d43a02ce9b1',
      symbol: 'UNI',
      decimals: 18,
      name: 'Uniswap',
      logoURI: 'https://tokens.1inch.io/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
      symbol: 'LINK',
      decimals: 18,
      name: 'ChainLink Token',
      logoURI: 'https://tokens.1inch.io/0x514910771af9ca656af840dff83e8264ecf986ca.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      symbol: 'USDC',
      decimals: 18,
      name: 'USD Coin',
      logoURI: 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.BSC,
      address: '0x5cd50aae14e14b3fdf3ff13c7a40e8cf5ae8b0a5',
      symbol: 'zSEED',
      decimals: 18,
      name: 'zSeedToken',
      logoURI: 'https://tokens.1inch.io/0x5cd50aae14e14b3fdf3ff13c7a40e8cf5ae8b0a5.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
      symbol: 'DAI',
      decimals: 18,
      name: 'Dai Token',
      logoURI: 'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.BSC,
      address: '0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18',
      symbol: 'BAND',
      decimals: 18,
      name: 'Band Protocol Token',
      logoURI: 'https://tokens.1inch.io/0xba11d00c5f74255f56a5e366f4f77f5a186d7f55.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x8b303d5bbfbbf46f1a4d9741e491e06986894e18',
      symbol: 'WOOP',
      decimals: 18,
      name: 'Woonkly Power',
      logoURI: 'https://tokens.1inch.io/0x8b303d5bbfbbf46f1a4d9741e491e06986894e18.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x0d9319565be7f53cefe84ad201be3f40feae2740',
      symbol: 'sBDO',
      decimals: 18,
      name: 'bDollar Share',
      logoURI: 'https://tokens.1inch.io/0x0d9319565be7f53cefe84ad201be3f40feae2740.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
      symbol: 'ADA',
      decimals: 18,
      name: 'Cardano Token',
      logoURI: 'https://tokens.1inch.io/0x3ee2200efb3400fabb9aacf31297cbdd1d435d47.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb',
      symbol: 'SFP',
      decimals: 18,
      name: 'SafePal Token',
      logoURI: 'https://tokens.1inch.io/0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x2090c8295769791ab7a3cf1cc6e0aa19f35e441a',
      symbol: 'Fuel',
      decimals: 18,
      name: 'Fuel Token',
      logoURI: 'https://tokens.1inch.io/0x2090c8295769791ab7a3cf1cc6e0aa19f35e441a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
      symbol: 'XVS',
      decimals: 18,
      name: 'Venus',
      logoURI: 'https://tokens.1inch.io/0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4b0f1812e5df2a09796481ff14017e6005508003',
      symbol: 'TWT',
      decimals: 18,
      name: 'Trust Wallet',
      logoURI: 'https://tokens.1inch.io/0x4b0f1812e5df2a09796481ff14017e6005508003.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      symbol: 'EGG',
      decimals: 18,
      name: 'Goose Golden Egg',
      logoURI: 'https://tokens.1inch.io/0xf952fc3ca7325cc27d15885d37117676d25bfda6.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf859bf77cbe8699013d6dbc7c2b926aaf307f830',
      symbol: 'BRY',
      decimals: 18,
      name: 'Berry Tributes',
      logoURI: 'https://tokens.1inch.io/0xf859bf77cbe8699013d6dbc7c2b926aaf307f830.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x47bead2563dcbf3bf2c9407fea4dc236faba485a',
      symbol: 'SXP',
      decimals: 18,
      name: 'Swipe',
      logoURI: 'https://tokens.1inch.io/0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
      symbol: 'XRP',
      decimals: 18,
      name: 'XRP Token',
      logoURI: 'https://tokens.1inch.io/0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x52ce071bd9b1c4b00a0b92d298c512478cad67e8',
      symbol: 'COMP',
      decimals: 18,
      name: 'Compound Coin',
      logoURI: 'https://tokens.1inch.io/0xc00e94cb662c3520282e6f5717214004a7f26888.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4197c6ef3879a08cd51e5560da5064b773aa1d29',
      symbol: 'ACS',
      decimals: 18,
      name: 'ACryptoS',
      logoURI: 'https://tokens.1inch.io/0x4197c6ef3879a08cd51e5560da5064b773aa1d29.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e',
      symbol: 'REEF',
      decimals: 18,
      name: 'Reef.finance',
      logoURI: 'https://tokens.1inch.io/0xfe3e6a25e6b192a42a44ecddcd13796471735acf.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xa1faa113cbe53436df28ff0aee54275c13b40975',
      symbol: 'ALPHA',
      decimals: 18,
      name: 'AlphaToken',
      logoURI: 'https://tokens.1inch.io/0xa1faa113cbe53436df28ff0aee54275c13b40975_1.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xa2b726b1145a4773f68593cf171187d8ebe4d495',
      symbol: 'INJ',
      decimals: 18,
      name: 'Injective Protocol',
      logoURI: 'https://tokens.1inch.io/0xe28b3b32b6c345a34ff64674606124dd5aceca30.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x56b6fb708fc5732dec1afc8d8556423a2edccbd6',
      symbol: 'EOS',
      decimals: 18,
      name: 'EOS Token',
      logoURI: 'https://tokens.1inch.io/0x56b6fb708fc5732dec1afc8d8556423a2edccbd6.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51',
      symbol: 'BUNNY',
      decimals: 18,
      name: 'Bunny Token',
      logoURI: 'https://tokens.1inch.io/0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
      symbol: 'LTC',
      decimals: 18,
      name: 'Litecoin Token',
      logoURI: 'https://tokens.1inch.io/0x4338665cbb7b2485a8855a139b75d5e34ab0db94.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xb59490ab09a0f526cc7305822ac65f2ab12f9723',
      symbol: 'LIT',
      decimals: 18,
      name: 'Litentry',
      logoURI: 'https://tokens.1inch.io/0xb59490ab09a0f526cc7305822ac65f2ab12f9723.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x8ff795a6f4d97e7887c79bea79aba5cc76444adf',
      symbol: 'BCH',
      decimals: 18,
      name: 'Bitcoin Cash Token',
      logoURI: 'https://tokens.1inch.io/0x8ff795a6f4d97e7887c79bea79aba5cc76444adf.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x948d2a81086a075b3130bac19e4c6dee1d2e3fe8',
      symbol: 'Helmet',
      decimals: 18,
      name: 'Helmet.insure Governance Token',
      logoURI: 'https://tokens.1inch.io/0x948d2a81086a075b3130bac19e4c6dee1d2e3fe8.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x928e55dab735aa8260af3cedada18b5f70c72f1b',
      symbol: 'FRONT',
      decimals: 18,
      name: 'Frontier Token',
      logoURI: 'https://tokens.1inch.io/0xf8c3527cc04340b208c854e985240c02f7b7793f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x78650b139471520656b9e7aa7a5e9276814a38e9',
      symbol: 'BTCST',
      decimals: 17,
      name: 'StandardBTCHashrateToken',
      logoURI: 'https://tokens.1inch.io/0x78650b139471520656b9e7aa7a5e9276814a38e9.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153',
      symbol: 'FIL',
      decimals: 18,
      name: 'Filecoin',
      logoURI: 'https://tokens.1inch.io/0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x0eb3a705fc54725037cc9e008bdede697f62f335',
      symbol: 'ATOM',
      decimals: 18,
      name: 'Cosmos Token',
      logoURI: 'https://tokens.1inch.io/0x0eb3a705fc54725037cc9e008bdede697f62f335.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x111111111117dc0aa78b770fa6a738034120c302',
      symbol: '1INCH',
      decimals: 18,
      name: '1INCH Token',
      logoURI: 'https://tokens.1inch.io/0x111111111117dc0aa78b770fa6a738034120c302.png',
      eip2612: true,
    },
    {
      chainId: ChainId.BSC,
      address: '0x2ff3d0f6990a40261c66e1ff2017acbc282eb6d0',
      symbol: 'vSXP',
      decimals: 8,
      name: 'Venus SXP',
      logoURI: 'https://tokens.1inch.io/0x2ff3d0f6990a40261c66e1ff2017acbc282eb6d0.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xeca88125a5adbe82614ffc12d0db554e2e2867c8',
      symbol: 'vUSDC',
      decimals: 8,
      name: 'Venus USDC',
      logoURI: 'https://tokens.1inch.io/0xeca88125a5adbe82614ffc12d0db554e2e2867c8.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.BSC,
      address: '0xfd5840cd36d94d7229439859c0112a4185bc0255',
      symbol: 'vUSDT',
      decimals: 8,
      name: 'Venus USDT',
      logoURI: 'https://tokens.1inch.io/0xfd5840cd36d94d7229439859c0112a4185bc0255.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.BSC,
      address: '0x95c78222b3d6e262426483d42cfa53685a67ab9d',
      symbol: 'vBUSD_2',
      decimals: 8,
      name: 'Venus BUSD',
      logoURI: 'https://tokens.1inch.io/0x95c78222b3d6e262426483d42cfa53685a67ab9d.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.BSC,
      address: '0xa07c5b74c9b40447a954e1466938b865b6bbea36',
      symbol: 'vBNB',
      decimals: 8,
      name: 'Venus BNB',
      logoURI: 'https://tokens.1inch.io/0xa07c5b74c9b40447a954e1466938b865b6bbea36.png',
      tags: ['PEG:BNB', 'tokens'],
    },
    {
      chainId: ChainId.BSC,
      address: '0x151b1e2635a717bcdc836ecd6fbb62b674fe3e1d',
      symbol: 'vXVS',
      decimals: 8,
      name: 'Venus XVS',
      logoURI: 'https://tokens.1inch.io/0x151b1e2635a717bcdc836ecd6fbb62b674fe3e1d.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x882c173bc7ff3b7786ca16dfed3dfffb9ee7847b',
      symbol: 'vBTC',
      decimals: 8,
      name: 'Venus BTC',
      logoURI: 'https://tokens.1inch.io/0x882c173bc7ff3b7786ca16dfed3dfffb9ee7847b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf508fcd89b8bd15579dc79a6827cb4686a3592c8',
      symbol: 'vETH',
      decimals: 8,
      name: 'Venus ETH',
      logoURI: 'https://tokens.1inch.io/0xf508fcd89b8bd15579dc79a6827cb4686a3592c8.png',
      tags: ['PEG:ETH', 'tokens'],
    },
    {
      chainId: ChainId.BSC,
      address: '0x57a5297f2cb2c0aac9d554660acd6d385ab50c6b',
      symbol: 'vLTC',
      decimals: 8,
      name: 'Venus LTC',
      logoURI: 'https://tokens.1inch.io/0x57a5297f2cb2c0aac9d554660acd6d385ab50c6b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xb248a295732e0225acd3337607cc01068e3b9c10',
      symbol: 'vXRP',
      decimals: 8,
      name: 'Venus XRP',
      logoURI: 'https://tokens.1inch.io/0xb248a295732e0225acd3337607cc01068e3b9c10.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x5f0388ebc2b94fa8e123f404b79ccf5f40b29176',
      symbol: 'vBCH',
      decimals: 8,
      name: 'Venus BCH',
      logoURI: 'https://tokens.1inch.io/0x5f0388ebc2b94fa8e123f404b79ccf5f40b29176.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x1610bc33319e9398de5f57b33a5b184c806ad217',
      symbol: 'vDOT',
      decimals: 8,
      name: 'Venus DOT',
      logoURI: 'https://tokens.1inch.io/0x1610bc33319e9398de5f57b33a5b184c806ad217.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x650b940a1033b8a1b1873f78730fcfc73ec11f1f',
      symbol: 'vLINK',
      decimals: 8,
      name: 'Venus LINK',
      logoURI: 'https://tokens.1inch.io/0x650b940a1033b8a1b1873f78730fcfc73ec11f1f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x972207a639cc1b374b893cc33fa251b55ceb7c07',
      symbol: 'vBETH',
      decimals: 8,
      name: 'Venus BETH',
      logoURI: 'https://tokens.1inch.io/0x972207a639cc1b374b893cc33fa251b55ceb7c07.png',
      tags: ['PEG:ETH', 'tokens'],
    },
    {
      chainId: ChainId.BSC,
      address: '0x334b3ecb4dca3593bccc3c7ebd1a1c1d1780fbf1',
      symbol: 'vDAI',
      decimals: 8,
      name: 'Venus DAI',
      logoURI: 'https://tokens.1inch.io/0x334b3ecb4dca3593bccc3c7ebd1a1c1d1780fbf1.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf91d58b5ae142dacc749f58a49fcbac340cb0343',
      symbol: 'vFIL',
      decimals: 8,
      name: 'Venus FIL',
      logoURI: 'https://tokens.1inch.io/0xf91d58b5ae142dacc749f58a49fcbac340cb0343.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x250632378e573c6be1ac2f97fcdf00515d0aa91b',
      symbol: 'BETH',
      decimals: 18,
      name: 'Binance Beacon ETH',
      logoURI: 'https://tokens.1inch.io/0x250632378e573c6be1ac2f97fcdf00515d0aa91b.png',
      tags: ['PEG:ETH', 'tokens'],
    },
    {
      chainId: ChainId.BSC,
      address: '0xe02df9e3e622debdd69fb838bb799e3f168902c5',
      symbol: 'BAKE',
      decimals: 18,
      name: 'BakeryToken',
      logoURI: 'https://tokens.1inch.io/0xe02df9e3e622debdd69fb838bb799e3f168902c5.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xfce146bf3146100cfe5db4129cf6c82b0ef4ad8c',
      symbol: 'renBTC',
      decimals: 8,
      name: 'renBTC',
      logoURI: 'https://tokens.1inch.io/0xfce146bf3146100cfe5db4129cf6c82b0ef4ad8c.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
      symbol: 'BANANA',
      decimals: 18,
      name: 'ApeSwapFinance Banana',
      logoURI: 'https://tokens.1inch.io/0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x1ba42e5193dfa8b03d15dd1b86a3113bbbef8eeb',
      symbol: 'ZEC',
      decimals: 18,
      name: 'Zcash Token',
      logoURI: 'https://tokens.1inch.io/0x1ba42e5193dfa8b03d15dd1b86a3113bbbef8eeb.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x1fa4a73a3f0133f0025378af00236f3abdee5d63',
      symbol: 'NEAR',
      decimals: 18,
      name: 'NEAR Protocol',
      logoURI: 'https://tokens.1inch.io/0x1fa4a73a3f0133f0025378af00236f3abdee5d63.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x3d6545b08693dae087e957cb1180ee38b9e3c25e',
      symbol: 'ETC',
      decimals: 18,
      name: 'Ethereum Classic',
      logoURI: 'https://tokens.1inch.io/0x3d6545b08693dae087e957cb1180ee38b9e3c25e.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xfd7b3a77848f1c2d67e05e54d78d174a0c850335',
      symbol: 'ONT',
      decimals: 18,
      name: 'Ontology Token',
      logoURI: 'https://tokens.1inch.io/0xfd7b3a77848f1c2d67e05e54d78d174a0c850335.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x101d82428437127bf1608f699cd651e6abf9766e',
      symbol: 'BAT',
      decimals: 18,
      name: 'Basic Attention Token',
      logoURI: 'https://tokens.1inch.io/0x0d8775f648430679a709e98d2b0cb6250d2887ef.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xb7f8cd00c5a06c0537e2abff0b58033d02e5e094',
      symbol: 'PAX',
      decimals: 18,
      name: 'Paxos Standard',
      logoURI: 'https://tokens.1inch.io/0x8e870d67f660d95d5be530380d0ec0bd388289e1.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2',
      symbol: 'DODO',
      decimals: 18,
      name: 'DODO bird',
      logoURI: 'https://tokens.1inch.io/0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x9678e42cebeb63f23197d726b29b1cb20d0064e5',
      symbol: 'IOTX',
      decimals: 18,
      name: 'IoTeX Network',
      logoURI: 'https://tokens.1inch.io/0x9678e42cebeb63f23197d726b29b1cb20d0064e5.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf307910a4c7bbc79691fd374889b36d8531b08e3',
      symbol: 'ANKR',
      decimals: 18,
      name: 'Ankr',
      logoURI: 'https://tokens.1inch.io/0x8290333cef9e6d528dd5618fb97a76f268f3edd4.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x762539b45a1dcce3d36d080f74d1aed37844b878',
      symbol: 'LINA',
      decimals: 18,
      name: 'Linear Token',
      logoURI: 'https://tokens.1inch.io/0x3e9bc21c9b189c09df3ef1b824798658d5011937.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf218184af829cf2b0019f8e6f0b2423498a36983',
      symbol: 'MATH',
      decimals: 18,
      name: 'MATH Token',
      logoURI: 'https://tokens.1inch.io/0x08d967bb0134f2d07f7cfb6e246680c53927dd30.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x857b222fc79e1cbbf8ca5f78cb133d1b7cf34bbd',
      symbol: 'LTO',
      decimals: 18,
      name: 'LTO Network',
      logoURI: 'https://tokens.1inch.io/0x3db6ba6ab6f95efed1a6e794cad492faaabf294d.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x3910db0600ea925f63c36ddb1351ab6e2c6eb102',
      symbol: 'SPARTA',
      decimals: 18,
      name: 'Spartan Protocol Token',
      logoURI: 'https://tokens.1inch.io/0x3910db0600ea925f63c36ddb1351ab6e2c6eb102.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf68c9df95a18b2a5a5fa1124d79eeeffbad0b6fa',
      symbol: 'ANY',
      decimals: 18,
      name: 'Anyswap-BEP20',
      logoURI: 'https://tokens.1inch.io/0xf99d58e463a2e07e5692127302c20a191861b4d6.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x5a41f637c3f7553dba6ddc2d3ca92641096577ea',
      symbol: 'JulD',
      decimals: 18,
      name: 'JulSwap',
      logoURI: 'https://tokens.1inch.io/0x5a41f637c3f7553dba6ddc2d3ca92641096577ea.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xae9269f27437f0fcbc232d39ec814844a51d6b8f',
      symbol: 'BURGER',
      decimals: 18,
      name: 'Burger Swap',
      logoURI: 'https://tokens.1inch.io/0xae9269f27437f0fcbc232d39ec814844a51d6b8f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xa8c2b8eec3d368c0253ad3dae65a5f2bbb89c929',
      symbol: 'CTK',
      decimals: 6,
      name: 'CertiK Token',
      logoURI: 'https://tokens.1inch.io/0xa8c2b8eec3d368c0253ad3dae65a5f2bbb89c929.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x12e34cdf6a031a10fe241864c32fb03a4fdad739',
      symbol: 'FREE',
      decimals: 18,
      name: 'FREE coin BSC',
      logoURI: 'https://tokens.1inch.io/0x12e34cdf6a031a10fe241864c32fb03a4fdad739.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4',
      symbol: 'BHC',
      decimals: 18,
      name: 'Billion Happiness',
      logoURI: 'https://tokens.1inch.io/0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd1102332a213e21faf78b69c03572031f3552c33',
      symbol: 'BTD',
      decimals: 18,
      name: 'Bolt Dollar',
      logoURI: 'https://tokens.1inch.io/0xd1102332a213e21faf78b69c03572031f3552c33.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x32dffc3fe8e3ef3571bf8a72c0d0015c5373f41d',
      symbol: 'JULb',
      decimals: 18,
      name: 'JULb',
      logoURI: 'https://tokens.1inch.io/0x32dffc3fe8e3ef3571bf8a72c0d0015c5373f41d.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xba2ae424d960c26247dd6c32edc70b295c744c43',
      symbol: 'DOGE',
      decimals: 8,
      name: 'Dogecoin',
      logoURI: 'https://tokens.1inch.io/0xba2ae424d960c26247dd6c32edc70b295c744c43.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
      symbol: 'ALPACA',
      decimals: 18,
      name: 'AlpacaToken',
      logoURI: 'https://tokens.1inch.io/0x8f0528ce5ef7b51152a59745befdd91d97091d2f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd632bd021a07af70592ce1e18717ab9aa126decb',
      symbol: 'bKANGAL',
      decimals: 18,
      name: 'Kangal',
      logoURI: 'https://tokens.1inch.io/0xd632bd021a07af70592ce1e18717ab9aa126decb.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd2ddfba7bb12f6e70c2aab6b6bf9edaef42ed22f',
      symbol: 'UBU',
      decimals: 18,
      name: 'UBUToken',
      logoURI: 'https://tokens.1inch.io/0xd2ddfba7bb12f6e70c2aab6b6bf9edaef42ed22f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe0e514c71282b6f4e823703a39374cf58dc3ea4f',
      symbol: 'BELT',
      decimals: 18,
      name: 'BELT Token',
      logoURI: 'https://tokens.1inch.io/0xe0e514c71282b6f4e823703a39374cf58dc3ea4f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x94f559ae621f1c810f31a6a620ad7376776fe09e',
      symbol: 'SOUP',
      decimals: 18,
      name: 'Soup',
      logoURI: 'https://tokens.1inch.io/0x94f559ae621f1c810f31a6a620ad7376776fe09e.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x35e869b7456462b81cdb5e6e42434bd27f3f788c',
      symbol: 'MDO',
      decimals: 18,
      name: 'Midas Dollar',
      logoURI: 'https://tokens.1inch.io/0x35e869b7456462b81cdb5e6e42434bd27f3f788c.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4da996c5fe84755c80e108cf96fe705174c5e36a',
      symbol: 'WOW',
      decimals: 18,
      name: 'WOWswap',
      logoURI: 'https://tokens.1inch.io/0x4da996c5fe84755c80e108cf96fe705174c5e36a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x5a3010d4d8d3b5fb49f8b6e57fb9e48063f16700',
      symbol: 'BSCPAD',
      decimals: 18,
      name: 'BSCPAD.com',
      logoURI: 'https://tokens.1inch.io/0x5a3010d4d8d3b5fb49f8b6e57fb9e48063f16700.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x14b1166ab53a237c8ceaee2bbc4bbca200cb7da8',
      symbol: 'bSRK',
      decimals: 18,
      name: 'SparkPoint',
      logoURI: 'https://tokens.1inch.io/0x14b1166ab53a237c8ceaee2bbc4bbca200cb7da8.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x96dd399f9c3afda1f194182f71600f1b65946501',
      symbol: 'COS',
      decimals: 18,
      name: 'Contentos',
      logoURI: 'https://tokens.1inch.io/0x96dd399f9c3afda1f194182f71600f1b65946501.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xc5e6689c9c8b02be7c49912ef19e79cf24977f03',
      symbol: 'ALPA',
      decimals: 18,
      name: 'AlpaToken',
      logoURI: 'https://tokens.1inch.io/0xc5e6689c9c8b02be7c49912ef19e79cf24977f03.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xc13b7a43223bb9bf4b69bd68ab20ca1b79d81c75',
      symbol: 'JGN',
      decimals: 18,
      name: 'Juggernaut DeFi',
      logoURI: 'https://tokens.1inch.io/0xc13b7a43223bb9bf4b69bd68ab20ca1b79d81c75.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd32d01a43c869edcd1117c640fbdcfcfd97d9d65',
      symbol: 'NMX',
      decimals: 18,
      name: 'Nominex',
      logoURI: 'https://tokens.1inch.io/0xd32d01a43c869edcd1117c640fbdcfcfd97d9d65.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x8c784c49097dcc637b93232e15810d53871992bf',
      symbol: 'MSC',
      decimals: 18,
      name: 'Monster Slayer Cash',
      logoURI: 'https://tokens.1inch.io/0x8c784c49097dcc637b93232e15810d53871992bf.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x5ef5994fa33ff4eb6c82d51ee1dc145c546065bd',
      symbol: 'ALLOY',
      decimals: 18,
      name: 'HyperAlloy',
      logoURI: 'https://tokens.1inch.io/0x5ef5994fa33ff4eb6c82d51ee1dc145c546065bd.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xa86d305a36cdb815af991834b46ad3d7fbb38523',
      symbol: 'BR34P',
      decimals: 8,
      name: 'BR34P',
      logoURI: 'https://tokens.1inch.io/0xa86d305a36cdb815af991834b46ad3d7fbb38523.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x92a42db88ed0f02c71d439e55962ca7cab0168b5',
      symbol: 'TRDG',
      decimals: 9,
      name: 'Tardigrades.Finance',
      logoURI: 'https://tokens.1inch.io/0x92a42db88ed0f02c71d439e55962ca7cab0168b5.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4f47a0d15c1e53f3d94c069c7d16977c29f9cb6b',
      symbol: 'Ramen',
      decimals: 18,
      name: 'Ramen Token',
      logoURI: 'https://tokens.1inch.io/0x4f47a0d15c1e53f3d94c069c7d16977c29f9cb6b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x7a9f28eb62c791422aa23ceae1da9c847cbec9b0',
      symbol: 'WATCH',
      decimals: 18,
      name: 'yieldwatch',
      logoURI: 'https://tokens.1inch.io/0x7a9f28eb62c791422aa23ceae1da9c847cbec9b0.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x444a0e0c139cac67e8f9be945c6dfe01a2766ed1',
      symbol: 'GST',
      decimals: 18,
      name: 'Gemstone Token',
      logoURI: 'https://tokens.1inch.io/0x444a0e0c139cac67e8f9be945c6dfe01a2766ed1.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x0288d3e353fe2299f11ea2c2e1696b4a648ecc07',
      symbol: 'ZEFI',
      decimals: 18,
      name: 'ZCore Finance',
      logoURI: 'https://tokens.1inch.io/0x0288d3e353fe2299f11ea2c2e1696b4a648ecc07.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4a5a34212404f30c5ab7eb61b078fa4a55adc5a5',
      symbol: 'MILK2',
      decimals: 18,
      name: 'MilkyWay Token by SpaceSwap v2',
      logoURI: 'https://tokens.1inch.io/0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xb2bd0749dbe21f623d9baba856d3b0f0e1bfec9c',
      symbol: 'DUSK',
      decimals: 18,
      name: 'Dusk Network',
      logoURI: 'https://tokens.1inch.io/0x940a2db1b7008b6c776d4faaca729d6d4a4aa551.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x630d98424efe0ea27fb1b3ab7741907dffeaad78',
      symbol: 'PEAK',
      decimals: 8,
      name: 'PEAKDEFI',
      logoURI: 'https://tokens.1inch.io/0x630d98424efe0ea27fb1b3ab7741907dffeaad78.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xacb2d47827c9813ae26de80965845d80935afd0b',
      symbol: 'MCRN',
      decimals: 18,
      name: 'MacaronSwap Token',
      logoURI: 'https://tokens.1inch.io/0xacb2d47827c9813ae26de80965845d80935afd0b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x1311b352467d2b5c296881badea82850bcd8f886',
      symbol: 'TOOLS',
      decimals: 18,
      name: 'TOOLS',
      logoURI: 'https://tokens.1inch.io/0x1311b352467d2b5c296881badea82850bcd8f886.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x9806aec346064183b5ce441313231dff89811f7a',
      symbol: 'yPANDA',
      decimals: 8,
      name: 'YieldPanda.finance',
      logoURI: 'https://tokens.1inch.io/0x9806aec346064183b5ce441313231dff89811f7a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x90e767a68a7d707b74d569c8e79f9bbb79b98a8b',
      symbol: 'FAT',
      decimals: 18,
      name: 'Fatfi Protocol',
      logoURI: 'https://tokens.1inch.io/0x90e767a68a7d707b74d569c8e79f9bbb79b98a8b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x3c00f8fcc8791fa78daa4a480095ec7d475781e2',
      symbol: 'SAFESTAR',
      decimals: 9,
      name: 'SafeStar',
      logoURI: 'https://tokens.1inch.io/0x3c00f8fcc8791fa78daa4a480095ec7d475781e2.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd88ca08d8eec1e9e09562213ae83a7853ebb5d28',
      symbol: 'XWIN',
      decimals: 18,
      name: 'xWIN Token',
      logoURI: 'https://tokens.1inch.io/0xd88ca08d8eec1e9e09562213ae83a7853ebb5d28.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x74b3abb94e9e1ecc25bd77d6872949b4a9b2aacf',
      symbol: 'DFX',
      decimals: 18,
      name: 'DeFireX on BSC',
      logoURI: 'https://tokens.1inch.io/0x74b3abb94e9e1ecc25bd77d6872949b4a9b2aacf.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xa72a0564d0e887123112e6a4dc1aba7611ad861d',
      symbol: 'FEB',
      decimals: 0,
      name: 'FEB Token',
      logoURI: 'https://tokens.1inch.io/0xa72a0564d0e887123112e6a4dc1aba7611ad861d.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf0e406c49c63abf358030a299c0e00118c4c6ba5',
      symbol: 'NVT',
      decimals: 8,
      name: 'NerveNetwork',
      logoURI: 'https://tokens.1inch.io/0xf0e406c49c63abf358030a299c0e00118c4c6ba5.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x42981d0bfbaf196529376ee702f2a9eb9092fcb5',
      symbol: 'SFM',
      decimals: 9,
      name: 'SafeMoon',
      logoURI: 'https://tokens.1inch.io/0x42981d0bfbaf196529376ee702f2a9eb9092fcb5.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x7f479d78380ad00341fdd7322fe8aef766e29e5a',
      symbol: 'WHIRL',
      decimals: 18,
      name: 'Whirl Finance',
      logoURI: 'https://tokens.1inch.io/0x7f479d78380ad00341fdd7322fe8aef766e29e5a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xeca41281c24451168a37211f0bc2b8645af45092',
      symbol: 'TPT',
      decimals: 4,
      name: 'TokenPocket Token',
      logoURI: 'https://tokens.1inch.io/0xeca41281c24451168a37211f0bc2b8645af45092.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xbcf39f0edda668c58371e519af37ca705f2bfcbd',
      symbol: 'pCWS',
      decimals: 18,
      name: 'PolyCrowns',
      logoURI: 'https://tokens.1inch.io/0xbcf39f0edda668c58371e519af37ca705f2bfcbd.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x7af173f350d916358af3e218bdf2178494beb748',
      symbol: 'TRADE',
      decimals: 18,
      name: 'UniTrade',
      logoURI: 'https://tokens.1inch.io/0x7af173f350d916358af3e218bdf2178494beb748.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x3a5325f0e5ee4da06a285e988f052d4e45aa64b4',
      symbol: 'POLAR',
      decimals: 18,
      name: 'Polaris',
      logoURI: 'https://tokens.1inch.io/0x1c545e9943cfd1b41e60a7917465911fa00fc28c.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4f0ed527e8a95ecaa132af214dfd41f30b361600',
      symbol: 'vBSWAP',
      decimals: 18,
      name: 'vSWAP.fi',
      logoURI: 'https://tokens.1inch.io/0x4f0ed527e8a95ecaa132af214dfd41f30b361600.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x5986d5c77c65e5801a5caa4fae80089f870a71da',
      symbol: 'bDIGG',
      decimals: 18,
      name: 'Badger Sett Digg',
      logoURI: 'https://tokens.1inch.io/0x5986d5c77c65e5801a5caa4fae80089f870a71da.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x51ba0b044d96c3abfca52b64d733603ccc4f0d4d',
      symbol: 'SUPER',
      decimals: 18,
      name: 'SUPER-ERC20',
      logoURI: 'https://tokens.1inch.io/0x51ba0b044d96c3abfca52b64d733603ccc4f0d4d.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x810ee35443639348adbbc467b33310d2ab43c168',
      symbol: 'CYC',
      decimals: 18,
      name: 'Cyclone Protocol',
      logoURI: 'https://tokens.1inch.io/0x810ee35443639348adbbc467b33310d2ab43c168.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x8597ba143ac509189e89aab3ba28d661a5dd9830',
      symbol: 'VANCAT',
      decimals: 0,
      name: 'VANCAT Token',
      logoURI: 'https://tokens.1inch.io/0x8597ba143ac509189e89aab3ba28d661a5dd9830.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x05b339b0a346bf01f851dde47a5d485c34fe220c',
      symbol: 'NAUT',
      decimals: 8,
      name: 'Astronaut',
      logoURI: 'https://tokens.1inch.io/0x05b339b0a346bf01f851dde47a5d485c34fe220c.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x182c763a4b2fbd18c9b5f2d18102a0ddd9d5df26',
      symbol: 'HOGL',
      decimals: 18,
      name: 'HOGL Finance',
      logoURI: 'https://tokens.1inch.io/0x182c763a4b2fbd18c9b5f2d18102a0ddd9d5df26.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x1f7216fdb338247512ec99715587bb97bbf96eae',
      symbol: 'bBADGER',
      decimals: 18,
      name: 'Badger Sett Badger',
      logoURI: 'https://tokens.1inch.io/0x1f7216fdb338247512ec99715587bb97bbf96eae.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x0231f91e02debd20345ae8ab7d71a41f8e140ce7',
      symbol: 'bwJUP',
      decimals: 18,
      name: 'BSC Wrapped Jupiter',
      logoURI: 'https://tokens.1inch.io/0x0231f91e02debd20345ae8ab7d71a41f8e140ce7.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xbf7c81fff98bbe61b40ed186e4afd6ddd01337fe',
      symbol: 'EGLD',
      decimals: 18,
      name: 'Elrond',
      logoURI: 'https://tokens.1inch.io/0xbf7c81fff98bbe61b40ed186e4afd6ddd01337fe.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x547cbe0f0c25085e7015aa6939b28402eb0ccdac',
      symbol: 'XBN',
      decimals: 18,
      name: 'Elastic BNB',
      logoURI: 'https://tokens.1inch.io/0x547cbe0f0c25085e7015aa6939b28402eb0ccdac.png',
      tags: ['PEG:BNB', 'tokens'],
    },
    {
      chainId: ChainId.BSC,
      address: '0xe3e1fabeabd48491bd6902b0c32fdeee8d2ff12b',
      symbol: 'UNICORN',
      decimals: 18,
      name: 'UNICORN Token',
      logoURI: 'https://tokens.1inch.io/0xe3e1fabeabd48491bd6902b0c32fdeee8d2ff12b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xeda21b525ac789eab1a08ef2404dd8505ffb973d',
      symbol: 'HPS',
      decimals: 18,
      name: 'HappinessToken',
      logoURI: 'https://tokens.1inch.io/0xeda21b525ac789eab1a08ef2404dd8505ffb973d.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x5b17b4d5e4009b5c43e3e3d63a5229f794cba389',
      symbol: 'ACSI',
      decimals: 18,
      name: 'ACryptoS(I)',
      logoURI: 'https://tokens.1inch.io/0x5b17b4d5e4009b5c43e3e3d63a5229f794cba389.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x2222227e22102fe3322098e4cbfe18cfebd57c95',
      symbol: 'TLM',
      decimals: 4,
      name: 'Alien Worlds Trilium',
      logoURI: 'https://tokens.1inch.io/0x2222227e22102fe3322098e4cbfe18cfebd57c95.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x5d0158a5c3ddf47d4ea4517d8db0d76aa2e87563',
      symbol: 'BONDLY',
      decimals: 18,
      name: 'Bondly Token',
      logoURI: 'https://tokens.1inch.io/0x5d0158a5c3ddf47d4ea4517d8db0d76aa2e87563.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x2a1d286ed5edad78befd6e0d8beb38791e8cd69d',
      symbol: 'CLIMB',
      decimals: 8,
      name: 'Climb Token Finance',
      logoURI: 'https://tokens.1inch.io/0x2a1d286ed5edad78befd6e0d8beb38791e8cd69d.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377',
      symbol: 'MBOX',
      decimals: 18,
      name: 'Mobox',
      logoURI: 'https://tokens.1inch.io/0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x52d86850bc8207b520340b7e39cdaf22561b9e56',
      symbol: 'SWIRL',
      decimals: 18,
      name: 'Swirl.Cash',
      logoURI: 'https://tokens.1inch.io/0x52d86850bc8207b520340b7e39cdaf22561b9e56.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xad90c05bc51672eedfee36e58b3ff1a78bbc146d',
      symbol: 'XSPACE',
      decimals: 9,
      name: 'XSPACE',
      logoURI: 'https://tokens.1inch.io/0xad90c05bc51672eedfee36e58b3ff1a78bbc146d.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x965b0df5bda0e7a0649324d78f03d5f7f2de086a',
      symbol: 'COOK',
      decimals: 18,
      name: 'Poly-Peg COOK',
      logoURI: 'https://tokens.1inch.io/0x965b0df5bda0e7a0649324d78f03d5f7f2de086a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x04c747b40be4d535fc83d09939fb0f626f32800b',
      symbol: 'ITAM',
      decimals: 18,
      name: 'ITAM',
      logoURI: 'https://tokens.1inch.io/0x04c747b40be4d535fc83d09939fb0f626f32800b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x0feadcc3824e7f3c12f40e324a60c23ca51627fc',
      symbol: 'Warden',
      decimals: 18,
      name: 'WardenSwap Token',
      logoURI: 'https://tokens.1inch.io/0x0feadcc3824e7f3c12f40e324a60c23ca51627fc.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x9f589e3eabe42ebc94a44727b3f3531c0c877809',
      symbol: 'TKO',
      decimals: 18,
      name: 'Tokocrypto Token',
      logoURI: 'https://tokens.1inch.io/0x9f589e3eabe42ebc94a44727b3f3531c0c877809.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x477bc8d23c634c154061869478bce96be6045d12',
      symbol: 'SFUND',
      decimals: 18,
      name: 'SeedifyFund',
      logoURI: 'https://tokens.1inch.io/0x477bc8d23c634c154061869478bce96be6045d12.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x2f7b4c618dc8e0bba648e54cdadce3d8361f9816',
      symbol: 'NFTL',
      decimals: 18,
      name: 'NFTL Token',
      logoURI: 'https://tokens.1inch.io/0x2f7b4c618dc8e0bba648e54cdadce3d8361f9816.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x5857c96dae9cf8511b08cb07f85753c472d36ea3',
      symbol: 'FUSE',
      decimals: 18,
      name: 'Fuse Token on BSC',
      logoURI: 'https://tokens.1inch.io/0x970b9bb2c0444f5e81e9d0efb84c8ccdcdcaf84d.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xfad8e46123d7b4e77496491769c167ff894d2acb',
      symbol: 'FOX',
      decimals: 9,
      name: 'Fox Finance',
      logoURI: 'https://tokens.1inch.io/0xfad8e46123d7b4e77496491769c167ff894d2acb.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x13958e1eb63dfb8540eaf6ed7dcbbc1a60fd52af',
      symbol: 'FREN',
      decimals: 18,
      name: 'Frenchie',
      logoURI: 'https://tokens.1inch.io/0x13958e1eb63dfb8540eaf6ed7dcbbc1a60fd52af.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x5621b5a3f4a8008c4ccdd1b942b121c8b1944f1f',
      symbol: 'XED',
      decimals: 18,
      name: 'Exeedme',
      logoURI: 'https://tokens.1inch.io/0x5621b5a3f4a8008c4ccdd1b942b121c8b1944f1f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x67d012f731c23f0313cea1186d0121779c77fcfe',
      symbol: 'SOUL',
      decimals: 8,
      name: 'APOyield SOULS',
      logoURI: 'https://tokens.1inch.io/0x67d012f731c23f0313cea1186d0121779c77fcfe.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x6b51231c43b1604815313801db5e9e614914d6e4',
      symbol: 'SAFEGALAXY',
      decimals: 9,
      name: 'SafeGalaxy',
      logoURI: 'https://tokens.1inch.io/0x6b51231c43b1604815313801db5e9e614914d6e4.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x6d949f9297a522c0f97c232cc209a67bd7cfa471',
      symbol: 'MRAT',
      decimals: 9,
      name: 'Moon Rat Token',
      logoURI: 'https://tokens.1inch.io/0x6d949f9297a522c0f97c232cc209a67bd7cfa471.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x3ad9594151886ce8538c1ff615efa2385a8c3a88',
      symbol: 'SAFEMARS',
      decimals: 9,
      name: 'SafeMars',
      logoURI: 'https://tokens.1inch.io/0x3ad9594151886ce8538c1ff615efa2385a8c3a88.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf8e026dc4c0860771f691ecffbbdfe2fa51c77cf',
      symbol: 'BGOV',
      decimals: 18,
      name: 'BGOV Token',
      logoURI: 'https://tokens.1inch.io/0xf8e026dc4c0860771f691ecffbbdfe2fa51c77cf.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xee738a9e5fb78c24d26cecd30389ed977c38d0ca',
      symbol: 'FSAFE',
      decimals: 9,
      name: 'Fair Safe',
      logoURI: 'https://tokens.1inch.io/0xee738a9e5fb78c24d26cecd30389ed977c38d0ca.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x380624a4a7e69db1ca07deecf764025fc224d056',
      symbol: 'SAFEBTC',
      decimals: 9,
      name: 'SafeBTC',
      logoURI: 'https://tokens.1inch.io/0x380624a4a7e69db1ca07deecf764025fc224d056.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x07af67b392b7a202fad8e0fbc64c34f33102165b',
      symbol: 'AQUAGOAT',
      decimals: 9,
      name: 'Aquagoat',
      logoURI: 'https://tokens.1inch.io/0x07af67b392b7a202fad8e0fbc64c34f33102165b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x8850d2c68c632e3b258e612abaa8fada7e6958e5',
      symbol: 'PIG',
      decimals: 9,
      name: 'Pig Token',
      logoURI: 'https://tokens.1inch.io/0x8850d2c68c632e3b258e612abaa8fada7e6958e5.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x375483cfa7fc18f6b455e005d835a8335fbdbb1f',
      symbol: 'ECP',
      decimals: 9,
      name: 'Eclipse',
      logoURI: 'https://tokens.1inch.io/0x375483cfa7fc18f6b455e005d835a8335fbdbb1f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x81e4d494b85a24a58a6ba45c9b418b32a4e039de',
      symbol: 'MOONTOKEN',
      decimals: 18,
      name: 'Moon Token',
      logoURI: 'https://tokens.1inch.io/0x81e4d494b85a24a58a6ba45c9b418b32a4e039de.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x2a9718deff471f3bb91fa0eceab14154f150a385',
      symbol: 'ElonGate',
      decimals: 9,
      name: 'ElonGate',
      logoURI: 'https://tokens.1inch.io/0x2a9718deff471f3bb91fa0eceab14154f150a385.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x5bccfbd33873a5498f8406146868eddd5e998962',
      symbol: 'PDO',
      decimals: 18,
      name: 'pDollar',
      logoURI: 'https://tokens.1inch.io/0x5bccfbd33873a5498f8406146868eddd5e998962.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x658a109c5900bc6d2357c87549b651670e5b0539',
      symbol: 'FOR',
      decimals: 18,
      name: 'The Force Token',
      logoURI: 'https://tokens.1inch.io/0x658a109c5900bc6d2357c87549b651670e5b0539.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xb86abcb37c3a4b64f74f59301aff131a1becc787',
      symbol: 'ZIL',
      decimals: 12,
      name: 'Zilliqa',
      logoURI: 'https://tokens.1inch.io/0xb86abcb37c3a4b64f74f59301aff131a1becc787_1.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd72aa9e1cddc2f6d6e0444580002170fba1f8eed',
      symbol: 'MDA',
      decimals: 18,
      name: 'Moeda Loyalty Points',
      logoURI: 'https://tokens.1inch.io/0xd72aa9e1cddc2f6d6e0444580002170fba1f8eed.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x86c3e4ffacdb3af628ef985a518cd6ee22a22b28',
      symbol: 'OCTA',
      decimals: 9,
      name: 'Octans',
      logoURI: 'https://tokens.1inch.io/0x86c3e4ffacdb3af628ef985a518cd6ee22a22b28.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x7e52a123ed6db6ac872a875552935fbbd2544c86',
      symbol: 'SYL',
      decimals: 6,
      name: 'SYL',
      logoURI: 'https://tokens.1inch.io/0x7e52a123ed6db6ac872a875552935fbbd2544c86.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x42f6f551ae042cbe50c739158b4f0cac0edb9096',
      symbol: 'NRV',
      decimals: 18,
      name: 'Nerve',
      logoURI: 'https://tokens.1inch.io/0x42f6f551ae042cbe50c739158b4f0cac0edb9096.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x97a30c692ece9c317235d48287d23d358170fc40',
      symbol: 'CRX',
      decimals: 18,
      name: 'CryptEx Token',
      logoURI: 'https://tokens.1inch.io/0x97a30c692ece9c317235d48287d23d358170fc40.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x7e624fa0e1c4abfd309cc15719b7e2580887f570',
      symbol: 'POLS',
      decimals: 18,
      name: 'PolkastarterToken',
      logoURI: 'https://tokens.1inch.io/0x7e624fa0e1c4abfd309cc15719b7e2580887f570.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4d4e595d643dc61ea7fcbf12e4b1aaa39f9975b8',
      symbol: 'PET',
      decimals: 18,
      name: 'Pet Token',
      logoURI: 'https://tokens.1inch.io/0x4d4e595d643dc61ea7fcbf12e4b1aaa39f9975b8.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf16e81dce15b08f326220742020379b855b87df9',
      symbol: 'ICE',
      decimals: 18,
      name: 'IceToken',
      logoURI: 'https://tokens.1inch.io/0xf16e81dce15b08f326220742020379b855b87df9.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4a080377f83d669d7bb83b3184a8a5e61b500608',
      symbol: 'XEND',
      decimals: 18,
      name: 'XEND',
      logoURI: 'https://tokens.1inch.io/0x4a080377f83d669d7bb83b3184a8a5e61b500608.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d',
      symbol: 'BSCS',
      decimals: 18,
      name: 'BSCS Token',
      logoURI: 'https://tokens.1inch.io/0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xb93ba7dc61ecfced69067151fc00c41ca369a797',
      symbol: 'WENMOON',
      decimals: 7,
      name: 'WenMoon Token',
      logoURI: 'https://tokens.1inch.io/0xb93ba7dc61ecfced69067151fc00c41ca369a797.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd4fbc57b6233f268e7fba3b66e62719d74deecbc',
      symbol: 'MOD',
      decimals: 18,
      name: 'Modefi',
      logoURI: 'https://tokens.1inch.io/0xd4fbc57b6233f268e7fba3b66e62719d74deecbc.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x99c6e435ec259a7e8d65e1955c9423db624ba54c',
      symbol: 'FMT',
      decimals: 18,
      name: 'Finminity',
      logoURI: 'https://tokens.1inch.io/0x99c6e435ec259a7e8d65e1955c9423db624ba54c.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x0e0e877894a101ad8711ae3a0194fa44ca837a79',
      symbol: 'MOONMOON',
      decimals: 9,
      name: 'MoonMoon',
      logoURI: 'https://tokens.1inch.io/0x0e0e877894a101ad8711ae3a0194fa44ca837a79.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xb0f2939a1c0e43683e5954c9fe142f7df9f8d967',
      symbol: 'GEN',
      decimals: 18,
      name: 'Gen Token',
      logoURI: 'https://tokens.1inch.io/0xb0f2939a1c0e43683e5954c9fe142f7df9f8d967.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x85c128ee1feeb39a59490c720a9c563554b51d33',
      symbol: 'KEY',
      decimals: 18,
      name: 'MoMo KEY',
      logoURI: 'https://tokens.1inch.io/0x85c128ee1feeb39a59490c720a9c563554b51d33.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4e6415a5727ea08aae4580057187923aec331227',
      symbol: 'FINE',
      decimals: 18,
      name: 'Refinable',
      logoURI: 'https://tokens.1inch.io/0x4e6415a5727ea08aae4580057187923aec331227.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x2fa5daf6fe0708fbd63b1a7d1592577284f52256',
      symbol: 'MARSH',
      decimals: 18,
      name: 'UnmarshalToken',
      logoURI: 'https://tokens.1inch.io/0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90',
      symbol: 'WEX',
      decimals: 18,
      name: 'WaultSwap',
      logoURI: 'https://tokens.1inch.io/0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x8519ea49c997f50ceffa444d240fb655e89248aa',
      symbol: 'RAMP',
      decimals: 18,
      name: 'RAMP DEFI',
      logoURI: 'https://tokens.1inch.io/0x8519ea49c997f50ceffa444d240fb655e89248aa.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xfb52fc1f90dd2b070b9cf7ad68ac3d68905643fa',
      symbol: 'SEA',
      decimals: 18,
      name: 'Sea Token',
      logoURI: 'https://tokens.1inch.io/0xfb52fc1f90dd2b070b9cf7ad68ac3d68905643fa.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x50d809c74e0b8e49e7b4c65bb3109abe3ff4c1c1',
      symbol: 'CUB',
      decimals: 18,
      name: 'Cub Finance',
      logoURI: 'https://tokens.1inch.io/0x50d809c74e0b8e49e7b4c65bb3109abe3ff4c1c1.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe1db3d1ee5cfe5c6333be96e6421f9bd5b85c987',
      symbol: 'SAFESPACE',
      decimals: 9,
      name: 'SAFESPACE',
      logoURI: 'https://tokens.1inch.io/0xe1db3d1ee5cfe5c6333be96e6421f9bd5b85c987.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xce5814efff15d53efd8025b9f2006d4d7d640b9b',
      symbol: 'MOONSTAR',
      decimals: 9,
      name: 'MoonStar',
      logoURI: 'https://tokens.1inch.io/0xce5814efff15d53efd8025b9f2006d4d7d640b9b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4e8a9d0bf525d78fd9e0c88710099f227f6924cf',
      symbol: 'LUNAR',
      decimals: 9,
      name: 'LunarHighway',
      logoURI: 'https://tokens.1inch.io/0x4e8a9d0bf525d78fd9e0c88710099f227f6924cf.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf7844cb890f4c339c497aeab599abdc3c874b67a',
      symbol: 'NFTART',
      decimals: 9,
      name: 'NFTArt.Finance',
      logoURI: 'https://tokens.1inch.io/0xf7844cb890f4c339c497aeab599abdc3c874b67a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x6421531af54c7b14ea805719035ebf1e3661c44a',
      symbol: 'bLEO',
      decimals: 3,
      name: 'BEP20 LEO',
      logoURI: 'https://tokens.1inch.io/0x6421531af54c7b14ea805719035ebf1e3661c44a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xce5347fdd503f25f8428151a274544a5bd1bd8ca',
      symbol: 'UNIF',
      decimals: 9,
      name: 'Unified',
      logoURI: 'https://tokens.1inch.io/0xce5347fdd503f25f8428151a274544a5bd1bd8ca.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xda20c8a5c3b1ab48e31ba6e43f0f2830e50218d8',
      symbol: 'BINGUS',
      decimals: 9,
      name: 'Bingus Token',
      logoURI: 'https://tokens.1inch.io/0xda20c8a5c3b1ab48e31ba6e43f0f2830e50218d8.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe64f5cb844946c1f102bd25bbd87a5ab4ae89fbe',
      symbol: 'bROOBEE',
      decimals: 18,
      name: 'ROOBEE',
      logoURI: 'https://tokens.1inch.io/0xa31b1767e09f842ecfd4bc471fe44f830e3891aa.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x27ae27110350b98d564b9a3eed31baebc82d878d',
      symbol: 'CUMMIES',
      decimals: 18,
      name: 'CumRocket',
      logoURI: 'https://tokens.1inch.io/0x27ae27110350b98d564b9a3eed31baebc82d878d.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4e7ae924fd9a5d60b56be486b2900efe0c6a9ca7',
      symbol: 'LOT',
      decimals: 9,
      name: 'Lottery Token',
      logoURI: 'https://tokens.1inch.io/0x4e7ae924fd9a5d60b56be486b2900efe0c6a9ca7.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4a68c250486a116dc8d6a0c5b0677de07cc09c5d',
      symbol: 'POODL',
      decimals: 9,
      name: 'Poodl',
      logoURI: 'https://tokens.1inch.io/0x56a980328aee33aabb540a02e002c8323326bf36.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x843d4a358471547f51534e3e51fae91cb4dc3f28',
      symbol: 'lowb',
      decimals: 18,
      name: 'loser coin',
      logoURI: 'https://tokens.1inch.io/0x843d4a358471547f51534e3e51fae91cb4dc3f28.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe5a09784b16e1065c37df14c6e2f06fdce317a1b',
      symbol: 'KaiInu',
      decimals: 9,
      name: 'Kai Inu',
      logoURI: 'https://tokens.1inch.io/0xe5a09784b16e1065c37df14c6e2f06fdce317a1b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x74926b3d118a63f6958922d3dc05eb9c6e6e00c6',
      symbol: 'DOGGY',
      decimals: 18,
      name: 'DOGGY',
      logoURI: 'https://tokens.1inch.io/0x74926b3d118a63f6958922d3dc05eb9c6e6e00c6.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x0f9e4d49f25de22c2202af916b681fbb3790497b',
      symbol: 'PERL',
      decimals: 18,
      name: 'Perlin',
      logoURI: 'https://tokens.1inch.io/0x0f9e4d49f25de22c2202af916b681fbb3790497b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf78d2e7936f5fe18308a3b2951a93b6c4a41f5e2',
      symbol: 'OM',
      decimals: 18,
      name: 'MANTRA DAO',
      logoURI: 'https://tokens.1inch.io/0xf78d2e7936f5fe18308a3b2951a93b6c4a41f5e2.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x31b9773f225408129a90788ef013bd449e283865',
      symbol: 'PORN',
      decimals: 9,
      name: 'Porn',
      logoURI: 'https://tokens.1inch.io/0x31b9773f225408129a90788ef013bd449e283865.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x994517e000aa3f117e7ad61b0e2336c76b4fd94a',
      symbol: 'MTDR',
      decimals: 18,
      name: 'Matador Token',
      logoURI: 'https://tokens.1inch.io/0x994517e000aa3f117e7ad61b0e2336c76b4fd94a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x050787de0cf5da03d9387b344334d51cae5dd0fd',
      symbol: 'PEKC',
      decimals: 9,
      name: 'PEACOCKCOIN',
      logoURI: 'https://tokens.1inch.io/0x050787de0cf5da03d9387b344334d51cae5dd0fd.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4691937a7508860f876c9c0a2a617e7d9e945d4b',
      symbol: 'WOO',
      decimals: 18,
      name: 'Wootrade Network',
      logoURI: 'https://tokens.1inch.io/0x4691937a7508860f876c9c0a2a617e7d9e945d4b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x55671114d774ee99d653d6c12460c780a67f1d18',
      symbol: 'PACOCA',
      decimals: 18,
      name: 'Pacoca',
      logoURI: 'https://tokens.1inch.io/0x55671114d774ee99d653d6c12460c780a67f1d18.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x7ee7f14427cc41d6db17829eb57dc74a26796b9d',
      symbol: 'MOONRISE',
      decimals: 9,
      name: 'MoonRise',
      logoURI: 'https://tokens.1inch.io/0x7ee7f14427cc41d6db17829eb57dc74a26796b9d.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x46d502fac9aea7c5bc7b13c8ec9d02378c33d36f',
      symbol: 'WSPP',
      decimals: 0,
      name: 'WolfSafePoorPeople',
      logoURI: 'https://tokens.1inch.io/0x46d502fac9aea7c5bc7b13c8ec9d02378c33d36f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x728c5bac3c3e370e372fc4671f9ef6916b814d8b',
      symbol: 'UNFI',
      decimals: 18,
      name: 'UNFI',
      logoURI: 'https://tokens.1inch.io/0x728c5bac3c3e370e372fc4671f9ef6916b814d8b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x8f0fb159380176d324542b3a7933f0c2fd0c2bbf',
      symbol: 'TFT',
      decimals: 7,
      name: 'TFT on BSC',
      logoURI: 'https://tokens.1inch.io/0x8f0fb159380176d324542b3a7933f0c2fd0c2bbf.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x3fcca8648651e5b974dd6d3e50f61567779772a8',
      symbol: 'POTS',
      decimals: 18,
      name: 'Moonpot',
      logoURI: 'https://tokens.1inch.io/0x3fcca8648651e5b974dd6d3e50f61567779772a8.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf750a26eb0acf95556e8529e72ed530f3b60f348',
      symbol: 'GNT',
      decimals: 18,
      name: 'GreenTrust',
      logoURI: 'https://tokens.1inch.io/0xf750a26eb0acf95556e8529e72ed530f3b60f348.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x9d986a3f147212327dd658f712d5264a73a1fdb0',
      symbol: 'LAND',
      decimals: 18,
      name: 'Landshare Token',
      logoURI: 'https://tokens.1inch.io/0x9d986a3f147212327dd658f712d5264a73a1fdb0.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x0255af6c9f86f6b0543357bacefa262a2664f80f',
      symbol: 'DARA',
      decimals: 18,
      name: 'Immutable',
      logoURI: 'https://tokens.1inch.io/0x0255af6c9f86f6b0543357bacefa262a2664f80f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x67d66e8ec1fd25d98b3ccd3b19b7dc4b4b7fc493',
      symbol: 'FEED',
      decimals: 18,
      name: 'Feeder.finance',
      logoURI: 'https://tokens.1inch.io/0x67d66e8ec1fd25d98b3ccd3b19b7dc4b4b7fc493.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x14c358b573a4ce45364a3dbd84bbb4dae87af034',
      symbol: 'DND',
      decimals: 18,
      name: 'DungeonSwap Token',
      logoURI: 'https://tokens.1inch.io/0x14c358b573a4ce45364a3dbd84bbb4dae87af034.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe20b9e246db5a0d21bf9209e4858bc9a3ff7a034',
      symbol: 'wBAN',
      decimals: 18,
      name: 'Wrapped Banano',
      logoURI: 'https://tokens.1inch.io/0xe20b9e246db5a0d21bf9209e4858bc9a3ff7a034.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xfe56d5892bdffc7bf58f2e84be1b2c32d21c308b',
      symbol: 'KNC',
      decimals: 18,
      name: 'Kyber Network Crystal',
      logoURI: 'https://tokens.1inch.io/0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x965f527d9159dce6288a2219db51fc6eef120dd1',
      symbol: 'BSW',
      decimals: 18,
      name: 'Biswap',
      logoURI: 'https://tokens.1inch.io/0x965f527d9159dce6288a2219db51fc6eef120dd1.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xbb46693ebbea1ac2070e59b4d043b47e2e095f86',
      symbol: 'BFG',
      decimals: 18,
      name: 'BFG Token',
      logoURI: 'https://tokens.1inch.io/0xbb46693ebbea1ac2070e59b4d043b47e2e095f86_1.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x6679eb24f59dfe111864aec72b443d1da666b360',
      symbol: 'ARV',
      decimals: 8,
      name: 'ARIVA',
      logoURI: 'https://tokens.1inch.io/0x6679eb24f59dfe111864aec72b443d1da666b360.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x0047a0deadafb7ee6b1a0d219e70fb6767057d93',
      symbol: 'xYSL',
      decimals: 18,
      name: 'xYSL token',
      logoURI: 'https://tokens.1inch.io/0x0047a0deadafb7ee6b1a0d219e70fb6767057d93.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4ef29f3b804c316ba8ba464a765c601fc092a2e9',
      symbol: 'QA',
      decimals: 18,
      name: 'Quantum Assets Token',
      logoURI: 'https://tokens.1inch.io/0x4ef29f3b804c316ba8ba464a765c601fc092a2e9.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xa96658cd0d04a8fdcdc30d1156cc65bbfc7591ed',
      symbol: 'SUSHIBA',
      decimals: 9,
      name: 'Sushiba',
      logoURI: 'https://tokens.1inch.io/0xa96658cd0d04a8fdcdc30d1156cc65bbfc7591ed.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xebc76079da0c245fae7225b58a57a54809b40618',
      symbol: 'BPAY',
      decimals: 9,
      name: 'BNBPay',
      logoURI: 'https://tokens.1inch.io/0xebc76079da0c245fae7225b58a57a54809b40618.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x58d372a8db7696c0c066f25c9eaf2af6f147b726',
      symbol: 'TSUGA',
      decimals: 18,
      name: 'Tsukiverse: Galactic Adventures',
      logoURI: 'https://tokens.1inch.io/0x58d372a8db7696c0c066f25c9eaf2af6f147b726.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x747d74db20cc422f39ab54edb2a3ce21f3c98af1',
      symbol: 'CGU',
      decimals: 8,
      name: 'Crypto Gaming United',
      logoURI: 'https://tokens.1inch.io/0x747d74db20cc422f39ab54edb2a3ce21f3c98af1.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xa57ac35ce91ee92caefaa8dc04140c8e232c2e50',
      symbol: 'PIT',
      decimals: 9,
      name: 'Pitbull',
      logoURI: 'https://tokens.1inch.io/0xa57ac35ce91ee92caefaa8dc04140c8e232c2e50.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xc3387e4285e9f80a7cfdf02b4ac6cdf2476a528a',
      symbol: 'ROCK',
      decimals: 18,
      name: 'Bedrock',
      logoURI: 'https://tokens.1inch.io/0xc3387e4285e9f80a7cfdf02b4ac6cdf2476a528a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe87e15b9c7d989474cb6d8c56b3db4efad5b21e8',
      symbol: 'HOKK',
      decimals: 18,
      name: 'Hokkaido Inu',
      logoURI: 'https://tokens.1inch.io/0xe87e15b9c7d989474cb6d8c56b3db4efad5b21e8.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x6dc3d0d6ec970bf5522611d8eff127145d02b675',
      symbol: 'RVL',
      decimals: 18,
      name: 'Revolotto',
      logoURI: 'https://tokens.1inch.io/0x6dc3d0d6ec970bf5522611d8eff127145d02b675.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x29a63f4b209c29b4dc47f06ffa896f32667dad2c',
      symbol: 'PURSE',
      decimals: 18,
      name: 'PURSE TOKEN',
      logoURI: 'https://tokens.1inch.io/0x29a63f4b209c29b4dc47f06ffa896f32667dad2c.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x9000cac49c3841926baac5b2e13c87d43e51b6a4',
      symbol: 'POR',
      decimals: 18,
      name: 'Portuma',
      logoURI: 'https://tokens.1inch.io/0x9000cac49c3841926baac5b2e13c87d43e51b6a4.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x016cf83732f1468150d87dcc5bdf67730b3934d3',
      symbol: 'AIRT',
      decimals: 18,
      name: 'AirNFT Token',
      logoURI: 'https://tokens.1inch.io/0x016cf83732f1468150d87dcc5bdf67730b3934d3.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xffeecbf8d7267757c2dc3d13d730e97e15bfdf7f',
      symbol: 'BORING',
      decimals: 18,
      name: 'BoringDAO',
      logoURI: 'https://tokens.1inch.io/0xffeecbf8d7267757c2dc3d13d730e97e15bfdf7f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xfb981ed9a92377ca4d75d924b9ca06df163924fd',
      symbol: 'SA',
      decimals: 18,
      name: 'Superalgos',
      logoURI: 'https://tokens.1inch.io/0xfb981ed9a92377ca4d75d924b9ca06df163924fd.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xace3574b8b054e074473a9bd002e5dc6dd3dff1b',
      symbol: 'RBX',
      decimals: 18,
      name: 'RBX',
      logoURI: 'https://tokens.1inch.io/0xace3574b8b054e074473a9bd002e5dc6dd3dff1b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xaef0a177c8c329cbc8508292bb7e06c00786bbfc',
      symbol: 'PULI',
      decimals: 9,
      name: 'PULI INU',
      logoURI: 'https://tokens.1inch.io/0xaef0a177c8c329cbc8508292bb7e06c00786bbfc.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xfb5b838b6cfeedc2873ab27866079ac55363d37e',
      symbol: 'FLOKI',
      decimals: 9,
      name: 'FLOKI',
      logoURI: 'https://tokens.1inch.io/0xfb5b838b6cfeedc2873ab27866079ac55363d37e_1.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x522348779dcb2911539e76a1042aa922f9c47ee3',
      symbol: 'BOMB',
      decimals: 18,
      name: 'bomb.money',
      logoURI: 'https://tokens.1inch.io/0x522348779dcb2911539e76a1042aa922f9c47ee3.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x7d89c67d3c4e72e8c5c64be201dc225f99d16aca',
      symbol: 'RVZ',
      decimals: 9,
      name: 'Revoluzion',
      logoURI: 'https://tokens.1inch.io/0x7d89c67d3c4e72e8c5c64be201dc225f99d16aca.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x475bfaa1848591ae0e6ab69600f48d828f61a80e',
      symbol: 'DOME',
      decimals: 18,
      name: 'Everdome',
      logoURI: 'https://tokens.1inch.io/0x475bfaa1848591ae0e6ab69600f48d828f61a80e.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xa477a79a118a84a0d371a53c8f46f8ce883ec1dd',
      symbol: 'BBS',
      decimals: 18,
      name: 'BBS [via ChainPort.io]',
      logoURI: 'https://tokens.1inch.io/0xa477a79a118a84a0d371a53c8f46f8ce883ec1dd.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa',
      symbol: 'deUSDC',
      decimals: 6,
      name: 'deBridge USD Coin',
      logoURI: 'https://tokens.1inch.io/0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa_2.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.BSC,
      address: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
      symbol: 'FRAX',
      decimals: 18,
      name: 'Frax',
      logoURI: 'https://tokens.1inch.io/0x90c97f71e18723b0cf0dfa30ee176ab653e89f40.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xfebe8c1ed424dbf688551d4e2267e7a53698f0aa',
      symbol: 'VINU',
      decimals: 18,
      name: 'Vita Inu',
      logoURI: 'https://tokens.1inch.io/0xfebe8c1ed424dbf688551d4e2267e7a53698f0aa.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4ef285c8cbe52267c022c39da98b97ca4b7e2ff9',
      symbol: 'ORE',
      decimals: 18,
      name: 'pTokens ORE [via ChainPort.io]',
      logoURI: 'https://tokens.1inch.io/0x4ef285c8cbe52267c022c39da98b97ca4b7e2ff9.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x69b14e8d3cebfdd8196bfe530954a0c226e5008e',
      symbol: 'SpacePi',
      decimals: 9,
      name: 'SpacePi Token',
      logoURI: 'https://tokens.1inch.io/0x69b14e8d3cebfdd8196bfe530954a0c226e5008e.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x52419258e3fa44deac7e670eadd4c892b480a805',
      symbol: 'STARSHIP',
      decimals: 9,
      name: 'StarShip',
      logoURI: 'https://tokens.1inch.io/0x52419258e3fa44deac7e670eadd4c892b480a805.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe7c9c6bc87b86f9e5b57072f907ee6460b593924',
      symbol: 'TOWER',
      decimals: 18,
      name: 'TOWER',
      logoURI: 'https://tokens.1inch.io/0xe7c9c6bc87b86f9e5b57072f907ee6460b593924.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x9096b4309224d751fcb43d7eb178dcffc122ad15',
      symbol: 'LGX',
      decimals: 18,
      name: 'Legion Token',
      logoURI: 'https://tokens.1inch.io/0x9096b4309224d751fcb43d7eb178dcffc122ad15.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x9ab70e92319f0b9127df78868fd3655fb9f1e322',
      symbol: 'WWY',
      decimals: 18,
      name: 'WeWay Token',
      logoURI: 'https://tokens.1inch.io/0x9ab70e92319f0b9127df78868fd3655fb9f1e322.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x373e768f79c820aa441540d254dca6d045c6d25b',
      symbol: 'DERC',
      decimals: 18,
      name: 'DeRace Token',
      logoURI: 'https://tokens.1inch.io/0x373e768f79c820aa441540d254dca6d045c6d25b.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1',
      symbol: 'GMT',
      decimals: 8,
      name: 'Green Metaverse Token',
      logoURI: 'https://tokens.1inch.io/0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1_1.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x75d107de2217ffe2cd574a1b3297c70c8fafd159',
      symbol: 'TRY',
      decimals: 18,
      name: 'TryHards',
      logoURI: 'https://tokens.1inch.io/0x75d107de2217ffe2cd574a1b3297c70c8fafd159.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x347e430b7cd1235e216be58ffa13394e5009e6e2',
      symbol: 'GAIA',
      decimals: 18,
      name: 'GAIA Everworld',
      logoURI: 'https://tokens.1inch.io/0x347e430b7cd1235e216be58ffa13394e5009e6e2.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe9c803f48dffe50180bd5b01dc04da939e3445fc',
      symbol: 'VLX',
      decimals: 18,
      name: 'Velas',
      logoURI: 'https://tokens.1inch.io/0xe9c803f48dffe50180bd5b01dc04da939e3445fc.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xa719b8ab7ea7af0ddb4358719a34631bb79d15dc',
      symbol: 'FRM',
      decimals: 18,
      name: 'Ferrum Network Token',
      logoURI: 'https://tokens.1inch.io/0xa719b8ab7ea7af0ddb4358719a34631bb79d15dc.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x833f307ac507d47309fd8cdd1f835bef8d702a93',
      symbol: 'REVV',
      decimals: 18,
      name: 'REVV',
      logoURI: 'https://tokens.1inch.io/0x833f307ac507d47309fd8cdd1f835bef8d702a93.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xc10358f062663448a3489fc258139944534592ac',
      symbol: 'BCMC',
      decimals: 18,
      name: 'Blockchain Monster Coin',
      logoURI: 'https://tokens.1inch.io/0xc10358f062663448a3489fc258139944534592ac.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x84e9a6f9d240fdd33801f7135908bfa16866939a',
      symbol: 'GMEE',
      decimals: 18,
      name: 'GAMEE',
      logoURI: 'https://tokens.1inch.io/0x84e9a6f9d240fdd33801f7135908bfa16866939a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xeeeeeb57642040be42185f49c52f7e9b38f8eeee',
      symbol: 'ELK',
      decimals: 18,
      name: 'Elk',
      logoURI: 'https://tokens.1inch.io/0xeeeeeb57642040be42185f49c52f7e9b38f8eeee.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x411ec510c85c9e56271bf4e10364ffa909e685d9',
      symbol: 'MOWA',
      decimals: 18,
      name: 'Moniwar',
      logoURI: 'https://tokens.1inch.io/0x411ec510c85c9e56271bf4e10364ffa909e685d9.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd3b71117e6c1558c1553305b44988cd944e97300',
      symbol: 'YEL',
      decimals: 18,
      name: 'YEL Token',
      logoURI: 'https://tokens.1inch.io/0xd3b71117e6c1558c1553305b44988cd944e97300.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x3192ccddf1cdce4ff055ebc80f3f0231b86a7e30',
      symbol: 'INSUR',
      decimals: 18,
      name: 'Bsc-Peg INSUR Token',
      logoURI: 'https://tokens.1inch.io/0x3192ccddf1cdce4ff055ebc80f3f0231b86a7e30.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf606bd19b1e61574ed625d9ea96c841d4e247a32',
      symbol: 'GUARD',
      decimals: 18,
      name: 'Guardian',
      logoURI: 'https://tokens.1inch.io/0xf606bd19b1e61574ed625d9ea96c841d4e247a32.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x232fb065d9d24c34708eedbf03724f2e95abe768',
      symbol: 'SHEESHA',
      decimals: 18,
      name: 'Sheesha Finance',
      logoURI: 'https://tokens.1inch.io/0x232fb065d9d24c34708eedbf03724f2e95abe768.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xd17479997f34dd9156deef8f95a52d81d265be9c',
      symbol: 'USDD',
      decimals: 18,
      name: 'Decentralized USD',
      logoURI: 'https://tokens.1inch.io/0xd17479997f34dd9156deef8f95a52d81d265be9c.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.BSC,
      address: '0x431e0cd023a32532bf3969cddfc002c00e98429d',
      symbol: 'XCAD',
      decimals: 18,
      name: 'Chainport.io-Peg XCAD Token',
      logoURI: 'https://tokens.1inch.io/0x431e0cd023a32532bf3969cddfc002c00e98429d.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x766afcf83fd5eaf884b3d529b432ca27a6d84617',
      symbol: 'BLID',
      decimals: 18,
      name: 'Bolide',
      logoURI: 'https://tokens.1inch.io/0x766afcf83fd5eaf884b3d529b432ca27a6d84617.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x8f49733210700d38098d7375c221c7d02f700cc8',
      symbol: 'PALLA',
      decimals: 18,
      name: 'Pallapay',
      logoURI: 'https://tokens.1inch.io/0x8f49733210700d38098d7375c221c7d02f700cc8.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xee9801669c6138e84bd50deb500827b776777d28',
      symbol: 'O3',
      decimals: 18,
      name: 'O3 Swap Token',
      logoURI: 'https://tokens.1inch.io/0xee9801669c6138e84bd50deb500827b776777d28.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x0864c156b3c5f69824564dec60c629ae6401bf2a',
      symbol: 'DATA',
      decimals: 18,
      name: 'Streamr',
      logoURI: 'https://tokens.1inch.io/0x0864c156b3c5f69824564dec60c629ae6401bf2a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x715d400f88c167884bbcc41c5fea407ed4d2f8a0',
      symbol: 'AXS',
      decimals: 18,
      name: 'Axie Infinity Shard',
      logoURI: 'https://tokens.1inch.io/0x715d400f88c167884bbcc41c5fea407ed4d2f8a0.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xcfcecfe2bd2fed07a9145222e8a7ad9cf1ccd22a',
      symbol: 'ADS',
      decimals: 11,
      name: 'Adshares',
      logoURI: 'https://tokens.1inch.io/0xcfcecfe2bd2fed07a9145222e8a7ad9cf1ccd22a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x04f73a09e2eb410205be256054794fb452f0d245',
      symbol: 'SALE',
      decimals: 18,
      name: 'DxSale.Network',
      logoURI: 'https://tokens.1inch.io/0x04f73a09e2eb410205be256054794fb452f0d245.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x7bc75e291e656e8658d66be1cc8154a3769a35dd',
      symbol: 'LIME',
      decimals: 18,
      name: 'iMe Lab',
      logoURI: 'https://tokens.1inch.io/0x7bc75e291e656e8658d66be1cc8154a3769a35dd.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5',
      symbol: 'HAY',
      decimals: 18,
      name: 'Hay Stablecoin',
      logoURI: 'https://tokens.1inch.io/0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x6911f552842236bd9e8ea8ddbb3fb414e2c5fa9d',
      symbol: 'SNP',
      decimals: 18,
      name: 'Synapse Network',
      logoURI: 'https://tokens.1inch.io/0x6911f552842236bd9e8ea8ddbb3fb414e2c5fa9d.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xad6742a35fb341a9cc6ad674738dd8da98b94fb1',
      symbol: 'WOM',
      decimals: 18,
      name: 'Wombat Token',
      logoURI: 'https://tokens.1inch.io/0xad6742a35fb341a9cc6ad674738dd8da98b94fb1.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe9cd2668fb580c96b035b6d081e5753f23fe7f46',
      symbol: 'AMA',
      decimals: 18,
      name: 'AMAUROT',
      logoURI: 'https://tokens.1inch.io/0xe9cd2668fb580c96b035b6d081e5753f23fe7f46.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x3c6dad0475d3a1696b359dc04c99fd401be134da',
      symbol: 'SAITO',
      decimals: 18,
      name: 'SAITO',
      logoURI: 'https://tokens.1inch.io/0x3c6dad0475d3a1696b359dc04c99fd401be134da.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xa045e37a0d1dd3a45fefb8803d22457abc0a728a',
      symbol: 'GHNY',
      decimals: 18,
      name: 'Grizzly Honey',
      logoURI: 'https://tokens.1inch.io/0xa045e37a0d1dd3a45fefb8803d22457abc0a728a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf625069dce62df95b4910f83446954b871f0fc4f',
      symbol: 'AMT',
      decimals: 18,
      name: 'Amazy Move Token',
      logoURI: 'https://tokens.1inch.io/0xf625069dce62df95b4910f83446954b871f0fc4f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf4ed363144981d3a65f42e7d0dc54ff9eef559a1',
      symbol: 'FARA',
      decimals: 18,
      name: 'FaraCrystal',
      logoURI: 'https://tokens.1inch.io/0xf4ed363144981d3a65f42e7d0dc54ff9eef559a1.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf4bea2c219eb95c6745983b68185c7340c319d9e',
      symbol: 'TFS',
      decimals: 18,
      name: 'Fairspin Token',
      logoURI: 'https://tokens.1inch.io/0xf4bea2c219eb95c6745983b68185c7340c319d9e.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf2c96e402c9199682d5ded26d3771c6b192c01af',
      symbol: 'SCLP',
      decimals: 18,
      name: 'Scallop',
      logoURI: 'https://tokens.1inch.io/0xf2c96e402c9199682d5ded26d3771c6b192c01af.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xef7d50069406a2f5a53806f7250a6c0f17ad9dcd',
      symbol: 'FIU',
      decimals: 18,
      name: 'beFITTER Token',
      logoURI: 'https://tokens.1inch.io/0xef7d50069406a2f5a53806f7250a6c0f17ad9dcd.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xeb953eda0dc65e3246f43dc8fa13f35623bdd5ed',
      symbol: 'RAINI',
      decimals: 18,
      name: 'Rainicorn',
      logoURI: 'https://tokens.1inch.io/0xeb953eda0dc65e3246f43dc8fa13f35623bdd5ed.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf018aea0a08a5d88674f0837bdac27ab89824dee',
      symbol: 'MMG',
      decimals: 18,
      name: 'MMG Token',
      logoURI: 'https://tokens.1inch.io/0xf018aea0a08a5d88674f0837bdac27ab89824dee.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xeceb87cf00dcbf2d4e2880223743ff087a995ad9',
      symbol: 'NUM',
      decimals: 18,
      name: 'NUM Token [via ChainPort.io]',
      logoURI: 'https://tokens.1inch.io/0xeceb87cf00dcbf2d4e2880223743ff087a995ad9.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe83ce6bfb580583bd6a62b4be7b34fc25f02910d',
      symbol: 'BABBC',
      decimals: 8,
      name: 'Binance ABBC',
      logoURI: 'https://tokens.1inch.io/0xe83ce6bfb580583bd6a62b4be7b34fc25f02910d.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xea51801b8f5b88543ddad3d1727400c15b209d8f',
      symbol: 'INUKO',
      decimals: 18,
      name: 'Inuko Coin',
      logoURI: 'https://tokens.1inch.io/0xea51801b8f5b88543ddad3d1727400c15b209d8f.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe9d7023f2132d55cbd4ee1f78273cb7a3e74f10a',
      symbol: 'DEC',
      decimals: 3,
      name: 'DarkEnergyCrystals',
      logoURI: 'https://tokens.1inch.io/0xe9d7023f2132d55cbd4ee1f78273cb7a3e74f10a.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe9c64384deb0c2bf06d991a8d708c77eb545e3d5',
      symbol: 'RDT',
      decimals: 18,
      name: 'Ridotto',
      logoURI: 'https://tokens.1inch.io/0xe9c64384deb0c2bf06d991a8d708c77eb545e3d5.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xe8670901e86818745b28c8b30b17986958fce8cc',
      symbol: 'XCT',
      decimals: 6,
      name: 'Citadel.one',
      logoURI: 'https://tokens.1inch.io/0xe8670901e86818745b28c8b30b17986958fce8cc.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x2dff88a56767223a5529ea5960da7a3f5f766406',
      symbol: 'ID',
      decimals: 18,
      name: 'SPACE ID',
      logoURI: 'https://tokens.1inch.io/0x2dff88a56767223a5529ea5960da7a3f5f766406.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xf7de7e8a6bd59ed41a4b5fe50278b3b7f31384df',
      symbol: 'RDNT',
      decimals: 18,
      name: 'Radiant',
      logoURI: 'https://tokens.1inch.io/0xf7de7e8a6bd59ed41a4b5fe50278b3b7f31384df.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x40af3827f39d0eacbf4a168f8d4ee67c121d11c9',
      symbol: 'TUSD',
      decimals: 18,
      name: 'TrueUSD',
      logoURI: 'https://tokens.1inch.io/0x40af3827f39d0eacbf4a168f8d4ee67c121d11c9.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.BSC,
      address: '0xa2e3356610840701bdf5611a53974510ae27e2e1',
      symbol: 'wBETH',
      decimals: 18,
      name: 'Wrapped Binance Beacon ETH',
      logoURI: 'https://tokens.1inch.io/0xa2e3356610840701bdf5611a53974510ae27e2e1.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x78a0a62fba6fb21a83fe8a3433d44c73a4017a6f',
      symbol: 'OX_OLD',
      decimals: 18,
      name: 'Open Exchange Token OLD',
    },
    {
      chainId: ChainId.BSC,
      address: '0x0034719300501b06e10ebb1d07ea5967301f0941',
      symbol: 'xALGO',
      decimals: 6,
      name: 'Governance xAlgo',
      logoURI: 'https://tokens.1inch.io/0x0034719300501b06e10ebb1d07ea5967301f0941.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x448d59b4302ab5d2dadf9611bed9457491926c8e',
      symbol: 'axlWBTC',
      decimals: 8,
      name: 'Axelar Wrapped WBTC',
    },
    {
      chainId: ChainId.BSC,
      address: '0xfe7eda5f2c56160d406869a8aa4b2f365d544c7b',
      symbol: 'axlETH',
      decimals: 18,
      name: 'Axelar Wrapped ETH',
    },
    {
      chainId: ChainId.BSC,
      address: '0x43a8cab15d06d3a5fe5854d714c37e7e9246f170',
      symbol: 'ORBS',
      decimals: 18,
      name: 'Token Orbs',
      logoURI: 'https://tokens.1inch.io/0x43a8cab15d06d3a5fe5854d714c37e7e9246f170.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xb72a20c7b8bd666f80ac053b0f4de20a787080f5',
      symbol: 'MLT',
      decimals: 18,
      name: 'Media Licensing Token',
      logoURI: 'https://tokens.1inch.io/0xb72a20c7b8bd666f80ac053b0f4de20a787080f5.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x2b72867c32cf673f7b02d208b26889fed353b1f8',
      symbol: 'SQR',
      decimals: 8,
      name: 'Magic Square',
      logoURI: 'https://tokens.1inch.io/0x2b72867c32cf673f7b02d208b26889fed353b1f8.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x4507cef57c46789ef8d1a19ea45f4216bae2b528',
      symbol: 'TOKEN',
      decimals: 9,
      name: 'TokenFi',
      logoURI: 'https://tokens.1inch.io/0x4507cef57c46789ef8d1a19ea45f4216bae2b528.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0x6d106c0b8d2f47c5465bdbd58d1be253762cbbc1',
      symbol: 'DEFI',
      decimals: 18,
      name: 'DeFi',
      logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/29200.png',
    },
    {
      chainId: ChainId.BSC,
      address: '0xba0dda8762c24da9487f5fa026a9b64b695a07ea',
      symbol: 'OX',
      decimals: 18,
      name: 'OX Coin',
    },
  ],
  [ChainId.AMOY_TESTNET]: [
    {
      symbol: 'LIF3',
      chainId: ChainId.AMOY_TESTNET,
      name: 'LIF3',
      address: '0x293475783df8fc51258b1004aFF1Cb1886BbDa6e',
      decimals: 18,
      logoURI: './assets/svg/lif3.svg',
      eip2612: false,
    },
    {
      symbol: 'LSHARE',
      chainId: ChainId.AMOY_TESTNET,
      name: 'LSHARE',
      address: '0x5eab32fe1d104ce0c5436fedc3433b646096e47c',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/31575/large/LSHARE.png?1693811153',
      eip2612: false,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x255707b70bf90aa112006e1b07b9aea6de021424',
      symbol: 'TETU',
      decimals: 18,
      name: 'TETU Reward Token',
      logoURI: 'https://tokens.1inch.io/0x255707b70bf90aa112006e1b07b9aea6de021424.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x2760e46d9bb43dafcbecaad1f64b93207f9f0ed7',
      symbol: 'MVX',
      decimals: 18,
      name: 'Metavault Trade',
      logoURI: 'https://tokens.1inch.io/0x2760e46d9bb43dafcbecaad1f64b93207f9f0ed7.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x49e6a20f1bbdfeec2a8222e052000bbb14ee6007',
      symbol: 'TNGBL',
      decimals: 18,
      name: 'Tangible',
      logoURI: 'https://tokens.1inch.io/0x49e6a20f1bbdfeec2a8222e052000bbb14ee6007.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590',
      symbol: 'STG',
      decimals: 18,
      name: 'StargateToken',
      logoURI: 'https://tokens.1inch.io/0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x0e9b89007eee9c958c0eda24ef70723c2c93dd58',
      symbol: 'ankrMATIC',
      decimals: 18,
      name: 'Ankr Staked MATIC',
      logoURI: 'https://tokens.1inch.io/0x0e9b89007eee9c958c0eda24ef70723c2c93dd58.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x5e430f88d1be82eb3ef92b6ff06125168fd5dcf2',
      symbol: 'MODA',
      decimals: 18,
      name: 'moda',
      logoURI: 'https://tokens.1inch.io/0x5e430f88d1be82eb3ef92b6ff06125168fd5dcf2.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x750e4c4984a9e0f12978ea6742bc1c5d248f40ed',
      symbol: 'axlUSDC',
      decimals: 6,
      name: 'Axelar Wrapped USDC',
      logoURI: 'https://tokens.1inch.io/0x750e4c4984a9e0f12978ea6742bc1c5d248f40ed.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xefee2de82343be622dcb4e545f75a3b9f50c272d',
      symbol: 'TRY',
      decimals: 18,
      name: 'TryHards',
      logoURI: 'https://tokens.1inch.io/0xefee2de82343be622dcb4e545f75a3b9f50c272d.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x9c9e5fd8bbc25984b178fdce6117defa39d2db39',
      symbol: 'BUSD',
      decimals: 18,
      name: 'BUSD Token',
      logoURI: 'https://tokens.1inch.io/0x9c9e5fd8bbc25984b178fdce6117defa39d2db39.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x2b9e7ccdf0f4e5b24757c1e1a80e311e34cb10c7',
      symbol: 'MASK',
      decimals: 18,
      name: 'Mask Network (PoS)',
      logoURI: 'https://tokens.1inch.io/0x2b9e7ccdf0f4e5b24757c1e1a80e311e34cb10c7.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xf4c83080e80ae530d6f8180572cbbf1ac9d5d435',
      symbol: 'BLANK',
      decimals: 18,
      name: 'GoBlank Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0xf4c83080e80ae530d6f8180572cbbf1ac9d5d435.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x2e1ad108ff1d8c782fcbbb89aad783ac49586756',
      symbol: 'TUSD',
      decimals: 18,
      name: 'TrueUSD (PoS)',
      logoURI: 'https://tokens.1inch.io/0x2e1ad108ff1d8c782fcbbb89aad783ac49586756.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x431d5dff03120afa4bdf332c61a6e1766ef37bdb',
      symbol: 'JPYC',
      decimals: 18,
      name: 'JPY Coin',
      logoURI: 'https://tokens.1inch.io/0x431d5dff03120afa4bdf332c61a6e1766ef37bdb.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x0c9c7712c83b3c70e7c5e11100d33d9401bdf9dd',
      symbol: 'WOMBAT',
      decimals: 18,
      name: 'Wombat',
      logoURI: 'https://tokens.1inch.io/0x0c9c7712c83b3c70e7c5e11100d33d9401bdf9dd.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x7f67639ffc8c93dd558d452b8920b28815638c44',
      symbol: 'LIME',
      decimals: 18,
      name: 'iMe Lab',
      logoURI: 'https://tokens.1inch.io/0x7f67639ffc8c93dd558d452b8920b28815638c44.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xfa68fb4628dff1028cfec22b4162fccd0d45efb6',
      symbol: 'MaticX',
      decimals: 18,
      name: 'Liquid Staking Matic (PoS)',
      logoURI: 'https://tokens.1inch.io/0xfa68fb4628dff1028cfec22b4162fccd0d45efb6.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xc3c7d422809852031b44ab29eec9f1eff2a58756',
      symbol: 'LDO',
      decimals: 18,
      name: 'Lido DAO Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0xc3c7d422809852031b44ab29eec9f1eff2a58756.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b',
      symbol: 'BOB',
      decimals: 18,
      name: 'BOB',
      logoURI: 'https://tokens.1inch.io/0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x29f1e986fca02b7e54138c04c4f503dddd250558',
      symbol: 'VSQ',
      decimals: 9,
      name: 'VSQ',
      logoURI: 'https://tokens.1inch.io/0x29f1e986fca02b7e54138c04c4f503dddd250558.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x55555555a687343c6ce28c8e1f6641dc71659fad',
      symbol: 'XY',
      decimals: 18,
      name: 'XY Token',
      logoURI: 'https://tokens.1inch.io/0x55555555a687343c6ce28c8e1f6641dc71659fad.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x23d29d30e35c5e8d321e1dc9a8a61bfd846d4c5c',
      symbol: 'HEX',
      decimals: 8,
      name: 'HEXX',
      logoURI: 'https://tokens.1inch.io/0x2b591e99afe9f32eaa6214f7b7629768c40eeb39.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xdbf31df14b66535af65aac99c32e9ea844e14501',
      symbol: 'renBTC',
      decimals: 8,
      name: 'renBTC',
      logoURI: 'https://tokens.1inch.io/0xdbf31df14b66535af65aac99c32e9ea844e14501.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x67eb41a14c0fe5cd701fc9d5a3d6597a72f641a6',
      symbol: 'GIDDY',
      decimals: 18,
      name: 'Giddy Token',
      logoURI: 'https://tokens.1inch.io/0x67eb41a14c0fe5cd701fc9d5a3d6597a72f641a6.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xd55fce7cdab84d84f2ef3f99816d765a2a94a509',
      symbol: 'CHAIN',
      decimals: 18,
      name: 'Chain Games',
      logoURI: 'https://tokens.1inch.io/0xd55fce7cdab84d84f2ef3f99816d765a2a94a509.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xd1f9c58e33933a993a3891f8acfe05a68e1afc05',
      symbol: 'SFL',
      decimals: 18,
      name: 'Sunflower Land',
      logoURI: 'https://tokens.1inch.io/0xd1f9c58e33933a993a3891f8acfe05a68e1afc05.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x236eec6359fb44cce8f97e99387aa7f8cd5cde1f',
      symbol: 'USD+',
      decimals: 6,
      name: 'USD+',
      logoURI: 'https://tokens.1inch.io/0x236eec6359fb44cce8f97e99387aa7f8cd5cde1f.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x4e1581f01046efdd7a1a2cdb0f82cdd7f71f2e59',
      symbol: 'ICE_3',
      decimals: 18,
      name: 'IceToken',
      logoURI: 'https://tokens.1inch.io/0x4e1581f01046efdd7a1a2cdb0f82cdd7f71f2e59.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xee7666aacaefaa6efeef62ea40176d3eb21953b9',
      symbol: 'MCHC',
      decimals: 18,
      name: 'MCHCoin (PoS)',
      logoURI: 'https://tokens.1inch.io/0xee7666aacaefaa6efeef62ea40176d3eb21953b9.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xe83ce6bfb580583bd6a62b4be7b34fc25f02910d',
      symbol: 'MABBC',
      decimals: 8,
      name: 'Matic ABBC',
      logoURI: 'https://tokens.1inch.io/0xe83ce6bfb580583bd6a62b4be7b34fc25f02910d.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xe26cda27c13f4f87cffc2f437c5900b27ebb5bbb',
      symbol: 'RBLS',
      decimals: 8,
      name: 'Rebel Bots Token',
      logoURI: 'https://tokens.1inch.io/0xe26cda27c13f4f87cffc2f437c5900b27ebb5bbb.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xe46b4a950c389e80621d10dfc398e91613c7e25e',
      symbol: 'pFi',
      decimals: 18,
      name: 'PartyFinance',
      logoURI: 'https://tokens.1inch.io/0xe46b4a950c389e80621d10dfc398e91613c7e25e.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xd93f7e271cb87c23aaa73edc008a79646d1f9912',
      symbol: 'SOL',
      decimals: 9,
      name: 'Wrapped SOL',
      logoURI: 'https://tokens.1inch.io/0xd93f7e271cb87c23aaa73edc008a79646d1f9912.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x5f0197ba06860dac7e31258bdf749f92b6a636d4',
      symbol: '1FLR',
      decimals: 18,
      name: 'Flare Token',
      logoURI: 'https://tokens.1inch.io/0x5f0197ba06860dac7e31258bdf749f92b6a636d4.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x6911f552842236bd9e8ea8ddbb3fb414e2c5fa9d',
      symbol: 'SNP',
      decimals: 18,
      name: 'Synapse Network',
      logoURI: 'https://tokens.1inch.io/0x6911f552842236bd9e8ea8ddbb3fb414e2c5fa9d.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xd838290e877e0188a4a44700463419ed96c16107',
      symbol: 'NCT',
      decimals: 18,
      name: 'Toucan Protocol: Nature Carbon Tonne',
      logoURI: 'https://tokens.1inch.io/0xd838290e877e0188a4a44700463419ed96c16107.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x2f800db0fdb5223b3c3f354886d907a671414a7f',
      symbol: 'BCT',
      decimals: 18,
      name: 'Toucan Protocol: Base Carbon Tonne',
      logoURI: 'https://tokens.1inch.io/0x2f800db0fdb5223b3c3f354886d907a671414a7f.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4',
      symbol: 'stMATIC',
      decimals: 18,
      name: 'Staked MATIC (PoS)',
      logoURI: 'https://tokens.1inch.io/0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4.png',
      tags: ['PEG:MATIC', 'tokens'],
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x7e4c577ca35913af564ee2a24d882a4946ec492b',
      symbol: 'MOONED',
      decimals: 18,
      name: 'MoonEdge',
      logoURI: 'https://tokens.1inch.io/0x7e4c577ca35913af564ee2a24d882a4946ec492b.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x64060ab139feaae7f06ca4e63189d86adeb51691',
      symbol: 'UNIM',
      decimals: 18,
      name: 'Unicorn Milk',
      logoURI: 'https://tokens.1inch.io/0x64060ab139feaae7f06ca4e63189d86adeb51691.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x2ab4f9ac80f33071211729e45cfc346c1f8446d5',
      symbol: 'CGG',
      decimals: 18,
      name: 'ChainGuardians Governance Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0x2ab4f9ac80f33071211729e45cfc346c1f8446d5.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x04b33078ea1aef29bf3fb29c6ab7b200c58ea126',
      symbol: 'SAFLE',
      decimals: 18,
      name: 'Safle',
      logoURI: 'https://tokens.1inch.io/0x04b33078ea1aef29bf3fb29c6ab7b200c58ea126.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x62f594339830b90ae4c084ae7d223ffafd9658a7',
      symbol: 'SPHERE',
      decimals: 18,
      name: 'Sphere Finance',
      logoURI: 'https://tokens.1inch.io/0x62f594339830b90ae4c084ae7d223ffafd9658a7.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xdb7cb471dd0b49b29cab4a1c14d070f27216a0ab',
      symbol: 'BANK',
      decimals: 18,
      name: 'Bankless Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0xdb7cb471dd0b49b29cab4a1c14d070f27216a0ab.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x23e8b6a3f6891254988b84da3738d2bfe5e703b9',
      symbol: 'WELT',
      decimals: 18,
      name: 'FABWELT',
      logoURI: 'https://tokens.1inch.io/0x23e8b6a3f6891254988b84da3738d2bfe5e703b9.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xfe712251173a2cd5f5be2b46bb528328ea3565e1',
      symbol: 'MVI',
      decimals: 18,
      name: 'Metaverse Index (PoS)',
      logoURI: 'https://tokens.1inch.io/0xfe712251173a2cd5f5be2b46bb528328ea3565e1.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x8839e639f210b80ffea73aedf51baed8dac04499',
      symbol: 'DWEB',
      decimals: 18,
      name: 'DecentraWeb (PoS)',
      logoURI: 'https://tokens.1inch.io/0x8839e639f210b80ffea73aedf51baed8dac04499.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x428360b02c1269bc1c79fbc399ad31d58c1e8fda',
      symbol: 'DEFIT',
      decimals: 18,
      name: 'Digital Fitness',
      logoURI: 'https://tokens.1inch.io/0x428360b02c1269bc1c79fbc399ad31d58c1e8fda.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xff2382bd52efacef02cc895bcbfc4618608aa56f',
      symbol: 'ORARE',
      decimals: 18,
      name: 'One Rare Token',
      logoURI: 'https://tokens.1inch.io/0xff2382bd52efacef02cc895bcbfc4618608aa56f.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x65a05db8322701724c197af82c9cae41195b0aa8',
      symbol: 'FOX',
      decimals: 18,
      name: 'FOX (PoS)',
      logoURI: 'https://tokens.1inch.io/0x65a05db8322701724c197af82c9cae41195b0aa8.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xa69d14d6369e414a32a5c7e729b7afbafd285965',
      symbol: 'GCR',
      decimals: 4,
      name: 'Global Coin Research (PoS)',
      logoURI: 'https://tokens.1inch.io/0xa69d14d6369e414a32a5c7e729b7afbafd285965.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xbd1463f02f61676d53fd183c2b19282bff93d099',
      symbol: 'jCHF',
      decimals: 18,
      name: 'Jarvis Synthetic Swiss Franc',
      logoURI: 'https://tokens.1inch.io/0xbd1463f02f61676d53fd183c2b19282bff93d099.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x8f6196901a4a153d8ee8f3fa779a042f6092d908',
      symbol: 'SALE',
      decimals: 18,
      name: 'DxSale.Network (PoS)',
      logoURI: 'https://tokens.1inch.io/0x8f6196901a4a153d8ee8f3fa779a042f6092d908.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x431cd3c9ac9fc73644bf68bf5691f4b83f9e104f',
      symbol: 'RBW',
      decimals: 18,
      name: 'Rainbow Token',
      logoURI: 'https://tokens.1inch.io/0x431cd3c9ac9fc73644bf68bf5691f4b83f9e104f.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x1599fe55cda767b1f631ee7d414b41f5d6de393d',
      symbol: 'MILK',
      decimals: 18,
      name: 'Milk',
      logoURI: 'https://tokens.1inch.io/0x1599fe55cda767b1f631ee7d414b41f5d6de393d.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x00e5646f60ac6fb446f621d146b6e1886f002905',
      symbol: 'RAI',
      decimals: 18,
      name: 'Rai Reflex Index (PoS)',
      logoURI: 'https://tokens.1inch.io/0x00e5646f60ac6fb446f621d146b6e1886f002905.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x8a0e8b4b0903929f47c3ea30973940d4a9702067',
      symbol: 'INSUR',
      decimals: 18,
      name: 'InsurAce (PoS)',
      logoURI: 'https://tokens.1inch.io/0x8a0e8b4b0903929f47c3ea30973940d4a9702067.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x5fe2b58c013d7601147dcdd68c143a77499f5531',
      symbol: 'GRT',
      decimals: 18,
      name: 'Graph Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0x5fe2b58c013d7601147dcdd68c143a77499f5531.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x187ae45f2d361cbce37c6a8622119c91148f261b',
      symbol: 'POLX',
      decimals: 18,
      name: 'Polylastic',
      logoURI: 'https://tokens.1inch.io/0x187ae45f2d361cbce37c6a8622119c91148f261b.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x1379e8886a944d2d9d440b3d88df536aea08d9f3',
      symbol: 'MYST',
      decimals: 18,
      name: 'Mysterium (PoS)',
      logoURI: 'https://tokens.1inch.io/0x1379e8886a944d2d9d440b3d88df536aea08d9f3.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xf1428850f92b87e629c6f3a3b75bffbc496f7ba6',
      symbol: 'GEO$',
      decimals: 18,
      name: 'GEOPOLY',
      logoURI: 'https://tokens.1inch.io/0xf1428850f92b87e629c6f3a3b75bffbc496f7ba6.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xab3d689c22a2bb821f50a4ff0f21a7980dcb8591',
      symbol: 'PRXY',
      decimals: 18,
      name: 'Proxy',
      logoURI: 'https://tokens.1inch.io/0xab3d689c22a2bb821f50a4ff0f21a7980dcb8591.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xa486c6bc102f409180ccb8a94ba045d39f8fc7cb',
      symbol: 'NEX',
      decimals: 8,
      name: 'Nash Exchange Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0xa486c6bc102f409180ccb8a94ba045d39f8fc7cb.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xec38621e72d86775a89c7422746de1f52bba5320',
      symbol: 'DAVOS',
      decimals: 18,
      name: 'Davos',
      logoURI: 'https://tokens.1inch.io/0xec38621e72d86775a89c7422746de1f52bba5320.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xa3c322ad15218fbfaed26ba7f616249f7705d945',
      symbol: 'MV',
      decimals: 18,
      name: 'Metaverse (PoS)',
      logoURI: 'https://tokens.1inch.io/0xa3c322ad15218fbfaed26ba7f616249f7705d945.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xd8ca34fd379d9ca3c6ee3b3905678320f5b45195',
      symbol: 'gOHM',
      decimals: 18,
      name: 'Governance OHM',
      logoURI: 'https://tokens.1inch.io/0xd8ca34fd379d9ca3c6ee3b3905678320f5b45195.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x8f9e8e833a69aa467e42c46cca640da84dd4585f',
      symbol: 'CHAMP',
      decimals: 8,
      name: 'NFT Champions',
      logoURI: 'https://tokens.1inch.io/0x8f9e8e833a69aa467e42c46cca640da84dd4585f.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xc004e2318722ea2b15499d6375905d75ee5390b8',
      symbol: 'KOM',
      decimals: 8,
      name: 'Kommunitas',
      logoURI: 'https://tokens.1inch.io/0xc004e2318722ea2b15499d6375905d75ee5390b8.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x42d61d766b85431666b39b89c43011f24451bff6',
      symbol: 'PSP',
      decimals: 18,
      name: 'ParaSwap (PoS)',
      logoURI: 'https://tokens.1inch.io/0x42d61d766b85431666b39b89c43011f24451bff6.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xe5417af564e4bfda1c483642db72007871397896',
      symbol: 'GNS',
      decimals: 18,
      name: 'Gains Network',
      logoURI: 'https://tokens.1inch.io/0xe5417af564e4bfda1c483642db72007871397896.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xd0258a3fd00f38aa8090dfee343f10a9d4d30d3f',
      symbol: 'VOXEL',
      decimals: 18,
      name: 'VOXEL Token',
      logoURI: 'https://tokens.1inch.io/0xd0258a3fd00f38aa8090dfee343f10a9d4d30d3f.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x723b17718289a91af252d616de2c77944962d122',
      symbol: 'GAIA',
      decimals: 18,
      name: 'GAIA Everworld',
      logoURI: 'https://tokens.1inch.io/0x723b17718289a91af252d616de2c77944962d122.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x70c006878a5a50ed185ac4c87d837633923de296',
      symbol: 'REVV',
      decimals: 18,
      name: 'REVV',
      logoURI: 'https://tokens.1inch.io/0x70c006878a5a50ed185ac4c87d837633923de296.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x0d0b8488222f7f83b23e365320a4021b12ead608',
      symbol: 'NXTT',
      decimals: 18,
      name: 'NextEarthToken',
      logoURI: 'https://tokens.1inch.io/0x0d0b8488222f7f83b23e365320a4021b12ead608.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x071ac29d569a47ebffb9e57517f855cb577dcc4c',
      symbol: 'GFC',
      decimals: 18,
      name: 'GCOIN',
      logoURI: 'https://tokens.1inch.io/0x071ac29d569a47ebffb9e57517f855cb577dcc4c.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      symbol: 'MATIC',
      decimals: 18,
      name: 'MATIC',
      logoURI: 'https://tokens.1inch.io/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png',
      tags: ['native'],
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xd6df932a45c0f255f85145f286ea0b292b21c90b',
      symbol: 'AAVE',
      decimals: 18,
      name: 'Aave',
      logoURI: 'https://tokens.1inch.io/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
      symbol: 'MUST',
      decimals: 18,
      name: 'Must',
      logoURI: 'https://tokens.1inch.io/0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xb33eaad8d922b1083446dc23f610c2567fb5180f',
      symbol: 'UNI',
      decimals: 18,
      name: 'Uniswap',
      logoURI: 'https://tokens.1inch.io/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
      symbol: 'USDT',
      decimals: 6,
      name: 'Tether USD',
      logoURI: 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
      symbol: 'DAI',
      decimals: 18,
      name: '(PoS) Dai Stablecoin',
      logoURI: 'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
      symbol: 'WBTC',
      decimals: 8,
      name: 'Wrapped BTC',
      logoURI: 'https://tokens.1inch.io/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599.png',
      tags: ['PEG:BTC', 'tokens'],
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39',
      symbol: 'LINK',
      decimals: 18,
      name: 'ChainLink Token',
      logoURI: 'https://tokens.1inch.io/0x514910771af9ca656af840dff83e8264ecf986ca.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xa1428174f516f527fafdd146b883bb4428682737',
      symbol: 'SUPER',
      decimals: 18,
      name: 'SuperFarm',
      logoURI: 'https://tokens.1inch.io/0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4',
      symbol: 'MANA',
      decimals: 18,
      name: 'Decentraland MANA',
      logoURI: 'https://tokens.1inch.io/0x0f5d2fb29fb7d3cfee444a200298f468908cc942.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
      symbol: 'WMATIC',
      decimals: 18,
      name: 'Wrapped Matic',
      logoURI: 'https://tokens.1inch.io/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x8505b9d2254a7ae468c0e9dd10ccea3a837aef5c',
      symbol: 'COMP',
      decimals: 18,
      name: 'Compound',
      logoURI: 'https://tokens.1inch.io/0xc00e94cb662c3520282e6f5717214004a7f26888.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x033d942a6b495c4071083f4cde1f17e986fe856c',
      symbol: 'AGA',
      decimals: 4,
      name: 'AGA Token',
      logoURI: 'https://tokens.1inch.io/0x2d80f5f5328fdcb6eceb7cacf5dd8aedaec94e20.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xf84bd51eab957c2e7b7d646a3427c5a50848281d',
      symbol: 'AGAr',
      decimals: 8,
      name: 'AGA Rewards',
      logoURI: 'https://tokens.1inch.io/0xb453f1f2ee776daf2586501361c457db70e1ca0f.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xd28449bb9bb659725accad52947677cce3719fd7',
      symbol: 'DMT',
      decimals: 18,
      name: 'Dark Matter Token',
      logoURI: 'https://tokens.1inch.io/0xd28449bb9bb659725accad52947677cce3719fd7.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
      symbol: 'ETH',
      decimals: 18,
      name: 'Ether',
      logoURI: 'https://tokens.1inch.io/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x385eeac5cb85a38a9a07a70c73e0a3271cfb54a7',
      symbol: 'GHST',
      decimals: 18,
      name: 'Aavegotchi GHST Token',
      logoURI: 'https://tokens.1inch.io/0x3f382dbd960e3a9bbceae22651e88158d2791550.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x6968105460f67c3bf751be7c15f92f5286fd0ce5',
      symbol: 'MONA',
      decimals: 18,
      name: 'Monavale',
      logoURI: 'https://tokens.1inch.io/0x275f5ad03be0fa221b4c6649b8aee09a42d9412a.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xc3ec80343d2bae2f8e680fdadde7c17e71e114ea',
      symbol: 'OM',
      decimals: 18,
      name: 'OM',
      logoURI: 'https://tokens.1inch.io/0x3593d125a4f7849a1b059e64f4517a86dd60c95d.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x2b88ad57897a8b496595925f43048301c37615da',
      symbol: 'PICKLE',
      decimals: 18,
      name: 'PickleToken',
      logoURI: 'https://tokens.1inch.io/0x429881672b9ae42b8eba0e26cd9c73711b891ca5.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x361a5a4993493ce00f61c32d4ecca5512b82ce90',
      symbol: 'SDT',
      decimals: 18,
      name: 'Stake DAO Token',
      logoURI: 'https://tokens.1inch.io/0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a',
      symbol: 'SUSHI',
      decimals: 18,
      name: 'SushiToken',
      logoURI: 'https://tokens.1inch.io/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x840195888db4d6a99ed9f73fcd3b225bb3cb1a79',
      symbol: 'SX',
      decimals: 18,
      name: 'SportX',
      logoURI: 'https://tokens.1inch.io/0x99fe3b1391503a1bc1788051347a1324bff41452.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x034b2090b579228482520c589dbd397c53fc51cc',
      symbol: 'VISION',
      decimals: 18,
      name: 'Vision Token',
      logoURI: 'https://tokens.1inch.io/0x034b2090b579228482520c589dbd397c53fc51cc.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xb77e62709e39ad1cbeebe77cf493745aec0f453a',
      symbol: 'WISE',
      decimals: 18,
      name: 'Wise Token',
      logoURI: 'https://tokens.1inch.io/0x66a0f676479cee1d7373f3dc2e2952778bff5bd6.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x8f18dc399594b451eda8c5da02d0563c0b2d0f16',
      symbol: 'WOLF',
      decimals: 9,
      name: 'moonwolf.io',
      logoURI: 'https://tokens.1inch.io/0x8f18dc399594b451eda8c5da02d0563c0b2d0f16.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xab0b2ddb9c7e440fac8e140a89c0dbcbf2d7bbff',
      symbol: 'iFARM',
      decimals: 18,
      name: 'iFARM',
      logoURI: 'https://tokens.1inch.io/0xa0246c9032bc3a600820415ae600c6388619a14d.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x282d8efce846a88b159800bd4130ad77443fa1a1',
      symbol: 'mOCEAN',
      decimals: 18,
      name: 'Ocean Token',
      logoURI: 'https://tokens.1inch.io/0x967da4048cd07ab37855c090aaf366e4ce1b9f48.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x7075cab6bcca06613e2d071bd918d1a0241379e2',
      symbol: 'GFARM2',
      decimals: 18,
      name: 'Gains V2',
      logoURI: 'https://tokens.1inch.io/0x7075cab6bcca06613e2d071bd918d1a0241379e2.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x05089c9ebffa4f0aca269e32056b1b36b37ed71b',
      symbol: 'Krill',
      decimals: 18,
      name: 'Krill',
      logoURI: 'https://tokens.1inch.io/0x05089c9ebffa4f0aca269e32056b1b36b37ed71b.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xe82808eaa78339b06a691fd92e1be79671cad8d3',
      symbol: 'PLOT',
      decimals: 18,
      name: 'PLOT',
      logoURI: 'https://tokens.1inch.io/0x72f020f8f3e8fd9382705723cd26380f8d0c66bb.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xdf7837de1f2fa4631d716cf2502f8b230f1dcc32',
      symbol: 'TEL',
      decimals: 2,
      name: 'Telcoin',
      logoURI: 'https://tokens.1inch.io/0x467bccd9d29f223bce8043b84e8c8b282827790f.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x1a13f4ca1d028320a707d99520abfefca3998b7f',
      symbol: 'amUSDC',
      decimals: 6,
      name: 'Aave Matic Market USDC',
      logoURI: 'https://tokens.1inch.io/0xbcca60bb61934080951369a648fb03df4f96263c.png',
      eip2612: true,
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x60d55f02a771d515e077c9c2403a1ef324885cec',
      symbol: 'amUSDT',
      decimals: 6,
      name: 'Aave Matic Market USDT',
      logoURI: 'https://tokens.1inch.io/0x3ed3b47dd13ec9a98b44e6204a523e766b225811.png',
      eip2612: true,
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x5c2ed810328349100a66b82b78a1791b101c9d61',
      symbol: 'amWBTC',
      decimals: 8,
      name: 'Aave Matic Market WBTC',
      logoURI: 'https://tokens.1inch.io/0x9ff58f4ffb29fa2266ab25e75e2a8b3503311656.png',
      eip2612: true,
      tags: ['PEG:BTC', 'tokens'],
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x28424507fefb6f7f8e9d3860f56504e4e5f5f390',
      symbol: 'amWETH',
      decimals: 18,
      name: 'Aave Matic Market WETH',
      logoURI: 'https://tokens.1inch.io/0x030ba81f1c18d280636f32af80b9aad02cf0854e.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4',
      symbol: 'amWMATIC',
      decimals: 18,
      name: 'Aave Matic Market WMATIC',
      logoURI: 'https://tokens.1inch.io/0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x1d2a0e5ec8e5bbdca5cb219e649b565d8e5c3360',
      symbol: 'amAAVE',
      decimals: 18,
      name: 'Aave Matic Market AAVE',
      logoURI: 'https://tokens.1inch.io/0xffc97d72e13e01096502cb8eb52dee56f74dad7b.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x27f8d03b3a2196956ed754badc28d73be8830a6e',
      symbol: 'amDAI',
      decimals: 18,
      name: 'Aave Matic Market DAI',
      logoURI: 'https://tokens.1inch.io/0x028171bca77440897b824ca71d1c56cac55b68a3.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x172370d5cd63279efa6d502dab29171933a610af',
      symbol: 'CRV',
      decimals: 18,
      name: 'CRV',
      logoURI: 'https://tokens.1inch.io/0xd533a949740bb3306d119cc777fa900ba034cd52.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x3a3df212b7aa91aa0402b9035b098891d276572b',
      symbol: 'FISH',
      decimals: 18,
      name: 'Fish',
      logoURI: 'https://tokens.1inch.io/0x3a3df212b7aa91aa0402b9035b098891d276572b.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x8a953cfe442c5e8855cc6c61b1293fa648bae472',
      symbol: 'PolyDoge',
      decimals: 18,
      name: 'PolyDoge',
      logoURI: 'https://tokens.1inch.io/0x8a953cfe442c5e8855cc6c61b1293fa648bae472.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xaaa5b9e6c589642f98a1cda99b9d024b8407285a',
      symbol: 'TITAN',
      decimals: 18,
      name: 'IRON Titanium Token',
      logoURI: 'https://tokens.1inch.io/0xaaa5b9e6c589642f98a1cda99b9d024b8407285a.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
      symbol: 'DFYN',
      decimals: 18,
      name: 'DFYN Token',
      logoURI: 'https://tokens.1inch.io/0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603',
      symbol: 'WOO',
      decimals: 18,
      name: 'Wootrade Network',
      logoURI: 'https://tokens.1inch.io/0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x4c4bf319237d98a30a929a96112effa8da3510eb',
      symbol: 'WEXpoly',
      decimals: 18,
      name: 'WaultSwap AMOY_TESTNET',
      logoURI: 'https://tokens.1inch.io/0x4c4bf319237d98a30a929a96112effa8da3510eb.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x580a84c73811e1839f75d86d75d88cca0c241ff4',
      symbol: 'QI',
      decimals: 18,
      name: 'Qi Dao',
      logoURI: 'https://tokens.1inch.io/0x580a84c73811e1839f75d86d75d88cca0c241ff4.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xa3fa99a148fa48d14ed51d610c367c61876997f1',
      symbol: 'miMATIC',
      decimals: 18,
      name: 'miMATIC',
      logoURI: 'https://tokens.1inch.io/0xa3fa99a148fa48d14ed51d610c367c61876997f1.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x76e63a3e7ba1e2e61d3da86a87479f983de89a7e',
      symbol: 'OMEN',
      decimals: 18,
      name: 'Augury Finance',
      logoURI: 'https://tokens.1inch.io/0x76e63a3e7ba1e2e61d3da86a87479f983de89a7e.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c',
      symbol: 'KNC',
      decimals: 18,
      name: 'Kyber Network Crystal v2',
      logoURI: 'https://tokens.1inch.io/0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3',
      symbol: 'BAL',
      decimals: 18,
      name: 'Balancer',
      logoURI: 'https://tokens.1inch.io/0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x4a81f8796e0c6ad4877a51c86693b0de8093f2ef',
      symbol: 'ICE_1',
      decimals: 18,
      name: 'Iron Finance ICE Token',
      logoURI: 'https://tokens.1inch.io/0x4a81f8796e0c6ad4877a51c86693b0de8093f2ef.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xc3fdbadc7c795ef1d6ba111e06ff8f16a20ea539',
      symbol: 'ADDY',
      decimals: 18,
      name: 'Adamant',
      logoURI: 'https://tokens.1inch.io/0xc3fdbadc7c795ef1d6ba111e06ff8f16a20ea539.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x692597b009d13c4049a947cab2239b7d6517875f',
      symbol: 'UST',
      decimals: 18,
      name: 'Wrapped UST Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0x692597b009d13c4049a947cab2239b7d6517875f.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x689f8e5913c158ffb5ac5aeb83b3c875f5d20309',
      symbol: 'SNK',
      decimals: 18,
      name: 'Snook',
      logoURI: 'https://tokens.1inch.io/0x689f8e5913c158ffb5ac5aeb83b3c875f5d20309.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xdab35042e63e93cc8556c9bae482e5415b5ac4b1',
      symbol: 'IRIS',
      decimals: 18,
      name: 'Iris',
      logoURI: 'https://tokens.1inch.io/0xdab35042e63e93cc8556c9bae482e5415b5ac4b1.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x3a3e7650f8b9f667da98f236010fbf44ee4b2975',
      symbol: 'xUSD',
      decimals: 18,
      name: 'xDollar Stablecoin',
      logoURI: 'https://tokens.1inch.io/0x3a3e7650f8b9f667da98f236010fbf44ee4b2975.png',
      eip2612: true,
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x13748d548d95d78a3c83fe3f32604b4796cffa23',
      symbol: 'KOGECOIN',
      decimals: 9,
      name: 'kogecoin.io',
      logoURI: 'https://tokens.1inch.io/0x13748d548d95d78a3c83fe3f32604b4796cffa23.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xe111178a87a3bff0c8d18decba5798827539ae99',
      symbol: 'EURS',
      decimals: 2,
      name: 'STASIS EURS Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0xe111178a87a3bff0c8d18decba5798827539ae99.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x6c0ab120dbd11ba701aff6748568311668f63fe0',
      symbol: 'APW',
      decimals: 18,
      name: 'APWine Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0x4104b135dbc9609fc1a9490e61369036497660c8.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x8c92e38eca8210f4fcbf17f0951b198dd7668292',
      symbol: 'DHT',
      decimals: 18,
      name: 'dHedge DAO Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0x8c92e38eca8210f4fcbf17f0951b198dd7668292.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xcd86152047e800d67bdf00a4c635a8b6c0e5c4c2',
      symbol: 'NACHO',
      decimals: 18,
      name: 'NACHO',
      logoURI: 'https://tokens.1inch.io/0xcd86152047e800d67bdf00a4c635a8b6c0e5c4c2.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xf50d05a1402d0adafa880d36050736f9f6ee7dee',
      symbol: 'INST',
      decimals: 18,
      name: 'Instadapp (PoS)',
      logoURI: 'https://tokens.1inch.io/0xf50d05a1402d0adafa880d36050736f9f6ee7dee.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x1ba17c639bdaecd8dc4aac37df062d17ee43a1b8',
      symbol: 'WIXS',
      decimals: 18,
      name: 'Wrapped Ixs Token',
      logoURI: 'https://tokens.1inch.io/0x1ba17c639bdaecd8dc4aac37df062d17ee43a1b8.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xe0b52e49357fd4daf2c15e02058dce6bc0057db4',
      symbol: 'agEUR',
      decimals: 18,
      name: 'agEUR',
      logoURI: 'https://tokens.1inch.io/0xe0b52e49357fd4daf2c15e02058dce6bc0057db4.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa',
      symbol: 'deUSDC',
      decimals: 6,
      name: 'deBridge USD Coin',
      logoURI: 'https://tokens.1inch.io/0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa_2.png',
      eip2612: true,
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x45c32fa6df82ead1e2ef74d17b76547eddfaff89',
      symbol: 'FRAX',
      decimals: 18,
      name: 'Frax',
      logoURI: 'https://tokens.1inch.io/0x45c32fa6df82ead1e2ef74d17b76547eddfaff89.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xb25e20de2f2ebb4cffd4d16a55c7b395e8a94762',
      symbol: 'REQ',
      decimals: 18,
      name: 'Request',
      logoURI: 'https://tokens.1inch.io/0xb25e20de2f2ebb4cffd4d16a55c7b395e8a94762.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x59b5654a17ac44f3068b3882f298881433bb07ef',
      symbol: 'CHP',
      decimals: 18,
      name: 'CoinPoker Chips (PoS)',
      logoURI: 'https://tokens.1inch.io/0x59b5654a17ac44f3068b3882f298881433bb07ef.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x8623e66bea0dce41b6d47f9c44e806a115babae0',
      symbol: 'NFTY',
      decimals: 18,
      name: 'NFTY Token',
      logoURI: 'https://tokens.1inch.io/0x8623e66bea0dce41b6d47f9c44e806a115babae0.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x4ff0b68abc2b9e4e1401e9b691dba7d66b264ac8',
      symbol: 'RIOT',
      decimals: 18,
      name: 'RIOT (PoS)',
      logoURI: 'https://tokens.1inch.io/0x4ff0b68abc2b9e4e1401e9b691dba7d66b264ac8.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xba3cb8329d442e6f9eb70fafe1e214251df3d275',
      symbol: 'SWASH',
      decimals: 18,
      name: 'Swash Token',
      logoURI: 'https://tokens.1inch.io/0xba3cb8329d442e6f9eb70fafe1e214251df3d275.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xd52f6ca48882be8fbaa98ce390db18e1dbe1062d',
      symbol: 'ORE',
      decimals: 18,
      name: 'pTokens ORE (PoS)',
      logoURI: 'https://tokens.1inch.io/0xd52f6ca48882be8fbaa98ce390db18e1dbe1062d.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xeeeeeb57642040be42185f49c52f7e9b38f8eeee',
      symbol: 'ELK',
      decimals: 18,
      name: 'Elk',
      logoURI: 'https://tokens.1inch.io/0xeeeeeb57642040be42185f49c52f7e9b38f8eeee.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xfe4546fefe124f30788c4cc1bb9aa6907a7987f9',
      symbol: 'cxETH',
      decimals: 18,
      name: 'CelsiusX Wrapped ETH',
      logoURI: 'https://tokens.1inch.io/0xfe4546fefe124f30788c4cc1bb9aa6907a7987f9.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x34667ed7c36cbbbf2d5d5c5c8d6eb76a094edb9f',
      symbol: 'GENE',
      decimals: 18,
      name: 'GenomesDAO (PoS)',
      logoURI: 'https://tokens.1inch.io/0x34667ed7c36cbbbf2d5d5c5c8d6eb76a094edb9f.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xc1c93d475dc82fe72dbc7074d55f5a734f8ceeae',
      symbol: 'PGX',
      decimals: 18,
      name: 'Pegaxy Stone',
      logoURI: 'https://tokens.1inch.io/0xc1c93d475dc82fe72dbc7074d55f5a734f8ceeae.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xe06bd4f5aac8d0aa337d13ec88db6defc6eaeefe',
      symbol: 'IXT',
      decimals: 18,
      name: 'PlanetIX',
      logoURI: 'https://tokens.1inch.io/0xe06bd4f5aac8d0aa337d13ec88db6defc6eaeefe.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xbbba073c31bf03b8acf7c28ef0738decf3695683',
      symbol: 'SAND',
      decimals: 18,
      name: 'SAND',
      logoURI: 'https://tokens.1inch.io/0xbbba073c31bf03b8acf7c28ef0738decf3695683.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xc6c855ad634dcdad23e64da71ba85b8c51e5ad7c',
      symbol: 'ICE_2',
      decimals: 18,
      name: 'Decentral Games ICE',
      logoURI: 'https://tokens.1inch.io/0xc6c855ad634dcdad23e64da71ba85b8c51e5ad7c.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x50b728d8d964fd00c2d0aad81718b71311fef68a',
      symbol: 'SNX',
      decimals: 18,
      name: 'Synthetix Network Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0x50b728d8d964fd00c2d0aad81718b71311fef68a.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x229b1b6c23ff8953d663c4cbb519717e323a0a84',
      symbol: 'BLOK',
      decimals: 18,
      name: 'BLOK',
      logoURI: 'https://tokens.1inch.io/0x229b1b6c23ff8953d663c4cbb519717e323a0a84.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x3b56a704c01d650147ade2b8cee594066b3f9421',
      symbol: 'FYN',
      decimals: 18,
      name: 'Affyn',
      logoURI: 'https://tokens.1inch.io/0x3b56a704c01d650147ade2b8cee594066b3f9421.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x4e78011ce80ee02d2c3e649fb657e45898257815',
      symbol: 'KLIMA',
      decimals: 9,
      name: 'Klima DAO',
      logoURI: 'https://tokens.1inch.io/0x4e78011ce80ee02d2c3e649fb657e45898257815.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x2c89bbc92bd86f8075d1decc58c7f4e0107f286b',
      symbol: 'AVAX',
      decimals: 18,
      name: 'Avalanche Token',
      logoURI: 'https://tokens.1inch.io/0x2c89bbc92bd86f8075d1decc58c7f4e0107f286b.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4',
      symbol: 'ROUTE (PoS)',
      decimals: 18,
      name: 'Route',
      logoURI: 'https://tokens.1inch.io/0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xef938b6da8576a896f6e0321ef80996f4890f9c4',
      symbol: 'DG',
      decimals: 18,
      name: 'Decentral Games (PoS)',
      logoURI: 'https://tokens.1inch.io/0xef938b6da8576a896f6e0321ef80996f4890f9c4.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x2bc07124d8dac638e290f401046ad584546bc47b',
      symbol: 'TOWER',
      decimals: 18,
      name: 'TOWER',
      logoURI: 'https://tokens.1inch.io/0x2bc07124d8dac638e290f401046ad584546bc47b.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x235737dbb56e8517391473f7c964db31fa6ef280',
      symbol: 'KASTA',
      decimals: 18,
      name: 'KastaToken',
      logoURI: 'https://tokens.1inch.io/0x235737dbb56e8517391473f7c964db31fa6ef280.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xb35fcbcf1fd489fce02ee146599e893fdcdc60e6',
      symbol: 'DERC',
      decimals: 18,
      name: 'DeRace Token',
      logoURI: 'https://tokens.1inch.io/0xb35fcbcf1fd489fce02ee146599e893fdcdc60e6.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xe8377a076adabb3f9838afb77bee96eac101ffb1',
      symbol: 'MSU',
      decimals: 18,
      name: 'MetaSoccer Universe',
      logoURI: 'https://tokens.1inch.io/0xe8377a076adabb3f9838afb77bee96eac101ffb1.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x5d47baba0d66083c52009271faf3f50dcc01023c',
      symbol: 'BANANA',
      decimals: 18,
      name: 'ApeSwapFinance Banana',
      logoURI: 'https://tokens.1inch.io/0x5d47baba0d66083c52009271faf3f50dcc01023c.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xc10358f062663448a3489fc258139944534592ac',
      symbol: 'BCMC',
      decimals: 18,
      name: 'Blockchain Monster Coin',
      logoURI: 'https://tokens.1inch.io/0xc10358f062663448a3489fc258139944534592ac.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x3b1a0c9252ee7403093ff55b4a5886d49a3d837a',
      symbol: 'UM',
      decimals: 18,
      name: 'Continuum',
      logoURI: 'https://tokens.1inch.io/0x3b1a0c9252ee7403093ff55b4a5886d49a3d837a.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xcf32822ff397ef82425153a9dcb726e5ff61dca7',
      symbol: 'GMEE',
      decimals: 18,
      name: 'GAMEE',
      logoURI: 'https://tokens.1inch.io/0xcf32822ff397ef82425153a9dcb726e5ff61dca7.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
      symbol: 'USDC_1',
      decimals: 6,
      name: 'USD Coin (PoS)',
      logoURI: 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xe7804d91dfcde7f776c90043e03eaa6df87e6395',
      symbol: 'DFX',
      decimals: 18,
      name: 'DFX Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0xe7804d91dfcde7f776c90043e03eaa6df87e6395.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xe2aa7db6da1dae97c5f5c6914d285fbfcc32a128',
      symbol: 'PAR',
      decimals: 18,
      name: 'PAR Stablecoin',
      logoURI: 'https://tokens.1inch.io/0xe2aa7db6da1dae97c5f5c6914d285fbfcc32a128.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x88c949b4eb85a90071f2c0bef861bddee1a7479d',
      symbol: 'mSHEESHA',
      decimals: 18,
      name: 'SHEESHA AMOY_TESTNET',
      logoURI: 'https://tokens.1inch.io/0x88c949b4eb85a90071f2c0bef861bddee1a7479d.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x51b5619f5180e333d18b6310c8d540aea43a0371',
      symbol: 'VHC',
      decimals: 18,
      name: 'Vault Hill City (PoS)',
      logoURI: 'https://tokens.1inch.io/0x51b5619f5180e333d18b6310c8d540aea43a0371.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x82362ec182db3cf7829014bc61e9be8a2e82868a',
      symbol: 'MESH',
      decimals: 18,
      name: 'Meshswap Protocol',
      logoURI: 'https://tokens.1inch.io/0x82362ec182db3cf7829014bc61e9be8a2e82868a.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x228b5c21ac00155cf62c57bcc704c0da8187950b',
      symbol: 'NXD',
      decimals: 18,
      name: 'Nexus Dubai',
      logoURI: 'https://tokens.1inch.io/0x228b5c21ac00155cf62c57bcc704c0da8187950b.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xa55870278d6389ec5b524553d03c04f5677c061e',
      symbol: 'XCAD',
      decimals: 18,
      name: 'XCAD Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0xa55870278d6389ec5b524553d03c04f5677c061e.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x8c4476dfec8e7eedf2de3e9e9461b7c14c828d46',
      symbol: 'UNIX',
      decimals: 18,
      name: 'UniX Gaming (PoS)',
      logoURI: 'https://tokens.1inch.io/0x8c4476dfec8e7eedf2de3e9e9461b7c14c828d46.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x02649c1ff4296038de4b9ba8f491b42b940a8252',
      symbol: 'XGEM',
      decimals: 18,
      name: 'Exchange Genesis Ethlas Medium',
      logoURI: 'https://tokens.1inch.io/0x02649c1ff4296038de4b9ba8f491b42b940a8252.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xb9638272ad6998708de56bbc0a290a1de534a578',
      symbol: 'IQ',
      decimals: 18,
      name: 'Everipedia IQ (PoS)',
      logoURI: 'https://tokens.1inch.io/0xb9638272ad6998708de56bbc0a290a1de534a578.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x614389eaae0a6821dc49062d56bda3d9d45fa2ff',
      symbol: 'ORBS',
      decimals: 18,
      name: 'Orbs (PoS)',
      logoURI: 'https://tokens.1inch.io/0x614389eaae0a6821dc49062d56bda3d9d45fa2ff.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x30de46509dbc3a491128f97be0aaf70dc7ff33cb',
      symbol: 'XZAR',
      decimals: 18,
      name: 'South African Tether (PoS)',
      logoURI: 'https://tokens.1inch.io/0x30de46509dbc3a491128f97be0aaf70dc7ff33cb.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xd4945a3d0de9923035521687d4bf18cc9b0c7c2a',
      symbol: 'LUXY',
      decimals: 18,
      name: 'LUXY',
      logoURI: 'https://tokens.1inch.io/0xd4945a3d0de9923035521687d4bf18cc9b0c7c2a.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c',
      symbol: 'jEUR',
      decimals: 18,
      name: 'Jarvis Synthetic Euro',
      logoURI: 'https://tokens.1inch.io/0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x695fc8b80f344411f34bdbcb4e621aa69ada384b',
      symbol: 'NITRO',
      decimals: 18,
      name: 'Nitro (PoS)',
      logoURI: 'https://tokens.1inch.io/0x695fc8b80f344411f34bdbcb4e621aa69ada384b.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xdb725f82818de83e99f1dac22a9b5b51d3d04dd4',
      symbol: 'GET',
      decimals: 18,
      name: 'GET Protocol (PoS)',
      logoURI: 'https://tokens.1inch.io/0xdb725f82818de83e99f1dac22a9b5b51d3d04dd4.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x3a9a81d576d83ff21f26f325066054540720fc34',
      symbol: 'DATA',
      decimals: 18,
      name: 'Streamr',
      logoURI: 'https://tokens.1inch.io/0x3a9a81d576d83ff21f26f325066054540720fc34.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x2934b36ca9a4b31e633c5be670c8c8b28b6aa015',
      symbol: 'THX',
      decimals: 18,
      name: 'THX Network (PoS)',
      logoURI: 'https://tokens.1inch.io/0x2934b36ca9a4b31e633c5be670c8c8b28b6aa015.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xee9a352f6aac4af1a5b9f467f6a93e0ffbe9dd35',
      symbol: 'MASQ',
      decimals: 18,
      name: 'MASQ (PoS)',
      logoURI: 'https://tokens.1inch.io/0xee9a352f6aac4af1a5b9f467f6a93e0ffbe9dd35.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x44d09156c7b4acf0c64459fbcced7613f5519918',
      symbol: '$KMC',
      decimals: 18,
      name: '$KMC',
      logoURI: 'https://tokens.1inch.io/0x44d09156c7b4acf0c64459fbcced7613f5519918.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x598e49f01befeb1753737934a5b11fea9119c796',
      symbol: 'ADS',
      decimals: 11,
      name: 'Adshares (PoS)',
      logoURI: 'https://tokens.1inch.io/0x598e49f01befeb1753737934a5b11fea9119c796.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x8346ab8d5ea7a9db0209aed2d1806afa0e2c4c21',
      symbol: 'MOD',
      decimals: 18,
      name: 'MODEFI (PoS)',
      logoURI: 'https://tokens.1inch.io/0x8346ab8d5ea7a9db0209aed2d1806afa0e2c4c21.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x64ca1571d1476b7a21c5aaf9f1a750a193a103c0',
      symbol: 'BONDLY',
      decimals: 18,
      name: 'Bondly (PoS)',
      logoURI: 'https://tokens.1inch.io/0x64ca1571d1476b7a21c5aaf9f1a750a193a103c0.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x82a0e6c02b91ec9f6ff943c0a933c03dbaa19689',
      symbol: 'WNT',
      decimals: 18,
      name: 'Wicrypt Network Token',
      logoURI: 'https://tokens.1inch.io/0x82a0e6c02b91ec9f6ff943c0a933c03dbaa19689.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xc5b57e9a1e7914fda753a88f24e5703e617ee50c',
      symbol: 'POP',
      decimals: 18,
      name: 'Popcorn (PoS)',
      logoURI: 'https://tokens.1inch.io/0xc5b57e9a1e7914fda753a88f24e5703e617ee50c.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xdfce1e99a31c4597a3f8a8945cbfa9037655e335',
      symbol: 'ASTRAFER',
      decimals: 18,
      name: 'Astrafer',
      logoURI: 'https://tokens.1inch.io/0xdfce1e99a31c4597a3f8a8945cbfa9037655e335.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xdc3326e71d45186f113a2f448984ca0e8d201995',
      symbol: 'XSGD',
      decimals: 6,
      name: 'XSGD',
      logoURI: 'https://tokens.1inch.io/0xdc3326e71d45186f113a2f448984ca0e8d201995.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xdab625853c2b35d0a9c6bd8e5a097a664ef4ccfb',
      symbol: 'eQUAD',
      decimals: 18,
      name: 'Quadrant Protocol',
      logoURI: 'https://tokens.1inch.io/0xdab625853c2b35d0a9c6bd8e5a097a664ef4ccfb.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xc17c30e98541188614df99239cabd40280810ca3',
      symbol: 'RISE',
      decimals: 18,
      name: 'EverRise',
      logoURI: 'https://tokens.1inch.io/0xc17c30e98541188614df99239cabd40280810ca3.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x9c32185b81766a051e08de671207b34466dd1021',
      symbol: 'BTCpx',
      decimals: 8,
      name: 'BTC Proxy',
      logoURI: 'https://tokens.1inch.io/0x9c32185b81766a051e08de671207b34466dd1021.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xb5c064f955d8e7f38fe0460c556a72987494ee17',
      symbol: 'QUICK',
      decimals: 18,
      name: 'QuickSwap',
      logoURI: 'https://tokens.1inch.io/0xb5c064f955d8e7f38fe0460c556a72987494ee17.png',
      eip2612: true,
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x3c720206bfacb2d16fa3ac0ed87d2048dbc401fc',
      symbol: 'UCO',
      decimals: 18,
      name: 'UnirisToken (PoS)',
      logoURI: 'https://tokens.1inch.io/0x3c720206bfacb2d16fa3ac0ed87d2048dbc401fc.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x34d4ab47bee066f361fa52d792e69ac7bd05ee23',
      symbol: 'AURUM',
      decimals: 18,
      name: 'RaiderAurum',
      logoURI: 'https://tokens.1inch.io/0x34d4ab47bee066f361fa52d792e69ac7bd05ee23.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xcd7361ac3307d1c5a46b63086a90742ff44c63b3',
      symbol: 'RAIDER',
      decimals: 18,
      name: 'RaiderToken',
      logoURI: 'https://tokens.1inch.io/0xcd7361ac3307d1c5a46b63086a90742ff44c63b3.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x90f3edc7d5298918f7bb51694134b07356f7d0c7',
      symbol: 'DDAO',
      decimals: 18,
      name: 'DEFI HUNTERS DAO Token',
      logoURI: 'https://tokens.1inch.io/0x90f3edc7d5298918f7bb51694134b07356f7d0c7.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xd3b71117e6c1558c1553305b44988cd944e97300',
      symbol: 'YEL',
      decimals: 18,
      name: 'YEL Token',
      logoURI: 'https://tokens.1inch.io/0xd3b71117e6c1558c1553305b44988cd944e97300.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x03b54a6e9a984069379fae1a4fc4dbae93b3bccd',
      symbol: 'wstETH',
      decimals: 18,
      name: 'Wrapped liquid staked Ether 2.0 (PoS)',
      logoURI: 'https://tokens.1inch.io/0x03b54a6e9a984069379fae1a4fc4dbae93b3bccd.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x081ec4c0e30159c8259bad8f4887f83010a681dc',
      symbol: 'DE',
      decimals: 18,
      name: 'DeNet File Token',
      logoURI: 'https://tokens.1inch.io/0x081ec4c0e30159c8259bad8f4887f83010a681dc.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xef6ab48ef8dfe984fab0d5c4cd6aff2e54dfda14',
      symbol: 'CRISP-M',
      decimals: 18,
      name: 'CRISP Scored Mangroves',
      logoURI: 'https://tokens.1inch.io/0xef6ab48ef8dfe984fab0d5c4cd6aff2e54dfda14.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x78a0a62fba6fb21a83fe8a3433d44c73a4017a6f',
      symbol: 'OX_OLD',
      decimals: 18,
      name: 'Open Exchange Token OLD',
      logoURI: 'https://tokens.1inch.io/0x78a0a62fba6fb21a83fe8a3433d44c73a4017a6f.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xbfa35599c7aebb0dace9b5aa3ca5f2a79624d8eb',
      symbol: 'RETRO',
      decimals: 18,
      name: 'RETRO',
      logoURI: 'https://tokens.1inch.io/0xbfa35599c7aebb0dace9b5aa3ca5f2a79624d8eb.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
      symbol: 'USDC',
      decimals: 6,
      name: 'Native USD Coin (PoS)',
      logoURI: 'https://tokens.1inch.io/0x3c499c542cef5e3811e1192ce70d8cc03d5c3359.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xc19669a405067927865b40ea045a2baabbbe57f5',
      symbol: 'STAR',
      decimals: 18,
      name: 'STAR',
      logoURI: 'https://tokens.1inch.io/0xc19669a405067927865b40ea045a2baabbbe57f5.png',
    },
    {
      chainId: ChainId.AMOY_TESTNET,
      address: '0xba0dda8762c24da9487f5fa026a9b64b695a07ea',
      symbol: 'OX',
      decimals: 18,
      name: 'OX Coin',
    },
  ],
  [ChainId.POLYGON]: [
    {
      symbol: 'L3USD',
      chainId: 137,
      name: 'L3USD',
      address: '0x2c2d8a078b33bf7782a16acce2c5ba6653a90d5f',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/26937/large/L3USD.png?1660999834',
      rating: 13,
      eip2612: false,
    },
    {
      symbol: 'LIF3',
      chainId: ChainId.POLYGON,
      name: 'LIF3',
      address: '0x110b25d2b21ee73eb401f3ae7833f7072912a0bf',
      decimals: 18,
      logoURI: './assets/svg/lif3.svg',
      eip2612: false,
    },
    {
      symbol: 'LSHARE',
      chainId: ChainId.POLYGON,
      name: 'LSHARE',
      address: '0x5eab32fe1d104ce0c5436fedc3433b646096e47c',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/31575/large/LSHARE.png?1693811153',
      eip2612: false,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x255707b70bf90aa112006e1b07b9aea6de021424',
      symbol: 'TETU',
      decimals: 18,
      name: 'TETU Reward Token',
      logoURI: 'https://tokens.1inch.io/0x255707b70bf90aa112006e1b07b9aea6de021424.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x2760e46d9bb43dafcbecaad1f64b93207f9f0ed7',
      symbol: 'MVX',
      decimals: 18,
      name: 'Metavault Trade',
      logoURI: 'https://tokens.1inch.io/0x2760e46d9bb43dafcbecaad1f64b93207f9f0ed7.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x49e6a20f1bbdfeec2a8222e052000bbb14ee6007',
      symbol: 'TNGBL',
      decimals: 18,
      name: 'Tangible',
      logoURI: 'https://tokens.1inch.io/0x49e6a20f1bbdfeec2a8222e052000bbb14ee6007.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590',
      symbol: 'STG',
      decimals: 18,
      name: 'StargateToken',
      logoURI: 'https://tokens.1inch.io/0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x0e9b89007eee9c958c0eda24ef70723c2c93dd58',
      symbol: 'ankrMATIC',
      decimals: 18,
      name: 'Ankr Staked MATIC',
      logoURI: 'https://tokens.1inch.io/0x0e9b89007eee9c958c0eda24ef70723c2c93dd58.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x5e430f88d1be82eb3ef92b6ff06125168fd5dcf2',
      symbol: 'MODA',
      decimals: 18,
      name: 'moda',
      logoURI: 'https://tokens.1inch.io/0x5e430f88d1be82eb3ef92b6ff06125168fd5dcf2.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x750e4c4984a9e0f12978ea6742bc1c5d248f40ed',
      symbol: 'axlUSDC',
      decimals: 6,
      name: 'Axelar Wrapped USDC',
      logoURI: 'https://tokens.1inch.io/0x750e4c4984a9e0f12978ea6742bc1c5d248f40ed.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xefee2de82343be622dcb4e545f75a3b9f50c272d',
      symbol: 'TRY',
      decimals: 18,
      name: 'TryHards',
      logoURI: 'https://tokens.1inch.io/0xefee2de82343be622dcb4e545f75a3b9f50c272d.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x9c9e5fd8bbc25984b178fdce6117defa39d2db39',
      symbol: 'BUSD',
      decimals: 18,
      name: 'BUSD Token',
      logoURI: 'https://tokens.1inch.io/0x9c9e5fd8bbc25984b178fdce6117defa39d2db39.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x2b9e7ccdf0f4e5b24757c1e1a80e311e34cb10c7',
      symbol: 'MASK',
      decimals: 18,
      name: 'Mask Network (PoS)',
      logoURI: 'https://tokens.1inch.io/0x2b9e7ccdf0f4e5b24757c1e1a80e311e34cb10c7.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xf4c83080e80ae530d6f8180572cbbf1ac9d5d435',
      symbol: 'BLANK',
      decimals: 18,
      name: 'GoBlank Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0xf4c83080e80ae530d6f8180572cbbf1ac9d5d435.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x2e1ad108ff1d8c782fcbbb89aad783ac49586756',
      symbol: 'TUSD',
      decimals: 18,
      name: 'TrueUSD (PoS)',
      logoURI: 'https://tokens.1inch.io/0x2e1ad108ff1d8c782fcbbb89aad783ac49586756.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x431d5dff03120afa4bdf332c61a6e1766ef37bdb',
      symbol: 'JPYC',
      decimals: 18,
      name: 'JPY Coin',
      logoURI: 'https://tokens.1inch.io/0x431d5dff03120afa4bdf332c61a6e1766ef37bdb.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x0c9c7712c83b3c70e7c5e11100d33d9401bdf9dd',
      symbol: 'WOMBAT',
      decimals: 18,
      name: 'Wombat',
      logoURI: 'https://tokens.1inch.io/0x0c9c7712c83b3c70e7c5e11100d33d9401bdf9dd.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x7f67639ffc8c93dd558d452b8920b28815638c44',
      symbol: 'LIME',
      decimals: 18,
      name: 'iMe Lab',
      logoURI: 'https://tokens.1inch.io/0x7f67639ffc8c93dd558d452b8920b28815638c44.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xfa68fb4628dff1028cfec22b4162fccd0d45efb6',
      symbol: 'MaticX',
      decimals: 18,
      name: 'Liquid Staking Matic (PoS)',
      logoURI: 'https://tokens.1inch.io/0xfa68fb4628dff1028cfec22b4162fccd0d45efb6.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xc3c7d422809852031b44ab29eec9f1eff2a58756',
      symbol: 'LDO',
      decimals: 18,
      name: 'Lido DAO Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0xc3c7d422809852031b44ab29eec9f1eff2a58756.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b',
      symbol: 'BOB',
      decimals: 18,
      name: 'BOB',
      logoURI: 'https://tokens.1inch.io/0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x29f1e986fca02b7e54138c04c4f503dddd250558',
      symbol: 'VSQ',
      decimals: 9,
      name: 'VSQ',
      logoURI: 'https://tokens.1inch.io/0x29f1e986fca02b7e54138c04c4f503dddd250558.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x55555555a687343c6ce28c8e1f6641dc71659fad',
      symbol: 'XY',
      decimals: 18,
      name: 'XY Token',
      logoURI: 'https://tokens.1inch.io/0x55555555a687343c6ce28c8e1f6641dc71659fad.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x23d29d30e35c5e8d321e1dc9a8a61bfd846d4c5c',
      symbol: 'HEX',
      decimals: 8,
      name: 'HEXX',
      logoURI: 'https://tokens.1inch.io/0x2b591e99afe9f32eaa6214f7b7629768c40eeb39.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xdbf31df14b66535af65aac99c32e9ea844e14501',
      symbol: 'renBTC',
      decimals: 8,
      name: 'renBTC',
      logoURI: 'https://tokens.1inch.io/0xdbf31df14b66535af65aac99c32e9ea844e14501.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x67eb41a14c0fe5cd701fc9d5a3d6597a72f641a6',
      symbol: 'GIDDY',
      decimals: 18,
      name: 'Giddy Token',
      logoURI: 'https://tokens.1inch.io/0x67eb41a14c0fe5cd701fc9d5a3d6597a72f641a6.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xd55fce7cdab84d84f2ef3f99816d765a2a94a509',
      symbol: 'CHAIN',
      decimals: 18,
      name: 'Chain Games',
      logoURI: 'https://tokens.1inch.io/0xd55fce7cdab84d84f2ef3f99816d765a2a94a509.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xd1f9c58e33933a993a3891f8acfe05a68e1afc05',
      symbol: 'SFL',
      decimals: 18,
      name: 'Sunflower Land',
      logoURI: 'https://tokens.1inch.io/0xd1f9c58e33933a993a3891f8acfe05a68e1afc05.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x236eec6359fb44cce8f97e99387aa7f8cd5cde1f',
      symbol: 'USD+',
      decimals: 6,
      name: 'USD+',
      logoURI: 'https://tokens.1inch.io/0x236eec6359fb44cce8f97e99387aa7f8cd5cde1f.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x4e1581f01046efdd7a1a2cdb0f82cdd7f71f2e59',
      symbol: 'ICE_3',
      decimals: 18,
      name: 'IceToken',
      logoURI: 'https://tokens.1inch.io/0x4e1581f01046efdd7a1a2cdb0f82cdd7f71f2e59.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xee7666aacaefaa6efeef62ea40176d3eb21953b9',
      symbol: 'MCHC',
      decimals: 18,
      name: 'MCHCoin (PoS)',
      logoURI: 'https://tokens.1inch.io/0xee7666aacaefaa6efeef62ea40176d3eb21953b9.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xe83ce6bfb580583bd6a62b4be7b34fc25f02910d',
      symbol: 'MABBC',
      decimals: 8,
      name: 'Matic ABBC',
      logoURI: 'https://tokens.1inch.io/0xe83ce6bfb580583bd6a62b4be7b34fc25f02910d.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xe26cda27c13f4f87cffc2f437c5900b27ebb5bbb',
      symbol: 'RBLS',
      decimals: 8,
      name: 'Rebel Bots Token',
      logoURI: 'https://tokens.1inch.io/0xe26cda27c13f4f87cffc2f437c5900b27ebb5bbb.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xe46b4a950c389e80621d10dfc398e91613c7e25e',
      symbol: 'pFi',
      decimals: 18,
      name: 'PartyFinance',
      logoURI: 'https://tokens.1inch.io/0xe46b4a950c389e80621d10dfc398e91613c7e25e.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xd93f7e271cb87c23aaa73edc008a79646d1f9912',
      symbol: 'SOL',
      decimals: 9,
      name: 'Wrapped SOL',
      logoURI: 'https://tokens.1inch.io/0xd93f7e271cb87c23aaa73edc008a79646d1f9912.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x5f0197ba06860dac7e31258bdf749f92b6a636d4',
      symbol: '1FLR',
      decimals: 18,
      name: 'Flare Token',
      logoURI: 'https://tokens.1inch.io/0x5f0197ba06860dac7e31258bdf749f92b6a636d4.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x6911f552842236bd9e8ea8ddbb3fb414e2c5fa9d',
      symbol: 'SNP',
      decimals: 18,
      name: 'Synapse Network',
      logoURI: 'https://tokens.1inch.io/0x6911f552842236bd9e8ea8ddbb3fb414e2c5fa9d.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xd838290e877e0188a4a44700463419ed96c16107',
      symbol: 'NCT',
      decimals: 18,
      name: 'Toucan Protocol: Nature Carbon Tonne',
      logoURI: 'https://tokens.1inch.io/0xd838290e877e0188a4a44700463419ed96c16107.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x2f800db0fdb5223b3c3f354886d907a671414a7f',
      symbol: 'BCT',
      decimals: 18,
      name: 'Toucan Protocol: Base Carbon Tonne',
      logoURI: 'https://tokens.1inch.io/0x2f800db0fdb5223b3c3f354886d907a671414a7f.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4',
      symbol: 'stMATIC',
      decimals: 18,
      name: 'Staked MATIC (PoS)',
      logoURI: 'https://tokens.1inch.io/0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4.png',
      tags: ['PEG:MATIC', 'tokens'],
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x7e4c577ca35913af564ee2a24d882a4946ec492b',
      symbol: 'MOONED',
      decimals: 18,
      name: 'MoonEdge',
      logoURI: 'https://tokens.1inch.io/0x7e4c577ca35913af564ee2a24d882a4946ec492b.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x64060ab139feaae7f06ca4e63189d86adeb51691',
      symbol: 'UNIM',
      decimals: 18,
      name: 'Unicorn Milk',
      logoURI: 'https://tokens.1inch.io/0x64060ab139feaae7f06ca4e63189d86adeb51691.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x2ab4f9ac80f33071211729e45cfc346c1f8446d5',
      symbol: 'CGG',
      decimals: 18,
      name: 'ChainGuardians Governance Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0x2ab4f9ac80f33071211729e45cfc346c1f8446d5.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x04b33078ea1aef29bf3fb29c6ab7b200c58ea126',
      symbol: 'SAFLE',
      decimals: 18,
      name: 'Safle',
      logoURI: 'https://tokens.1inch.io/0x04b33078ea1aef29bf3fb29c6ab7b200c58ea126.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x62f594339830b90ae4c084ae7d223ffafd9658a7',
      symbol: 'SPHERE',
      decimals: 18,
      name: 'Sphere Finance',
      logoURI: 'https://tokens.1inch.io/0x62f594339830b90ae4c084ae7d223ffafd9658a7.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xdb7cb471dd0b49b29cab4a1c14d070f27216a0ab',
      symbol: 'BANK',
      decimals: 18,
      name: 'Bankless Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0xdb7cb471dd0b49b29cab4a1c14d070f27216a0ab.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x23e8b6a3f6891254988b84da3738d2bfe5e703b9',
      symbol: 'WELT',
      decimals: 18,
      name: 'FABWELT',
      logoURI: 'https://tokens.1inch.io/0x23e8b6a3f6891254988b84da3738d2bfe5e703b9.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xfe712251173a2cd5f5be2b46bb528328ea3565e1',
      symbol: 'MVI',
      decimals: 18,
      name: 'Metaverse Index (PoS)',
      logoURI: 'https://tokens.1inch.io/0xfe712251173a2cd5f5be2b46bb528328ea3565e1.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x8839e639f210b80ffea73aedf51baed8dac04499',
      symbol: 'DWEB',
      decimals: 18,
      name: 'DecentraWeb (PoS)',
      logoURI: 'https://tokens.1inch.io/0x8839e639f210b80ffea73aedf51baed8dac04499.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x428360b02c1269bc1c79fbc399ad31d58c1e8fda',
      symbol: 'DEFIT',
      decimals: 18,
      name: 'Digital Fitness',
      logoURI: 'https://tokens.1inch.io/0x428360b02c1269bc1c79fbc399ad31d58c1e8fda.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xff2382bd52efacef02cc895bcbfc4618608aa56f',
      symbol: 'ORARE',
      decimals: 18,
      name: 'One Rare Token',
      logoURI: 'https://tokens.1inch.io/0xff2382bd52efacef02cc895bcbfc4618608aa56f.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x65a05db8322701724c197af82c9cae41195b0aa8',
      symbol: 'FOX',
      decimals: 18,
      name: 'FOX (PoS)',
      logoURI: 'https://tokens.1inch.io/0x65a05db8322701724c197af82c9cae41195b0aa8.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xa69d14d6369e414a32a5c7e729b7afbafd285965',
      symbol: 'GCR',
      decimals: 4,
      name: 'Global Coin Research (PoS)',
      logoURI: 'https://tokens.1inch.io/0xa69d14d6369e414a32a5c7e729b7afbafd285965.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xbd1463f02f61676d53fd183c2b19282bff93d099',
      symbol: 'jCHF',
      decimals: 18,
      name: 'Jarvis Synthetic Swiss Franc',
      logoURI: 'https://tokens.1inch.io/0xbd1463f02f61676d53fd183c2b19282bff93d099.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x8f6196901a4a153d8ee8f3fa779a042f6092d908',
      symbol: 'SALE',
      decimals: 18,
      name: 'DxSale.Network (PoS)',
      logoURI: 'https://tokens.1inch.io/0x8f6196901a4a153d8ee8f3fa779a042f6092d908.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x431cd3c9ac9fc73644bf68bf5691f4b83f9e104f',
      symbol: 'RBW',
      decimals: 18,
      name: 'Rainbow Token',
      logoURI: 'https://tokens.1inch.io/0x431cd3c9ac9fc73644bf68bf5691f4b83f9e104f.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x1599fe55cda767b1f631ee7d414b41f5d6de393d',
      symbol: 'MILK',
      decimals: 18,
      name: 'Milk',
      logoURI: 'https://tokens.1inch.io/0x1599fe55cda767b1f631ee7d414b41f5d6de393d.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x00e5646f60ac6fb446f621d146b6e1886f002905',
      symbol: 'RAI',
      decimals: 18,
      name: 'Rai Reflex Index (PoS)',
      logoURI: 'https://tokens.1inch.io/0x00e5646f60ac6fb446f621d146b6e1886f002905.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x8a0e8b4b0903929f47c3ea30973940d4a9702067',
      symbol: 'INSUR',
      decimals: 18,
      name: 'InsurAce (PoS)',
      logoURI: 'https://tokens.1inch.io/0x8a0e8b4b0903929f47c3ea30973940d4a9702067.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x5fe2b58c013d7601147dcdd68c143a77499f5531',
      symbol: 'GRT',
      decimals: 18,
      name: 'Graph Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0x5fe2b58c013d7601147dcdd68c143a77499f5531.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x187ae45f2d361cbce37c6a8622119c91148f261b',
      symbol: 'POLX',
      decimals: 18,
      name: 'Polylastic',
      logoURI: 'https://tokens.1inch.io/0x187ae45f2d361cbce37c6a8622119c91148f261b.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x1379e8886a944d2d9d440b3d88df536aea08d9f3',
      symbol: 'MYST',
      decimals: 18,
      name: 'Mysterium (PoS)',
      logoURI: 'https://tokens.1inch.io/0x1379e8886a944d2d9d440b3d88df536aea08d9f3.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xf1428850f92b87e629c6f3a3b75bffbc496f7ba6',
      symbol: 'GEO$',
      decimals: 18,
      name: 'GEOPOLY',
      logoURI: 'https://tokens.1inch.io/0xf1428850f92b87e629c6f3a3b75bffbc496f7ba6.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xab3d689c22a2bb821f50a4ff0f21a7980dcb8591',
      symbol: 'PRXY',
      decimals: 18,
      name: 'Proxy',
      logoURI: 'https://tokens.1inch.io/0xab3d689c22a2bb821f50a4ff0f21a7980dcb8591.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xa486c6bc102f409180ccb8a94ba045d39f8fc7cb',
      symbol: 'NEX',
      decimals: 8,
      name: 'Nash Exchange Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0xa486c6bc102f409180ccb8a94ba045d39f8fc7cb.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xec38621e72d86775a89c7422746de1f52bba5320',
      symbol: 'DAVOS',
      decimals: 18,
      name: 'Davos',
      logoURI: 'https://tokens.1inch.io/0xec38621e72d86775a89c7422746de1f52bba5320.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xa3c322ad15218fbfaed26ba7f616249f7705d945',
      symbol: 'MV',
      decimals: 18,
      name: 'Metaverse (PoS)',
      logoURI: 'https://tokens.1inch.io/0xa3c322ad15218fbfaed26ba7f616249f7705d945.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xd8ca34fd379d9ca3c6ee3b3905678320f5b45195',
      symbol: 'gOHM',
      decimals: 18,
      name: 'Governance OHM',
      logoURI: 'https://tokens.1inch.io/0xd8ca34fd379d9ca3c6ee3b3905678320f5b45195.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x8f9e8e833a69aa467e42c46cca640da84dd4585f',
      symbol: 'CHAMP',
      decimals: 8,
      name: 'NFT Champions',
      logoURI: 'https://tokens.1inch.io/0x8f9e8e833a69aa467e42c46cca640da84dd4585f.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xc004e2318722ea2b15499d6375905d75ee5390b8',
      symbol: 'KOM',
      decimals: 8,
      name: 'Kommunitas',
      logoURI: 'https://tokens.1inch.io/0xc004e2318722ea2b15499d6375905d75ee5390b8.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x42d61d766b85431666b39b89c43011f24451bff6',
      symbol: 'PSP',
      decimals: 18,
      name: 'ParaSwap (PoS)',
      logoURI: 'https://tokens.1inch.io/0x42d61d766b85431666b39b89c43011f24451bff6.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xe5417af564e4bfda1c483642db72007871397896',
      symbol: 'GNS',
      decimals: 18,
      name: 'Gains Network',
      logoURI: 'https://tokens.1inch.io/0xe5417af564e4bfda1c483642db72007871397896.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xd0258a3fd00f38aa8090dfee343f10a9d4d30d3f',
      symbol: 'VOXEL',
      decimals: 18,
      name: 'VOXEL Token',
      logoURI: 'https://tokens.1inch.io/0xd0258a3fd00f38aa8090dfee343f10a9d4d30d3f.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x723b17718289a91af252d616de2c77944962d122',
      symbol: 'GAIA',
      decimals: 18,
      name: 'GAIA Everworld',
      logoURI: 'https://tokens.1inch.io/0x723b17718289a91af252d616de2c77944962d122.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x70c006878a5a50ed185ac4c87d837633923de296',
      symbol: 'REVV',
      decimals: 18,
      name: 'REVV',
      logoURI: 'https://tokens.1inch.io/0x70c006878a5a50ed185ac4c87d837633923de296.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x0d0b8488222f7f83b23e365320a4021b12ead608',
      symbol: 'NXTT',
      decimals: 18,
      name: 'NextEarthToken',
      logoURI: 'https://tokens.1inch.io/0x0d0b8488222f7f83b23e365320a4021b12ead608.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x071ac29d569a47ebffb9e57517f855cb577dcc4c',
      symbol: 'GFC',
      decimals: 18,
      name: 'GCOIN',
      logoURI: 'https://tokens.1inch.io/0x071ac29d569a47ebffb9e57517f855cb577dcc4c.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      symbol: 'POL',
      decimals: 18,
      name: 'POL',
      logoURI: 'https://tokens.1inch.io/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png',
      tags: ['native'],
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xd6df932a45c0f255f85145f286ea0b292b21c90b',
      symbol: 'AAVE',
      decimals: 18,
      name: 'Aave',
      logoURI: 'https://tokens.1inch.io/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
      symbol: 'MUST',
      decimals: 18,
      name: 'Must',
      logoURI: 'https://tokens.1inch.io/0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xb33eaad8d922b1083446dc23f610c2567fb5180f',
      symbol: 'UNI',
      decimals: 18,
      name: 'Uniswap',
      logoURI: 'https://tokens.1inch.io/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
      symbol: 'USDT',
      decimals: 6,
      name: 'Tether USD',
      logoURI: 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
      symbol: 'DAI',
      decimals: 18,
      name: '(PoS) Dai Stablecoin',
      logoURI: 'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
      symbol: 'WBTC',
      decimals: 8,
      name: 'Wrapped BTC',
      logoURI: 'https://tokens.1inch.io/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599.png',
      tags: ['PEG:BTC', 'tokens'],
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39',
      symbol: 'LINK',
      decimals: 18,
      name: 'ChainLink Token',
      logoURI: 'https://tokens.1inch.io/0x514910771af9ca656af840dff83e8264ecf986ca.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xa1428174f516f527fafdd146b883bb4428682737',
      symbol: 'SUPER',
      decimals: 18,
      name: 'SuperFarm',
      logoURI: 'https://tokens.1inch.io/0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4',
      symbol: 'MANA',
      decimals: 18,
      name: 'Decentraland MANA',
      logoURI: 'https://tokens.1inch.io/0x0f5d2fb29fb7d3cfee444a200298f468908cc942.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
      symbol: 'WMATIC',
      decimals: 18,
      name: 'Wrapped Matic',
      logoURI: 'https://tokens.1inch.io/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x8505b9d2254a7ae468c0e9dd10ccea3a837aef5c',
      symbol: 'COMP',
      decimals: 18,
      name: 'Compound',
      logoURI: 'https://tokens.1inch.io/0xc00e94cb662c3520282e6f5717214004a7f26888.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x033d942a6b495c4071083f4cde1f17e986fe856c',
      symbol: 'AGA',
      decimals: 4,
      name: 'AGA Token',
      logoURI: 'https://tokens.1inch.io/0x2d80f5f5328fdcb6eceb7cacf5dd8aedaec94e20.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xf84bd51eab957c2e7b7d646a3427c5a50848281d',
      symbol: 'AGAr',
      decimals: 8,
      name: 'AGA Rewards',
      logoURI: 'https://tokens.1inch.io/0xb453f1f2ee776daf2586501361c457db70e1ca0f.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xd28449bb9bb659725accad52947677cce3719fd7',
      symbol: 'DMT',
      decimals: 18,
      name: 'Dark Matter Token',
      logoURI: 'https://tokens.1inch.io/0xd28449bb9bb659725accad52947677cce3719fd7.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
      symbol: 'ETH',
      decimals: 18,
      name: 'Ether',
      logoURI: 'https://tokens.1inch.io/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x385eeac5cb85a38a9a07a70c73e0a3271cfb54a7',
      symbol: 'GHST',
      decimals: 18,
      name: 'Aavegotchi GHST Token',
      logoURI: 'https://tokens.1inch.io/0x3f382dbd960e3a9bbceae22651e88158d2791550.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x6968105460f67c3bf751be7c15f92f5286fd0ce5',
      symbol: 'MONA',
      decimals: 18,
      name: 'Monavale',
      logoURI: 'https://tokens.1inch.io/0x275f5ad03be0fa221b4c6649b8aee09a42d9412a.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xc3ec80343d2bae2f8e680fdadde7c17e71e114ea',
      symbol: 'OM',
      decimals: 18,
      name: 'OM',
      logoURI: 'https://tokens.1inch.io/0x3593d125a4f7849a1b059e64f4517a86dd60c95d.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x2b88ad57897a8b496595925f43048301c37615da',
      symbol: 'PICKLE',
      decimals: 18,
      name: 'PickleToken',
      logoURI: 'https://tokens.1inch.io/0x429881672b9ae42b8eba0e26cd9c73711b891ca5.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x361a5a4993493ce00f61c32d4ecca5512b82ce90',
      symbol: 'SDT',
      decimals: 18,
      name: 'Stake DAO Token',
      logoURI: 'https://tokens.1inch.io/0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a',
      symbol: 'SUSHI',
      decimals: 18,
      name: 'SushiToken',
      logoURI: 'https://tokens.1inch.io/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x840195888db4d6a99ed9f73fcd3b225bb3cb1a79',
      symbol: 'SX',
      decimals: 18,
      name: 'SportX',
      logoURI: 'https://tokens.1inch.io/0x99fe3b1391503a1bc1788051347a1324bff41452.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x034b2090b579228482520c589dbd397c53fc51cc',
      symbol: 'VISION',
      decimals: 18,
      name: 'Vision Token',
      logoURI: 'https://tokens.1inch.io/0x034b2090b579228482520c589dbd397c53fc51cc.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xb77e62709e39ad1cbeebe77cf493745aec0f453a',
      symbol: 'WISE',
      decimals: 18,
      name: 'Wise Token',
      logoURI: 'https://tokens.1inch.io/0x66a0f676479cee1d7373f3dc2e2952778bff5bd6.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x8f18dc399594b451eda8c5da02d0563c0b2d0f16',
      symbol: 'WOLF',
      decimals: 9,
      name: 'moonwolf.io',
      logoURI: 'https://tokens.1inch.io/0x8f18dc399594b451eda8c5da02d0563c0b2d0f16.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xab0b2ddb9c7e440fac8e140a89c0dbcbf2d7bbff',
      symbol: 'iFARM',
      decimals: 18,
      name: 'iFARM',
      logoURI: 'https://tokens.1inch.io/0xa0246c9032bc3a600820415ae600c6388619a14d.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x282d8efce846a88b159800bd4130ad77443fa1a1',
      symbol: 'mOCEAN',
      decimals: 18,
      name: 'Ocean Token',
      logoURI: 'https://tokens.1inch.io/0x967da4048cd07ab37855c090aaf366e4ce1b9f48.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x7075cab6bcca06613e2d071bd918d1a0241379e2',
      symbol: 'GFARM2',
      decimals: 18,
      name: 'Gains V2',
      logoURI: 'https://tokens.1inch.io/0x7075cab6bcca06613e2d071bd918d1a0241379e2.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x05089c9ebffa4f0aca269e32056b1b36b37ed71b',
      symbol: 'Krill',
      decimals: 18,
      name: 'Krill',
      logoURI: 'https://tokens.1inch.io/0x05089c9ebffa4f0aca269e32056b1b36b37ed71b.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xe82808eaa78339b06a691fd92e1be79671cad8d3',
      symbol: 'PLOT',
      decimals: 18,
      name: 'PLOT',
      logoURI: 'https://tokens.1inch.io/0x72f020f8f3e8fd9382705723cd26380f8d0c66bb.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xdf7837de1f2fa4631d716cf2502f8b230f1dcc32',
      symbol: 'TEL',
      decimals: 2,
      name: 'Telcoin',
      logoURI: 'https://tokens.1inch.io/0x467bccd9d29f223bce8043b84e8c8b282827790f.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x1a13f4ca1d028320a707d99520abfefca3998b7f',
      symbol: 'amUSDC',
      decimals: 6,
      name: 'Aave Matic Market USDC',
      logoURI: 'https://tokens.1inch.io/0xbcca60bb61934080951369a648fb03df4f96263c.png',
      eip2612: true,
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x60d55f02a771d515e077c9c2403a1ef324885cec',
      symbol: 'amUSDT',
      decimals: 6,
      name: 'Aave Matic Market USDT',
      logoURI: 'https://tokens.1inch.io/0x3ed3b47dd13ec9a98b44e6204a523e766b225811.png',
      eip2612: true,
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x5c2ed810328349100a66b82b78a1791b101c9d61',
      symbol: 'amWBTC',
      decimals: 8,
      name: 'Aave Matic Market WBTC',
      logoURI: 'https://tokens.1inch.io/0x9ff58f4ffb29fa2266ab25e75e2a8b3503311656.png',
      eip2612: true,
      tags: ['PEG:BTC', 'tokens'],
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x28424507fefb6f7f8e9d3860f56504e4e5f5f390',
      symbol: 'amWETH',
      decimals: 18,
      name: 'Aave Matic Market WETH',
      logoURI: 'https://tokens.1inch.io/0x030ba81f1c18d280636f32af80b9aad02cf0854e.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4',
      symbol: 'amWMATIC',
      decimals: 18,
      name: 'Aave Matic Market WMATIC',
      logoURI: 'https://tokens.1inch.io/0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x1d2a0e5ec8e5bbdca5cb219e649b565d8e5c3360',
      symbol: 'amAAVE',
      decimals: 18,
      name: 'Aave Matic Market AAVE',
      logoURI: 'https://tokens.1inch.io/0xffc97d72e13e01096502cb8eb52dee56f74dad7b.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x27f8d03b3a2196956ed754badc28d73be8830a6e',
      symbol: 'amDAI',
      decimals: 18,
      name: 'Aave Matic Market DAI',
      logoURI: 'https://tokens.1inch.io/0x028171bca77440897b824ca71d1c56cac55b68a3.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x172370d5cd63279efa6d502dab29171933a610af',
      symbol: 'CRV',
      decimals: 18,
      name: 'CRV',
      logoURI: 'https://tokens.1inch.io/0xd533a949740bb3306d119cc777fa900ba034cd52.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x3a3df212b7aa91aa0402b9035b098891d276572b',
      symbol: 'FISH',
      decimals: 18,
      name: 'Fish',
      logoURI: 'https://tokens.1inch.io/0x3a3df212b7aa91aa0402b9035b098891d276572b.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x8a953cfe442c5e8855cc6c61b1293fa648bae472',
      symbol: 'PolyDoge',
      decimals: 18,
      name: 'PolyDoge',
      logoURI: 'https://tokens.1inch.io/0x8a953cfe442c5e8855cc6c61b1293fa648bae472.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xaaa5b9e6c589642f98a1cda99b9d024b8407285a',
      symbol: 'TITAN',
      decimals: 18,
      name: 'IRON Titanium Token',
      logoURI: 'https://tokens.1inch.io/0xaaa5b9e6c589642f98a1cda99b9d024b8407285a.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
      symbol: 'DFYN',
      decimals: 18,
      name: 'DFYN Token',
      logoURI: 'https://tokens.1inch.io/0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603',
      symbol: 'WOO',
      decimals: 18,
      name: 'Wootrade Network',
      logoURI: 'https://tokens.1inch.io/0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x4c4bf319237d98a30a929a96112effa8da3510eb',
      symbol: 'WEXpoly',
      decimals: 18,
      name: 'WaultSwap Polygon',
      logoURI: 'https://tokens.1inch.io/0x4c4bf319237d98a30a929a96112effa8da3510eb.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x580a84c73811e1839f75d86d75d88cca0c241ff4',
      symbol: 'QI',
      decimals: 18,
      name: 'Qi Dao',
      logoURI: 'https://tokens.1inch.io/0x580a84c73811e1839f75d86d75d88cca0c241ff4.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xa3fa99a148fa48d14ed51d610c367c61876997f1',
      symbol: 'miMATIC',
      decimals: 18,
      name: 'miMATIC',
      logoURI: 'https://tokens.1inch.io/0xa3fa99a148fa48d14ed51d610c367c61876997f1.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x76e63a3e7ba1e2e61d3da86a87479f983de89a7e',
      symbol: 'OMEN',
      decimals: 18,
      name: 'Augury Finance',
      logoURI: 'https://tokens.1inch.io/0x76e63a3e7ba1e2e61d3da86a87479f983de89a7e.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c',
      symbol: 'KNC',
      decimals: 18,
      name: 'Kyber Network Crystal v2',
      logoURI: 'https://tokens.1inch.io/0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3',
      symbol: 'BAL',
      decimals: 18,
      name: 'Balancer',
      logoURI: 'https://tokens.1inch.io/0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x4a81f8796e0c6ad4877a51c86693b0de8093f2ef',
      symbol: 'ICE_1',
      decimals: 18,
      name: 'Iron Finance ICE Token',
      logoURI: 'https://tokens.1inch.io/0x4a81f8796e0c6ad4877a51c86693b0de8093f2ef.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xc3fdbadc7c795ef1d6ba111e06ff8f16a20ea539',
      symbol: 'ADDY',
      decimals: 18,
      name: 'Adamant',
      logoURI: 'https://tokens.1inch.io/0xc3fdbadc7c795ef1d6ba111e06ff8f16a20ea539.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x692597b009d13c4049a947cab2239b7d6517875f',
      symbol: 'UST',
      decimals: 18,
      name: 'Wrapped UST Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0x692597b009d13c4049a947cab2239b7d6517875f.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x689f8e5913c158ffb5ac5aeb83b3c875f5d20309',
      symbol: 'SNK',
      decimals: 18,
      name: 'Snook',
      logoURI: 'https://tokens.1inch.io/0x689f8e5913c158ffb5ac5aeb83b3c875f5d20309.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xdab35042e63e93cc8556c9bae482e5415b5ac4b1',
      symbol: 'IRIS',
      decimals: 18,
      name: 'Iris',
      logoURI: 'https://tokens.1inch.io/0xdab35042e63e93cc8556c9bae482e5415b5ac4b1.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x3a3e7650f8b9f667da98f236010fbf44ee4b2975',
      symbol: 'xUSD',
      decimals: 18,
      name: 'xDollar Stablecoin',
      logoURI: 'https://tokens.1inch.io/0x3a3e7650f8b9f667da98f236010fbf44ee4b2975.png',
      eip2612: true,
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x13748d548d95d78a3c83fe3f32604b4796cffa23',
      symbol: 'KOGECOIN',
      decimals: 9,
      name: 'kogecoin.io',
      logoURI: 'https://tokens.1inch.io/0x13748d548d95d78a3c83fe3f32604b4796cffa23.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xe111178a87a3bff0c8d18decba5798827539ae99',
      symbol: 'EURS',
      decimals: 2,
      name: 'STASIS EURS Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0xe111178a87a3bff0c8d18decba5798827539ae99.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x6c0ab120dbd11ba701aff6748568311668f63fe0',
      symbol: 'APW',
      decimals: 18,
      name: 'APWine Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0x4104b135dbc9609fc1a9490e61369036497660c8.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x8c92e38eca8210f4fcbf17f0951b198dd7668292',
      symbol: 'DHT',
      decimals: 18,
      name: 'dHedge DAO Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0x8c92e38eca8210f4fcbf17f0951b198dd7668292.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xcd86152047e800d67bdf00a4c635a8b6c0e5c4c2',
      symbol: 'NACHO',
      decimals: 18,
      name: 'NACHO',
      logoURI: 'https://tokens.1inch.io/0xcd86152047e800d67bdf00a4c635a8b6c0e5c4c2.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xf50d05a1402d0adafa880d36050736f9f6ee7dee',
      symbol: 'INST',
      decimals: 18,
      name: 'Instadapp (PoS)',
      logoURI: 'https://tokens.1inch.io/0xf50d05a1402d0adafa880d36050736f9f6ee7dee.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x1ba17c639bdaecd8dc4aac37df062d17ee43a1b8',
      symbol: 'WIXS',
      decimals: 18,
      name: 'Wrapped Ixs Token',
      logoURI: 'https://tokens.1inch.io/0x1ba17c639bdaecd8dc4aac37df062d17ee43a1b8.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xe0b52e49357fd4daf2c15e02058dce6bc0057db4',
      symbol: 'agEUR',
      decimals: 18,
      name: 'agEUR',
      logoURI: 'https://tokens.1inch.io/0xe0b52e49357fd4daf2c15e02058dce6bc0057db4.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa',
      symbol: 'deUSDC',
      decimals: 6,
      name: 'deBridge USD Coin',
      logoURI: 'https://tokens.1inch.io/0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa_2.png',
      eip2612: true,
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x45c32fa6df82ead1e2ef74d17b76547eddfaff89',
      symbol: 'FRAX',
      decimals: 18,
      name: 'Frax',
      logoURI: 'https://tokens.1inch.io/0x45c32fa6df82ead1e2ef74d17b76547eddfaff89.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xb25e20de2f2ebb4cffd4d16a55c7b395e8a94762',
      symbol: 'REQ',
      decimals: 18,
      name: 'Request',
      logoURI: 'https://tokens.1inch.io/0xb25e20de2f2ebb4cffd4d16a55c7b395e8a94762.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x59b5654a17ac44f3068b3882f298881433bb07ef',
      symbol: 'CHP',
      decimals: 18,
      name: 'CoinPoker Chips (PoS)',
      logoURI: 'https://tokens.1inch.io/0x59b5654a17ac44f3068b3882f298881433bb07ef.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x8623e66bea0dce41b6d47f9c44e806a115babae0',
      symbol: 'NFTY',
      decimals: 18,
      name: 'NFTY Token',
      logoURI: 'https://tokens.1inch.io/0x8623e66bea0dce41b6d47f9c44e806a115babae0.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x4ff0b68abc2b9e4e1401e9b691dba7d66b264ac8',
      symbol: 'RIOT',
      decimals: 18,
      name: 'RIOT (PoS)',
      logoURI: 'https://tokens.1inch.io/0x4ff0b68abc2b9e4e1401e9b691dba7d66b264ac8.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xba3cb8329d442e6f9eb70fafe1e214251df3d275',
      symbol: 'SWASH',
      decimals: 18,
      name: 'Swash Token',
      logoURI: 'https://tokens.1inch.io/0xba3cb8329d442e6f9eb70fafe1e214251df3d275.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xd52f6ca48882be8fbaa98ce390db18e1dbe1062d',
      symbol: 'ORE',
      decimals: 18,
      name: 'pTokens ORE (PoS)',
      logoURI: 'https://tokens.1inch.io/0xd52f6ca48882be8fbaa98ce390db18e1dbe1062d.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xeeeeeb57642040be42185f49c52f7e9b38f8eeee',
      symbol: 'ELK',
      decimals: 18,
      name: 'Elk',
      logoURI: 'https://tokens.1inch.io/0xeeeeeb57642040be42185f49c52f7e9b38f8eeee.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xfe4546fefe124f30788c4cc1bb9aa6907a7987f9',
      symbol: 'cxETH',
      decimals: 18,
      name: 'CelsiusX Wrapped ETH',
      logoURI: 'https://tokens.1inch.io/0xfe4546fefe124f30788c4cc1bb9aa6907a7987f9.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x34667ed7c36cbbbf2d5d5c5c8d6eb76a094edb9f',
      symbol: 'GENE',
      decimals: 18,
      name: 'GenomesDAO (PoS)',
      logoURI: 'https://tokens.1inch.io/0x34667ed7c36cbbbf2d5d5c5c8d6eb76a094edb9f.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xc1c93d475dc82fe72dbc7074d55f5a734f8ceeae',
      symbol: 'PGX',
      decimals: 18,
      name: 'Pegaxy Stone',
      logoURI: 'https://tokens.1inch.io/0xc1c93d475dc82fe72dbc7074d55f5a734f8ceeae.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xe06bd4f5aac8d0aa337d13ec88db6defc6eaeefe',
      symbol: 'IXT',
      decimals: 18,
      name: 'PlanetIX',
      logoURI: 'https://tokens.1inch.io/0xe06bd4f5aac8d0aa337d13ec88db6defc6eaeefe.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xbbba073c31bf03b8acf7c28ef0738decf3695683',
      symbol: 'SAND',
      decimals: 18,
      name: 'SAND',
      logoURI: 'https://tokens.1inch.io/0xbbba073c31bf03b8acf7c28ef0738decf3695683.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xc6c855ad634dcdad23e64da71ba85b8c51e5ad7c',
      symbol: 'ICE_2',
      decimals: 18,
      name: 'Decentral Games ICE',
      logoURI: 'https://tokens.1inch.io/0xc6c855ad634dcdad23e64da71ba85b8c51e5ad7c.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x50b728d8d964fd00c2d0aad81718b71311fef68a',
      symbol: 'SNX',
      decimals: 18,
      name: 'Synthetix Network Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0x50b728d8d964fd00c2d0aad81718b71311fef68a.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x229b1b6c23ff8953d663c4cbb519717e323a0a84',
      symbol: 'BLOK',
      decimals: 18,
      name: 'BLOK',
      logoURI: 'https://tokens.1inch.io/0x229b1b6c23ff8953d663c4cbb519717e323a0a84.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x3b56a704c01d650147ade2b8cee594066b3f9421',
      symbol: 'FYN',
      decimals: 18,
      name: 'Affyn',
      logoURI: 'https://tokens.1inch.io/0x3b56a704c01d650147ade2b8cee594066b3f9421.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x4e78011ce80ee02d2c3e649fb657e45898257815',
      symbol: 'KLIMA',
      decimals: 9,
      name: 'Klima DAO',
      logoURI: 'https://tokens.1inch.io/0x4e78011ce80ee02d2c3e649fb657e45898257815.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x2c89bbc92bd86f8075d1decc58c7f4e0107f286b',
      symbol: 'AVAX',
      decimals: 18,
      name: 'Avalanche Token',
      logoURI: 'https://tokens.1inch.io/0x2c89bbc92bd86f8075d1decc58c7f4e0107f286b.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4',
      symbol: 'ROUTE (PoS)',
      decimals: 18,
      name: 'Route',
      logoURI: 'https://tokens.1inch.io/0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xef938b6da8576a896f6e0321ef80996f4890f9c4',
      symbol: 'DG',
      decimals: 18,
      name: 'Decentral Games (PoS)',
      logoURI: 'https://tokens.1inch.io/0xef938b6da8576a896f6e0321ef80996f4890f9c4.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x2bc07124d8dac638e290f401046ad584546bc47b',
      symbol: 'TOWER',
      decimals: 18,
      name: 'TOWER',
      logoURI: 'https://tokens.1inch.io/0x2bc07124d8dac638e290f401046ad584546bc47b.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x235737dbb56e8517391473f7c964db31fa6ef280',
      symbol: 'KASTA',
      decimals: 18,
      name: 'KastaToken',
      logoURI: 'https://tokens.1inch.io/0x235737dbb56e8517391473f7c964db31fa6ef280.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xb35fcbcf1fd489fce02ee146599e893fdcdc60e6',
      symbol: 'DERC',
      decimals: 18,
      name: 'DeRace Token',
      logoURI: 'https://tokens.1inch.io/0xb35fcbcf1fd489fce02ee146599e893fdcdc60e6.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xe8377a076adabb3f9838afb77bee96eac101ffb1',
      symbol: 'MSU',
      decimals: 18,
      name: 'MetaSoccer Universe',
      logoURI: 'https://tokens.1inch.io/0xe8377a076adabb3f9838afb77bee96eac101ffb1.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x5d47baba0d66083c52009271faf3f50dcc01023c',
      symbol: 'BANANA',
      decimals: 18,
      name: 'ApeSwapFinance Banana',
      logoURI: 'https://tokens.1inch.io/0x5d47baba0d66083c52009271faf3f50dcc01023c.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xc10358f062663448a3489fc258139944534592ac',
      symbol: 'BCMC',
      decimals: 18,
      name: 'Blockchain Monster Coin',
      logoURI: 'https://tokens.1inch.io/0xc10358f062663448a3489fc258139944534592ac.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x3b1a0c9252ee7403093ff55b4a5886d49a3d837a',
      symbol: 'UM',
      decimals: 18,
      name: 'Continuum',
      logoURI: 'https://tokens.1inch.io/0x3b1a0c9252ee7403093ff55b4a5886d49a3d837a.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xcf32822ff397ef82425153a9dcb726e5ff61dca7',
      symbol: 'GMEE',
      decimals: 18,
      name: 'GAMEE',
      logoURI: 'https://tokens.1inch.io/0xcf32822ff397ef82425153a9dcb726e5ff61dca7.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
      symbol: 'USDC_1',
      decimals: 6,
      name: 'USD Coin (PoS)',
      logoURI: 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xe7804d91dfcde7f776c90043e03eaa6df87e6395',
      symbol: 'DFX',
      decimals: 18,
      name: 'DFX Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0xe7804d91dfcde7f776c90043e03eaa6df87e6395.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xe2aa7db6da1dae97c5f5c6914d285fbfcc32a128',
      symbol: 'PAR',
      decimals: 18,
      name: 'PAR Stablecoin',
      logoURI: 'https://tokens.1inch.io/0xe2aa7db6da1dae97c5f5c6914d285fbfcc32a128.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x88c949b4eb85a90071f2c0bef861bddee1a7479d',
      symbol: 'mSHEESHA',
      decimals: 18,
      name: 'SHEESHA POLYGON',
      logoURI: 'https://tokens.1inch.io/0x88c949b4eb85a90071f2c0bef861bddee1a7479d.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x51b5619f5180e333d18b6310c8d540aea43a0371',
      symbol: 'VHC',
      decimals: 18,
      name: 'Vault Hill City (PoS)',
      logoURI: 'https://tokens.1inch.io/0x51b5619f5180e333d18b6310c8d540aea43a0371.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x82362ec182db3cf7829014bc61e9be8a2e82868a',
      symbol: 'MESH',
      decimals: 18,
      name: 'Meshswap Protocol',
      logoURI: 'https://tokens.1inch.io/0x82362ec182db3cf7829014bc61e9be8a2e82868a.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x228b5c21ac00155cf62c57bcc704c0da8187950b',
      symbol: 'NXD',
      decimals: 18,
      name: 'Nexus Dubai',
      logoURI: 'https://tokens.1inch.io/0x228b5c21ac00155cf62c57bcc704c0da8187950b.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xa55870278d6389ec5b524553d03c04f5677c061e',
      symbol: 'XCAD',
      decimals: 18,
      name: 'XCAD Token (PoS)',
      logoURI: 'https://tokens.1inch.io/0xa55870278d6389ec5b524553d03c04f5677c061e.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x8c4476dfec8e7eedf2de3e9e9461b7c14c828d46',
      symbol: 'UNIX',
      decimals: 18,
      name: 'UniX Gaming (PoS)',
      logoURI: 'https://tokens.1inch.io/0x8c4476dfec8e7eedf2de3e9e9461b7c14c828d46.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x02649c1ff4296038de4b9ba8f491b42b940a8252',
      symbol: 'XGEM',
      decimals: 18,
      name: 'Exchange Genesis Ethlas Medium',
      logoURI: 'https://tokens.1inch.io/0x02649c1ff4296038de4b9ba8f491b42b940a8252.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xb9638272ad6998708de56bbc0a290a1de534a578',
      symbol: 'IQ',
      decimals: 18,
      name: 'Everipedia IQ (PoS)',
      logoURI: 'https://tokens.1inch.io/0xb9638272ad6998708de56bbc0a290a1de534a578.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x614389eaae0a6821dc49062d56bda3d9d45fa2ff',
      symbol: 'ORBS',
      decimals: 18,
      name: 'Orbs (PoS)',
      logoURI: 'https://tokens.1inch.io/0x614389eaae0a6821dc49062d56bda3d9d45fa2ff.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x30de46509dbc3a491128f97be0aaf70dc7ff33cb',
      symbol: 'XZAR',
      decimals: 18,
      name: 'South African Tether (PoS)',
      logoURI: 'https://tokens.1inch.io/0x30de46509dbc3a491128f97be0aaf70dc7ff33cb.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xd4945a3d0de9923035521687d4bf18cc9b0c7c2a',
      symbol: 'LUXY',
      decimals: 18,
      name: 'LUXY',
      logoURI: 'https://tokens.1inch.io/0xd4945a3d0de9923035521687d4bf18cc9b0c7c2a.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c',
      symbol: 'jEUR',
      decimals: 18,
      name: 'Jarvis Synthetic Euro',
      logoURI: 'https://tokens.1inch.io/0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x695fc8b80f344411f34bdbcb4e621aa69ada384b',
      symbol: 'NITRO',
      decimals: 18,
      name: 'Nitro (PoS)',
      logoURI: 'https://tokens.1inch.io/0x695fc8b80f344411f34bdbcb4e621aa69ada384b.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xdb725f82818de83e99f1dac22a9b5b51d3d04dd4',
      symbol: 'GET',
      decimals: 18,
      name: 'GET Protocol (PoS)',
      logoURI: 'https://tokens.1inch.io/0xdb725f82818de83e99f1dac22a9b5b51d3d04dd4.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x3a9a81d576d83ff21f26f325066054540720fc34',
      symbol: 'DATA',
      decimals: 18,
      name: 'Streamr',
      logoURI: 'https://tokens.1inch.io/0x3a9a81d576d83ff21f26f325066054540720fc34.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x2934b36ca9a4b31e633c5be670c8c8b28b6aa015',
      symbol: 'THX',
      decimals: 18,
      name: 'THX Network (PoS)',
      logoURI: 'https://tokens.1inch.io/0x2934b36ca9a4b31e633c5be670c8c8b28b6aa015.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xee9a352f6aac4af1a5b9f467f6a93e0ffbe9dd35',
      symbol: 'MASQ',
      decimals: 18,
      name: 'MASQ (PoS)',
      logoURI: 'https://tokens.1inch.io/0xee9a352f6aac4af1a5b9f467f6a93e0ffbe9dd35.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x44d09156c7b4acf0c64459fbcced7613f5519918',
      symbol: '$KMC',
      decimals: 18,
      name: '$KMC',
      logoURI: 'https://tokens.1inch.io/0x44d09156c7b4acf0c64459fbcced7613f5519918.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x598e49f01befeb1753737934a5b11fea9119c796',
      symbol: 'ADS',
      decimals: 11,
      name: 'Adshares (PoS)',
      logoURI: 'https://tokens.1inch.io/0x598e49f01befeb1753737934a5b11fea9119c796.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x8346ab8d5ea7a9db0209aed2d1806afa0e2c4c21',
      symbol: 'MOD',
      decimals: 18,
      name: 'MODEFI (PoS)',
      logoURI: 'https://tokens.1inch.io/0x8346ab8d5ea7a9db0209aed2d1806afa0e2c4c21.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x64ca1571d1476b7a21c5aaf9f1a750a193a103c0',
      symbol: 'BONDLY',
      decimals: 18,
      name: 'Bondly (PoS)',
      logoURI: 'https://tokens.1inch.io/0x64ca1571d1476b7a21c5aaf9f1a750a193a103c0.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x82a0e6c02b91ec9f6ff943c0a933c03dbaa19689',
      symbol: 'WNT',
      decimals: 18,
      name: 'Wicrypt Network Token',
      logoURI: 'https://tokens.1inch.io/0x82a0e6c02b91ec9f6ff943c0a933c03dbaa19689.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xc5b57e9a1e7914fda753a88f24e5703e617ee50c',
      symbol: 'POP',
      decimals: 18,
      name: 'Popcorn (PoS)',
      logoURI: 'https://tokens.1inch.io/0xc5b57e9a1e7914fda753a88f24e5703e617ee50c.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xdfce1e99a31c4597a3f8a8945cbfa9037655e335',
      symbol: 'ASTRAFER',
      decimals: 18,
      name: 'Astrafer',
      logoURI: 'https://tokens.1inch.io/0xdfce1e99a31c4597a3f8a8945cbfa9037655e335.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xdc3326e71d45186f113a2f448984ca0e8d201995',
      symbol: 'XSGD',
      decimals: 6,
      name: 'XSGD',
      logoURI: 'https://tokens.1inch.io/0xdc3326e71d45186f113a2f448984ca0e8d201995.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xdab625853c2b35d0a9c6bd8e5a097a664ef4ccfb',
      symbol: 'eQUAD',
      decimals: 18,
      name: 'Quadrant Protocol',
      logoURI: 'https://tokens.1inch.io/0xdab625853c2b35d0a9c6bd8e5a097a664ef4ccfb.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xc17c30e98541188614df99239cabd40280810ca3',
      symbol: 'RISE',
      decimals: 18,
      name: 'EverRise',
      logoURI: 'https://tokens.1inch.io/0xc17c30e98541188614df99239cabd40280810ca3.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x9c32185b81766a051e08de671207b34466dd1021',
      symbol: 'BTCpx',
      decimals: 8,
      name: 'BTC Proxy',
      logoURI: 'https://tokens.1inch.io/0x9c32185b81766a051e08de671207b34466dd1021.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xb5c064f955d8e7f38fe0460c556a72987494ee17',
      symbol: 'QUICK',
      decimals: 18,
      name: 'QuickSwap',
      logoURI: 'https://tokens.1inch.io/0xb5c064f955d8e7f38fe0460c556a72987494ee17.png',
      eip2612: true,
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x3c720206bfacb2d16fa3ac0ed87d2048dbc401fc',
      symbol: 'UCO',
      decimals: 18,
      name: 'UnirisToken (PoS)',
      logoURI: 'https://tokens.1inch.io/0x3c720206bfacb2d16fa3ac0ed87d2048dbc401fc.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x34d4ab47bee066f361fa52d792e69ac7bd05ee23',
      symbol: 'AURUM',
      decimals: 18,
      name: 'RaiderAurum',
      logoURI: 'https://tokens.1inch.io/0x34d4ab47bee066f361fa52d792e69ac7bd05ee23.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xcd7361ac3307d1c5a46b63086a90742ff44c63b3',
      symbol: 'RAIDER',
      decimals: 18,
      name: 'RaiderToken',
      logoURI: 'https://tokens.1inch.io/0xcd7361ac3307d1c5a46b63086a90742ff44c63b3.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x90f3edc7d5298918f7bb51694134b07356f7d0c7',
      symbol: 'DDAO',
      decimals: 18,
      name: 'DEFI HUNTERS DAO Token',
      logoURI: 'https://tokens.1inch.io/0x90f3edc7d5298918f7bb51694134b07356f7d0c7.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xd3b71117e6c1558c1553305b44988cd944e97300',
      symbol: 'YEL',
      decimals: 18,
      name: 'YEL Token',
      logoURI: 'https://tokens.1inch.io/0xd3b71117e6c1558c1553305b44988cd944e97300.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x03b54a6e9a984069379fae1a4fc4dbae93b3bccd',
      symbol: 'wstETH',
      decimals: 18,
      name: 'Wrapped liquid staked Ether 2.0 (PoS)',
      logoURI: 'https://tokens.1inch.io/0x03b54a6e9a984069379fae1a4fc4dbae93b3bccd.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x081ec4c0e30159c8259bad8f4887f83010a681dc',
      symbol: 'DE',
      decimals: 18,
      name: 'DeNet File Token',
      logoURI: 'https://tokens.1inch.io/0x081ec4c0e30159c8259bad8f4887f83010a681dc.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xef6ab48ef8dfe984fab0d5c4cd6aff2e54dfda14',
      symbol: 'CRISP-M',
      decimals: 18,
      name: 'CRISP Scored Mangroves',
      logoURI: 'https://tokens.1inch.io/0xef6ab48ef8dfe984fab0d5c4cd6aff2e54dfda14.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x78a0a62fba6fb21a83fe8a3433d44c73a4017a6f',
      symbol: 'OX_OLD',
      decimals: 18,
      name: 'Open Exchange Token OLD',
      logoURI: 'https://tokens.1inch.io/0x78a0a62fba6fb21a83fe8a3433d44c73a4017a6f.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xbfa35599c7aebb0dace9b5aa3ca5f2a79624d8eb',
      symbol: 'RETRO',
      decimals: 18,
      name: 'RETRO',
      logoURI: 'https://tokens.1inch.io/0xbfa35599c7aebb0dace9b5aa3ca5f2a79624d8eb.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
      symbol: 'USDC',
      decimals: 6,
      name: 'Native USD Coin (PoS)',
      logoURI: 'https://tokens.1inch.io/0x3c499c542cef5e3811e1192ce70d8cc03d5c3359.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xc19669a405067927865b40ea045a2baabbbe57f5',
      symbol: 'STAR',
      decimals: 18,
      name: 'STAR',
      logoURI: 'https://tokens.1inch.io/0xc19669a405067927865b40ea045a2baabbbe57f5.png',
    },
    {
      chainId: ChainId.POLYGON,
      address: '0xba0dda8762c24da9487f5fa026a9b64b695a07ea',
      symbol: 'OX',
      decimals: 18,
      name: 'OX Coin',
    },
  ],
  [ChainId.OPTIMISM]: [
    {
      chainId: ChainId.OPTIMISM,
      address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      symbol: 'ETH',
      decimals: 18,
      name: 'Ether',
      logoURI: 'https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
      tags: ['native'],
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x4200000000000000000000000000000000000006',
      symbol: 'WETH',
      decimals: 18,
      name: 'Wrapped Ether',
      logoURI: 'https://tokens.1inch.io/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png',
      tags: ['PEG:ETH', 'tokens'],
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x8700daec35af8ff88c16bdf0418774cb3d7599b4',
      symbol: 'SNX',
      decimals: 18,
      name: 'Synthetix',
      logoURI: 'https://ethereum-optimism.github.io/logos/SNX.svg',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
      symbol: 'DAI',
      decimals: 18,
      name: 'Dai stable coin',
      logoURI: 'https://tokens.1inch.io/0xda10009cbd5d07dd0cecc66161fc93d7c9000da1.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58',
      symbol: 'USDT',
      decimals: 6,
      name: 'Tether USD',
      logoURI: 'https://tokens.1inch.io/0x94b008aa00579c1307b0ef2c499ad98a8ce58e58.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x68f180fcce6836688e9084f035309e29bf0a2095',
      symbol: 'WBTC',
      decimals: 8,
      name: 'Wrapped Bitcoin',
      logoURI: 'https://tokens.1inch.io/0x68f180fcce6836688e9084f035309e29bf0a2095.png',
      tags: ['PEG:BTC', 'tokens'],
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6',
      symbol: 'LINK',
      decimals: 18,
      name: 'Chainlink',
      logoURI: 'https://tokens.1inch.io/0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
      symbol: 'USDC.e',
      decimals: 6,
      name: 'USD Coin',
      logoURI: 'https://tokens.1inch.io/0x7f5c764cbc14f9669b88837ca1490cca17c31607.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x8c6f28f2f1a3c87f0f938b96d27520d9751ec8d9',
      symbol: 'sUSD',
      decimals: 18,
      name: 'Synth sUSD',
      logoURI: 'https://tokens.1inch.io/0x57ab1ec28d129707052df4df418d58a2d46d5f51.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x217d47011b23bb961eb6d93ca9945b7501a5bb11',
      symbol: 'THALES',
      decimals: 18,
      name: 'Optimistic Thales Token',
      logoURI: 'https://tokens.1inch.io/0x217d47011b23bb961eb6d93ca9945b7501a5bb11.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x61baadcf22d2565b0f471b291c475db5555e0b76',
      symbol: 'AELIN',
      decimals: 18,
      name: 'Aelin Token',
      logoURI: 'https://tokens.1inch.io/0x61baadcf22d2565b0f471b291c475db5555e0b76.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x7fb688ccf682d58f86d7e38e03f9d22e7705448b',
      symbol: 'RAI',
      decimals: 18,
      name: 'Rai Reflex Index',
      logoURI: 'https://tokens.1inch.io/0x7fb688ccf682d58f86d7e38e03f9d22e7705448b.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x50c5725949a6f0c72e6c4a641f24049a917db0cb',
      symbol: 'LYRA',
      decimals: 18,
      name: 'Lyra Token',
      logoURI: 'https://tokens.1inch.io/0x50c5725949a6f0c72e6c4a641f24049a917db0cb.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x9e1028f5f1d5ede59748ffcee5532509976840e0',
      symbol: 'PERP',
      decimals: 18,
      name: 'Perpetual',
      logoURI: 'https://tokens.1inch.io/0x9e1028f5f1d5ede59748ffcee5532509976840e0.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0xf98dcd95217e15e05d8638da4c91125e59590b07',
      symbol: 'KROM',
      decimals: 18,
      name: 'Kromatika',
      logoURI: 'https://tokens.1inch.io/0xf98dcd95217e15e05d8638da4c91125e59590b07.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x4200000000000000000000000000000000000042',
      symbol: 'OP',
      decimals: 18,
      name: 'Optimism',
      logoURI: 'https://tokens.1inch.io/0x4200000000000000000000000000000000000042.png',
      eip2612: true,
      tags: ['PEG:OP', 'tokens'],
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0xeeeeeb57642040be42185f49c52f7e9b38f8eeee',
      symbol: 'ELK',
      decimals: 18,
      name: 'Elk',
      logoURI: 'https://tokens.1inch.io/0xeeeeeb57642040be42185f49c52f7e9b38f8eeee.png',
      eip2612: true,
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0xe405de8f52ba7559f9df3c368500b6e6ae6cee49',
      symbol: 'sETH',
      decimals: 18,
      name: 'Synth sETH',
      logoURI: 'https://tokens.1inch.io/0xe405de8f52ba7559f9df3c368500b6e6ae6cee49.png',
      tags: ['PEG:ETH', 'tokens'],
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x73cb180bf0521828d8849bc8cf2b920918e23032',
      symbol: 'USD+',
      decimals: 6,
      name: 'USD+',
      logoURI: 'https://tokens.1inch.io/0x73cb180bf0521828d8849bc8cf2b920918e23032.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0xae31207ac34423c41576ff59bfb4e036150f9cf7',
      symbol: 'SDL',
      decimals: 18,
      name: 'Saddle DAO',
      logoURI: 'https://tokens.1inch.io/0xae31207ac34423c41576ff59bfb4e036150f9cf7.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x296f55f8fb28e498b858d0bcda06d955b2cb3f97',
      symbol: 'STG',
      decimals: 18,
      name: 'StargateToken',
      logoURI: 'https://tokens.1inch.io/0x296f55f8fb28e498b858d0bcda06d955b2cb3f97.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x8ae125e8653821e851f12a49f7765db9a9ce7384',
      symbol: 'DOLA',
      decimals: 18,
      name: 'Dola USD Stablecoin',
      logoURI: 'https://tokens.1inch.io/0x8ae125e8653821e851f12a49f7765db9a9ce7384.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b',
      symbol: 'BOB',
      decimals: 18,
      name: 'BOB',
      logoURI: 'https://tokens.1inch.io/0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b.png',
      eip2612: true,
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc',
      symbol: 'HOP',
      decimals: 18,
      name: 'Hop',
      logoURI: 'https://tokens.1inch.io/0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc.png',
      eip2612: true,
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x1f32b1c2345538c0c6f582fcb022739c4a194ebb',
      symbol: 'wstETH',
      decimals: 18,
      name: 'Wrapped liquid staked Ether 2.0',
      logoURI: 'https://tokens.1inch.io/0x1f32b1c2345538c0c6f582fcb022739c4a194ebb.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0xcdb4bb51801a1f399d4402c61bc098a72c382e65',
      symbol: 'OPX',
      decimals: 18,
      name: 'OPX',
      logoURI: 'https://tokens.1inch.io/0xcdb4bb51801a1f399d4402c61bc098a72c382e65.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x920cf626a271321c151d027030d5d08af699456b',
      symbol: 'KWENTA',
      decimals: 18,
      name: 'Kwenta',
      logoURI: 'https://tokens.1inch.io/0x920cf626a271321c151d027030d5d08af699456b.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x3e29d3a9316dab217754d13b28646b76607c5f04',
      symbol: 'alETH',
      decimals: 18,
      name: 'Alchemix ETH',
      logoURI: 'https://tokens.1inch.io/0x3e29d3a9316dab217754d13b28646b76607c5f04.png',
      eip2612: true,
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0xcb8fa9a76b8e203d8c3797bf438d8fb81ea3326a',
      symbol: 'alUSD',
      decimals: 18,
      name: 'Alchemix USD',
      logoURI: 'https://tokens.1inch.io/0xcb8fa9a76b8e203d8c3797bf438d8fb81ea3326a.png',
      eip2612: true,
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x9bcef72be871e61ed4fbbc7630889bee758eb81d',
      symbol: 'rETH',
      decimals: 18,
      name: 'Rocket Pool ETH',
      logoURI: 'https://tokens.1inch.io/0x9bcef72be871e61ed4fbbc7630889bee758eb81d.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0xdfa46478f9e5ea86d57387849598dbfb2e964b02',
      symbol: 'MAI',
      decimals: 18,
      name: 'Mai Stablecoin',
      logoURI: 'https://tokens.1inch.io/0xdfa46478f9e5ea86d57387849598dbfb2e964b02.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x9c9e5fd8bbc25984b178fdce6117defa39d2db39',
      symbol: 'BUSD',
      decimals: 18,
      name: 'BUSD Token',
      logoURI: 'https://tokens.1inch.io/0x9c9e5fd8bbc25984b178fdce6117defa39d2db39.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0xfdeffc7ad816bf7867c642df7ebc2cc5554ec265',
      symbol: 'BeVELO',
      decimals: 18,
      name: 'Beefy Velo',
      logoURI: 'https://tokens.1inch.io/0xfdeffc7ad816bf7867c642df7ebc2cc5554ec265.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x00a35fd824c717879bf370e70ac6868b95870dfb',
      symbol: 'IB',
      decimals: 18,
      name: 'IronBank',
      logoURI: 'https://tokens.1inch.io/0x00a35fd824c717879bf370e70ac6868b95870dfb.png',
      eip2612: true,
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0xaf9fe3b5ccdae78188b1f8b9a49da7ae9510f151',
      symbol: 'DHT',
      decimals: 18,
      name: 'dHEDGE DAO Token',
      logoURI: 'https://tokens.1inch.io/0xaf9fe3b5ccdae78188b1f8b9a49da7ae9510f151.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x47536f17f4ff30e64a96a7555826b8f9e66ec468',
      symbol: 'MMY',
      decimals: 18,
      name: 'MUMMY',
      logoURI: 'https://tokens.1inch.io/0x47536f17f4ff30e64a96a7555826b8f9e66ec468.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x0b2c639c533813f4aa9d7837caf62653d097ff85',
      symbol: 'USDC',
      decimals: 6,
      name: 'USD Coin',
      logoURI: 'https://tokens.1inch.io/0x0b2c639c533813f4aa9d7837caf62653d097ff85.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x81ab7e0d570b01411fcc4afd3d50ec8c241cb74b',
      symbol: 'EQZ',
      decimals: 18,
      name: 'Equalizer',
      logoURI: 'https://tokens.1inch.io/0x81ab7e0d570b01411fcc4afd3d50ec8c241cb74b.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x9560e827af36c94d2ac33a39bce1fe78631088db',
      symbol: 'VELO',
      decimals: 18,
      name: 'VelodromeV2',
      logoURI: 'https://tokens.1inch.io/0x9560e827af36c94d2ac33a39bce1fe78631088db.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x1e925de1c68ef83bd98ee3e130ef14a50309c01b',
      symbol: 'EXA',
      decimals: 18,
      name: 'exactly',
      logoURI: 'https://tokens.1inch.io/0x1e925de1c68ef83bd98ee3e130ef14a50309c01b.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0xc55e93c62874d8100dbd2dfe307edc1036ad5434',
      symbol: 'mooBIFI',
      decimals: 18,
      name: 'Moo BIFI',
      logoURI: 'https://tokens.1inch.io/0xc55e93c62874d8100dbd2dfe307edc1036ad5434.png',
    },
    {
      chainId: ChainId.OPTIMISM,
      address: '0x323665443cef804a3b5206103304bd4872ea4253',
      symbol: 'USDV',
      decimals: 6,
      name: 'USDV',
      logoURI: 'https://tokens.1inch.io/0x323665443cef804a3b5206103304bd4872ea4253.png',
      tags: ['PEG:USD', 'tokens'],
    },
  ],
  [ChainId.ARBITRUM]: [
    {
      symbol: 'LIF3',
      chainId: ChainId.ARBITRUM,
      name: 'LIF3',
      address: '0x02a2b736f9150d36c0919f3acee8ba2a92fbbb40',
      decimals: 18,
      logoURI: './assets/svg/lif3.svg',
      eip2612: false,
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x32eb7902d4134bf98a28b963d26de779af92a212',
      symbol: 'RDPX',
      decimals: 18,
      name: 'Dopex Rebate Token',
      logoURI: 'https://tokens.1inch.io/0x32eb7902d4134bf98a28b963d26de779af92a212.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xb766039cc6db368759c1e56b79affe831d0cc507',
      symbol: 'RPL',
      decimals: 18,
      name: 'Rocket Pool Protocol',
      logoURI: 'https://tokens.1inch.io/0xb766039cc6db368759c1e56b79affe831d0cc507.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x9e758b8a98a42d612b3d38b66a22074dc03d7370',
      symbol: 'SIS',
      decimals: 18,
      name: 'Symbiosis',
      logoURI: 'https://tokens.1inch.io/0x9e758b8a98a42d612b3d38b66a22074dc03d7370.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xec70dcb4a1efa46b8f2d97c310c9c4790ba5ffa8',
      symbol: 'rETH',
      decimals: 18,
      name: 'Rocket Pool ETH',
      logoURI: 'https://tokens.1inch.io/0xec70dcb4a1efa46b8f2d97c310c9c4790ba5ffa8.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x3f56e0c36d275367b8c502090edf38289b3dea0d',
      symbol: 'MAI',
      decimals: 18,
      name: 'Mai Stablecoin',
      logoURI: 'https://tokens.1inch.io/0x3f56e0c36d275367b8c502090edf38289b3dea0d.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x55ff62567f09906a85183b866df84bf599a4bf70',
      symbol: 'KROM',
      decimals: 18,
      name: 'Kromatika',
      logoURI: 'https://tokens.1inch.io/0x55ff62567f09906a85183b866df84bf599a4bf70.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
      symbol: 'USDC',
      decimals: 6,
      name: 'USD Coin',
      logoURI: 'https://tokens.1inch.io/0xaf88d065e77c8cc2239327c5edb3a432268e5831.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07',
      symbol: 'JOE',
      decimals: 18,
      name: 'JoeToken',
      logoURI: 'https://tokens.1inch.io/0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x0c880f6761f1af8d9aa9c466984b80dab9a8c9e8',
      symbol: 'PENDLE',
      decimals: 18,
      name: 'Pendle',
      logoURI: 'https://tokens.1inch.io/0x0c880f6761f1af8d9aa9c466984b80dab9a8c9e8.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x9623063377ad1b27544c965ccd7342f7ea7e88c7',
      symbol: 'GRT',
      decimals: 18,
      name: 'Graph Token',
      logoURI: 'https://tokens.1inch.io/0xc944e90c64b2c07662a292be6244bdf05cda44a7.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x088cd8f5ef3652623c22d48b1605dcfe860cd704',
      symbol: 'VELA',
      decimals: 18,
      name: 'VelaToken',
      logoURI: 'https://tokens.1inch.io/0x088cd8f5ef3652623c22d48b1605dcfe860cd704.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xda51015b73ce11f77a115bb1b8a7049e02ddecf0',
      symbol: 'NEU',
      decimals: 18,
      name: 'Neutra Token',
      logoURI: 'https://tokens.1inch.io/0xda51015b73ce11f77a115bb1b8a7049e02ddecf0.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x079504b86d38119f859c4194765029f692b7b7aa',
      symbol: 'LYRA',
      decimals: 18,
      name: 'Lyra Token',
      logoURI: 'https://tokens.1inch.io/0x079504b86d38119f859c4194765029f692b7b7aa.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x123389c2f0e9194d9ba98c21e63c375b67614108',
      symbol: 'EMAX',
      decimals: 18,
      name: 'EthereumMax',
      logoURI: 'https://tokens.1inch.io/0x123389c2f0e9194d9ba98c21e63c375b67614108.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xcab86f6fb6d1c2cbeeb97854a0c023446a075fe3',
      symbol: 'deETH',
      decimals: 18,
      name: 'deBridge Ether',
      logoURI: 'https://tokens.1inch.io/0xcab86f6fb6d1c2cbeeb97854a0c023446a075fe3.png',
      tags: ['PEG:ETH', 'tokens'],
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xeeeeeb57642040be42185f49c52f7e9b38f8eeee',
      symbol: 'ELK',
      decimals: 18,
      name: 'Elk',
      logoURI: 'https://tokens.1inch.io/0xeeeeeb57642040be42185f49c52f7e9b38f8eeee.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x75c9bc761d88f70156daf83aa010e84680baf131',
      symbol: 'SDL',
      decimals: 18,
      name: 'Saddle DAO',
      logoURI: 'https://tokens.1inch.io/0x75c9bc761d88f70156daf83aa010e84680baf131.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xc74fe4c715510ec2f8c61d70d397b32043f55abe',
      symbol: 'MYC',
      decimals: 18,
      name: 'Mycelium',
      logoURI: 'https://tokens.1inch.io/0xc74fe4c715510ec2f8c61d70d397b32043f55abe.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x64343594ab9b56e99087bfa6f2335db24c2d1f17',
      symbol: 'VST',
      decimals: 18,
      name: 'Vesta Stable',
      logoURI: 'https://tokens.1inch.io/0x64343594ab9b56e99087bfa6f2335db24c2d1f17.png',
      eip2612: true,
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x6c2c06790b3e3e3c38e12ee22f8183b37a13ee55',
      symbol: 'DPX',
      decimals: 18,
      name: 'Dopex Governance Token',
      logoURI: 'https://tokens.1inch.io/0x6c2c06790b3e3e3c38e12ee22f8183b37a13ee55.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x17fc002b466eec40dae837fc4be5c67993ddbd6f',
      symbol: 'FRAX',
      decimals: 18,
      name: 'Frax',
      logoURI: 'https://tokens.1inch.io/0x17fc002b466eec40dae837fc4be5c67993ddbd6f.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x6694340fc020c5e6b96567843da2df01b2ce1eb6',
      symbol: 'STG',
      decimals: 18,
      name: 'StargateToken',
      logoURI: 'https://tokens.1inch.io/0x6694340fc020c5e6b96567843da2df01b2ce1eb6.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x93c15cd7de26f07265f0272e0b831c5d7fab174f',
      symbol: 'LIQD',
      decimals: 18,
      name: 'Liquid',
      logoURI: 'https://tokens.1inch.io/0x93c15cd7de26f07265f0272e0b831c5d7fab174f.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc',
      symbol: 'HOP',
      decimals: 18,
      name: 'Hop',
      logoURI: 'https://tokens.1inch.io/0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x5979d7b546e38e414f7e9822514be443a4800529',
      symbol: 'wstETH',
      decimals: 18,
      name: 'Wrapped liquid staked Ether 2.0',
      logoURI: 'https://tokens.1inch.io/0x5979d7b546e38e414f7e9822514be443a4800529.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xd74f5255d557944cf7dd0e45ff521520002d5748',
      symbol: 'USDs',
      decimals: 18,
      name: 'Sperax USD',
      logoURI: 'https://tokens.1inch.io/0xd74f5255d557944cf7dd0e45ff521520002d5748.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x15a808ed3846d25e88ae868de79f1bcb1ac382b5',
      symbol: 'MVD',
      decimals: 9,
      name: 'Metavault DAO',
      logoURI: 'https://tokens.1inch.io/0x15a808ed3846d25e88ae868de79f1bcb1ac382b5.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x417a1afd44250314bffb11ff68e989775e990ab6',
      symbol: 'VOLTA',
      decimals: 18,
      name: 'Volta Protocol Token',
      logoURI: 'https://tokens.1inch.io/0x417a1afd44250314bffb11ff68e989775e990ab6.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xde903e2712288a1da82942dddf2c20529565ac30',
      symbol: 'SWPR',
      decimals: 18,
      name: 'Swapr',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0',
      symbol: 'UNI',
      decimals: 18,
      name: 'Uniswap',
      logoURI: 'https://tokens.1inch.io/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
      symbol: 'USDT',
      decimals: 6,
      name: 'Tether USD',
      logoURI: 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
      symbol: 'USDC.e',
      decimals: 6,
      name: 'USD Coin (Arb1)',
      logoURI: 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
      eip2612: true,
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
      symbol: 'WBTC',
      decimals: 8,
      name: 'Wrapped BTC',
      logoURI: 'https://tokens.1inch.io/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599.png',
      tags: ['PEG:BTC', 'tokens'],
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
      symbol: 'WETH',
      decimals: 18,
      name: 'Wrapped Ether',
      logoURI: 'https://tokens.1inch.io/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png',
      tags: ['PEG:ETH', 'tokens'],
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x0f61b24272af65eacf6adfe507028957698e032f',
      symbol: 'ZIPT',
      decimals: 18,
      name: 'Zippie',
      logoURI: 'https://tokens.1inch.io/0xedd7c94fd7b4971b916d15067bc454b9e1bad980.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
      symbol: 'DAI',
      decimals: 18,
      name: 'Dai Stablecoin',
      logoURI: 'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xd5ebd23d5eb968c2efba2b03f27ee61718609a71',
      symbol: 'UNB',
      decimals: 18,
      name: 'Unbound',
      logoURI: 'https://tokens.1inch.io/0xd5ebd23d5eb968c2efba2b03f27ee61718609a71.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x3e6648c5a70a150a88bce65f4ad4d506fe15d2af',
      symbol: 'SPELL',
      decimals: 18,
      name: 'Spell Token',
      logoURI: 'https://tokens.1inch.io/0x3e6648c5a70a150a88bce65f4ad4d506fe15d2af.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x739ca6d71365a08f584c8fc4e1029045fa8abc4b',
      symbol: 'wsOHM',
      decimals: 18,
      name: 'Wrapped sOHM',
      logoURI: 'https://tokens.1inch.io/0x739ca6d71365a08f584c8fc4e1029045fa8abc4b.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa',
      symbol: 'deUSDC',
      decimals: 6,
      name: 'deBridge USD Coin',
      logoURI: 'https://tokens.1inch.io/0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa_2.png',
      eip2612: true,
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xcfe3fbc98d80f7eca0bc76cd1f406a19dd425896',
      symbol: 'NFTI',
      decimals: 18,
      name: 'Scalara NFT Index',
      logoURI: 'https://tokens.1inch.io/0xcfe3fbc98d80f7eca0bc76cd1f406a19dd425896.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xfa5ed56a203466cbbc2430a43c66b9d8723528e7',
      symbol: 'agEUR',
      decimals: 18,
      name: 'agEUR',
      logoURI: 'https://tokens.1inch.io/0xfa5ed56a203466cbbc2430a43c66b9d8723528e7.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x13ad51ed4f1b7e9dc168d8a00cb3f4ddd85efa60',
      symbol: 'LDO',
      decimals: 18,
      name: 'Lido DAO Token',
      logoURI: 'https://tokens.1inch.io/0x13ad51ed4f1b7e9dc168d8a00cb3f4ddd85efa60.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a',
      symbol: 'MIM',
      decimals: 18,
      name: 'Magic Internet Money',
      logoURI: 'https://tokens.1inch.io/0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x8038f3c971414fd1fc220ba727f2d4a0fc98cb65',
      symbol: 'DHT',
      decimals: 18,
      name: 'dHedge DAO Token',
      logoURI: 'https://tokens.1inch.io/0xca1207647ff814039530d7d35df0e1dd2e91fa84.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xc3ae0333f0f34aa734d5493276223d95b8f9cb37',
      symbol: 'DXD',
      decimals: 18,
      name: 'DXdao',
      logoURI: 'https://tokens.1inch.io/0xa1d65e8fb6e87b60feccbc582f7f97804b725521.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a',
      symbol: 'GMX',
      decimals: 18,
      name: 'GMX',
      logoURI: 'https://tokens.1inch.io/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xa0b862f60edef4452f25b4160f177db44deb6cf1',
      symbol: 'GNO',
      decimals: 18,
      name: 'Gnosis Token',
      logoURI: 'https://tokens.1inch.io/0x6810e776880c02933d47db1b9fc05908e5386b96.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x3cd1833ce959e087d0ef0cb45ed06bffe60f23ba',
      symbol: 'LAND',
      decimals: 18,
      name: 'Land',
      logoURI: 'https://tokens.1inch.io/0x9d986a3f147212327dd658f712d5264a73a1fdb0.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
      symbol: 'LINK',
      decimals: 18,
      name: 'ChainLink Token',
      logoURI: 'https://tokens.1inch.io/0x514910771af9ca656af840dff83e8264ecf986ca.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x99f40b01ba9c469193b360f72740e416b17ac332',
      symbol: 'MATH',
      decimals: 18,
      name: 'MATH Token',
      logoURI: 'https://tokens.1inch.io/0x08d967bb0134f2d07f7cfb6e246680c53927dd30.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x4e352cf164e64adcbad318c3a1e222e9eba4ce42',
      symbol: 'MCB',
      decimals: 18,
      name: 'MCDEX Token',
      logoURI: 'https://tokens.1inch.io/0x4e352cf164e64adcbad318c3a1e222e9eba4ce42.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x2e9a6df78e42a30712c10a9dc4b1c8656f8f2879',
      symbol: 'MKR',
      decimals: 18,
      name: 'Maker',
      logoURI: 'https://tokens.1inch.io/0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      symbol: 'ETH',
      decimals: 18,
      name: 'Ether',
      logoURI: 'https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
      tags: ['native'],
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xbfa641051ba0a0ad1b0acf549a89536a0d76472e',
      symbol: 'BADGER',
      decimals: 18,
      name: 'Badger',
      logoURI: 'https://tokens.1inch.io/0x3472a5a71965499acd81997a54bba8d852c6e53d.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x040d1edc9569d4bab2d15287dc5a4f10f56a56b8',
      symbol: 'BAL',
      decimals: 18,
      name: 'Balancer',
      logoURI: 'https://tokens.1inch.io/0xba100000625a3754423978a60c9317c58a424e3d.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x3a8b787f78d775aecfeea15706d4221b40f345ab',
      symbol: 'CELR',
      decimals: 18,
      name: 'CelerToken',
      logoURI: 'https://zapper.fi/images/CELR-icon.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x3642c0680329ae3e103e2b5ab29ddfed4d43cbe5',
      symbol: 'PL2',
      decimals: 18,
      name: 'Plenny',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x51fc0f6660482ea73330e414efd7808811a57fa2',
      symbol: 'PREMIA',
      decimals: 18,
      name: 'Premia',
      logoURI: 'https://tokens.1inch.io/0x6399c842dd2be3de30bf99bc7d1bbf6fa3650e70.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xd4d42f0b6def4ce0383636770ef773390d85c61a',
      symbol: 'SUSHI',
      decimals: 18,
      name: 'SushiToken',
      logoURI: 'https://tokens.1inch.io/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xe80772eaf6e2e18b651f160bc9158b2a5cafca65',
      symbol: 'USD+',
      decimals: 6,
      name: 'USD+',
      logoURI: 'https://tokens.1inch.io/0xe80772eaf6e2e18b651f160bc9158b2a5cafca65.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x354a6da3fcde098f8389cad84b0182725c6c91de',
      symbol: 'COMP',
      decimals: 18,
      name: 'Compound',
      logoURI: 'https://tokens.1inch.io/0xc00e94cb662c3520282e6f5717214004a7f26888.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x11cdb42b0eb46d95f990bedd4695a6e3fa034978',
      symbol: 'CRV',
      decimals: 18,
      name: 'Curve DAO Token',
      logoURI: 'https://tokens.1inch.io/0xd533a949740bb3306d119cc777fa900ba034cd52.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xc628534100180582e43271448098cb2c185795bd',
      symbol: 'FLASH',
      decimals: 18,
      name: 'Flashstake',
      logoURI: 'https://tokens.1inch.io/0xc628534100180582e43271448098cb2c185795bd.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xd77b108d4f6cefaa0cae9506a934e825becca46e',
      symbol: 'WINR',
      decimals: 18,
      name: 'WINR',
      logoURI: 'https://tokens.1inch.io/0xd77b108d4f6cefaa0cae9506a934e825becca46e.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xeb8e93a0c7504bffd8a8ffa56cd754c63aaebfe8',
      symbol: 'DAI+',
      decimals: 18,
      name: 'DAI+',
      logoURI: 'https://tokens.1inch.io/0xeb8e93a0c7504bffd8a8ffa56cd754c63aaebfe8.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x3082cc23568ea640225c2467653db90e9250aaa0',
      symbol: 'RDNT',
      decimals: 18,
      name: 'Radiant',
      logoURI: 'https://tokens.1inch.io/0x3082cc23568ea640225c2467653db90e9250aaa0.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x912ce59144191c1204e64559fe8253a0e49e6548',
      symbol: 'ARB',
      decimals: 18,
      name: 'Arbitrum',
      logoURI: 'https://tokens.1inch.io/0x912ce59144191c1204e64559fe8253a0e49e6548.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x9ed7e4b1bff939ad473da5e7a218c771d1569456',
      symbol: 'REUNI',
      decimals: 6,
      name: 'Reunit Token',
      logoURI: 'https://tokens.1inch.io/0x9ed7e4b1bff939ad473da5e7a218c771d1569456.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x965f298e4ade51c0b0bb24e3369deb6c7d5b3951',
      symbol: 'DCA',
      decimals: 18,
      name: 'AutoDCA',
      logoURI: 'https://tokens.1inch.io/0x965f298e4ade51c0b0bb24e3369deb6c7d5b3951.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xf0a562bee81f674e0c5486c9716060f4cd5ef944',
      symbol: 'UND',
      decimals: 18,
      name: 'Unbound Dollar',
      logoURI: 'https://tokens.1inch.io/0xf0a562bee81f674e0c5486c9716060f4cd5ef944.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x57f12fe6a4e5fe819eec699fadf9db2d06606bb4',
      symbol: 'NPM',
      decimals: 18,
      name: 'Neptune Mutual',
      logoURI: 'https://tokens.1inch.io/0x57f12fe6a4e5fe819eec699fadf9db2d06606bb4.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xb64e280e9d1b5dbec4accedb2257a87b400db149',
      symbol: 'LVL',
      decimals: 18,
      name: 'Level Token',
      logoURI: 'https://tokens.1inch.io/0xb64e280e9d1b5dbec4accedb2257a87b400db149.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x78a0a62fba6fb21a83fe8a3433d44c73a4017a6f',
      symbol: 'OX_OLD',
      decimals: 18,
      name: 'Open Exchange Token OLD',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xc19669a405067927865b40ea045a2baabbbe57f5',
      symbol: 'STAR',
      decimals: 18,
      name: 'STAR',
      logoURI: 'https://tokens.1inch.io/0xc19669a405067927865b40ea045a2baabbbe57f5.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x58b9cb810a68a7f3e1e4f8cb45d1b9b3c79705e8',
      symbol: 'NEXT',
      decimals: 18,
      name: 'Connext',
      logoURI: 'https://tokens.1inch.io/0x58b9cb810a68a7f3e1e4f8cb45d1b9b3c79705e8.png',
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0x323665443cef804a3b5206103304bd4872ea4253',
      symbol: 'USDV',
      decimals: 6,
      name: 'USDV',
      logoURI: 'https://tokens.1inch.io/0x323665443cef804a3b5206103304bd4872ea4253.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.ARBITRUM,
      address: '0xba0dda8762c24da9487f5fa026a9b64b695a07ea',
      symbol: 'OX',
      decimals: 18,
      name: 'OX Coin',
    },
  ],
  [ChainId.ETHEREUM]: [
    {
      symbol: 'L3USD',
      chainId: ChainId.ETHEREUM,
      name: 'L3USD',
      address: '0x2c2d8a078b33bf7782a16acce2c5ba6653a90d5f',
      decimals: 18,
      logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21496.png',
      eip2612: false,
    },
    {
      symbol: 'LSHARE',
      chainId: ChainId.ETHEREUM,
      name: 'LSHARE',
      address: '0x8e01397163b21f64cec1f06ca6cc7d9aa8f718e9',
      decimals: 18,
      logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20728.png',
      eip2612: false,
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x320623b8e4ff03373931769a31fc52a4e78b5d70',
      symbol: 'RSR',
      decimals: 18,
      name: 'Reserve Rights',
      logoURI: 'https://tokens.1inch.io/0x320623b8e4ff03373931769a31fc52a4e78b5d70.png',
      eip2612: true,
    },
    {
      symbol: 'LIF3',
      chainId: ChainId.ETHEREUM,
      name: 'LIF3',
      address: '0x7138eb0d563f3f6722500936a11dcae99d738a2c',
      decimals: 18,
      logoURI: './assets/svg/lif3.svg',
      eip2612: false,
    },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x71ab77b7dbb4fa7e017bc15090b2163221420282',
    //   symbol: 'HIGH',
    //   decimals: 18,
    //   name: 'Highstreet token',
    //   logoURI: 'https://tokens.1inch.io/0x71ab77b7dbb4fa7e017bc15090b2163221420282.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x256d1fce1b1221e8398f65f9b36033ce50b2d497',
    //   symbol: 'wALV',
    //   decimals: 18,
    //   name: 'Alvey Chain',
    //   logoURI: 'https://tokens.1inch.io/0x256d1fce1b1221e8398f65f9b36033ce50b2d497.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x461b71cff4d4334bba09489ace4b5dc1a1813445',
    //   symbol: 'HRD',
    //   decimals: 9,
    //   name: 'Hoard',
    //   logoURI: 'https://tokens.1inch.io/0x461b71cff4d4334bba09489ace4b5dc1a1813445.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x55c08ca52497e2f1534b59e2917bf524d4765257',
    //   symbol: 'UwU',
    //   decimals: 18,
    //   name: 'UwU Lend',
    //   logoURI: 'https://tokens.1inch.io/0x55c08ca52497e2f1534b59e2917bf524d4765257.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x491e136ff7ff03e6ab097e54734697bb5802fc1c',
    //   symbol: 'KTN',
    //   decimals: 18,
    //   name: 'Kattana',
    //   logoURI: 'https://tokens.1inch.io/0x491e136ff7ff03e6ab097e54734697bb5802fc1c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9ae380f0272e2162340a5bb646c354271c0f5cfc',
    //   symbol: 'CNC',
    //   decimals: 18,
    //   name: 'Conic Finance Token',
    //   logoURI: 'https://tokens.1inch.io/0x9ae380f0272e2162340a5bb646c354271c0f5cfc.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9e5bd9d9fad182ff0a93ba8085b664bcab00fa68',
    //   symbol: 'DINGER',
    //   decimals: 9,
    //   name: 'Dinger Token',
    //   logoURI: 'https://tokens.1inch.io/0x9e5bd9d9fad182ff0a93ba8085b664bcab00fa68.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x30dcba0405004cf124045793e1933c798af9e66a',
    //   symbol: 'YDF',
    //   decimals: 18,
    //   name: 'Yieldification',
    //   logoURI: 'https://tokens.1inch.io/0x30dcba0405004cf124045793e1933c798af9e66a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa8c8cfb141a3bb59fea1e2ea6b79b5ecbcd7b6ca',
    //   symbol: 'NOIA',
    //   decimals: 18,
    //   name: 'NOIA Token',
    //   logoURI: 'https://tokens.1inch.io/0xa8c8cfb141a3bb59fea1e2ea6b79b5ecbcd7b6ca.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfcf8eda095e37a41e002e266daad7efc1579bc0a',
    //   symbol: 'FLEX',
    //   decimals: 18,
    //   name: 'FLEX Coin',
    //   logoURI: 'https://tokens.1inch.io/0xfcf8eda095e37a41e002e266daad7efc1579bc0a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x66761fa41377003622aee3c7675fc7b5c1c2fac5',
    //   symbol: 'CPOOL',
    //   decimals: 18,
    //   name: 'Clearpool',
    //   logoURI: 'https://tokens.1inch.io/0x66761fa41377003622aee3c7675fc7b5c1c2fac5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x32e6c34cd57087abbd59b5a4aecc4cb495924356',
    //   symbol: 'BTBS',
    //   decimals: 18,
    //   name: 'BitBase',
    //   logoURI: 'https://tokens.1inch.io/0x32e6c34cd57087abbd59b5a4aecc4cb495924356.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa01199c61841fce3b3dafb83fefc1899715c8756',
    //   symbol: 'CIRUS',
    //   decimals: 18,
    //   name: 'Cirus',
    //   logoURI: 'https://tokens.1inch.io/0xa01199c61841fce3b3dafb83fefc1899715c8756.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9aab071b4129b083b01cb5a0cb513ce7eca26fa5',
    //   symbol: 'HUNT',
    //   decimals: 18,
    //   name: 'HuntToken',
    //   logoURI: 'https://tokens.1inch.io/0x9aab071b4129b083b01cb5a0cb513ce7eca26fa5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9559aaa82d9649c7a7b220e7c461d2e74c9a3593',
    //   symbol: 'rETH_2',
    //   decimals: 18,
    //   name: 'StaFi',
    //   logoURI: 'https://tokens.1inch.io/0x9559aaa82d9649c7a7b220e7c461d2e74c9a3593.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8a6d4c8735371ebaf8874fbd518b56edd66024eb',
    //   symbol: 'BLOCKS',
    //   decimals: 18,
    //   name: 'BLOCKS',
    //   logoURI: 'https://tokens.1inch.io/0x8a6d4c8735371ebaf8874fbd518b56edd66024eb.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x25f8087ead173b73d6e8b84329989a8eea16cf73',
    //   symbol: 'YGG',
    //   decimals: 18,
    //   name: 'Yield Guild Games Token',
    //   logoURI: 'https://tokens.1inch.io/0x25f8087ead173b73d6e8b84329989a8eea16cf73.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1117ac6ad6cdf1a3bc543bad3b133724620522d5',
    //   symbol: 'MODA',
    //   decimals: 18,
    //   name: 'moda',
    //   logoURI: 'https://tokens.1inch.io/0x1117ac6ad6cdf1a3bc543bad3b133724620522d5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9355372396e3f6daf13359b7b607a3374cc638e0',
    //   symbol: 'WHALE',
    //   decimals: 4,
    //   name: 'WHALE',
    //   logoURI: 'https://tokens.1inch.io/0x9355372396e3f6daf13359b7b607a3374cc638e0.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x08c32b0726c5684024ea6e141c50ade9690bbdcc',
    //   symbol: 'STOS',
    //   decimals: 18,
    //   name: 'Stratos Token',
    //   logoURI: 'https://tokens.1inch.io/0x08c32b0726c5684024ea6e141c50ade9690bbdcc.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x675bbc7514013e2073db7a919f6e4cbef576de37',
    //   symbol: 'CLS',
    //   decimals: 18,
    //   name: 'Coldstack',
    //   logoURI: 'https://tokens.1inch.io/0x675bbc7514013e2073db7a919f6e4cbef576de37.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x269616d549d7e8eaa82dfb17028d0b212d11232a',
    //   symbol: 'PUNK',
    //   decimals: 18,
    //   name: 'CryptoPunks',
    //   logoURI: 'https://tokens.1inch.io/0x269616d549d7e8eaa82dfb17028d0b212d11232a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2a3bff78b79a009976eea096a51a948a3dc00e34',
    //   symbol: 'WILD',
    //   decimals: 18,
    //   name: 'Wilder',
    //   logoURI: 'https://tokens.1inch.io/0x2a3bff78b79a009976eea096a51a948a3dc00e34.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x43a96962254855f16b925556f9e97be436a43448',
    //   symbol: 'HORD',
    //   decimals: 18,
    //   name: 'HORD Token',
    //   logoURI: 'https://tokens.1inch.io/0x43a96962254855f16b925556f9e97be436a43448.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0b63128c40737b13647552e0c926bcfeccc35f93',
    //   symbol: 'wLITI',
    //   decimals: 18,
    //   name: 'wLitiCapital',
    //   logoURI: 'https://tokens.1inch.io/0x0b63128c40737b13647552e0c926bcfeccc35f93.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x74232704659ef37c08995e386a2e26cc27a8d7b1',
    //   symbol: 'STRK',
    //   decimals: 18,
    //   name: 'Strike Token',
    //   logoURI: 'https://tokens.1inch.io/0x74232704659ef37c08995e386a2e26cc27a8d7b1.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x35e78b3982e87ecfd5b3f3265b601c046cdbe232',
    //   symbol: 'XAI',
    //   decimals: 18,
    //   name: 'SideShift Token',
    //   logoURI: 'https://tokens.1inch.io/0x35e78b3982e87ecfd5b3f3265b601c046cdbe232.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa41f142b6eb2b164f8164cae0716892ce02f311f',
    //   symbol: 'AVG',
    //   decimals: 18,
    //   name: 'Avocado DAO Token',
    //   logoURI: 'https://tokens.1inch.io/0xa41f142b6eb2b164f8164cae0716892ce02f311f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x51fe2e572e97bfeb1d719809d743ec2675924edc',
    //   symbol: 'VPAD',
    //   decimals: 18,
    //   name: 'VLaunch',
    //   logoURI: 'https://tokens.1inch.io/0x51fe2e572e97bfeb1d719809d743ec2675924edc.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf0d33beda4d734c72684b5f9abbebf715d0a7935',
    //   symbol: 'NTX',
    //   decimals: 6,
    //   name: 'NuNet',
    //   logoURI: 'https://tokens.1inch.io/0xf0d33beda4d734c72684b5f9abbebf715d0a7935.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x333a4823466879eef910a04d473505da62142069',
    //   symbol: 'NATION',
    //   decimals: 18,
    //   name: 'Nation3',
    //   logoURI: 'https://tokens.1inch.io/0x333a4823466879eef910a04d473505da62142069.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0e5c8c387c5eba2ecbc137ad012aed5fe729e251',
    //   symbol: 'RPG',
    //   decimals: 18,
    //   name: 'Rangers Protocol Gas',
    //   logoURI: 'https://tokens.1inch.io/0x0e5c8c387c5eba2ecbc137ad012aed5fe729e251.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3ea8ea4237344c9931214796d9417af1a1180770',
    //   symbol: 'FLX',
    //   decimals: 18,
    //   name: 'Flux Token',
    //   logoURI: 'https://tokens.1inch.io/0x3ea8ea4237344c9931214796d9417af1a1180770.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0d02755a5700414b26ff040e1de35d337df56218',
    //   symbol: 'BEND',
    //   decimals: 18,
    //   name: 'Bend Token',
    //   logoURI: 'https://tokens.1inch.io/0x0d02755a5700414b26ff040e1de35d337df56218.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9f52c8ecbee10e00d9faaac5ee9ba0ff6550f511',
    //   symbol: 'SIPHER',
    //   decimals: 18,
    //   name: 'Sipher Token',
    //   logoURI: 'https://tokens.1inch.io/0x9f52c8ecbee10e00d9faaac5ee9ba0ff6550f511.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x329c6e459ffa7475718838145e5e85802db2a303',
    //   symbol: 'eMAID',
    //   decimals: 18,
    //   name: 'MaidSafeCoin',
    //   logoURI: 'https://tokens.1inch.io/0x329c6e459ffa7475718838145e5e85802db2a303.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x83e9f223e1edb3486f876ee888d76bfba26c475a',
    //   symbol: 'GUILD',
    //   decimals: 18,
    //   name: 'BlockchainSpace',
    //   logoURI: 'https://tokens.1inch.io/0x83e9f223e1edb3486f876ee888d76bfba26c475a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x68749665ff8d2d112fa859aa293f07a622782f38',
    //   symbol: 'XAUt',
    //   decimals: 6,
    //   name: 'Tether Gold',
    //   logoURI: 'https://tokens.1inch.io/0x68749665ff8d2d112fa859aa293f07a622782f38.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x616e8bfa43f920657b3497dbf40d6b1a02d4608d',
    //   symbol: 'auraBAL',
    //   decimals: 18,
    //   name: 'Aura BAL',
    //   logoURI: 'https://tokens.1inch.io/0x616e8bfa43f920657b3497dbf40d6b1a02d4608d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3819f64f282bf135d62168c1e513280daf905e06',
    //   symbol: 'HDRN',
    //   decimals: 9,
    //   name: 'Hedron',
    //   logoURI: 'https://tokens.1inch.io/0x3819f64f282bf135d62168c1e513280daf905e06.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9d65ff81a3c488d585bbfb0bfe3c7707c7917f54',
    //   symbol: 'SSV',
    //   decimals: 18,
    //   name: 'SSV Token',
    //   logoURI: 'https://tokens.1inch.io/0x9d65ff81a3c488d585bbfb0bfe3c7707c7917f54.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x549020a9cb845220d66d3e9c6d9f9ef61c981102',
    //   symbol: 'SIDUS',
    //   decimals: 18,
    //   name: 'SIDUS',
    //   logoURI: 'https://tokens.1inch.io/0x549020a9cb845220d66d3e9c6d9f9ef61c981102.png',
    // },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x3ec8798b81485a254928b70cda1cf0a2bb0b74d7',
      symbol: 'GRO',
      decimals: 18,
      name: 'Gro DAO Token',
      logoURI: 'https://tokens.1inch.io/0x3ec8798b81485a254928b70cda1cf0a2bb0b74d7.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xfaba6f8e4a5e8ab82f62fe7c39859fa577269be3',
      symbol: 'ONDO',
      decimals: 18,
      name: 'Ondo Finance',
      logoURI: 'https://tokens.1inch.io/0xfaba6f8e4a5e8ab82f62fe7c39859fa577269be3.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x86b4dbe5d203e634a12364c0e428fa242a3fba98',
      symbol: 'GBPT',
      decimals: 18,
      name: 'poundtoken',
      logoURI: 'https://tokens.1inch.io/0x86b4dbe5d203e634a12364c0e428fa242a3fba98.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x30d20208d987713f46dfd34ef128bb16c404d10f',
      symbol: 'SD',
      decimals: 18,
      name: 'Stader',
      logoURI: 'https://tokens.1inch.io/0x30d20208d987713f46dfd34ef128bb16c404d10f.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xe5a733681bbe6cd8c764bb8078ef8e13a576dd78',
      symbol: 'DPAY',
      decimals: 18,
      name: 'Devour',
      logoURI: 'https://tokens.1inch.io/0xe5a733681bbe6cd8c764bb8078ef8e13a576dd78.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x12bb890508c125661e03b09ec06e404bc9289040',
      symbol: 'RACA',
      decimals: 18,
      name: 'Radio Caca V2',
      logoURI: 'https://tokens.1inch.io/0x12bb890508c125661e03b09ec06e404bc9289040.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x505b5eda5e25a67e1c24a2bf1a527ed9eb88bf04',
      symbol: 'CWEB',
      decimals: 18,
      name: 'Coinweb',
      logoURI: 'https://tokens.1inch.io/0x505b5eda5e25a67e1c24a2bf1a527ed9eb88bf04.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x01ba67aac7f75f647d94220cc98fb30fcc5105bf',
      symbol: 'LYRA',
      decimals: 18,
      name: 'Lyra Token',
      logoURI: 'https://tokens.1inch.io/0x01ba67aac7f75f647d94220cc98fb30fcc5105bf.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x2ebd53d035150f328bd754d6dc66b99b0edb89aa',
      symbol: 'MET',
      decimals: 18,
      name: 'Metronome2',
      logoURI: 'https://tokens.1inch.io/0x2ebd53d035150f328bd754d6dc66b99b0edb89aa.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x120a3879da835a5af037bb2d1456bebd6b54d4ba',
      symbol: 'RVST',
      decimals: 18,
      name: 'Revest',
      logoURI: 'https://tokens.1inch.io/0x120a3879da835a5af037bb2d1456bebd6b54d4ba.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x80d55c03180349fff4a229102f62328220a96444',
      symbol: 'OPUL',
      decimals: 18,
      name: 'OpulousToken',
      logoURI: 'https://tokens.1inch.io/0x80d55c03180349fff4a229102f62328220a96444.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x467719ad09025fcc6cf6f8311755809d45a5e5f3',
      symbol: 'AXL',
      decimals: 6,
      name: 'Axelar',
      logoURI: 'https://tokens.1inch.io/0x467719ad09025fcc6cf6f8311755809d45a5e5f3.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x12b6893ce26ea6341919fe289212ef77e51688c8',
      symbol: 'TAMA',
      decimals: 18,
      name: 'Tamadoge',
      logoURI: 'https://tokens.1inch.io/0x12b6893ce26ea6341919fe289212ef77e51688c8.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x37cd4e8875e3edaffdfe9be63958f07effbd0bfd',
      symbol: 'VCASH',
      decimals: 18,
      name: 'void.cash',
      logoURI: 'https://tokens.1inch.io/0x37cd4e8875e3edaffdfe9be63958f07effbd0bfd.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x06450dee7fd2fb8e39061434babcfc05599a6fb8',
      symbol: 'XEN',
      decimals: 18,
      name: 'XEN Crypto',
      logoURI: 'https://tokens.1inch.io/0x06450dee7fd2fb8e39061434babcfc05599a6fb8.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x6c3ea9036406852006290770bedfcaba0e23a0e8',
      symbol: 'PYUSD',
      decimals: 6,
      name: 'Paypal USD',
      logoURI: 'https://tokens.1inch.io/0x6c3ea9036406852006290770bedfcaba0e23a0e8.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x3496b523e5c00a4b4150d6721320cddb234c3079',
      symbol: 'NUM',
      decimals: 18,
      name: 'NUM Token',
      logoURI: 'https://tokens.1inch.io/0x3496b523e5c00a4b4150d6721320cddb234c3079.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xea7cc765ebc94c4805e3bff28d7e4ae48d06468a',
      symbol: 'PAD',
      decimals: 18,
      name: 'NearPad Token',
      logoURI: 'https://tokens.1inch.io/0xea7cc765ebc94c4805e3bff28d7e4ae48d06468a.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xe80c0cd204d654cebe8dd64a4857cab6be8345a3',
      symbol: 'JPEG',
      decimals: 18,
      name: 'JPEG’d Governance Token',
      logoURI: 'https://tokens.1inch.io/0xe80c0cd204d654cebe8dd64a4857cab6be8345a3.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x5807ca447851c98569c567963b25b1c83d41bebc',
      symbol: 'REALTOKEN-10024-10028-APPOLINE-ST-DETROIT-MI',
      decimals: 18,
      name: 'RealToken 10024-10028 Appoline Street Detroit MI',
      logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x08ad1f3a48be1d23c723a6cc8486b247f5de935a',
      symbol: 'REALTOKEN-S-13116-KILBOURNE-AVE-DETROIT-MI_1',
      decimals: 18,
      name: 'RealToken S 13116 Kilbourne Ave Detroit MI',
      logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xd1c15cebfdcd16f00d91666bf64c8b66cbf5e9b5',
      symbol: 'REALTOKEN-S-10612-SOMERSET-AVE-DETROIT-MI_1',
      decimals: 18,
      name: 'RealToken S 10612 Somerset Ave Detroit MI',
      logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x3d2129d9ceed93992cea3ee7d8e44754faedc922',
      symbol: 'REALTOKEN-S-12730-WADE-ST-DETROIT-MI',
      decimals: 18,
      name: 'RealToken S 12730 Wade St Detroit MI',
      logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xa5fd99f142ebf4343974137a3200e5197bf0c81b',
      symbol: 'REALTOKEN-S-1907-OTTAWA-DR-TOLEDO-OH',
      decimals: 18,
      name: 'RealToken S 1907 Ottawa Dr Toledo OH',
      logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x964ee9e5d9d8abfc768f8d860cc6c33e75d37112',
      symbol: 'REALTOKEN-S-9151-WHITTIER-AVE-DETROIT-MI',
      decimals: 18,
      name: 'RealToken S 9151 Whittier Ave Detroit MI',
      logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xfd0205066521550d7d7ab19da8f72bb004b4c341',
      symbol: 'LIT',
      decimals: 18,
      name: 'Liquidity Incentive Token',
      logoURI: 'https://tokens.1inch.io/0xfd0205066521550d7d7ab19da8f72bb004b4c341.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x3472a5a71965499acd81997a54bba8d852c6e53d',
      symbol: 'BADGER',
      decimals: 18,
      name: 'Badger',
      logoURI: 'https://tokens.1inch.io/0x3472a5a71965499acd81997a54bba8d852c6e53d.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xc944e90c64b2c07662a292be6244bdf05cda44a7',
      symbol: 'GRT',
      decimals: 18,
      name: 'Graph Token',
      logoURI: 'https://tokens.1inch.io/0xc944e90c64b2c07662a292be6244bdf05cda44a7.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
      symbol: 'LDO',
      decimals: 18,
      name: 'Lido DAO Token',
      logoURI: 'https://tokens.1inch.io/0x5a98fcbea516cf06857215779fd812ca3bef1b32.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
      symbol: 'stETH',
      decimals: 18,
      name: 'stETH',
      logoURI: 'https://tokens.1inch.io/0xae7ab96520de3a18e5e111b5eaab095312d7fe84.png',
      tags: ['PEG:ETH', 'tokens'],
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x341c05c0e9b33c0e38d64de76516b2ce970bb3be',
      symbol: 'dsETH',
      decimals: 18,
      name: 'Diversified Staked ETH Index (dsETH)',
      logoURI: 'https://tokens.1inch.io/0x341c05c0e9b33c0e38d64de76516b2ce970bb3be.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x98c23e9d8f34fefb1b7bd6a91b7ff122f4e16f5c',
      symbol: 'aEthUSDC',
      decimals: 6,
      name: 'Aave v3 USDC',
      logoURI: 'https://tokens.1inch.io/0x98c23e9d8f34fefb1b7bd6a91b7ff122f4e16f5c.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x1e31b601488e97bc247c57af7b6aa336edbc5477',
      symbol: 'UP',
      decimals: 18,
      name: 'Unicorn Power',
      logoURI: 'https://tokens.1inch.io/0x1e31b601488e97bc247c57af7b6aa336edbc5477.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x0ae055097c6d159879521c384f1d2123d1f195e6',
      symbol: 'STAKE',
      decimals: 18,
      name: 'STAKE',
      logoURI: 'https://tokens.1inch.io/0x0ae055097c6d159879521c384f1d2123d1f195e6.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x88df592f8eb5d7bd38bfef7deb0fbc02cf3778a0',
      symbol: 'TRB',
      decimals: 18,
      name: 'Tellor Tributes',
      logoURI: 'https://tokens.1inch.io/0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
      symbol: 'YFI',
      decimals: 18,
      name: 'yearn.finance',
      logoURI: 'https://tokens.1inch.io/0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x0d438f3b5175bebc262bf23753c1e53d03432bde',
      symbol: 'wNXM',
      decimals: 18,
      name: 'Wrapped NXM',
      logoURI: 'https://tokens.1inch.io/0x0d438f3b5175bebc262bf23753c1e53d03432bde.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x0d8775f648430679a709e98d2b0cb6250d2887ef',
      symbol: 'BAT',
      decimals: 18,
      name: 'Basic Attention Token',
      logoURI: 'https://tokens.1inch.io/0x0d8775f648430679a709e98d2b0cb6250d2887ef.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x0e8d6b471e332f140e7d9dbb99e5e3822f728da6',
      symbol: 'ABYSS',
      decimals: 18,
      name: 'Abyss',
      logoURI: 'https://tokens.1inch.io/0x0e8d6b471e332f140e7d9dbb99e5e3822f728da6.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x0327112423f3a68efdf1fcf402f6c5cb9f7c33fd',
      symbol: 'BTC++',
      decimals: 18,
      name: 'PieDAO BTC++',
      logoURI: 'https://tokens.1inch.io/0x0327112423f3a68efdf1fcf402f6c5cb9f7c33fd.png',
      tags: ['PEG:BTC', 'tokens'],
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x04fa0d235c4abf4bcf4787af4cf447de572ef828',
      symbol: 'UMA',
      decimals: 18,
      name: 'UMA Voting Token v1',
      logoURI: 'https://tokens.1inch.io/0x04fa0d235c4abf4bcf4787af4cf447de572ef828.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x08d967bb0134f2d07f7cfb6e246680c53927dd30',
      symbol: 'MATH',
      decimals: 18,
      name: 'MATH Token',
      logoURI: 'https://tokens.1inch.io/0x08d967bb0134f2d07f7cfb6e246680c53927dd30.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x249ca82617ec3dfb2589c4c17ab7ec9765350a18',
      symbol: 'VERSE',
      decimals: 18,
      name: 'Verse',
      logoURI: 'https://tokens.1inch.io/0x249ca82617ec3dfb2589c4c17ab7ec9765350a18.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x5732046a883704404f284ce41ffadd5b007fd668',
      symbol: 'BLZ',
      decimals: 18,
      name: 'Bluzelle',
      logoURI: 'https://tokens.1inch.io/0x5732046a883704404f284ce41ffadd5b007fd668.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x57700244b20f84799a31c6c96dadff373ca9d6c5',
      symbol: 'TRUST',
      decimals: 18,
      name: 'TRUST DAO',
      logoURI: 'https://tokens.1inch.io/0x57700244b20f84799a31c6c96dadff373ca9d6c5.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x58b6a8a3302369daec383334672404ee733ab239',
      symbol: 'LPT',
      decimals: 18,
      name: 'Livepeer Token',
      logoURI: 'https://tokens.1inch.io/0x58b6a8a3302369daec383334672404ee733ab239.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x595832f8fc6bf59c85c527fec3740a1b7a361269',
      symbol: 'POWR',
      decimals: 6,
      name: 'Power Ledger',
      logoURI: 'https://tokens.1inch.io/0x595832f8fc6bf59c85c527fec3740a1b7a361269.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x5adc961d6ac3f7062d2ea45fefb8d8167d44b190',
      symbol: 'DTH',
      decimals: 18,
      name: 'Dether',
      logoURI: 'https://tokens.1inch.io/0x5adc961d6ac3f7062d2ea45fefb8d8167d44b190.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x1beef31946fbbb40b877a72e4ae04a8d1a5cee06',
      symbol: 'PAR_2',
      decimals: 18,
      name: 'Parachute',
      logoURI: 'https://tokens.1inch.io/0x1beef31946fbbb40b877a72e4ae04a8d1a5cee06.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x196f4727526ea7fb1e17b2071b3d8eaa38486988',
      symbol: 'RSV',
      decimals: 18,
      name: 'Reserve',
      logoURI: 'https://tokens.1inch.io/0x196f4727526ea7fb1e17b2071b3d8eaa38486988.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c',
      symbol: 'BNT',
      decimals: 18,
      name: 'Bancor',
      logoURI: 'https://tokens.1inch.io/0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x221657776846890989a759ba2973e427dff5c9bb',
      symbol: 'REPv2',
      decimals: 18,
      name: 'Reputation V2',
      logoURI: 'https://tokens.1inch.io/0x221657776846890989a759ba2973e427dff5c9bb.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
      symbol: 'WBTC',
      decimals: 8,
      name: 'Wrapped BTC',
      logoURI: 'https://tokens.1inch.io/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599.png',
      tags: ['PEG:BTC', 'tokens'],
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x27054b13b1b798b345b591a4d22e6562d47ea75a',
      symbol: 'AST',
      decimals: 4,
      name: 'AirSwap',
      logoURI: 'https://tokens.1inch.io/0x27054b13b1b798b345b591a4d22e6562d47ea75a.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x28cb7e841ee97947a86b06fa4090c8451f64c0be',
      symbol: 'YFL',
      decimals: 18,
      name: 'YFLink',
      logoURI: 'https://tokens.1inch.io/0x28cb7e841ee97947a86b06fa4090c8451f64c0be.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x28dee01d53fed0edf5f6e310bf8ef9311513ae40',
      symbol: 'XBP',
      decimals: 18,
      name: 'BlitzPredict',
      logoURI: 'https://tokens.1inch.io/0x28dee01d53fed0edf5f6e310bf8ef9311513ae40.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x2ba592f78db6436527729929aaf6c908497cb200',
      symbol: 'CREAM',
      decimals: 18,
      name: 'Cream',
      logoURI: 'https://tokens.1inch.io/0x2ba592f78db6436527729929aaf6c908497cb200.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x36c833eed0d376f75d1ff9dfdee260191336065e',
      symbol: 'gtcETH',
      decimals: 18,
      name: 'Gitcoin Staked ETH Index (gtcETH)',
      logoURI: 'https://tokens.1inch.io/0x36c833eed0d376f75d1ff9dfdee260191336065e.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x644192291cc835a93d6330b24ea5f5fedd0eef9e',
      symbol: 'NXRA',
      decimals: 18,
      name: 'AllianceBlock Nexera Token',
      logoURI: 'https://tokens.1inch.io/0x644192291cc835a93d6330b24ea5f5fedd0eef9e.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x76bc677d444f1e9d57daf5187ee2b7dc852745ae',
      symbol: 'XFT',
      decimals: 18,
      name: 'Offshift',
      logoURI: 'https://tokens.1inch.io/0x76bc677d444f1e9d57daf5187ee2b7dc852745ae.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x0f5d2fb29fb7d3cfee444a200298f468908cc942',
      symbol: 'MANA',
      decimals: 18,
      name: 'Mana',
      logoURI: 'https://tokens.1inch.io/0x0f5d2fb29fb7d3cfee444a200298f468908cc942.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xaaef88cea01475125522e117bfe45cf32044e238',
      symbol: 'GF',
      decimals: 18,
      name: 'GuildFi Token',
      logoURI: 'https://tokens.1inch.io/0xaaef88cea01475125522e117bfe45cf32044e238.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x4946fcea7c692606e8908002e55a582af44ac121',
      symbol: 'FOAM',
      decimals: 18,
      name: 'FOAM Token',
      logoURI: 'https://tokens.1inch.io/0x4946fcea7c692606e8908002e55a582af44ac121.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x4a220e6096b25eadb88358cb44068a3248254675',
      symbol: 'QNT',
      decimals: 18,
      name: 'Quant',
      logoURI: 'https://tokens.1inch.io/0x4a220e6096b25eadb88358cb44068a3248254675.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x4da9b813057d04baef4e5800e36083717b4a0341',
      symbol: 'aTUSDv1',
      decimals: 18,
      name: 'Aave Interest bearing TUSD',
      logoURI: 'https://tokens.1inch.io/0x4da9b813057d04baef4e5800e36083717b4a0341.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x0f7f961648ae6db43c75663ac7e5414eb79b5704',
      symbol: 'XIO',
      decimals: 18,
      name: 'XIO Network',
      logoURI: 'https://tokens.1inch.io/0x0f7f961648ae6db43c75663ac7e5414eb79b5704.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x1776e1f26f98b1a5df9cd347953a26dd3cb46671',
      symbol: 'NMR',
      decimals: 18,
      name: 'Numeraire',
      logoURI: 'https://tokens.1inch.io/0x1776e1f26f98b1a5df9cd347953a26dd3cb46671.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x178c820f862b14f316509ec36b13123da19a6054',
      symbol: 'EWTB',
      decimals: 18,
      name: 'Energy Web Token Bridged',
      logoURI: 'https://tokens.1inch.io/0x178c820f862b14f316509ec36b13123da19a6054.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x1a5f9352af8af974bfc03399e3767df6370d82e4',
      symbol: 'OWL',
      decimals: 18,
      name: 'OWL Token',
      logoURI: 'https://tokens.1inch.io/0x1a5f9352af8af974bfc03399e3767df6370d82e4.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x340d2bde5eb28c1eed91b2f790723e3b160613b7',
      symbol: 'VEE',
      decimals: 18,
      name: 'BLOCKv',
      logoURI: 'https://tokens.1inch.io/0x340d2bde5eb28c1eed91b2f790723e3b160613b7.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x408e41876cccdc0f92210600ef50372656052a38',
      symbol: 'REN',
      decimals: 18,
      name: 'Republic',
      logoURI: 'https://tokens.1inch.io/0x408e41876cccdc0f92210600ef50372656052a38.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x40fd72257597aa14c7231a7b1aaa29fce868f677',
      symbol: 'XOR',
      decimals: 18,
      name: 'Sora Token',
      logoURI: 'https://tokens.1inch.io/0x40fd72257597aa14c7231a7b1aaa29fce868f677.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x419d0d8bdd9af5e606ae2232ed285aff190e711b',
      symbol: 'FUN',
      decimals: 8,
      name: 'FunFair',
      logoURI: 'https://tokens.1inch.io/0x419d0d8bdd9af5e606ae2232ed285aff190e711b.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x41e5560054824ea6b0732e656e3ad64e20e94e45',
      symbol: 'CVC',
      decimals: 8,
      name: 'Civic',
      logoURI: 'https://tokens.1inch.io/0x41e5560054824ea6b0732e656e3ad64e20e94e45.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x4fabb145d64652a948d72533023f6e7a623c7c53',
      symbol: 'BUSD',
      decimals: 18,
      name: 'Binance USD',
      logoURI: 'https://tokens.1inch.io/0x4fabb145d64652a948d72533023f6e7a623c7c53.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x514910771af9ca656af840dff83e8264ecf986ca',
      symbol: 'LINK',
      decimals: 18,
      name: 'Chain Link',
      logoURI: 'https://tokens.1inch.io/0x514910771af9ca656af840dff83e8264ecf986ca.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x543ff227f64aa17ea132bf9886cab5db55dcaddf',
      symbol: 'GEN',
      decimals: 18,
      name: 'DAOStack',
      logoURI: 'https://tokens.1inch.io/0x543ff227f64aa17ea132bf9886cab5db55dcaddf.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x5caf454ba92e6f2c929df14667ee360ed9fd5b26',
      symbol: 'DEV',
      decimals: 18,
      name: 'Dev',
      logoURI: 'https://tokens.1inch.io/0x5caf454ba92e6f2c929df14667ee360ed9fd5b26.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x5d3a536e4d6dbd6114cc1ead35777bab948e3643',
      symbol: 'cDAI',
      decimals: 8,
      name: 'Compound Dai',
      logoURI: 'https://tokens.1inch.io/0x5d3a536e4d6dbd6114cc1ead35777bab948e3643.png',
      tags: ['savings'],
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x5d60d8d7ef6d37e16ebabc324de3be57f135e0bc',
      symbol: 'MYB',
      decimals: 18,
      name: 'MyBit',
      logoURI: 'https://tokens.1inch.io/0x5d60d8d7ef6d37e16ebabc324de3be57f135e0bc.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x607c794cda77efb21f8848b7910ecf27451ae842',
      symbol: 'PIE',
      decimals: 18,
      name: 'DeFiPIE Token',
      logoURI: 'https://tokens.1inch.io/0x607c794cda77efb21f8848b7910ecf27451ae842.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x607f4c5bb672230e8672085532f7e901544a7375',
      symbol: 'RLC',
      decimals: 9,
      name: 'iExec RLC',
      logoURI: 'https://tokens.1inch.io/0x607f4c5bb672230e8672085532f7e901544a7375.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x625ae63000f46200499120b906716420bd059240',
      symbol: 'aSUSDv1',
      decimals: 18,
      name: 'Aave Interest bearing SUSD',
      logoURI: 'https://tokens.1inch.io/0x625ae63000f46200499120b906716420bd059240.png',
      tags: ['savings'],
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x6710c63432a2de02954fc0f851db07146a6c0312',
      symbol: 'MFG',
      decimals: 18,
      name: 'Smart MFG',
      logoURI: 'https://tokens.1inch.io/0x6710c63432a2de02954fc0f851db07146a6c0312.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x6810e776880c02933d47db1b9fc05908e5386b96',
      symbol: 'GNO',
      decimals: 18,
      name: 'Gnosis',
      logoURI: 'https://tokens.1inch.io/0x6810e776880c02933d47db1b9fc05908e5386b96.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x68d57c9a1c35f63e2c83ee8e49a64e9d70528d25',
      symbol: 'SRN',
      decimals: 18,
      name: 'Sirin Labs',
      logoURI: 'https://tokens.1inch.io/0x68d57c9a1c35f63e2c83ee8e49a64e9d70528d25.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x6b785a0322126826d8226d77e173d75dafb84d11',
      symbol: 'VLT',
      decimals: 18,
      name: 'Bankroll Vault',
      logoURI: 'https://tokens.1inch.io/0x6b785a0322126826d8226d77e173d75dafb84d11.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x6b9f031d718dded0d681c20cb754f97b3bb81b78',
      symbol: 'GEEQ',
      decimals: 18,
      name: 'Geeq',
      logoURI: 'https://tokens.1inch.io/0x6b9f031d718dded0d681c20cb754f97b3bb81b78.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x6c6ee5e31d828de241282b9606c8e98ea48526e2',
      symbol: 'HOT',
      decimals: 18,
      name: 'HoloToken',
      logoURI: 'https://tokens.1inch.io/0x6c6ee5e31d828de241282b9606c8e98ea48526e2.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x71fc860f7d3a592a4a98740e39db31d25db65ae8',
      symbol: 'aUSDTv1',
      decimals: 6,
      name: 'Aave Interest bearing USDT',
      logoURI: 'https://tokens.1inch.io/0x71fc860f7d3a592a4a98740e39db31d25db65ae8.png',
      tags: ['PEG:USD', 'savings'],
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x744d70fdbe2ba4cf95131626614a1763df805b9e',
      symbol: 'SNT',
      decimals: 18,
      name: 'Status',
      logoURI: 'https://tokens.1inch.io/0x744d70fdbe2ba4cf95131626614a1763df805b9e.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x7b0c06043468469967dba22d1af33d77d44056c8',
      symbol: 'MRPH',
      decimals: 4,
      name: 'Morpheus Network',
      logoURI: 'https://tokens.1inch.io/0x7b0c06043468469967dba22d1af33d77d44056c8.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x7b123f53421b1bf8533339bfbdc7c98aa94163db',
      symbol: 'buidl_1',
      decimals: 18,
      name: 'dfohub',
      logoURI: 'https://tokens.1inch.io/0x7b123f53421b1bf8533339bfbdc7c98aa94163db.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x7c5a0ce9267ed19b22f8cae653f198e3e8daf098',
      symbol: 'SAN',
      decimals: 18,
      name: 'Santiment',
      logoURI: 'https://tokens.1inch.io/0x7c5a0ce9267ed19b22f8cae653f198e3e8daf098.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x7de91b204c1c737bcee6f000aaa6569cf7061cb7',
      symbol: 'XRT',
      decimals: 9,
      name: 'Robonomics',
      logoURI: 'https://tokens.1inch.io/0x7de91b204c1c737bcee6f000aaa6569cf7061cb7.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x80fb784b7ed66730e8b1dbd9820afd29931aab03',
      symbol: 'LEND',
      decimals: 18,
      name: 'EthLend',
      logoURI: 'https://tokens.1inch.io/0x80fb784b7ed66730e8b1dbd9820afd29931aab03.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x814e0908b12a99fecf5bc101bb5d0b8b5cdf7d26',
      symbol: 'MDT_1',
      decimals: 18,
      name: 'Measurable Data Token',
      logoURI: 'https://tokens.1inch.io/0x814e0908b12a99fecf5bc101bb5d0b8b5cdf7d26.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x8207c1ffc5b6804f6024322ccf34f29c3541ae26',
      symbol: 'OGN',
      decimals: 18,
      name: 'Origin Protocol',
      logoURI: 'https://tokens.1inch.io/0x8207c1ffc5b6804f6024322ccf34f29c3541ae26.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x8400d94a5cb0fa0d041a3788e395285d61c9ee5e',
      symbol: 'UBT',
      decimals: 8,
      name: 'UniBright',
      logoURI: 'https://tokens.1inch.io/0x8400d94a5cb0fa0d041a3788e395285d61c9ee5e.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x84ca8bc7997272c7cfb4d0cd3d55cd942b3c9419',
      symbol: 'DIA',
      decimals: 18,
      name: 'DIAToken',
      logoURI: 'https://tokens.1inch.io/0x84ca8bc7997272c7cfb4d0cd3d55cd942b3c9419.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x89ab32156e46f46d02ade3fecbe5fc4243b9aaed',
      symbol: 'PNT',
      decimals: 18,
      name: 'pNetwork Token',
      logoURI: 'https://tokens.1inch.io/0x89ab32156e46f46d02ade3fecbe5fc4243b9aaed.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7',
      symbol: 'AKRO',
      decimals: 18,
      name: 'Akropolis',
      logoURI: 'https://tokens.1inch.io/0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9',
      symbol: 'SXP',
      decimals: 18,
      name: 'Swipe',
      logoURI: 'https://tokens.1inch.io/0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x8e870d67f660d95d5be530380d0ec0bd388289e1',
      symbol: 'USDP',
      decimals: 18,
      name: 'Pax Dollar',
      logoURI: 'https://tokens.1inch.io/0x8e870d67f660d95d5be530380d0ec0bd388289e1_1.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x8eb24319393716668d768dcec29356ae9cffe285',
      symbol: 'AGI',
      decimals: 8,
      name: 'SingularityNET Token',
      logoURI: 'https://tokens.1inch.io/0x8eb24319393716668d768dcec29356ae9cffe285.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x8f8221afbb33998d8584a2b05749ba73c37a938a',
      symbol: 'REQ',
      decimals: 18,
      name: 'Request',
      logoURI: 'https://tokens.1inch.io/0x8f8221afbb33998d8584a2b05749ba73c37a938a.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x0258f474786ddfd37abce6df6bbb1dd5dfc4434a',
      symbol: 'ORN',
      decimals: 8,
      name: 'Orion Protocol',
      logoURI: 'https://tokens.1inch.io/0x0258f474786ddfd37abce6df6bbb1dd5dfc4434a.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d',
      symbol: 'PNK',
      decimals: 18,
      name: 'Kleros',
      logoURI: 'https://tokens.1inch.io/0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x95172ccbe8344fecd73d0a30f54123652981bd6f',
      symbol: 'LOCK',
      decimals: 18,
      name: 'Meridian Network',
      logoURI: 'https://tokens.1inch.io/0x95172ccbe8344fecd73d0a30f54123652981bd6f.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x960b236a07cf122663c4303350609a66a7b288c0',
      symbol: 'ANTv1',
      decimals: 18,
      name: 'Aragon Network Token',
      logoURI: 'https://tokens.1inch.io/0x960b236a07cf122663c4303350609a66a7b288c0.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x967da4048cd07ab37855c090aaf366e4ce1b9f48',
      symbol: 'OCEAN',
      decimals: 18,
      name: 'OceanToken',
      logoURI: 'https://tokens.1inch.io/0x967da4048cd07ab37855c090aaf366e4ce1b9f48.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x990f341946a3fdb507ae7e52d17851b87168017c',
      symbol: 'STRONG',
      decimals: 18,
      name: 'Strong',
      logoURI: 'https://tokens.1inch.io/0x990f341946a3fdb507ae7e52d17851b87168017c.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x9992ec3cf6a55b00978cddf2b27bc6882d88d1ec',
      symbol: 'POLY',
      decimals: 18,
      name: 'Polymath',
      logoURI: 'https://tokens.1inch.io/0x9992ec3cf6a55b00978cddf2b27bc6882d88d1ec.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x9ba00d6856a4edf4665bca2c2309936572473b7e',
      symbol: 'aUSDCv1',
      decimals: 6,
      name: 'Aave Interest bearing USDC',
      logoURI: 'https://tokens.1inch.io/0x9ba00d6856a4edf4665bca2c2309936572473b7e.png',
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x9cb2f26a23b8d89973f08c957c4d7cdf75cd341c',
      symbol: 'DZAR',
      decimals: 6,
      name: 'Digital Rand',
      logoURI: 'https://tokens.1inch.io/0x9cb2f26a23b8d89973f08c957c4d7cdf75cd341c.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
      symbol: 'MKR',
      decimals: 18,
      name: 'Maker',
      logoURI: 'https://tokens.1inch.io/0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      symbol: 'USDC',
      decimals: 6,
      name: 'USD Coin',
      logoURI: 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
      eip2612: true,
      tags: ['PEG:USD', 'tokens'],
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xa1d0e215a23d7030842fc67ce582a6afa3ccab83',
      symbol: 'YFII',
      decimals: 18,
      name: 'YFII.finance',
      logoURI: 'https://tokens.1inch.io/0xa1d0e215a23d7030842fc67ce582a6afa3ccab83.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xa1d65e8fb6e87b60feccbc582f7f97804b725521',
      symbol: 'DXD',
      decimals: 18,
      name: 'DXdao',
      logoURI: 'https://tokens.1inch.io/0xa1d65e8fb6e87b60feccbc582f7f97804b725521.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2',
      symbol: 'MTA',
      decimals: 18,
      name: 'Meta',
      logoURI: 'https://tokens.1inch.io/0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xa462d0e6bb788c7807b1b1c96992ce1f7069e195',
      symbol: 'EQMT',
      decimals: 18,
      name: 'EQUUSMiningToken',
      logoURI: 'https://tokens.1inch.io/0xa462d0e6bb788c7807b1b1c96992ce1f7069e195.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xa64bd6c70cb9051f6a9ba1f163fdc07e0dfb5f84',
      symbol: 'aLINKv1',
      decimals: 18,
      name: 'Aave Interest bearing LINK',
      logoURI: 'https://tokens.1inch.io/0xa64bd6c70cb9051f6a9ba1f163fdc07e0dfb5f84.png',
      tags: ['savings'],
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d',
      symbol: 'CEL',
      decimals: 4,
      name: 'Celsius',
      logoURI: 'https://tokens.1inch.io/0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xaaaf91d9b90df800df4f55c205fd6989c977e73a',
      symbol: 'TKN',
      decimals: 8,
      name: 'Monolith',
      logoURI: 'https://tokens.1inch.io/0xaaaf91d9b90df800df4f55c205fd6989c977e73a.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xb4272071ecadd69d933adcd19ca99fe80664fc08',
      symbol: 'XCHF',
      decimals: 18,
      name: 'CryptoFranc',
      logoURI: 'https://tokens.1inch.io/0xb4272071ecadd69d933adcd19ca99fe80664fc08.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xeca82185adce47f39c684352b0439f030f860318',
      symbol: 'PERL',
      decimals: 18,
      name: 'Perlin',
      logoURI: 'https://tokens.1inch.io/0xeca82185adce47f39c684352b0439f030f860318.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xb6c4267c4877bb0d6b1685cfd85b0fbe82f105ec',
      symbol: 'REL',
      decimals: 18,
      name: 'Relevant',
      logoURI: 'https://tokens.1inch.io/0xb6c4267c4877bb0d6b1685cfd85b0fbe82f105ec.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xba100000625a3754423978a60c9317c58a424e3d',
      symbol: 'BAL',
      decimals: 18,
      name: 'Balancer',
      logoURI: 'https://tokens.1inch.io/0xba100000625a3754423978a60c9317c58a424e3d.png',
      eip2612: true,
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xba11d00c5f74255f56a5e366f4f77f5a186d7f55',
      symbol: 'BAND',
      decimals: 18,
      name: 'Band Protocol',
      logoURI: 'https://tokens.1inch.io/0xba11d00c5f74255f56a5e366f4f77f5a186d7f55.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd',
      symbol: 'LRC',
      decimals: 18,
      name: 'Loopring',
      logoURI: 'https://tokens.1inch.io/0xbbbbca6a901c926f240b89eacb641d8aec7aeafd.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xbd2949f67dcdc549c6ebe98696449fa79d988a9f',
      symbol: 'eMTRG',
      decimals: 18,
      name: 'Meter Governance mapped by Meter.io',
      logoURI: 'https://tokens.1inch.io/0xbd2949f67dcdc549c6ebe98696449fa79d988a9f.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xbe9375c6a420d2eeb258962efb95551a5b722803',
      symbol: 'STMX',
      decimals: 18,
      name: 'Kyber StormX Token',
      logoURI: 'https://tokens.1inch.io/0xbe9375c6a420d2eeb258962efb95551a5b722803.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xbf2179859fc6d5bee9bf9158632dc51678a4100e',
      symbol: 'ELF',
      decimals: 18,
      name: 'Aelf',
      logoURI: 'https://tokens.1inch.io/0xbf2179859fc6d5bee9bf9158632dc51678a4100e.png',
    },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc00e94cb662c3520282e6f5717214004a7f26888',
    //   symbol: 'COMP',
    //   decimals: 18,
    //   name: 'Compound',
    //   logoURI: 'https://tokens.1inch.io/0xc00e94cb662c3520282e6f5717214004a7f26888.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
    //   symbol: 'SNX',
    //   decimals: 18,
    //   name: 'Synthetix Network Token',
    //   logoURI: 'https://tokens.1inch.io/0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x27702a26126e0b3702af63ee09ac4d1a084ef628',
    //   symbol: 'ALEPH',
    //   decimals: 18,
    //   name: 'aleph.im v2',
    //   logoURI: 'https://tokens.1inch.io/0x27702a26126e0b3702af63ee09ac4d1a084ef628.png',
    //   eip2612: true,
    // },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      symbol: 'WETH',
      decimals: 18,
      name: 'Wrapped Ether',
      logoURI: 'https://tokens.1inch.io/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png',
    },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc11b1268c1a384e55c48c2391d8d480264a3a7f4',
    //   symbol: 'cWBTC',
    //   decimals: 8,
    //   name: 'Compound Wrapped BTC',
    //   logoURI: 'https://tokens.1inch.io/0xc11b1268c1a384e55c48c2391d8d480264a3a7f4.png',
    //   tags: ['PEG:BTC', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc12d099be31567add4e4e4d0d45691c3f58f5663',
    //   symbol: 'AUC',
    //   decimals: 18,
    //   name: 'Auctus',
    //   logoURI: 'https://tokens.1inch.io/0xc12d099be31567add4e4e4d0d45691c3f58f5663.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc28e931814725bbeb9e670676fabbcb694fe7df2',
    //   symbol: 'EQUAD',
    //   decimals: 18,
    //   name: 'Quadrant Protocol',
    //   logoURI: 'https://tokens.1inch.io/0xc28e931814725bbeb9e670676fabbcb694fe7df2.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc3dd23a0a854b4f9ae80670f528094e9eb607ccb',
    //   symbol: 'TRND',
    //   decimals: 18,
    //   name: 'Trendering',
    //   logoURI: 'https://tokens.1inch.io/0xc3dd23a0a854b4f9ae80670f528094e9eb607ccb.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcc4304a31d09258b0029ea7fe63d032f52e44efe',
    //   symbol: 'SWAP',
    //   decimals: 18,
    //   name: 'TrustSwap Token',
    //   logoURI: 'https://tokens.1inch.io/0xcc4304a31d09258b0029ea7fe63d032f52e44efe.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcc80c051057b774cd75067dc48f8987c4eb97a5e',
    //   symbol: 'NEC',
    //   decimals: 18,
    //   name: 'Ethfinex Nectar Token',
    //   logoURI: 'https://tokens.1inch.io/0xcc80c051057b774cd75067dc48f8987c4eb97a5e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcd62b1c403fa761baadfc74c525ce2b51780b184',
    //   symbol: 'ANJ',
    //   decimals: 18,
    //   name: 'Aragon Network Juror',
    //   logoURI: 'https://tokens.1inch.io/0xcd62b1c403fa761baadfc74c525ce2b51780b184.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd15ecdcf5ea68e3995b2d0527a0ae0a3258302f8',
    //   symbol: 'MCX',
    //   decimals: 18,
    //   name: 'MachiX Token',
    //   logoURI: 'https://tokens.1inch.io/0xd15ecdcf5ea68e3995b2d0527a0ae0a3258302f8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd26114cd6ee289accf82350c8d8487fedb8a0c07',
    //   symbol: 'OMG',
    //   decimals: 18,
    //   name: 'OmiseGO',
    //   logoURI: 'https://tokens.1inch.io/0xd26114cd6ee289accf82350c8d8487fedb8a0c07.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd46ba6d942050d489dbd938a2c909a5d5039a161',
    //   symbol: 'AMPL',
    //   decimals: 9,
    //   name: 'Ampleforth',
    //   logoURI: 'https://tokens.1inch.io/0xd46ba6d942050d489dbd938a2c909a5d5039a161.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd4c435f5b09f855c3317c8524cb1f586e42795fa',
    //   symbol: 'CND',
    //   decimals: 18,
    //   name: 'Cindicator',
    //   logoURI: 'https://tokens.1inch.io/0xd4c435f5b09f855c3317c8524cb1f586e42795fa.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd559f20296ff4895da39b5bd9add54b442596a61',
    //   symbol: 'FTX',
    //   decimals: 18,
    //   name: 'FintruX',
    //   logoURI: 'https://tokens.1inch.io/0xd559f20296ff4895da39b5bd9add54b442596a61.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd6f0bb2a45110f819e908a915237d652ac7c5aa8',
    //   symbol: 'BUIDL_2',
    //   decimals: 18,
    //   name: 'DFOHub',
    //   logoURI: 'https://tokens.1inch.io/0xd6f0bb2a45110f819e908a915237d652ac7c5aa8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd8912c10681d8b21fd3742244f44658dba12264e',
    //   symbol: 'PLU',
    //   decimals: 18,
    //   name: 'Pluton',
    //   logoURI: 'https://tokens.1inch.io/0xd8912c10681d8b21fd3742244f44658dba12264e.png',
    // },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      symbol: 'USDT',
      decimals: 6,
      name: 'Tether USD',
      logoURI: 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
      tags: ['PEG:USD', 'tokens'],
    },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdb25f211ab05b1c97d595516f45794528a807ad8',
    //   symbol: 'EURS',
    //   decimals: 2,
    //   name: 'STASIS EURS',
    //   logoURI: 'https://tokens.1inch.io/0xdb25f211ab05b1c97d595516f45794528a807ad8.png',
    //   tags: ['PEG:EUR', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdd974d5c2e2928dea5f71b9825b8b646686bd200',
    //   symbol: 'KNCL',
    //   decimals: 18,
    //   name: 'KyberNetwork Legacy',
    //   logoURI: 'https://tokens.1inch.io/0xdd974d5c2e2928dea5f71b9825b8b646686bd200.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdf2c7238198ad8b389666574f2d8bc411a4b7428',
    //   symbol: 'MFT',
    //   decimals: 18,
    //   name: 'Mainframe',
    //   logoURI: 'https://tokens.1inch.io/0xdf2c7238198ad8b389666574f2d8bc411a4b7428.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdfe691f37b6264a90ff507eb359c45d55037951c',
    //   symbol: 'KARMA',
    //   decimals: 4,
    //   name: 'Karma',
    //   logoURI: 'https://tokens.1inch.io/0xdfe691f37b6264a90ff507eb359c45d55037951c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe17f017475a709de58e976081eb916081ff4c9d5',
    //   symbol: 'RMPL',
    //   decimals: 9,
    //   name: 'RMPL',
    //   logoURI: 'https://tokens.1inch.io/0xe17f017475a709de58e976081eb916081ff4c9d5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe3818504c1b32bf1557b16c238b2e01fd3149c17',
    //   symbol: 'PLR',
    //   decimals: 18,
    //   name: 'Pillar',
    //   logoURI: 'https://tokens.1inch.io/0xe3818504c1b32bf1557b16c238b2e01fd3149c17.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe41d2489571d322189246dafa5ebde1f4699f498',
    //   symbol: 'ZRX',
    //   decimals: 18,
    //   name: '0x Protocol',
    //   logoURI: 'https://tokens.1inch.io/0xe41d2489571d322189246dafa5ebde1f4699f498.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe48972fcd82a274411c01834e2f031d4377fa2c0',
    //   symbol: '2KEY',
    //   decimals: 18,
    //   name: '2key.network',
    //   logoURI: 'https://tokens.1inch.io/0xe48972fcd82a274411c01834e2f031d4377fa2c0.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d',
    //   symbol: 'renBTC',
    //   decimals: 8,
    //   name: 'renBTC',
    //   logoURI: 'https://tokens.1inch.io/0xeb4c2781e4eba804ce9a9803c67d0893436bb27d.png',
    //   eip2612: true,
    //   tags: ['PEG:BTC', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xec67005c4e498ec7f55e092bd1d35cbc47c91892',
    //   symbol: 'MLN',
    //   decimals: 18,
    //   name: 'Melon Token',
    //   logoURI: 'https://tokens.1inch.io/0xec67005c4e498ec7f55e092bd1d35cbc47c91892.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf29e46887ffae92f1ff87dfe39713875da541373',
    //   symbol: 'UNC',
    //   decimals: 18,
    //   name: 'UniCrypt',
    //   logoURI: 'https://tokens.1inch.io/0xf29e46887ffae92f1ff87dfe39713875da541373.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf2f9a7e93f845b3ce154efbeb64fb9346fcce509',
    //   symbol: 'POWER',
    //   decimals: 18,
    //   name: 'UniPower',
    //   logoURI: 'https://tokens.1inch.io/0xf2f9a7e93f845b3ce154efbeb64fb9346fcce509.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf433089366899d83a9f26a773d59ec7ecf30355e',
    //   symbol: 'MTL',
    //   decimals: 8,
    //   name: 'Metal',
    //   logoURI: 'https://tokens.1inch.io/0xf433089366899d83a9f26a773d59ec7ecf30355e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c',
    //   symbol: 'ENJ',
    //   decimals: 18,
    //   name: 'Enjin Coin',
    //   logoURI: 'https://tokens.1inch.io/0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9',
    //   symbol: 'cUSDT',
    //   decimals: 8,
    //   name: 'Compound USDT',
    //   logoURI: 'https://tokens.1inch.io/0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9.png',
    //   tags: ['PEG:USD', 'savings'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf8e386eda857484f5a12e4b5daa9984e06e73705',
    //   symbol: 'IND',
    //   decimals: 18,
    //   name: 'Indorse',
    //   logoURI: 'https://tokens.1inch.io/0xf8e386eda857484f5a12e4b5daa9984e06e73705.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf911a7ec46a2c6fa49193212fe4a2a9b95851c27',
    //   symbol: 'XAMP',
    //   decimals: 9,
    //   name: 'Antiample',
    //   logoURI: 'https://tokens.1inch.io/0xf911a7ec46a2c6fa49193212fe4a2a9b95851c27.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf970b8e36e23f7fc3fd752eea86f8be8d83375a6',
    //   symbol: 'RCN',
    //   decimals: 18,
    //   name: 'Ripio',
    //   logoURI: 'https://tokens.1inch.io/0xf970b8e36e23f7fc3fd752eea86f8be8d83375a6.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfc1e690f61efd961294b3e1ce3313fbd8aa4f85d',
    //   symbol: 'aDAIv1',
    //   decimals: 18,
    //   name: 'Aave Interest bearing DAI',
    //   logoURI: 'https://tokens.1inch.io/0xfc1e690f61efd961294b3e1ce3313fbd8aa4f85d.png',
    //   tags: ['savings'],
    // },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      symbol: 'ETH',
      decimals: 18,
      name: 'Ether',
      logoURI: 'https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
    },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x06af07097c9eeb7fd685c692751d5c66db49c215',
    //   symbol: 'CHAI',
    //   decimals: 18,
    //   name: 'Chai Token',
    //   logoURI: 'https://tokens.1inch.io/0x06af07097c9eeb7fd685c692751d5c66db49c215.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf5dce57282a584d2746faf1593d3121fcac444dc',
    //   symbol: 'cSAI',
    //   decimals: 8,
    //   name: 'Compound Sai',
    //   logoURI: 'https://tokens.1inch.io/0xf5dce57282a584d2746faf1593d3121fcac444dc.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5',
    //   symbol: 'cETH',
    //   decimals: 8,
    //   name: 'Compound ETH',
    //   logoURI: 'https://tokens.1inch.io/0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5.png',
    //   tags: ['PEG:ETH', 'savings'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x39aa39c021dfbae8fac545936693ac917d5e7563',
    //   symbol: 'cUSDC',
    //   decimals: 8,
    //   name: 'Compound USD Coin',
    //   logoURI: 'https://tokens.1inch.io/0x39aa39c021dfbae8fac545936693ac917d5e7563.png',
    //   tags: ['PEG:USD', 'savings'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6f259637dcd74c767781e37bc6133cd6a68aa161',
    //   symbol: 'HT',
    //   decimals: 18,
    //   name: 'HuobiToken',
    //   logoURI: 'https://tokens.1inch.io/0x6f259637dcd74c767781e37bc6133cd6a68aa161.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
    //   symbol: 'MATIC',
    //   decimals: 18,
    //   name: 'Matic Token',
    //   logoURI: 'https://tokens.1inch.io/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png',
    // },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x6b175474e89094c44da98b954eedeac495271d0f',
      symbol: 'DAI',
      decimals: 18,
      name: 'Dai Stablecoin',
      logoURI: 'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',
      eip2612: true,
      tags: ['PEG:USD', 'tokens'],
    },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359',
    //   symbol: 'SAI',
    //   decimals: 18,
    //   name: 'Sai Stablecoin',
    //   logoURI: 'https://tokens.1inch.io/0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x57ab1ec28d129707052df4df418d58a2d46d5f51',
    //   symbol: 'sUSD',
    //   decimals: 18,
    //   name: 'Synth sUSD',
    //   logoURI: 'https://tokens.1inch.io/0x57ab1ec28d129707052df4df418d58a2d46d5f51.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0000000000085d4780b73119b644ae5ecd22b376',
    //   symbol: 'TUSD',
    //   decimals: 18,
    //   name: 'TrueUSD',
    //   logoURI: 'https://tokens.1inch.io/0x0000000000085d4780b73119b644ae5ecd22b376.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3a3a65aab0dd2a17e3f1947ba16138cd37d08c04',
    //   symbol: 'aETHv1',
    //   decimals: 18,
    //   name: 'Aave Interest bearing ETH',
    //   logoURI: 'https://tokens.1inch.io/0x3a3a65aab0dd2a17e3f1947ba16138cd37d08c04.png',
    //   tags: ['PEG:ETH', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe1ba0fb44ccb0d11b80f92f4f8ed94ca3ff51d00',
    //   symbol: 'aBATv1',
    //   decimals: 18,
    //   name: 'Aave Interest bearing BAT',
    //   logoURI: 'https://tokens.1inch.io/0xe1ba0fb44ccb0d11b80f92f4f8ed94ca3ff51d00.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9d91be44c06d373a8a226e1f3b146956083803eb',
    //   symbol: 'aKNCv1',
    //   decimals: 18,
    //   name: 'Aave Interest bearing KNC',
    //   logoURI: 'https://tokens.1inch.io/0x9d91be44c06d373a8a226e1f3b146956083803eb.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7d2d3688df45ce7c552e19c27e007673da9204b8',
    //   symbol: 'aLENDv1',
    //   decimals: 18,
    //   name: 'Aave Interest bearing LEND',
    //   logoURI: 'https://tokens.1inch.io/0x7d2d3688df45ce7c552e19c27e007673da9204b8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6fce4a401b6b80ace52baaefe4421bd188e76f6f',
    //   symbol: 'aMANAv1',
    //   decimals: 18,
    //   name: 'Aave Interest bearing MANA',
    //   logoURI: 'https://tokens.1inch.io/0x6fce4a401b6b80ace52baaefe4421bd188e76f6f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7deb5e830be29f91e298ba5ff1356bb7f8146998',
    //   symbol: 'aMKRv1',
    //   decimals: 18,
    //   name: 'Aave Interest bearing MKR',
    //   logoURI: 'https://tokens.1inch.io/0x7deb5e830be29f91e298ba5ff1356bb7f8146998.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x71010a9d003445ac60c4e6a7017c1e89a477b438',
    //   symbol: 'aREPv1',
    //   decimals: 18,
    //   name: 'Aave Interest bearing REP',
    //   logoURI: 'https://tokens.1inch.io/0x71010a9d003445ac60c4e6a7017c1e89a477b438.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x328c4c80bc7aca0834db37e6600a6c49e12da4de',
    //   symbol: 'aSNXv1',
    //   decimals: 18,
    //   name: 'Aave Interest bearing SNX',
    //   logoURI: 'https://tokens.1inch.io/0x328c4c80bc7aca0834db37e6600a6c49e12da4de.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfc4b8ed459e00e5400be803a9bb3954234fd50e3',
    //   symbol: 'aWBTCv1',
    //   decimals: 8,
    //   name: 'Aave Interest bearing WBTC',
    //   logoURI: 'https://tokens.1inch.io/0xfc4b8ed459e00e5400be803a9bb3954234fd50e3.png',
    //   tags: ['PEG:BTC', 'savings'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6fb0855c404e09c47c3fbca25f08d4e41f9f062f',
    //   symbol: 'aZRXv1',
    //   decimals: 18,
    //   name: 'Aave Interest bearing ZRX',
    //   logoURI: 'https://tokens.1inch.io/0x6fb0855c404e09c47c3fbca25f08d4e41f9f062f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x16de59092dae5ccf4a1e6439d611fd0653f0bd01',
    //   symbol: 'yDAIv2',
    //   decimals: 18,
    //   name: 'iearn DAIv2',
    //   logoURI: 'https://tokens.1inch.io/0x16de59092dae5ccf4a1e6439d611fd0653f0bd01.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc2cb1040220768554cf699b0d863a3cd4324ce32',
    //   symbol: 'yDAIv3',
    //   decimals: 18,
    //   name: 'iearn DAI v3',
    //   logoURI: 'https://tokens.1inch.io/0xc2cb1040220768554cf699b0d863a3cd4324ce32.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x04aa51bbcb46541455ccf1b8bef2ebc5d3787ec9',
    //   symbol: 'yBTC',
    //   decimals: 8,
    //   name: 'iearn WBTC',
    //   logoURI: 'https://tokens.1inch.io/0x04aa51bbcb46541455ccf1b8bef2ebc5d3787ec9.png',
    //   tags: ['PEG:BTC', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x83f798e925bcd4017eb265844fddabb448f1707d',
    //   symbol: 'yUSDTv2',
    //   decimals: 6,
    //   name: 'iearn USDT v2',
    //   logoURI: 'https://tokens.1inch.io/0x83f798e925bcd4017eb265844fddabb448f1707d.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe6354ed5bc4b393a5aad09f21c46e101e692d447',
    //   symbol: 'yUSDTv3',
    //   decimals: 6,
    //   name: 'iearn USDT v3',
    //   logoURI: 'https://tokens.1inch.io/0xe6354ed5bc4b393a5aad09f21c46e101e692d447.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd6ad7a6750a7593e092a9b218d66c0a814a3436e',
    //   symbol: 'yUSDCv2',
    //   decimals: 6,
    //   name: 'iearn USDC v2',
    //   logoURI: 'https://tokens.1inch.io/0xd6ad7a6750a7593e092a9b218d66c0a814a3436e.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x26ea744e5b887e5205727f55dfbe8685e3b21951',
    //   symbol: 'yUSDCv3',
    //   decimals: 6,
    //   name: 'iearn USDC v3',
    //   logoURI: 'https://tokens.1inch.io/0x26ea744e5b887e5205727f55dfbe8685e3b21951.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xacfa209fb73bf3dd5bbfb1101b9bc999c49062a5',
    //   symbol: 'BCDT',
    //   decimals: 18,
    //   name: 'Blockchain Certified Data Token',
    //   logoURI: 'https://tokens.1inch.io/0xacfa209fb73bf3dd5bbfb1101b9bc999c49062a5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4de2573e27e648607b50e1cfff921a33e4a34405',
    //   symbol: 'LST',
    //   decimals: 18,
    //   name: 'Lendroid Support Token',
    //   logoURI: 'https://tokens.1inch.io/0x4de2573e27e648607b50e1cfff921a33e4a34405.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x056fd409e1d7a124bd7017459dfea2f387b6d5cd',
    //   symbol: 'GUSD',
    //   decimals: 2,
    //   name: 'Gemini dollar',
    //   logoURI: 'https://tokens.1inch.io/0x056fd409e1d7a124bd7017459dfea2f387b6d5cd.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6ee0f7bb50a54ab5253da0667b0dc2ee526c30a8',
    //   symbol: 'aBUSDv1',
    //   decimals: 18,
    //   name: 'Aave Interest bearing Binance USD',
    //   logoURI: 'https://tokens.1inch.io/0x6ee0f7bb50a54ab5253da0667b0dc2ee526c30a8.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd7efb00d12c2c13131fd319336fdf952525da2af',
    //   symbol: 'XPR',
    //   decimals: 4,
    //   name: 'Proton',
    //   logoURI: 'https://tokens.1inch.io/0xd7efb00d12c2c13131fd319336fdf952525da2af.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x85eee30c52b0b379b046fb0f85f4f3dc3009afec',
    //   symbol: 'KEEP',
    //   decimals: 18,
    //   name: 'KEEP Token',
    //   logoURI: 'https://tokens.1inch.io/0x85eee30c52b0b379b046fb0f85f4f3dc3009afec.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1c5db575e2ff833e46a2e9864c22f4b22e0b37c2',
    //   symbol: 'renZEC',
    //   decimals: 8,
    //   name: 'renZEC',
    //   logoURI: 'https://tokens.1inch.io/0x1c5db575e2ff833e46a2e9864c22f4b22e0b37c2.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0316eb71485b0ab14103307bf65a021042c6d380',
    //   symbol: 'HBTC',
    //   decimals: 18,
    //   name: 'Huobi BTC',
    //   logoURI: 'https://tokens.1inch.io/0x0316eb71485b0ab14103307bf65a021042c6d380.png',
    //   tags: ['PEG:BTC', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3a9fff453d50d4ac52a6890647b823379ba36b9e',
    //   symbol: 'SHUF',
    //   decimals: 18,
    //   name: 'Shuffle.Monster V3',
    //   logoURI: 'https://tokens.1inch.io/0x3a9fff453d50d4ac52a6890647b823379ba36b9e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc0f9bd5fa5698b6505f643900ffa515ea5df54a9',
    //   symbol: 'DONUT',
    //   decimals: 18,
    //   name: 'DONUT',
    //   logoURI: 'https://tokens.1inch.io/0xc0f9bd5fa5698b6505f643900ffa515ea5df54a9.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x45804880de22913dafe09f4980848ece6ecbaf78',
    //   symbol: 'PAXG',
    //   decimals: 18,
    //   name: 'Paxos Gold',
    //   logoURI: 'https://tokens.1inch.io/0x45804880de22913dafe09f4980848ece6ecbaf78.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac',
    //   symbol: 'STORJ',
    //   decimals: 8,
    //   name: 'Storj',
    //   logoURI: 'https://tokens.1inch.io/0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4156d3342d5c385a87d264f90653733592000581',
    //   symbol: 'SALT',
    //   decimals: 8,
    //   name: 'Salt',
    //   logoURI: 'https://tokens.1inch.io/0x4156d3342d5c385a87d264f90653733592000581.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfca59cd816ab1ead66534d82bc21e7515ce441cf',
    //   symbol: 'RARI',
    //   decimals: 18,
    //   name: 'Rarible',
    //   logoURI: 'https://tokens.1inch.io/0xfca59cd816ab1ead66534d82bc21e7515ce441cf.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5228a22e72ccc52d415ecfd199f99d0665e7733b',
    //   symbol: 'pBTC',
    //   decimals: 18,
    //   name: 'pTokens BTC',
    //   logoURI: 'https://tokens.1inch.io/0x5228a22e72ccc52d415ecfd199f99d0665e7733b.png',
    //   tags: ['PEG:BTC', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x476c5e26a75bd202a9683ffd34359c0cc15be0ff',
    //   symbol: 'SRM',
    //   decimals: 6,
    //   name: 'Serum',
    //   logoURI: 'https://tokens.1inch.io/0x476c5e26a75bd202a9683ffd34359c0cc15be0ff.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc813ea5e3b48bebeedb796ab42a30c5599b01740',
    //   symbol: 'NIOX',
    //   decimals: 4,
    //   name: 'Autonio',
    //   logoURI: 'https://tokens.1inch.io/0xc813ea5e3b48bebeedb796ab42a30c5599b01740.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa7de087329bfcda5639247f96140f9dabe3deed1',
    //   symbol: 'STA',
    //   decimals: 18,
    //   name: 'Statera',
    //   logoURI: 'https://tokens.1inch.io/0xa7de087329bfcda5639247f96140f9dabe3deed1.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdc5864ede28bd4405aa04d93e05a0531797d9d59',
    //   symbol: 'FNT',
    //   decimals: 6,
    //   name: 'Falcon',
    //   logoURI: 'https://tokens.1inch.io/0xdc5864ede28bd4405aa04d93e05a0531797d9d59.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0aacfbec6a24756c20d41914f2caba817c0d8521',
    //   symbol: 'YAM',
    //   decimals: 18,
    //   name: 'YAM',
    //   logoURI: 'https://tokens.1inch.io/0x0aacfbec6a24756c20d41914f2caba817c0d8521.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xade00c28244d5ce17d72e40330b1c318cd12b7c3',
    //   symbol: 'ADX',
    //   decimals: 18,
    //   name: 'AdEx Network',
    //   logoURI: 'https://tokens.1inch.io/0xade00c28244d5ce17d72e40330b1c318cd12b7c3.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd533a949740bb3306d119cc777fa900ba034cd52',
    //   symbol: 'CRV',
    //   decimals: 18,
    //   name: 'Curve DAO Token',
    //   logoURI: 'https://tokens.1inch.io/0xd533a949740bb3306d119cc777fa900ba034cd52.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9469d013805bffb7d3debe5e7839237e535ec483',
    //   symbol: 'RING',
    //   decimals: 18,
    //   name: 'Darwinia Network Native Token',
    //   logoURI: 'https://tokens.1inch.io/0x9469d013805bffb7d3debe5e7839237e535ec483.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2baecdf43734f22fd5c152db08e3c27233f0c7d2',
    //   symbol: 'OMv1',
    //   decimals: 18,
    //   name: 'OM Token',
    //   logoURI: 'https://tokens.1inch.io/0x2baecdf43734f22fd5c152db08e3c27233f0c7d2.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x491604c0fdf08347dd1fa4ee062a822a5dd06b5d',
    //   symbol: 'CTSI',
    //   decimals: 18,
    //   name: 'Cartesi Token',
    //   logoURI: 'https://tokens.1inch.io/0x491604c0fdf08347dd1fa4ee062a822a5dd06b5d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0ff6ffcfda92c53f615a4a75d982f399c989366b',
    //   symbol: 'LAYER',
    //   decimals: 18,
    //   name: 'Unilayer',
    //   logoURI: 'https://tokens.1inch.io/0x0ff6ffcfda92c53f615a4a75d982f399c989366b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xaa7a9ca87d3694b5755f213b5d04094b8d0f0a6f',
    //   symbol: 'TRAC',
    //   decimals: 18,
    //   name: 'Trace',
    //   logoURI: 'https://tokens.1inch.io/0xaa7a9ca87d3694b5755f213b5d04094b8d0f0a6f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8a9c67fee641579deba04928c4bc45f66e26343a',
    //   symbol: 'JRT',
    //   decimals: 18,
    //   name: 'Jarvis Reward Token',
    //   logoURI: 'https://tokens.1inch.io/0x8a9c67fee641579deba04928c4bc45f66e26343a.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x674c6ad92fd080e4004b2312b45f796a192d27a0',
    //   symbol: 'USDN',
    //   decimals: 18,
    //   name: 'Neutrino',
    //   logoURI: 'https://tokens.1inch.io/0x674c6ad92fd080e4004b2312b45f796a192d27a0.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x362bc847a3a9637d3af6624eec853618a43ed7d2',
    //   symbol: 'PRQ',
    //   decimals: 18,
    //   name: 'Parsiq Token',
    //   logoURI: 'https://tokens.1inch.io/0x362bc847a3a9637d3af6624eec853618a43ed7d2.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0e29e5abbb5fd88e28b2d355774e73bd47de3bcd',
    //   symbol: 'HAKKA',
    //   decimals: 18,
    //   name: 'Hakka Finance',
    //   logoURI: 'https://tokens.1inch.io/0x0e29e5abbb5fd88e28b2d355774e73bd47de3bcd.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x38e4adb44ef08f22f5b5b76a8f0c2d0dcbe7dca1',
    //   symbol: 'CVP',
    //   decimals: 18,
    //   name: 'Concentrated Voting Power',
    //   logoURI: 'https://tokens.1inch.io/0x38e4adb44ef08f22f5b5b76a8f0c2d0dcbe7dca1.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4fe5851c9af07df9e5ad8217afae1ea72737ebda',
    //   symbol: 'OPT',
    //   decimals: 18,
    //   name: 'Open Predict Token',
    //   logoURI: 'https://tokens.1inch.io/0x4fe5851c9af07df9e5ad8217afae1ea72737ebda.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
    //   symbol: 'SUSHI',
    //   decimals: 18,
    //   name: 'SushiToken',
    //   logoURI: 'https://tokens.1inch.io/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3affcca64c2a6f4e3b6bd9c64cd2c969efd1ecbe',
    //   symbol: 'DSLA',
    //   decimals: 18,
    //   name: 'DSLA',
    //   logoURI: 'https://tokens.1inch.io/0x3affcca64c2a6f4e3b6bd9c64cd2c969efd1ecbe.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xba21ef4c9f433ede00badefcc2754b8e74bd538a',
    //   symbol: 'SWFL',
    //   decimals: 18,
    //   name: 'Swapfolio',
    //   logoURI: 'https://tokens.1inch.io/0xba21ef4c9f433ede00badefcc2754b8e74bd538a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfffffffff15abf397da76f1dcc1a1604f45126db',
    //   symbol: 'FSW',
    //   decimals: 18,
    //   name: 'FalconSwap Token',
    //   logoURI: 'https://tokens.1inch.io/0xfffffffff15abf397da76f1dcc1a1604f45126db.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb8baa0e4287890a5f79863ab62b7f175cecbd433',
    //   symbol: 'SWRV',
    //   decimals: 18,
    //   name: 'Swerve DAO Token',
    //   logoURI: 'https://tokens.1inch.io/0xb8baa0e4287890a5f79863ab62b7f175cecbd433.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8a6f3bf52a26a21531514e23016eeae8ba7e7018',
    //   symbol: 'MXX',
    //   decimals: 8,
    //   name: 'Multiplier',
    //   logoURI: 'https://tokens.1inch.io/0x8a6f3bf52a26a21531514e23016eeae8ba7e7018.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5dbcf33d8c2e976c6b560249878e6f1491bca25c',
    //   symbol: 'yUSD',
    //   decimals: 18,
    //   name: 'yearn Curve.fi yDAI/yUSDC/yUSDT/yTUSD',
    //   logoURI: 'https://tokens.1inch.io/0x5dbcf33d8c2e976c6b560249878e6f1491bca25c.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x50026ad58b338cf3eccc2b422deb8faa725f377f',
    //   symbol: 'STEP',
    //   decimals: 8,
    //   name: '1Step.finance',
    //   logoURI: 'https://tokens.1inch.io/0x50026ad58b338cf3eccc2b422deb8faa725f377f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b',
    //   symbol: 'DPI',
    //   decimals: 18,
    //   name: 'DefiPulse Index',
    //   logoURI: 'https://tokens.1inch.io/0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3f382dbd960e3a9bbceae22651e88158d2791550',
    //   symbol: 'GHST',
    //   decimals: 18,
    //   name: 'Aavegotchi GHST Token',
    //   logoURI: 'https://tokens.1inch.io/0x3f382dbd960e3a9bbceae22651e88158d2791550.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    //   symbol: 'UNI',
    //   decimals: 18,
    //   name: 'Uniswap',
    //   logoURI: 'https://tokens.1inch.io/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x429881672b9ae42b8eba0e26cd9c73711b891ca5',
    //   symbol: 'PICKLE',
    //   decimals: 18,
    //   name: 'PickleToken',
    //   logoURI: 'https://tokens.1inch.io/0x429881672b9ae42b8eba0e26cd9c73711b891ca5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf8c3527cc04340b208c854e985240c02f7b7793f',
    //   symbol: 'FRONT',
    //   decimals: 18,
    //   name: 'Frontier Token',
    //   logoURI: 'https://tokens.1inch.io/0xf8c3527cc04340b208c854e985240c02f7b7793f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa0246c9032bc3a600820415ae600c6388619a14d',
    //   symbol: 'FARM',
    //   decimals: 18,
    //   name: 'FARM Reward Token',
    //   logoURI: 'https://tokens.1inch.io/0xa0246c9032bc3a600820415ae600c6388619a14d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x488e0369f9bc5c40c002ea7c1fe4fd01a198801c',
    //   symbol: 'GOF',
    //   decimals: 18,
    //   name: 'Golff.finance',
    //   logoURI: 'https://tokens.1inch.io/0x488e0369f9bc5c40c002ea7c1fe4fd01a198801c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2a8e1e676ec238d8a992307b495b45b3feaa5e86',
    //   symbol: 'OUSD',
    //   decimals: 18,
    //   name: 'Origin Dollar',
    //   logoURI: 'https://tokens.1inch.io/0x2a8e1e676ec238d8a992307b495b45b3feaa5e86.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
    //   symbol: 'AAVE',
    //   decimals: 18,
    //   name: 'Aave Token',
    //   logoURI: 'https://tokens.1inch.io/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd',
    //   symbol: 'DODO',
    //   decimals: 18,
    //   name: 'DODO bird',
    //   logoURI: 'https://tokens.1inch.io/0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6006fc2a849fedaba8330ce36f5133de01f96189',
    //   symbol: 'SHAKE',
    //   decimals: 18,
    //   name: 'SHAKE token by SpaceSwap v2 ',
    //   logoURI: 'https://tokens.1inch.io/0x6006fc2a849fedaba8330ce36f5133de01f96189.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de',
    //   symbol: 'MILK2',
    //   decimals: 18,
    //   name: 'MilkyWay Token by SpaceSwap v2',
    //   logoURI: 'https://tokens.1inch.io/0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x62359ed7505efc61ff1d56fef82158ccaffa23d7',
    //   symbol: 'CORE',
    //   decimals: 18,
    //   name: 'cVault.finance',
    //   logoURI: 'https://tokens.1inch.io/0x62359ed7505efc61ff1d56fef82158ccaffa23d7.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xbc396689893d065f41bc2c6ecbee5e0085233447',
    //   symbol: 'PERP',
    //   decimals: 18,
    //   name: 'Perpetual',
    //   logoURI: 'https://tokens.1inch.io/0xbc396689893d065f41bc2c6ecbee5e0085233447.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1c48f86ae57291f7686349f12601910bd8d470bb',
    //   symbol: 'USDK',
    //   decimals: 18,
    //   name: 'USDK',
    //   logoURI: 'https://tokens.1inch.io/0x1c48f86ae57291f7686349f12601910bd8d470bb.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x87edffde3e14c7a66c9b9724747a1c5696b742e6',
    //   symbol: 'SWAG',
    //   decimals: 18,
    //   name: 'Swag Token',
    //   logoURI: 'https://tokens.1inch.io/0x87edffde3e14c7a66c9b9724747a1c5696b742e6.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xad6a626ae2b43dcb1b39430ce496d2fa0365ba9c',
    //   symbol: 'DEFI+S',
    //   decimals: 18,
    //   name: 'PieDAO DEFI Small Cap',
    //   logoURI: 'https://tokens.1inch.io/0xad6a626ae2b43dcb1b39430ce496d2fa0365ba9c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xad32a8e6220741182940c5abf610bde99e737b2d',
    //   symbol: 'DOUGH',
    //   decimals: 18,
    //   name: 'PieDAO DOUGH v2',
    //   logoURI: 'https://tokens.1inch.io/0xad32a8e6220741182940c5abf610bde99e737b2d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x35a18000230da775cac24873d00ff85bccded550',
    //   symbol: 'cUNI',
    //   decimals: 8,
    //   name: 'Compound Uniswap',
    //   logoURI: 'https://tokens.1inch.io/0x35a18000230da775cac24873d00ff85bccded550.png',
    //   tags: ['savings'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe2f2a5c287993345a840db3b0845fbc70f5935a5',
    //   symbol: 'mUSD',
    //   decimals: 18,
    //   name: 'mStable USD',
    //   logoURI: 'https://tokens.1inch.io/0xe2f2a5c287993345a840db3b0845fbc70f5935a5.png',
    //   tags: ['PEG:USD', 'pools'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2edf094db69d6dcd487f1b3db9febe2eec0dd4c5',
    //   symbol: 'ZEE',
    //   decimals: 18,
    //   name: 'ZeroSwapToken',
    //   logoURI: 'https://tokens.1inch.io/0x2edf094db69d6dcd487f1b3db9febe2eec0dd4c5.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x584bc13c7d411c00c01a62e8019472de68768430',
    //   symbol: 'HEGIC',
    //   decimals: 18,
    //   name: 'Hegic',
    //   logoURI: 'https://tokens.1inch.io/0x584bc13c7d411c00c01a62e8019472de68768430.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcbd55d4ffc43467142761a764763652b48b969ff',
    //   symbol: 'ASTRO',
    //   decimals: 18,
    //   name: 'AstroTools.io',
    //   logoURI: 'https://tokens.1inch.io/0xcbd55d4ffc43467142761a764763652b48b969ff.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xff20817765cb7f73d4bde2e66e067e58d11095c2',
    //   symbol: 'AMP',
    //   decimals: 18,
    //   name: 'Amp',
    //   logoURI: 'https://tokens.1inch.io/0xff20817765cb7f73d4bde2e66e067e58d11095c2.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0391d2021f89dc339f60fff84546ea23e337750f',
    //   symbol: 'BOND',
    //   decimals: 18,
    //   name: 'BarnBridge Governance Token',
    //   logoURI: 'https://tokens.1inch.io/0x0391d2021f89dc339f60fff84546ea23e337750f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa117000000f279d81a1d3cc75430faa017fa5a2e',
    //   symbol: 'ANT',
    //   decimals: 18,
    //   name: 'Aragon Network Token',
    //   logoURI: 'https://tokens.1inch.io/0xa117000000f279d81a1d3cc75430faa017fa5a2e.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x970b9bb2c0444f5e81e9d0efb84c8ccdcdcaf84d',
    //   symbol: 'FUSE',
    //   decimals: 18,
    //   name: 'Fuse Token',
    //   logoURI: 'https://tokens.1inch.io/0x970b9bb2c0444f5e81e9d0efb84c8ccdcdcaf84d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x36f3fd68e7325a35eb768f1aedaae9ea0689d723',
    //   symbol: 'ESD',
    //   decimals: 18,
    //   name: 'Empty Set Dollar',
    //   logoURI: 'https://tokens.1inch.io/0x36f3fd68e7325a35eb768f1aedaae9ea0689d723.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44',
    //   symbol: 'KP3R',
    //   decimals: 18,
    //   name: 'Keep3rV1',
    //   logoURI: 'https://tokens.1inch.io/0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5bc25f649fc4e26069ddf4cf4010f9f706c23831',
    //   symbol: 'DUSD',
    //   decimals: 18,
    //   name: 'DefiDollar',
    //   logoURI: 'https://tokens.1inch.io/0x5bc25f649fc4e26069ddf4cf4010f9f706c23831.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2e2364966267b5d7d2ce6cd9a9b5bd19d9c7c6a9',
    //   symbol: 'VOICE',
    //   decimals: 18,
    //   name: 'Voice Token',
    //   logoURI: 'https://tokens.1inch.io/0x2e2364966267b5d7d2ce6cd9a9b5bd19d9c7c6a9.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xeef9f339514298c6a857efcfc1a762af84438dee',
    //   symbol: 'HEZ',
    //   decimals: 18,
    //   name: 'Hermez Network Token',
    //   logoURI: 'https://tokens.1inch.io/0xeef9f339514298c6a857efcfc1a762af84438dee.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xea319e87cf06203dae107dd8e5672175e3ee976c',
    //   symbol: 'SURF',
    //   decimals: 18,
    //   name: 'SURF.Finance',
    //   logoURI: 'https://tokens.1inch.io/0xea319e87cf06203dae107dd8e5672175e3ee976c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3383c5a8969dc413bfddc9656eb80a1408e4ba20',
    //   symbol: 'wANATHA',
    //   decimals: 18,
    //   name: 'Wrapped ANATHA',
    //   logoURI: 'https://tokens.1inch.io/0x3383c5a8969dc413bfddc9656eb80a1408e4ba20.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x18aaa7115705e8be94bffebde57af9bfc265b998',
    //   symbol: 'AUDIO',
    //   decimals: 18,
    //   name: 'Audius',
    //   logoURI: 'https://tokens.1inch.io/0x18aaa7115705e8be94bffebde57af9bfc265b998.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0954906da0bf32d5479e25f46056d22f08464cab',
    //   symbol: 'INDEX',
    //   decimals: 18,
    //   name: 'Index',
    //   logoURI: 'https://tokens.1inch.io/0x0954906da0bf32d5479e25f46056d22f08464cab.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc57d533c50bc22247d49a368880fb49a1caa39f7',
    //   symbol: 'PTF',
    //   decimals: 18,
    //   name: 'PowerTrade Fuel Token',
    //   logoURI: 'https://tokens.1inch.io/0xc57d533c50bc22247d49a368880fb49a1caa39f7.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x95a4492f028aa1fd432ea71146b433e7b4446611',
    //   symbol: 'APY',
    //   decimals: 18,
    //   name: 'APY Governance Token',
    //   logoURI: 'https://tokens.1inch.io/0x95a4492f028aa1fd432ea71146b433e7b4446611.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xbea98c05eeae2f3bc8c3565db7551eb738c8ccab',
    //   symbol: 'GYSR',
    //   decimals: 18,
    //   name: 'Geyser',
    //   logoURI: 'https://tokens.1inch.io/0xbea98c05eeae2f3bc8c3565db7551eb738c8ccab_1.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa89ac6e529acf391cfbbd377f3ac9d93eae9664e',
    //   symbol: 'KP4R',
    //   decimals: 18,
    //   name: 'Keep4r',
    //   logoURI: 'https://tokens.1inch.io/0xa89ac6e529acf391cfbbd377f3ac9d93eae9664e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xbb0e17ef65f82ab018d8edd776e8dd940327b28b',
    //   symbol: 'AXS',
    //   decimals: 18,
    //   name: 'Axie Infinity Shard',
    //   logoURI: 'https://tokens.1inch.io/0xbb0e17ef65f82ab018d8edd776e8dd940327b28b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x05d3606d5c81eb9b7b18530995ec9b29da05faba',
    //   symbol: 'TOMOE',
    //   decimals: 18,
    //   name: 'TomoChain',
    //   logoURI: 'https://tokens.1inch.io/0x05d3606d5c81eb9b7b18530995ec9b29da05faba.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb1f66997a5760428d3a87d68b90bfe0ae64121cc',
    //   symbol: 'LUA',
    //   decimals: 18,
    //   name: 'LuaToken',
    //   logoURI: 'https://tokens.1inch.io/0xb1f66997a5760428d3a87d68b90bfe0ae64121cc.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf4cd3d3fda8d7fd6c5a500203e38640a70bf9577',
    //   symbol: 'Yf-DAI',
    //   decimals: 18,
    //   name: 'YfDAI.finance',
    //   logoURI: 'https://tokens.1inch.io/0xf4cd3d3fda8d7fd6c5a500203e38640a70bf9577.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x83e6f1e41cdd28eaceb20cb649155049fac3d5aa',
    //   symbol: 'POLS',
    //   decimals: 18,
    //   name: 'PolkastarterToken',
    //   logoURI: 'https://tokens.1inch.io/0x83e6f1e41cdd28eaceb20cb649155049fac3d5aa.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xaf9f549774ecedbd0966c52f250acc548d3f36e5',
    //   symbol: 'RFuel',
    //   decimals: 18,
    //   name: 'Rio Fuel Token',
    //   logoURI: 'https://tokens.1inch.io/0xaf9f549774ecedbd0966c52f250acc548d3f36e5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0202be363b8a4820f3f4de7faf5224ff05943ab1',
    //   symbol: 'UFT',
    //   decimals: 18,
    //   name: 'UniLend Finance Token',
    //   logoURI: 'https://tokens.1inch.io/0x0202be363b8a4820f3f4de7faf5224ff05943ab1.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xae697f994fc5ebc000f8e22ebffee04612f98a0d',
    //   symbol: 'LGCY',
    //   decimals: 18,
    //   name: 'LGCY Network',
    //   logoURI: 'https://tokens.1inch.io/0xae697f994fc5ebc000f8e22ebffee04612f98a0d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9d47894f8becb68b9cf3428d256311affe8b068b',
    //   symbol: '$ROPE',
    //   decimals: 18,
    //   name: '$ROPE',
    //   logoURI: 'https://tokens.1inch.io/0x9d47894f8becb68b9cf3428d256311affe8b068b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x72f020f8f3e8fd9382705723cd26380f8d0c66bb',
    //   symbol: 'PLOT',
    //   decimals: 18,
    //   name: 'PLOT',
    //   logoURI: 'https://tokens.1inch.io/0x72f020f8f3e8fd9382705723cd26380f8d0c66bb.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6a7ef4998eb9d0f706238756949f311a59e05745',
    //   symbol: 'KEN',
    //   decimals: 18,
    //   name: 'Kenysians Network',
    //   logoURI: 'https://tokens.1inch.io/0x6a7ef4998eb9d0f706238756949f311a59e05745.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x20945ca1df56d237fd40036d47e866c7dccd2114',
    //   symbol: 'Nsure',
    //   decimals: 18,
    //   name: 'Nsure Network Token',
    //   logoURI: 'https://tokens.1inch.io/0x20945ca1df56d237fd40036d47e866c7dccd2114.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x12e51e77daaa58aa0e9247db7510ea4b46f9bead',
    //   symbol: 'aYFIv1',
    //   decimals: 18,
    //   name: 'Aave Interest bearing YFI',
    //   logoURI: 'https://tokens.1inch.io/0x12e51e77daaa58aa0e9247db7510ea4b46f9bead.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xba3d9687cf50fe253cd2e1cfeede1d6787344ed5',
    //   symbol: 'aAAVEv1',
    //   decimals: 18,
    //   name: 'Aave Interest bearing Aave Token',
    //   logoURI: 'https://tokens.1inch.io/0xba3d9687cf50fe253cd2e1cfeede1d6787344ed5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb124541127a0a657f056d9dd06188c4f1b0e5aab',
    //   symbol: 'aUNIv1',
    //   decimals: 18,
    //   name: 'Aave Interest bearing Uniswap',
    //   logoURI: 'https://tokens.1inch.io/0xb124541127a0a657f056d9dd06188c4f1b0e5aab.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x712db54daa836b53ef1ecbb9c6ba3b9efb073f40',
    //   symbol: 'aENJv1',
    //   decimals: 18,
    //   name: 'Aave Interest bearing ENJ',
    //   logoURI: 'https://tokens.1inch.io/0x712db54daa836b53ef1ecbb9c6ba3b9efb073f40.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb753428af26e81097e7fd17f40c88aaa3e04902c',
    //   symbol: 'SFI',
    //   decimals: 18,
    //   name: 'Spice',
    //   logoURI: 'https://tokens.1inch.io/0xb753428af26e81097e7fd17f40c88aaa3e04902c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8888801af4d980682e47f1a9036e589479e835c5',
    //   symbol: 'MPH',
    //   decimals: 18,
    //   name: '88mph.app',
    //   logoURI: 'https://tokens.1inch.io/0x8888801af4d980682e47f1a9036e589479e835c5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6468e79a80c0eab0f9a2b574c8d5bc374af59414',
    //   symbol: 'eXRD',
    //   decimals: 18,
    //   name: 'E-RADIX',
    //   logoURI: 'https://tokens.1inch.io/0x6468e79a80c0eab0f9a2b574c8d5bc374af59414.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3e780920601d61cedb860fe9c4a90c9ea6a35e78',
    //   symbol: 'BOOST',
    //   decimals: 18,
    //   name: 'Boosted Finance',
    //   logoURI: 'https://tokens.1inch.io/0x3e780920601d61cedb860fe9c4a90c9ea6a35e78.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0',
    //   symbol: 'DF',
    //   decimals: 18,
    //   name: 'dForce',
    //   logoURI: 'https://tokens.1inch.io/0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3db6ba6ab6f95efed1a6e794cad492faaabf294d',
    //   symbol: 'LTO',
    //   decimals: 8,
    //   name: 'LTO Network Token',
    //   logoURI: 'https://tokens.1inch.io/0x3db6ba6ab6f95efed1a6e794cad492faaabf294d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4fe83213d56308330ec302a8bd641f1d0113a4cc',
    //   symbol: 'NU',
    //   decimals: 18,
    //   name: 'NuCypher',
    //   logoURI: 'https://tokens.1inch.io/0x4fe83213d56308330ec302a8bd641f1d0113a4cc.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6e0dade58d2d89ebbe7afc384e3e4f15b70b14d8',
    //   symbol: 'QRX',
    //   decimals: 18,
    //   name: 'QuiverX',
    //   logoURI: 'https://tokens.1inch.io/0x6e0dade58d2d89ebbe7afc384e3e4f15b70b14d8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x69948cc03f478b95283f7dbf1ce764d0fc7ec54c',
    //   symbol: 'aRENv1',
    //   decimals: 18,
    //   name: 'Aave Interest bearing REN',
    //   logoURI: 'https://tokens.1inch.io/0x69948cc03f478b95283f7dbf1ce764d0fc7ec54c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe88f8313e61a97cec1871ee37fbbe2a8bf3ed1e4',
    //   symbol: 'VAL',
    //   decimals: 18,
    //   name: 'Sora Validator Token',
    //   logoURI: 'https://tokens.1inch.io/0xe88f8313e61a97cec1871ee37fbbe2a8bf3ed1e4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0b38210ea11411557c13457d4da7dc6ea731b88a',
    //   symbol: 'API3',
    //   decimals: 18,
    //   name: 'API3',
    //   logoURI: 'https://tokens.1inch.io/0x0b38210ea11411557c13457d4da7dc6ea731b88a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3449fc1cd036255ba1eb19d65ff4ba2b8903a69a',
    //   symbol: 'BAC',
    //   decimals: 18,
    //   name: 'BAC',
    //   logoURI: 'https://tokens.1inch.io/0x3449fc1cd036255ba1eb19d65ff4ba2b8903a69a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x26607ac599266b21d13c7acf7942c7701a8b699c',
    //   symbol: 'PIPT',
    //   decimals: 18,
    //   name: 'Power Index Pool Token',
    //   logoURI: 'https://tokens.1inch.io/0x26607ac599266b21d13c7acf7942c7701a8b699c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x07150e919b4de5fd6a63de1f9384828396f25fdc',
    //   symbol: 'BASE',
    //   decimals: 9,
    //   name: 'Base Protocol',
    //   logoURI: 'https://tokens.1inch.io/0x07150e919b4de5fd6a63de1f9384828396f25fdc.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x91dfbee3965baaee32784c2d546b7a0c62f268c9',
    //   symbol: 'BONDLY',
    //   decimals: 18,
    //   name: 'Bondly',
    //   logoURI: 'https://tokens.1inch.io/0x91dfbee3965baaee32784c2d546b7a0c62f268c9.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206',
    //   symbol: 'NEXO',
    //   decimals: 18,
    //   name: 'Nexo',
    //   logoURI: 'https://tokens.1inch.io/0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xffc97d72e13e01096502cb8eb52dee56f74dad7b',
    //   symbol: 'aAAVE',
    //   decimals: 18,
    //   name: 'Aave interest bearing AAVE',
    //   logoURI: 'https://tokens.1inch.io/0xffc97d72e13e01096502cb8eb52dee56f74dad7b.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x05ec93c0365baaeabf7aeffb0972ea7ecdd39cf1',
    //   symbol: 'aBAT',
    //   decimals: 18,
    //   name: 'Aave interest bearing BAT',
    //   logoURI: 'https://tokens.1inch.io/0x05ec93c0365baaeabf7aeffb0972ea7ecdd39cf1.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa361718326c15715591c299427c62086f69923d9',
    //   symbol: 'aBUSD',
    //   decimals: 18,
    //   name: 'Aave interest bearing BUSD',
    //   logoURI: 'https://tokens.1inch.io/0xa361718326c15715591c299427c62086f69923d9.png',
    //   eip2612: true,
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x028171bca77440897b824ca71d1c56cac55b68a3',
    //   symbol: 'aDAI',
    //   decimals: 18,
    //   name: 'Aave interest bearing DAI',
    //   logoURI: 'https://tokens.1inch.io/0x028171bca77440897b824ca71d1c56cac55b68a3.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xac6df26a590f08dcc95d5a4705ae8abbc88509ef',
    //   symbol: 'aENJ',
    //   decimals: 18,
    //   name: 'Aave interest bearing ENJ',
    //   logoURI: 'https://tokens.1inch.io/0xac6df26a590f08dcc95d5a4705ae8abbc88509ef.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x39c6b3e42d6a679d7d776778fe880bc9487c2eda',
    //   symbol: 'aKNC',
    //   decimals: 18,
    //   name: 'Aave interest bearing KNC',
    //   logoURI: 'https://tokens.1inch.io/0x39c6b3e42d6a679d7d776778fe880bc9487c2eda.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa06bc25b5805d5f8d82847d191cb4af5a3e873e0',
    //   symbol: 'aLINK',
    //   decimals: 18,
    //   name: 'Aave interest bearing LINK',
    //   logoURI: 'https://tokens.1inch.io/0xa06bc25b5805d5f8d82847d191cb4af5a3e873e0.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa685a61171bb30d4072b338c80cb7b2c865c873e',
    //   symbol: 'aMANA',
    //   decimals: 18,
    //   name: 'Aave interest bearing MANA',
    //   logoURI: 'https://tokens.1inch.io/0xa685a61171bb30d4072b338c80cb7b2c865c873e.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc713e5e149d5d0715dcd1c156a020976e7e56b88',
    //   symbol: 'aMKR',
    //   decimals: 18,
    //   name: 'Aave interest bearing MKR',
    //   logoURI: 'https://tokens.1inch.io/0xc713e5e149d5d0715dcd1c156a020976e7e56b88.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcc12abe4ff81c9378d670de1b57f8e0dd228d77a',
    //   symbol: 'aREN',
    //   decimals: 18,
    //   name: 'Aave Interest bearing REN',
    //   logoURI: 'https://tokens.1inch.io/0xcc12abe4ff81c9378d670de1b57f8e0dd228d77a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x35f6b052c598d933d69a4eec4d04c73a191fe6c2',
    //   symbol: 'aSNX',
    //   decimals: 18,
    //   name: 'Aave interest bearing SNX',
    //   logoURI: 'https://tokens.1inch.io/0x35f6b052c598d933d69a4eec4d04c73a191fe6c2.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6c5024cd4f8a59110119c56f8933403a539555eb',
    //   symbol: 'aSUSD',
    //   decimals: 18,
    //   name: 'Aave interest bearing SUSD',
    //   logoURI: 'https://tokens.1inch.io/0x6c5024cd4f8a59110119c56f8933403a539555eb.png',
    //   eip2612: true,
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x101cc05f4a51c0319f570d5e146a8c625198e636',
    //   symbol: 'aTUSD',
    //   decimals: 18,
    //   name: 'Aave interest bearing TUSD',
    //   logoURI: 'https://tokens.1inch.io/0x101cc05f4a51c0319f570d5e146a8c625198e636.png',
    //   eip2612: true,
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb9d7cb55f463405cdfbe4e90a6d2df01c2b92bf1',
    //   symbol: 'aUNI',
    //   decimals: 18,
    //   name: 'Aave interest bearing UNI',
    //   logoURI: 'https://tokens.1inch.io/0xb9d7cb55f463405cdfbe4e90a6d2df01c2b92bf1.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xbcca60bb61934080951369a648fb03df4f96263c',
    //   symbol: 'aUSDC',
    //   decimals: 6,
    //   name: 'Aave interest bearing USDC',
    //   logoURI: 'https://tokens.1inch.io/0xbcca60bb61934080951369a648fb03df4f96263c.png',
    //   eip2612: true,
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3ed3b47dd13ec9a98b44e6204a523e766b225811',
    //   symbol: 'aUSDT',
    //   decimals: 6,
    //   name: 'Aave interest bearing USDT',
    //   logoURI: 'https://tokens.1inch.io/0x3ed3b47dd13ec9a98b44e6204a523e766b225811.png',
    //   eip2612: true,
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9ff58f4ffb29fa2266ab25e75e2a8b3503311656',
    //   symbol: 'aWBTC',
    //   decimals: 8,
    //   name: 'Aave interest bearing WBTC',
    //   logoURI: 'https://tokens.1inch.io/0x9ff58f4ffb29fa2266ab25e75e2a8b3503311656.png',
    //   eip2612: true,
    //   tags: ['PEG:BTC', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x030ba81f1c18d280636f32af80b9aad02cf0854e',
    //   symbol: 'aWETH',
    //   decimals: 18,
    //   name: 'Aave interest bearing WETH',
    //   logoURI: 'https://tokens.1inch.io/0x030ba81f1c18d280636f32af80b9aad02cf0854e.png',
    //   eip2612: true,
    //   tags: ['PEG:ETH', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5165d24277cd063f5ac44efd447b27025e888f37',
    //   symbol: 'aYFI',
    //   decimals: 18,
    //   name: 'Aave interest bearing YFI',
    //   logoURI: 'https://tokens.1inch.io/0x5165d24277cd063f5ac44efd447b27025e888f37.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdf7ff54aacacbff42dfe29dd6144a69b629f8c9e',
    //   symbol: 'aZRX',
    //   decimals: 18,
    //   name: 'Aave interest bearing ZRX',
    //   logoURI: 'https://tokens.1inch.io/0xdf7ff54aacacbff42dfe29dd6144a69b629f8c9e.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdc9ac3c20d1ed0b540df9b1fedc10039df13f99c',
    //   symbol: 'UTK',
    //   decimals: 18,
    //   name: 'Utrust Token',
    //   logoURI: 'https://tokens.1inch.io/0xdc9ac3c20d1ed0b540df9b1fedc10039df13f99c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x57b946008913b82e4df85f501cbaed910e58d26c',
    //   symbol: 'POND',
    //   decimals: 18,
    //   name: 'Marlin POND',
    //   logoURI: 'https://tokens.1inch.io/0x57b946008913b82e4df85f501cbaed910e58d26c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3593d125a4f7849a1b059e64f4517a86dd60c95d',
    //   symbol: 'OMv2',
    //   decimals: 18,
    //   name: 'MANTRA DAO',
    //   logoURI: 'https://tokens.1inch.io/0x3593d125a4f7849a1b059e64f4517a86dd60c95d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0',
    //   symbol: 'FXS',
    //   decimals: 18,
    //   name: 'Frax Share',
    //   logoURI: 'https://tokens.1inch.io/0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x111111111117dc0aa78b770fa6a738034120c302',
    //   symbol: '1INCH',
    //   decimals: 18,
    //   name: '1INCH Token',
    //   logoURI: 'https://tokens.1inch.io/0x111111111117dc0aa78b770fa6a738034120c302.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x054d64b73d3d8a21af3d764efd76bcaa774f3bb2',
    //   symbol: 'PPAY',
    //   decimals: 18,
    //   name: 'Plasma',
    //   logoURI: 'https://tokens.1inch.io/0x054d64b73d3d8a21af3d764efd76bcaa774f3bb2.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x368b3a58b5f49392e5c9e4c998cb0bb966752e51',
    //   symbol: 'MIC',
    //   decimals: 18,
    //   name: 'MIC',
    //   logoURI: 'https://tokens.1inch.io/0x368b3a58b5f49392e5c9e4c998cb0bb966752e51.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4b4d2e899658fb59b1d518b68fe836b100ee8958',
    //   symbol: 'MIS',
    //   decimals: 18,
    //   name: 'MIS',
    //   logoURI: 'https://tokens.1inch.io/0x4b4d2e899658fb59b1d518b68fe836b100ee8958.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xee573a945b01b788b9287ce062a0cfc15be9fd86',
    //   symbol: 'XED',
    //   decimals: 18,
    //   name: 'Exeedme',
    //   logoURI: 'https://tokens.1inch.io/0xee573a945b01b788b9287ce062a0cfc15be9fd86.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x34950ff2b487d9e5282c5ab342d08a2f712eb79f',
    //   symbol: 'WOZX',
    //   decimals: 18,
    //   name: 'EFFORCE IEO',
    //   logoURI: 'https://tokens.1inch.io/0x34950ff2b487d9e5282c5ab342d08a2f712eb79f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc770eefad204b5180df6a14ee197d99d808ee52d',
    //   symbol: 'FOX',
    //   decimals: 18,
    //   name: 'FOX',
    //   logoURI: 'https://tokens.1inch.io/0xc770eefad204b5180df6a14ee197d99d808ee52d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x66a0f676479cee1d7373f3dc2e2952778bff5bd6',
    //   symbol: 'WISE',
    //   decimals: 18,
    //   name: 'Wise Token',
    //   logoURI: 'https://tokens.1inch.io/0x66a0f676479cee1d7373f3dc2e2952778bff5bd6.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xffffffff2ba8f66d4e51811c5190992176930278',
    //   symbol: 'COMBO',
    //   decimals: 18,
    //   name: 'Furucombo',
    //   logoURI: 'https://tokens.1inch.io/0xffffffff2ba8f66d4e51811c5190992176930278.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2b4200a8d373d484993c37d63ee14aee0096cd12',
    //   symbol: 'USDFL',
    //   decimals: 18,
    //   name: 'USDFreeLiquidity',
    //   logoURI: 'https://tokens.1inch.io/0x2b4200a8d373d484993c37d63ee14aee0096cd12.png',
    //   eip2612: true,
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xaea46a60368a7bd060eec7df8cba43b7ef41ad85',
    //   symbol: 'FET',
    //   decimals: 18,
    //   name: 'Fetch',
    //   logoURI: 'https://tokens.1inch.io/0xaea46a60368a7bd060eec7df8cba43b7ef41ad85.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6c5ba91642f10282b576d91922ae6448c9d52f4e',
    //   symbol: 'PHA',
    //   decimals: 18,
    //   name: 'Phala',
    //   logoURI: 'https://tokens.1inch.io/0x6c5ba91642f10282b576d91922ae6448c9d52f4e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa8b12cc90abf65191532a12bb5394a714a46d358',
    //   symbol: 'pBTC35A',
    //   decimals: 18,
    //   name: 'POW BTC-35W/T',
    //   logoURI: 'https://tokens.1inch.io/0xa8b12cc90abf65191532a12bb5394a714a46d358.png',
    //   eip2612: true,
    //   tags: ['PEG:BTC', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x853d955acef822db058eb8505911ed77f175b99e',
    //   symbol: 'FRAX',
    //   decimals: 18,
    //   name: 'Frax',
    //   logoURI: 'https://tokens.1inch.io/0x853d955acef822db058eb8505911ed77f175b99e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe28b3b32b6c345a34ff64674606124dd5aceca30',
    //   symbol: 'INJ',
    //   decimals: 18,
    //   name: 'Injective Token',
    //   logoURI: 'https://tokens.1inch.io/0xe28b3b32b6c345a34ff64674606124dd5aceca30.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf94b5c5651c888d928439ab6514b93944eee6f48',
    //   symbol: 'YLD_APP',
    //   decimals: 18,
    //   name: 'Yield',
    //   logoURI: 'https://tokens.1inch.io/0xf94b5c5651c888d928439ab6514b93944eee6f48.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x63b4f3e3fa4e438698ce330e365e831f7ccd1ef4',
    //   symbol: 'CFi',
    //   decimals: 18,
    //   name: 'CyberFi Token',
    //   logoURI: 'https://tokens.1inch.io/0x63b4f3e3fa4e438698ce330e365e831f7ccd1ef4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd291e7a03283640fdc51b121ac401383a46cc623',
    //   symbol: 'RGT',
    //   decimals: 18,
    //   name: 'Rari Governance Token',
    //   logoURI: 'https://tokens.1inch.io/0xd291e7a03283640fdc51b121ac401383a46cc623.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfa5047c9c78b8877af97bdcb85db743fd7313d4a',
    //   symbol: 'ROOK',
    //   decimals: 18,
    //   name: 'ROOK',
    //   logoURI: 'https://tokens.1inch.io/0xfa5047c9c78b8877af97bdcb85db743fd7313d4a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x87d73e916d7057945c9bcd8cdd94e42a6f47f776',
    //   symbol: 'NFTX',
    //   decimals: 18,
    //   name: 'NFTX',
    //   logoURI: 'https://tokens.1inch.io/0x87d73e916d7057945c9bcd8cdd94e42a6f47f776.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f',
    //   symbol: 'SDT',
    //   decimals: 18,
    //   name: 'Stake DAO Token',
    //   logoURI: 'https://tokens.1inch.io/0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9248c485b0b80f76da451f167a8db30f33c70907',
    //   symbol: 'DEBASE',
    //   decimals: 18,
    //   name: 'Debase',
    //   logoURI: 'https://tokens.1inch.io/0x9248c485b0b80f76da451f167a8db30f33c70907.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8290333cef9e6d528dd5618fb97a76f268f3edd4',
    //   symbol: 'ANKR',
    //   decimals: 18,
    //   name: 'Ankr Network',
    //   logoURI: 'https://tokens.1inch.io/0x8290333cef9e6d528dd5618fb97a76f268f3edd4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc719d010b63e5bbf2c0551872cd5316ed26acd83',
    //   symbol: 'DIP_Insurance',
    //   decimals: 18,
    //   name: 'Decentralized Insurance Protocol',
    //   logoURI: 'https://tokens.1inch.io/0xc719d010b63e5bbf2c0551872cd5316ed26acd83.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3155ba85d5f96b2d030a4966af206230e46849cb',
    //   symbol: 'RUNE',
    //   decimals: 18,
    //   name: 'THORChain ETH.RUNE',
    //   logoURI: 'https://tokens.1inch.io/0x3155ba85d5f96b2d030a4966af206230e46849cb.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x374cb8c27130e2c9e04f44303f3c8351b9de61c1',
    //   symbol: 'BAO',
    //   decimals: 18,
    //   name: 'BaoToken',
    //   logoURI: 'https://tokens.1inch.io/0x374cb8c27130e2c9e04f44303f3c8351b9de61c1.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfe3e6a25e6b192a42a44ecddcd13796471735acf',
    //   symbol: 'REEF',
    //   decimals: 18,
    //   name: 'Reef.finance',
    //   logoURI: 'https://tokens.1inch.io/0xfe3e6a25e6b192a42a44ecddcd13796471735acf.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4c19596f5aaff459fa38b0f7ed92f11ae6543784',
    //   symbol: 'TRU',
    //   decimals: 8,
    //   name: 'TrustToken',
    //   logoURI: 'https://tokens.1inch.io/0x4c19596f5aaff459fa38b0f7ed92f11ae6543784.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x86772b1409b61c639eaac9ba0acfbb6e238e5f83',
    //   symbol: 'NDX',
    //   decimals: 18,
    //   name: 'Indexed',
    //   logoURI: 'https://tokens.1inch.io/0x86772b1409b61c639eaac9ba0acfbb6e238e5f83.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x67c597624b17b16fb77959217360b7cd18284253',
    //   symbol: 'MARK',
    //   decimals: 9,
    //   name: 'Benchmark',
    //   logoURI: 'https://tokens.1inch.io/0x67c597624b17b16fb77959217360b7cd18284253.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf0939011a9bb95c3b791f0cb546377ed2693a574',
    //   symbol: 'ZERO',
    //   decimals: 18,
    //   name: 'Zero.Exchange Token',
    //   logoURI: 'https://tokens.1inch.io/0xf0939011a9bb95c3b791f0cb546377ed2693a574.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7240ac91f01233baaf8b064248e80feaa5912ba3',
    //   symbol: 'OCTO',
    //   decimals: 18,
    //   name: 'Octo.fi',
    //   logoURI: 'https://tokens.1inch.io/0x7240ac91f01233baaf8b064248e80feaa5912ba3.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4c11249814f11b9346808179cf06e71ac328c1b5',
    //   symbol: 'ORAI',
    //   decimals: 18,
    //   name: 'Oraichain Token',
    //   logoURI: 'https://tokens.1inch.io/0x4c11249814f11b9346808179cf06e71ac328c1b5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x70401dfd142a16dc7031c56e862fc88cb9537ce0',
    //   symbol: 'BIRD',
    //   decimals: 18,
    //   name: 'Bird.Money',
    //   logoURI: 'https://tokens.1inch.io/0x70401dfd142a16dc7031c56e862fc88cb9537ce0.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x86ed939b500e121c0c5f493f399084db596dad20',
    //   symbol: 'SPC',
    //   decimals: 18,
    //   name: 'SpaceChainV2',
    //   logoURI: 'https://tokens.1inch.io/0x86ed939b500e121c0c5f493f399084db596dad20.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x33d0568941c0c64ff7e0fb4fba0b11bd37deed9f',
    //   symbol: 'RAMP',
    //   decimals: 18,
    //   name: 'RAMP DEFI',
    //   logoURI: 'https://tokens.1inch.io/0x33d0568941c0c64ff7e0fb4fba0b11bd37deed9f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb987d48ed8f2c468d52d6405624eadba5e76d723',
    //   symbol: 'STBZ',
    //   decimals: 18,
    //   name: 'Stabilize Token',
    //   logoURI: 'https://tokens.1inch.io/0xb987d48ed8f2c468d52d6405624eadba5e76d723.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x159751323a9e0415dd3d6d42a1212fe9f4a0848c',
    //   symbol: 'INFI',
    //   decimals: 18,
    //   name: 'INFI',
    //   logoURI: 'https://tokens.1inch.io/0x159751323a9e0415dd3d6d42a1212fe9f4a0848c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b',
    //   symbol: 'CRO',
    //   decimals: 8,
    //   name: 'CRO',
    //   logoURI: 'https://tokens.1inch.io/0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa8b919680258d369114910511cc87595aec0be6d',
    //   symbol: 'LYXe',
    //   decimals: 18,
    //   name: 'LUKSO Token',
    //   logoURI: 'https://tokens.1inch.io/0xa8b919680258d369114910511cc87595aec0be6d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988',
    //   symbol: 'TVK',
    //   decimals: 18,
    //   name: 'Terra Virtua Kolect',
    //   logoURI: 'https://tokens.1inch.io/0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x798d1be841a82a273720ce31c822c61a67a601c3',
    //   symbol: 'DIGG',
    //   decimals: 9,
    //   name: 'Digg',
    //   logoURI: 'https://tokens.1inch.io/0x798d1be841a82a273720ce31c822c61a67a601c3.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa1faa113cbe53436df28ff0aee54275c13b40975',
    //   symbol: 'ALPHA',
    //   decimals: 18,
    //   name: 'AlphaToken',
    //   logoURI: 'https://tokens.1inch.io/0xa1faa113cbe53436df28ff0aee54275c13b40975_1.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x817bbdbc3e8a1204f3691d14bb44992841e3db35',
    //   symbol: 'CUDOS',
    //   decimals: 18,
    //   name: 'CudosToken',
    //   logoURI: 'https://tokens.1inch.io/0x817bbdbc3e8a1204f3691d14bb44992841e3db35.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xde4ee8057785a7e8e800db58f9784845a5c2cbd6',
    //   symbol: 'DEXE',
    //   decimals: 18,
    //   name: 'Dexe',
    //   logoURI: 'https://tokens.1inch.io/0xde4ee8057785a7e8e800db58f9784845a5c2cbd6.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3845badade8e6dff049820680d1f14bd3903a5d0',
    //   symbol: 'SAND',
    //   decimals: 18,
    //   name: 'SAND',
    //   logoURI: 'https://tokens.1inch.io/0x3845badade8e6dff049820680d1f14bd3903a5d0.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe5caef4af8780e59df925470b050fb23c43ca68c',
    //   symbol: 'FRM',
    //   decimals: 6,
    //   name: 'Ferrum Network Token',
    //   logoURI: 'https://tokens.1inch.io/0xe5caef4af8780e59df925470b050fb23c43ca68c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x298d492e8c1d909d3f63bc4a36c66c64acb3d695',
    //   symbol: 'PBR',
    //   decimals: 18,
    //   name: 'PolkaBridge',
    //   logoURI: 'https://tokens.1inch.io/0x298d492e8c1d909d3f63bc4a36c66c64acb3d695.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfe9a29ab92522d14fc65880d817214261d8479ae',
    //   symbol: 'SNOW',
    //   decimals: 18,
    //   name: 'SnowSwap',
    //   logoURI: 'https://tokens.1inch.io/0xfe9a29ab92522d14fc65880d817214261d8479ae.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1337def16f9b486faed0293eb623dc8395dfe46a',
    //   symbol: 'ARMOR',
    //   decimals: 18,
    //   name: 'Armor',
    //   logoURI: 'https://tokens.1inch.io/0x1337def16f9b486faed0293eb623dc8395dfe46a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1337def18c680af1f9f45cbcab6309562975b1dd',
    //   symbol: 'arNXM',
    //   decimals: 18,
    //   name: 'Armor NXM',
    //   logoURI: 'https://tokens.1inch.io/0x1337def18c680af1f9f45cbcab6309562975b1dd.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x888888888889c00c67689029d7856aac1065ec11',
    //   symbol: 'OPIUM',
    //   decimals: 18,
    //   name: 'Opium Governance Token',
    //   logoURI: 'https://tokens.1inch.io/0x888888888889c00c67689029d7856aac1065ec11.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3fa729b4548becbad4eab6ef18413470e6d5324c',
    //   symbol: 'HH',
    //   decimals: 18,
    //   name: 'Holyheld',
    //   logoURI: 'https://tokens.1inch.io/0x3fa729b4548becbad4eab6ef18413470e6d5324c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17',
    //   symbol: 'DYP',
    //   decimals: 18,
    //   name: 'DeFiYieldProtocol',
    //   logoURI: 'https://tokens.1inch.io/0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe95a203b1a91a908f9b9ce46459d101078c2c3cb',
    //   symbol: 'ANKRETH',
    //   decimals: 18,
    //   name: 'Ankr Staked ETH',
    //   logoURI: 'https://tokens.1inch.io/0xe95a203b1a91a908f9b9ce46459d101078c2c3cb.png',
    //   tags: ['PEG:ETH', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4e15361fd6b4bb609fa63c81a2be19d873717870',
    //   symbol: 'FTM',
    //   decimals: 18,
    //   name: 'Fantom Token',
    //   logoURI: 'https://tokens.1inch.io/0x4e15361fd6b4bb609fa63c81a2be19d873717870.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8642a849d0dcb7a15a974794668adcfbe4794b56',
    //   symbol: 'PROS',
    //   decimals: 18,
    //   name: 'Prosper',
    //   logoURI: 'https://tokens.1inch.io/0x8642a849d0dcb7a15a974794668adcfbe4794b56.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa1afffe3f4d611d252010e3eaf6f4d77088b0cd7',
    //   symbol: 'RFI',
    //   decimals: 9,
    //   name: 'reflect.finance',
    //   logoURI: 'https://tokens.1inch.io/0xa1afffe3f4d611d252010e3eaf6f4d77088b0cd7.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa47c8bf37f92abed4a126bda807a7b7498661acd',
    //   symbol: 'UST',
    //   decimals: 18,
    //   name: 'Wrapped UST Token',
    //   logoURI: 'https://tokens.1inch.io/0xa47c8bf37f92abed4a126bda807a7b7498661acd.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x09a3ecafa817268f77be1283176b946c4ff2e608',
    //   symbol: 'MIR',
    //   decimals: 18,
    //   name: 'Wrapped MIR Token',
    //   logoURI: 'https://tokens.1inch.io/0x09a3ecafa817268f77be1283176b946c4ff2e608.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1d37986f252d0e349522ea6c3b98cb935495e63e',
    //   symbol: 'CHART',
    //   decimals: 18,
    //   name: 'ChartEx',
    //   logoURI: 'https://tokens.1inch.io/0x1d37986f252d0e349522ea6c3b98cb935495e63e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x725c263e32c72ddc3a19bea12c5a0479a81ee688',
    //   symbol: 'BMI',
    //   decimals: 18,
    //   name: 'Bridge Mutual',
    //   logoURI: 'https://tokens.1inch.io/0x725c263e32c72ddc3a19bea12c5a0479a81ee688.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0000000000095413afc295d19edeb1ad7b71c952',
    //   symbol: 'LON',
    //   decimals: 18,
    //   name: 'Tokenlon',
    //   logoURI: 'https://tokens.1inch.io/0x0000000000095413afc295d19edeb1ad7b71c952.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1f3f9d3068568f8040775be2e8c03c103c61f3af',
    //   symbol: 'ARCH',
    //   decimals: 18,
    //   name: 'Archer DAO Governance Token',
    //   logoURI: 'https://tokens.1inch.io/0x1f3f9d3068568f8040775be2e8c03c103c61f3af.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa393473d64d2f9f026b60b6df7859a689715d092',
    //   symbol: 'LTX',
    //   decimals: 8,
    //   name: 'Lattice Token',
    //   logoURI: 'https://tokens.1inch.io/0xa393473d64d2f9f026b60b6df7859a689715d092.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe0ad1806fd3e7edf6ff52fdb822432e847411033',
    //   symbol: 'ONX',
    //   decimals: 18,
    //   name: 'OnX.finance',
    //   logoURI: 'https://tokens.1inch.io/0xe0ad1806fd3e7edf6ff52fdb822432e847411033.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xef3a930e1ffffacd2fc13434ac81bd278b0ecc8d',
    //   symbol: 'FIS',
    //   decimals: 18,
    //   name: 'StaFi',
    //   logoURI: 'https://tokens.1inch.io/0xef3a930e1ffffacd2fc13434ac81bd278b0ecc8d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3e9bc21c9b189c09df3ef1b824798658d5011937',
    //   symbol: 'LINA',
    //   decimals: 18,
    //   name: 'Linear Token',
    //   logoURI: 'https://tokens.1inch.io/0x3e9bc21c9b189c09df3ef1b824798658d5011937.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb1f871ae9462f1b2c6826e88a7827e76f86751d4',
    //   symbol: 'GNYerc20',
    //   decimals: 18,
    //   name: 'GNYerc20',
    //   logoURI: 'https://tokens.1inch.io/0xb1f871ae9462f1b2c6826e88a7827e76f86751d4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9aeb50f542050172359a0e1a25a9933bc8c01259',
    //   symbol: 'OIN',
    //   decimals: 8,
    //   name: 'oinfinance',
    //   logoURI: 'https://tokens.1inch.io/0x9aeb50f542050172359a0e1a25a9933bc8c01259.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xac0104cca91d167873b8601d2e71eb3d4d8c33e0',
    //   symbol: 'CWS',
    //   decimals: 18,
    //   name: 'Crowns',
    //   logoURI: 'https://tokens.1inch.io/0xac0104cca91d167873b8601d2e71eb3d4d8c33e0.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
    //   symbol: 'SHIB',
    //   decimals: 18,
    //   name: 'SHIBA INU',
    //   logoURI: 'https://tokens.1inch.io/0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6d0f5149c502faf215c89ab306ec3e50b15e2892',
    //   symbol: 'PRT',
    //   decimals: 18,
    //   name: 'Portion Token',
    //   logoURI: 'https://tokens.1inch.io/0x6d0f5149c502faf215c89ab306ec3e50b15e2892.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8a9c4dfe8b9d8962b31e4e16f8321c44d48e246e',
    //   symbol: 'NCT',
    //   decimals: 18,
    //   name: 'NameChangeToken',
    //   logoURI: 'https://tokens.1inch.io/0x8a9c4dfe8b9d8962b31e4e16f8321c44d48e246e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81',
    //   symbol: 'MUSE',
    //   decimals: 18,
    //   name: 'Muse',
    //   logoURI: 'https://tokens.1inch.io/0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4f5fa8f2d12e5eb780f6082dd656c565c48e0f24',
    //   symbol: 'GUM',
    //   decimals: 18,
    //   name: 'GourmetGalaxy',
    //   logoURI: 'https://tokens.1inch.io/0x4f5fa8f2d12e5eb780f6082dd656c565c48e0f24.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x50de6856358cc35f3a9a57eaaa34bd4cb707d2cd',
    //   symbol: 'RAZOR',
    //   decimals: 18,
    //   name: 'RAZOR',
    //   logoURI: 'https://tokens.1inch.io/0x50de6856358cc35f3a9a57eaaa34bd4cb707d2cd.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x297d33e17e61c2ddd812389c2105193f8348188a',
    //   symbol: '$TRDL',
    //   decimals: 18,
    //   name: 'Strudel Finance',
    //   logoURI: 'https://tokens.1inch.io/0x297d33e17e61c2ddd812389c2105193f8348188a.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xec681f28f4561c2a9534799aa38e0d36a83cf478',
    //   symbol: 'YVS',
    //   decimals: 18,
    //   name: 'YVS.Finance',
    //   logoURI: 'https://tokens.1inch.io/0xec681f28f4561c2a9534799aa38e0d36a83cf478.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc28e27870558cf22add83540d2126da2e4b464c2',
    //   symbol: 'SASHIMI',
    //   decimals: 18,
    //   name: 'SashimiToken',
    //   logoURI: 'https://tokens.1inch.io/0xc28e27870558cf22add83540d2126da2e4b464c2.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7968bc6a03017ea2de509aaa816f163db0f35148',
    //   symbol: 'HGET',
    //   decimals: 6,
    //   name: 'Hedget',
    //   logoURI: 'https://tokens.1inch.io/0x7968bc6a03017ea2de509aaa816f163db0f35148.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x39795344cbcc76cc3fb94b9d1b15c23c2070c66d',
    //   symbol: 'SHARE',
    //   decimals: 9,
    //   name: 'Seigniorage Shares',
    //   logoURI: 'https://tokens.1inch.io/0x39795344cbcc76cc3fb94b9d1b15c23c2070c66d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9ed8e7c9604790f7ec589f99b94361d8aab64e5e',
    //   symbol: 'UNISTAKE',
    //   decimals: 18,
    //   name: 'Unistake',
    //   logoURI: 'https://tokens.1inch.io/0x9ed8e7c9604790f7ec589f99b94361d8aab64e5e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5f64ab1544d28732f0a24f4713c2c8ec0da089f0',
    //   symbol: 'DEXTF',
    //   decimals: 18,
    //   name: 'DEXTF Token',
    //   logoURI: 'https://tokens.1inch.io/0x5f64ab1544d28732f0a24f4713c2c8ec0da089f0.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x018fb5af9d015af25592a014c4266a84143de7a0',
    //   symbol: 'MP3',
    //   decimals: 18,
    //   name: 'mp3',
    //   logoURI: 'https://tokens.1inch.io/0x018fb5af9d015af25592a014c4266a84143de7a0.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb6ff96b8a8d214544ca0dbc9b33f7ad6503efd32',
    //   symbol: 'SYNC',
    //   decimals: 18,
    //   name: 'SYNC',
    //   logoURI: 'https://tokens.1inch.io/0xb6ff96b8a8d214544ca0dbc9b33f7ad6503efd32.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf9fbe825bfb2bf3e387af0dc18cac8d87f29dea8',
    //   symbol: 'BOTS',
    //   decimals: 18,
    //   name: 'Bot Ocean',
    //   logoURI: 'https://tokens.1inch.io/0xf9fbe825bfb2bf3e387af0dc18cac8d87f29dea8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x66c0dded8433c9ea86c8cf91237b14e10b4d70b7',
    //   symbol: 'Mars',
    //   decimals: 18,
    //   name: 'MarsToken',
    //   logoURI: 'https://tokens.1inch.io/0x66c0dded8433c9ea86c8cf91237b14e10b4d70b7.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc0ba369c8db6eb3924965e5c4fd0b4c1b91e305f',
    //   symbol: 'DUCK',
    //   decimals: 18,
    //   name: 'DLP Duck Token',
    //   logoURI: 'https://tokens.1inch.io/0xc0ba369c8db6eb3924965e5c4fd0b4c1b91e305f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcca0c9c383076649604ee31b20248bc04fdf61ca',
    //   symbol: 'BTMX',
    //   decimals: 18,
    //   name: 'BitMax token',
    //   logoURI: 'https://tokens.1inch.io/0xcca0c9c383076649604ee31b20248bc04fdf61ca.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xebd9d99a3982d547c5bb4db7e3b1f9f14b67eb83',
    //   symbol: 'ID',
    //   decimals: 18,
    //   name: 'Everest ID',
    //   logoURI: 'https://tokens.1inch.io/0xebd9d99a3982d547c5bb4db7e3b1f9f14b67eb83.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x00c83aecc790e8a4453e5dd3b0b4b3680501a7a7',
    //   symbol: 'SKL',
    //   decimals: 18,
    //   name: 'SKALE',
    //   logoURI: 'https://tokens.1inch.io/0x00c83aecc790e8a4453e5dd3b0b4b3680501a7a7.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0f51bb10119727a7e5ea3538074fb341f56b09ad',
    //   symbol: 'DAO',
    //   decimals: 18,
    //   name: 'DAO Maker',
    //   logoURI: 'https://tokens.1inch.io/0x0f51bb10119727a7e5ea3538074fb341f56b09ad.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x76c5449f4950f6338a393f53cda8b53b0cd3ca3a',
    //   symbol: 'BT',
    //   decimals: 18,
    //   name: 'BT.Finance',
    //   logoURI: 'https://tokens.1inch.io/0x76c5449f4950f6338a393f53cda8b53b0cd3ca3a.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf680429328caaacabee69b7a9fdb21a71419c063',
    //   symbol: 'BFLY',
    //   decimals: 18,
    //   name: 'Butterfly Protocol Governance Token',
    //   logoURI: 'https://tokens.1inch.io/0xf680429328caaacabee69b7a9fdb21a71419c063.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6fc13eace26590b80cccab1ba5d51890577d83b2',
    //   symbol: 'UMB',
    //   decimals: 18,
    //   name: 'Umbrella',
    //   logoURI: 'https://tokens.1inch.io/0x6fc13eace26590b80cccab1ba5d51890577d83b2.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1456688345527be1f37e9e627da0837d6f08c925',
    //   symbol: 'USDP_2',
    //   decimals: 18,
    //   name: 'USDP Stablecoin',
    //   logoURI: 'https://tokens.1inch.io/0x1456688345527be1f37e9e627da0837d6f08c925.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x23b608675a2b2fb1890d3abbd85c5775c51691d5',
    //   symbol: 'SOCKS',
    //   decimals: 18,
    //   name: 'Unisocks Edition 0',
    //   logoURI: 'https://tokens.1inch.io/0x23b608675a2b2fb1890d3abbd85c5775c51691d5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xaa4e3edb11afa93c41db59842b29de64b72e355b',
    //   symbol: 'MFI',
    //   decimals: 18,
    //   name: 'MarginSwap',
    //   logoURI: 'https://tokens.1inch.io/0xaa4e3edb11afa93c41db59842b29de64b72e355b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9af15d7b8776fa296019979e70a5be53c714a7ec',
    //   symbol: 'EVN',
    //   decimals: 18,
    //   name: 'Evn Token',
    //   logoURI: 'https://tokens.1inch.io/0x9af15d7b8776fa296019979e70a5be53c714a7ec.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe6c3502997f97f9bde34cb165fbce191065e068f',
    //   symbol: 'KBTC',
    //   decimals: 18,
    //   name: 'KBTC',
    //   logoURI: 'https://tokens.1inch.io/0xe6c3502997f97f9bde34cb165fbce191065e068f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x69e8b9528cabda89fe846c67675b5d73d463a916',
    //   symbol: 'OPEN',
    //   decimals: 18,
    //   name: 'BBB',
    //   logoURI: 'https://tokens.1inch.io/0x69e8b9528cabda89fe846c67675b5d73d463a916.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x196c81385bc536467433014042788eb707703934',
    //   symbol: 'CTASK',
    //   decimals: 18,
    //   name: 'CTASK Token',
    //   logoURI: 'https://tokens.1inch.io/0x196c81385bc536467433014042788eb707703934.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x89bd2e7e388fab44ae88bef4e1ad12b4f1e0911c',
    //   symbol: 'NUX',
    //   decimals: 18,
    //   name: 'NUX Peanut.trade',
    //   logoURI: 'https://tokens.1inch.io/0x89bd2e7e388fab44ae88bef4e1ad12b4f1e0911c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa0bed124a09ac2bd941b10349d8d224fe3c955eb',
    //   symbol: 'DEPAY',
    //   decimals: 18,
    //   name: 'DePay',
    //   logoURI: 'https://tokens.1inch.io/0xa0bed124a09ac2bd941b10349d8d224fe3c955eb.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb9ef770b6a5e12e45983c5d80545258aa38f3b78',
    //   symbol: 'ZCN',
    //   decimals: 10,
    //   name: '0chain',
    //   logoURI: 'https://tokens.1inch.io/0xb9ef770b6a5e12e45983c5d80545258aa38f3b78.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xadb2437e6f65682b85f814fbc12fec0508a7b1d0',
    //   symbol: 'UNCX',
    //   decimals: 18,
    //   name: 'UniCrypt',
    //   logoURI: 'https://tokens.1inch.io/0xadb2437e6f65682b85f814fbc12fec0508a7b1d0.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xed40834a13129509a89be39a9be9c0e96a0ddd71',
    //   symbol: 'WARP',
    //   decimals: 18,
    //   name: 'Warp Token',
    //   logoURI: 'https://tokens.1inch.io/0xed40834a13129509a89be39a9be9c0e96a0ddd71.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x875773784af8135ea0ef43b5a374aad105c5d39e',
    //   symbol: 'IDLE',
    //   decimals: 18,
    //   name: 'Idle',
    //   logoURI: 'https://tokens.1inch.io/0x875773784af8135ea0ef43b5a374aad105c5d39e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0488401c3f535193fa8df029d9ffe615a06e74e6',
    //   symbol: 'SRK',
    //   decimals: 18,
    //   name: 'SparkPoint',
    //   logoURI: 'https://tokens.1inch.io/0x0488401c3f535193fa8df029d9ffe615a06e74e6.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x038a68ff68c393373ec894015816e33ad41bd564',
    //   symbol: 'GLCH',
    //   decimals: 18,
    //   name: 'Glitch',
    //   logoURI: 'https://tokens.1inch.io/0x038a68ff68c393373ec894015816e33ad41bd564.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x10be9a8dae441d276a5027936c3aaded2d82bc15',
    //   symbol: 'UMX',
    //   decimals: 18,
    //   name: 'unimex network',
    //   logoURI: 'https://tokens.1inch.io/0x10be9a8dae441d276a5027936c3aaded2d82bc15.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5f0e628b693018f639d10e4a4f59bd4d8b2b6b44',
    //   symbol: 'WHITE',
    //   decimals: 18,
    //   name: 'Whiteheart Token',
    //   logoURI: 'https://tokens.1inch.io/0x5f0e628b693018f639d10e4a4f59bd4d8b2b6b44.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3597bfd533a99c9aa083587b074434e61eb0a258',
    //   symbol: 'DENT',
    //   decimals: 8,
    //   name: 'DENT',
    //   logoURI: 'https://tokens.1inch.io/0x3597bfd533a99c9aa083587b074434e61eb0a258.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x945facb997494cc2570096c74b5f66a3507330a1',
    //   symbol: 'mBTC',
    //   decimals: 18,
    //   name: 'mStable BTC',
    //   logoURI: 'https://tokens.1inch.io/0x945facb997494cc2570096c74b5f66a3507330a1.png',
    //   tags: ['PEG:BTC', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1b40183efb4dd766f11bda7a7c3ad8982e998421',
    //   symbol: 'VSP',
    //   decimals: 18,
    //   name: 'VesperToken',
    //   logoURI: 'https://tokens.1inch.io/0x1b40183efb4dd766f11bda7a7c3ad8982e998421.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xed0439eacf4c4965ae4613d77a5c2efe10e5f183',
    //   symbol: 'SHROOM',
    //   decimals: 18,
    //   name: 'shroom.finance',
    //   logoURI: 'https://tokens.1inch.io/0xed0439eacf4c4965ae4613d77a5c2efe10e5f183.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xaac41ec512808d64625576eddd580e7ea40ef8b2',
    //   symbol: 'GSWAP',
    //   decimals: 18,
    //   name: 'gameswap.org',
    //   logoURI: 'https://tokens.1inch.io/0xaac41ec512808d64625576eddd580e7ea40ef8b2.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x03ab458634910aad20ef5f1c8ee96f1d6ac54919',
    //   symbol: 'RAI',
    //   decimals: 18,
    //   name: 'Rai Reflex Index',
    //   logoURI: 'https://tokens.1inch.io/0x03ab458634910aad20ef5f1c8ee96f1d6ac54919.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfc05987bd2be489accf0f509e44b0145d68240f7',
    //   symbol: 'ESS',
    //   decimals: 18,
    //   name: 'ESSENTIA',
    //   logoURI: 'https://tokens.1inch.io/0xfc05987bd2be489accf0f509e44b0145d68240f7.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x34612903db071e888a4dadcaa416d3ee263a87b9',
    //   symbol: 'arte',
    //   decimals: 18,
    //   name: 'ethart',
    //   logoURI: 'https://tokens.1inch.io/0x34612903db071e888a4dadcaa416d3ee263a87b9.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7ca4408137eb639570f8e647d9bd7b7e8717514a',
    //   symbol: 'ALPA',
    //   decimals: 18,
    //   name: 'AlpaToken',
    //   logoURI: 'https://tokens.1inch.io/0x7ca4408137eb639570f8e647d9bd7b7e8717514a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa58a4f5c4bb043d2cc1e170613b74e767c94189b',
    //   symbol: 'UTU',
    //   decimals: 18,
    //   name: 'UTU Coin',
    //   logoURI: 'https://tokens.1inch.io/0xa58a4f5c4bb043d2cc1e170613b74e767c94189b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9a0aba393aac4dfbff4333b06c407458002c6183',
    //   symbol: 'AC',
    //   decimals: 18,
    //   name: 'ACoconut',
    //   logoURI: 'https://tokens.1inch.io/0x9a0aba393aac4dfbff4333b06c407458002c6183.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7eaf9c89037e4814dc0d9952ac7f888c784548db',
    //   symbol: 'ROYA',
    //   decimals: 18,
    //   name: 'Royale',
    //   logoURI: 'https://tokens.1inch.io/0x7eaf9c89037e4814dc0d9952ac7f888c784548db.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6399c842dd2be3de30bf99bc7d1bbf6fa3650e70',
    //   symbol: 'PREMIA',
    //   decimals: 18,
    //   name: 'Premia',
    //   logoURI: 'https://tokens.1inch.io/0x6399c842dd2be3de30bf99bc7d1bbf6fa3650e70.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0cec1a9154ff802e7934fc916ed7ca50bde6844e',
    //   symbol: 'POOL',
    //   decimals: 18,
    //   name: 'PoolTogether',
    //   logoURI: 'https://tokens.1inch.io/0x0cec1a9154ff802e7934fc916ed7ca50bde6844e.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x72e9d9038ce484ee986fea183f8d8df93f9ada13',
    //   symbol: 'SMARTCREDIT',
    //   decimals: 18,
    //   name: 'SMARTCREDIT Token',
    //   logoURI: 'https://tokens.1inch.io/0x72e9d9038ce484ee986fea183f8d8df93f9ada13.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcb5f72d37685c3d5ad0bb5f982443bc8fcdf570e',
    //   symbol: 'ROOT',
    //   decimals: 18,
    //   name: 'RootKit',
    //   logoURI: 'https://tokens.1inch.io/0xcb5f72d37685c3d5ad0bb5f982443bc8fcdf570e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x557b933a7c2c45672b610f8954a3deb39a51a8ca',
    //   symbol: 'REVV',
    //   decimals: 18,
    //   name: 'REVV',
    //   logoURI: 'https://tokens.1inch.io/0x557b933a7c2c45672b610f8954a3deb39a51a8ca.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x38a2fdc11f526ddd5a607c1f251c065f40fbf2f7',
    //   symbol: 'PHNX',
    //   decimals: 18,
    //   name: 'PhoenixDAO',
    //   logoURI: 'https://tokens.1inch.io/0x38a2fdc11f526ddd5a607c1f251c065f40fbf2f7.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7866e48c74cbfb8183cd1a929cd9b95a7a5cb4f4',
    //   symbol: 'KIT',
    //   decimals: 18,
    //   name: 'DexKit',
    //   logoURI: 'https://tokens.1inch.io/0x7866e48c74cbfb8183cd1a929cd9b95a7a5cb4f4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4691937a7508860f876c9c0a2a617e7d9e945d4b',
    //   symbol: 'WOO',
    //   decimals: 18,
    //   name: 'Wootrade Network',
    //   logoURI: 'https://tokens.1inch.io/0x4691937a7508860f876c9c0a2a617e7d9e945d4b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xea1ea0972fa092dd463f2968f9bb51cc4c981d71',
    //   symbol: 'MOD',
    //   decimals: 18,
    //   name: 'Modefi',
    //   logoURI: 'https://tokens.1inch.io/0xea1ea0972fa092dd463f2968f9bb51cc4c981d71.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf1f955016ecbcd7321c7266bccfb96c68ea5e49b',
    //   symbol: 'RLY',
    //   decimals: 18,
    //   name: 'Rally',
    //   logoURI: 'https://tokens.1inch.io/0xf1f955016ecbcd7321c7266bccfb96c68ea5e49b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x16980b3b4a3f9d89e33311b5aa8f80303e5ca4f8',
    //   symbol: 'KEX',
    //   decimals: 6,
    //   name: 'KIRA Network',
    //   logoURI: 'https://tokens.1inch.io/0x16980b3b4a3f9d89e33311b5aa8f80303e5ca4f8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd13c7342e1ef687c5ad21b27c2b65d772cab5c8c',
    //   symbol: 'UOS',
    //   decimals: 4,
    //   name: 'Ultra Token',
    //   logoURI: 'https://tokens.1inch.io/0xd13c7342e1ef687c5ad21b27c2b65d772cab5c8c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x147faf8de9d8d8daae129b187f0d02d819126750',
    //   symbol: 'GEO',
    //   decimals: 18,
    //   name: 'GeoDB Coin',
    //   logoURI: 'https://tokens.1inch.io/0x147faf8de9d8d8daae129b187f0d02d819126750.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8a854288a5976036a725879164ca3e91d30c6a1b',
    //   symbol: 'GET',
    //   decimals: 18,
    //   name: 'GET',
    //   logoURI: 'https://tokens.1inch.io/0x8a854288a5976036a725879164ca3e91d30c6a1b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9e78b8274e1d6a76a0dbbf90418894df27cbceb5',
    //   symbol: 'UniFi',
    //   decimals: 18,
    //   name: 'UniFi',
    //   logoURI: 'https://tokens.1inch.io/0x9e78b8274e1d6a76a0dbbf90418894df27cbceb5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3a880652f47bfaa771908c07dd8673a787daed3a',
    //   symbol: 'DDX',
    //   decimals: 18,
    //   name: 'DerivaDAO',
    //   logoURI: 'https://tokens.1inch.io/0x3a880652f47bfaa771908c07dd8673a787daed3a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa1d6df714f91debf4e0802a542e13067f31b8262',
    //   symbol: 'RFOX',
    //   decimals: 18,
    //   name: 'RFOX',
    //   logoURI: 'https://tokens.1inch.io/0xa1d6df714f91debf4e0802a542e13067f31b8262.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x275f5ad03be0fa221b4c6649b8aee09a42d9412a',
    //   symbol: 'MONA',
    //   decimals: 18,
    //   name: 'Monavale',
    //   logoURI: 'https://tokens.1inch.io/0x275f5ad03be0fa221b4c6649b8aee09a42d9412a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9b53e429b0badd98ef7f01f03702986c516a5715',
    //   symbol: 'HY',
    //   decimals: 18,
    //   name: 'hybrix hydra',
    //   logoURI: 'https://tokens.1inch.io/0x9b53e429b0badd98ef7f01f03702986c516a5715.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55',
    //   symbol: 'SUPER',
    //   decimals: 18,
    //   name: 'SuperFarm',
    //   logoURI: 'https://tokens.1inch.io/0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6149c26cd2f7b5ccdb32029af817123f6e37df5b',
    //   symbol: 'LPOOL',
    //   decimals: 18,
    //   name: 'Launchpool token',
    //   logoURI: 'https://tokens.1inch.io/0x6149c26cd2f7b5ccdb32029af817123f6e37df5b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x69af81e73a73b40adf4f3d4223cd9b1ece623074',
    //   symbol: 'MASK_NTWRK',
    //   decimals: 18,
    //   name: 'Mask Network',
    //   logoURI: 'https://tokens.1inch.io/0x69af81e73a73b40adf4f3d4223cd9b1ece623074.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7f3edcdd180dbe4819bd98fee8929b5cedb3adeb',
    //   symbol: 'XTK',
    //   decimals: 18,
    //   name: 'xToken',
    //   logoURI: 'https://tokens.1inch.io/0x7f3edcdd180dbe4819bd98fee8929b5cedb3adeb.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7777777777697cfeecf846a76326da79cc606517',
    //   symbol: 'SIG',
    //   decimals: 18,
    //   name: 'xSigma',
    //   logoURI: 'https://tokens.1inch.io/0x7777777777697cfeecf846a76326da79cc606517.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf5581dfefd8fb0e4aec526be659cfab1f8c781da',
    //   symbol: 'HOPR',
    //   decimals: 18,
    //   name: 'HOPR Token',
    //   logoURI: 'https://tokens.1inch.io/0xf5581dfefd8fb0e4aec526be659cfab1f8c781da.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6c972b70c533e2e045f333ee28b9ffb8d717be69',
    //   symbol: 'FRY',
    //   decimals: 18,
    //   name: 'Foundry Logistics Token',
    //   logoURI: 'https://tokens.1inch.io/0x6c972b70c533e2e045f333ee28b9ffb8d717be69.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24',
    //   symbol: 'RNDR',
    //   decimals: 18,
    //   name: 'Render Token',
    //   logoURI: 'https://tokens.1inch.io/0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x21bfbda47a0b4b5b1248c767ee49f7caa9b23697',
    //   symbol: 'OVR',
    //   decimals: 18,
    //   name: 'OVR',
    //   logoURI: 'https://tokens.1inch.io/0x21bfbda47a0b4b5b1248c767ee49f7caa9b23697.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2e1e15c44ffe4df6a0cb7371cd00d5028e571d14',
    //   symbol: 'MTLX',
    //   decimals: 18,
    //   name: 'Mettalex',
    //   logoURI: 'https://tokens.1inch.io/0x2e1e15c44ffe4df6a0cb7371cd00d5028e571d14.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd478161c952357f05f0292b56012cd8457f1cfbf',
    //   symbol: 'POLK',
    //   decimals: 18,
    //   name: 'Polkamarkets',
    //   logoURI: 'https://tokens.1inch.io/0xd478161c952357f05f0292b56012cd8457f1cfbf.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x48fb253446873234f2febbf9bdeaa72d9d387f94',
    //   symbol: 'vBNT',
    //   decimals: 18,
    //   name: 'Bancor Governance Token',
    //   logoURI: 'https://tokens.1inch.io/0x48fb253446873234f2febbf9bdeaa72d9d387f94.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x92b767185fb3b04f881e3ac8e5b0662a027a1d9f',
    //   symbol: 'crDAI',
    //   decimals: 8,
    //   name: 'Cream Dai Stablecoin',
    //   logoURI: 'https://tokens.1inch.io/0x92b767185fb3b04f881e3ac8e5b0662a027a1d9f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6e9730ecffbed43fd876a264c982e254ef05a0de',
    //   symbol: 'NORD',
    //   decimals: 18,
    //   name: 'Nord Token',
    //   logoURI: 'https://tokens.1inch.io/0x6e9730ecffbed43fd876a264c982e254ef05a0de.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x661ab0ed68000491d98c796146bcf28c20d7c559',
    //   symbol: 'DOWS',
    //   decimals: 18,
    //   name: 'Shadows Network',
    //   logoURI: 'https://tokens.1inch.io/0x661ab0ed68000491d98c796146bcf28c20d7c559.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0cdf9acd87e940837ff21bb40c9fd55f68bba059',
    //   symbol: 'MINT',
    //   decimals: 18,
    //   name: 'Public Mint',
    //   logoURI: 'https://tokens.1inch.io/0x0cdf9acd87e940837ff21bb40c9fd55f68bba059.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0c7d5ae016f806603cb1782bea29ac69471cab9c',
    //   symbol: 'BFC',
    //   decimals: 18,
    //   name: 'Bifrost',
    //   logoURI: 'https://tokens.1inch.io/0x0c7d5ae016f806603cb1782bea29ac69471cab9c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd23ac27148af6a2f339bd82d0e3cff380b5093de',
    //   symbol: 'SI',
    //   decimals: 18,
    //   name: 'SIREN',
    //   logoURI: 'https://tokens.1inch.io/0xd23ac27148af6a2f339bd82d0e3cff380b5093de.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4c25bdf026ea05f32713f00f73ca55857fbf6342',
    //   symbol: 'FONT',
    //   decimals: 18,
    //   name: 'Font',
    //   logoURI: 'https://tokens.1inch.io/0x4c25bdf026ea05f32713f00f73ca55857fbf6342.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x68a3637ba6e75c0f66b61a42639c4e9fcd3d4824',
    //   symbol: 'MOON',
    //   decimals: 18,
    //   name: 'MoonToken',
    //   logoURI: 'https://tokens.1inch.io/0x68a3637ba6e75c0f66b61a42639c4e9fcd3d4824.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4b1e80cac91e2216eeb63e29b957eb91ae9c2be8',
    //   symbol: 'JUP',
    //   decimals: 18,
    //   name: 'Jupiter',
    //   logoURI: 'https://tokens.1inch.io/0x4b1e80cac91e2216eeb63e29b957eb91ae9c2be8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x739763a258640919981f9ba610ae65492455be53',
    //   symbol: 'NDR',
    //   decimals: 18,
    //   name: 'NodeRunners',
    //   logoURI: 'https://tokens.1inch.io/0x739763a258640919981f9ba610ae65492455be53.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x31c8eacbffdd875c74b94b077895bd78cf1e64a3',
    //   symbol: 'RAD',
    //   decimals: 18,
    //   name: 'Radicle',
    //   logoURI: 'https://tokens.1inch.io/0x31c8eacbffdd875c74b94b077895bd78cf1e64a3.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdbdb4d16eda451d0503b854cf79d55697f90c8df',
    //   symbol: 'ALCX',
    //   decimals: 18,
    //   name: 'Alchemix',
    //   logoURI: 'https://tokens.1inch.io/0xdbdb4d16eda451d0503b854cf79d55697f90c8df.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x24a6a37576377f63f194caa5f518a60f45b42921',
    //   symbol: 'BANK_1',
    //   decimals: 18,
    //   name: 'Float Bank',
    //   logoURI: 'https://tokens.1inch.io/0x24a6a37576377f63f194caa5f518a60f45b42921.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9b99cca871be05119b2012fd4474731dd653febe',
    //   symbol: 'MATTER',
    //   decimals: 18,
    //   name: 'Antimatter.Finance Governance Token',
    //   logoURI: 'https://tokens.1inch.io/0x1c9491865a1de77c5b6e19d2e6a5f1d7a6f2b25f_1.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf4d861575ecc9493420a3f5a14f85b13f0b50eb3',
    //   symbol: 'FCL',
    //   decimals: 18,
    //   name: 'Fractal Protocol Token',
    //   logoURI: 'https://tokens.1inch.io/0xf4d861575ecc9493420a3f5a14f85b13f0b50eb3.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf411903cbc70a74d22900a5de66a2dda66507255',
    //   symbol: 'VRA',
    //   decimals: 18,
    //   name: 'VERA',
    //   logoURI: 'https://tokens.1inch.io/0xf411903cbc70a74d22900a5de66a2dda66507255.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe5feeac09d36b18b3fa757e5cf3f8da6b8e27f4c',
    //   symbol: 'NFTI',
    //   decimals: 18,
    //   name: 'NFT INDEX',
    //   logoURI: 'https://tokens.1inch.io/0xe5feeac09d36b18b3fa757e5cf3f8da6b8e27f4c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf3dcbc6d72a4e1892f7917b7c43b74131df8480e',
    //   symbol: 'BDP',
    //   decimals: 18,
    //   name: 'BDPToken',
    //   logoURI: 'https://tokens.1inch.io/0xf3dcbc6d72a4e1892f7917b7c43b74131df8480e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xbbc2ae13b23d715c30720f079fcd9b4a74093505',
    //   symbol: 'ERN',
    //   decimals: 18,
    //   name: '@EthernityChain $ERN Token',
    //   logoURI: 'https://tokens.1inch.io/0xbbc2ae13b23d715c30720f079fcd9b4a74093505.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfb5453340c03db5ade474b27e68b6a9c6b2823eb',
    //   symbol: 'ROBOT',
    //   decimals: 18,
    //   name: 'MetaFactory',
    //   logoURI: 'https://tokens.1inch.io/0xfb5453340c03db5ade474b27e68b6a9c6b2823eb.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4c6ec08cf3fc987c6c4beb03184d335a2dfc4042',
    //   symbol: 'PAINT',
    //   decimals: 18,
    //   name: 'Paint',
    //   logoURI: 'https://tokens.1inch.io/0x4c6ec08cf3fc987c6c4beb03184d335a2dfc4042.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x892a6f9df0147e5f079b0993f486f9aca3c87881',
    //   symbol: 'xFUND',
    //   decimals: 9,
    //   name: 'unification.com/xfund',
    //   logoURI: 'https://tokens.1inch.io/0x892a6f9df0147e5f079b0993f486f9aca3c87881.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7a5ce6abd131ea6b148a022cb76fc180ae3315a6',
    //   symbol: 'bALPHA',
    //   decimals: 18,
    //   name: 'bAlpha',
    //   logoURI: 'https://tokens.1inch.io/0x7a5ce6abd131ea6b148a022cb76fc180ae3315a6.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3506424f91fd33084466f402d5d97f05f8e3b4af',
    //   symbol: 'CHZ',
    //   decimals: 18,
    //   name: 'chiliZ',
    //   logoURI: 'https://tokens.1inch.io/0x3506424f91fd33084466f402d5d97f05f8e3b4af.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x41d5d79431a913c4ae7d69a668ecdfe5ff9dfb68',
    //   symbol: 'INV',
    //   decimals: 18,
    //   name: 'Inverse DAO',
    //   logoURI: 'https://tokens.1inch.io/0x41d5d79431a913c4ae7d69a668ecdfe5ff9dfb68.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xeeaa40b28a2d1b0b08f6f97bb1dd4b75316c6107',
    //   symbol: 'GOVI',
    //   decimals: 18,
    //   name: 'GOVI',
    //   logoURI: 'https://tokens.1inch.io/0xeeaa40b28a2d1b0b08f6f97bb1dd4b75316c6107.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcf3c8be2e2c42331da80ef210e9b1b307c03d36a',
    //   symbol: 'BEPRO',
    //   decimals: 18,
    //   name: 'BetProtocolToken',
    //   logoURI: 'https://tokens.1inch.io/0xcf3c8be2e2c42331da80ef210e9b1b307c03d36a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1cbb83ebcd552d5ebf8131ef8c9cd9d9bab342bc',
    //   symbol: 'NFY',
    //   decimals: 18,
    //   name: 'Non-Fungible Yearn',
    //   logoURI: 'https://tokens.1inch.io/0x1cbb83ebcd552d5ebf8131ef8c9cd9d9bab342bc.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x888888435fde8e7d4c54cab67f206e4199454c60',
    //   symbol: 'DFX',
    //   decimals: 18,
    //   name: 'DFX Token',
    //   logoURI: 'https://tokens.1inch.io/0x888888435fde8e7d4c54cab67f206e4199454c60.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc4de189abf94c57f396bd4c52ab13b954febefd8',
    //   symbol: 'B20',
    //   decimals: 18,
    //   name: 'B.20',
    //   logoURI: 'https://tokens.1inch.io/0xc4de189abf94c57f396bd4c52ab13b954febefd8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xed30dd7e50edf3581ad970efc5d9379ce2614adb',
    //   symbol: 'ARCX_OLD',
    //   decimals: 18,
    //   name: 'ARC Governance Token (Old)',
    //   logoURI: 'https://tokens.1inch.io/0xed30dd7e50edf3581ad970efc5d9379ce2614adb.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcd91538b91b4ba7797d39a2f66e63810b50a33d0',
    //   symbol: 'STABLEx',
    //   decimals: 18,
    //   name: 'ARC STABLEx',
    //   logoURI: 'https://tokens.1inch.io/0xcd91538b91b4ba7797d39a2f66e63810b50a33d0.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x940a2db1b7008b6c776d4faaca729d6d4a4aa551',
    //   symbol: 'DUSK',
    //   decimals: 18,
    //   name: 'Dusk Network',
    //   logoURI: 'https://tokens.1inch.io/0x940a2db1b7008b6c776d4faaca729d6d4a4aa551.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9',
    //   symbol: 'FTX Token',
    //   decimals: 18,
    //   name: 'FTT',
    //   logoURI: 'https://tokens.1inch.io/0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x037a54aab062628c9bbae1fdb1583c195585fe41',
    //   symbol: 'LCX',
    //   decimals: 18,
    //   name: 'LCX',
    //   logoURI: 'https://tokens.1inch.io/0x037a54aab062628c9bbae1fdb1583c195585fe41.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x544c42fbb96b39b21df61cf322b5edc285ee7429',
    //   symbol: 'INSUR',
    //   decimals: 18,
    //   name: 'InsurAce',
    //   logoURI: 'https://tokens.1inch.io/0x544c42fbb96b39b21df61cf322b5edc285ee7429.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1fe24f25b1cf609b9c4e7e12d802e3640dfa5e43',
    //   symbol: 'CGG',
    //   decimals: 18,
    //   name: 'ChainGuardians Governance Token',
    //   logoURI: 'https://tokens.1inch.io/0x1fe24f25b1cf609b9c4e7e12d802e3640dfa5e43.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x32a7c02e79c4ea1008dd6564b35f131428673c41',
    //   symbol: 'CRU',
    //   decimals: 18,
    //   name: 'CRUST',
    //   logoURI: 'https://tokens.1inch.io/0x32a7c02e79c4ea1008dd6564b35f131428673c41.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xac51066d7bec65dc4589368da368b212745d63e8',
    //   symbol: 'ALICE',
    //   decimals: 6,
    //   name: 'ALICE',
    //   logoURI: 'https://tokens.1inch.io/0xac51066d7bec65dc4589368da368b212745d63e8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1c9922314ed1415c95b9fd453c3818fd41867d0b',
    //   symbol: 'TOWER',
    //   decimals: 18,
    //   name: 'TOWER',
    //   logoURI: 'https://tokens.1inch.io/0x1c9922314ed1415c95b9fd453c3818fd41867d0b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa8b61cff52564758a204f841e636265bebc8db9b',
    //   symbol: 'YIELD',
    //   decimals: 18,
    //   name: 'Yield Protocol',
    //   logoURI: 'https://tokens.1inch.io/0xa8b61cff52564758a204f841e636265bebc8db9b.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x850aab69f0e0171a9a49db8be3e71351c8247df4',
    //   symbol: 'KONO',
    //   decimals: 18,
    //   name: 'Konomi',
    //   logoURI: 'https://tokens.1inch.io/0x850aab69f0e0171a9a49db8be3e71351c8247df4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xac3211a5025414af2866ff09c23fc18bc97e79b1',
    //   symbol: 'DOV',
    //   decimals: 18,
    //   name: 'DOVU',
    //   logoURI: 'https://tokens.1inch.io/0xac3211a5025414af2866ff09c23fc18bc97e79b1.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x73374ea518de7addd4c2b624c0e8b113955ee041',
    //   symbol: 'JGN',
    //   decimals: 18,
    //   name: 'Juggernaut DeFi',
    //   logoURI: 'https://tokens.1inch.io/0x73374ea518de7addd4c2b624c0e8b113955ee041.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfad45e47083e4607302aa43c65fb3106f1cd7607',
    //   symbol: 'HOGE',
    //   decimals: 9,
    //   name: 'hoge.finance',
    //   logoURI: 'https://tokens.1inch.io/0xfad45e47083e4607302aa43c65fb3106f1cd7607.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe9a95d175a5f4c9369f3b74222402eb1b837693b',
    //   symbol: 'NOW',
    //   decimals: 8,
    //   name: 'ChangeNOW',
    //   logoURI: 'https://tokens.1inch.io/0xe9a95d175a5f4c9369f3b74222402eb1b837693b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdea67845a51e24461d5fed8084e69b426af3d5db',
    //   symbol: 'HTRE',
    //   decimals: 18,
    //   name: 'HodlTree',
    //   logoURI: 'https://tokens.1inch.io/0xdea67845a51e24461d5fed8084e69b426af3d5db.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8b39b70e39aa811b69365398e0aace9bee238aeb',
    //   symbol: 'PKF',
    //   decimals: 18,
    //   name: 'PolkaFoundry',
    //   logoURI: 'https://tokens.1inch.io/0x8b39b70e39aa811b69365398e0aace9bee238aeb.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb20043f149817bff5322f1b928e89abfc65a9925',
    //   symbol: 'EXRT',
    //   decimals: 8,
    //   name: 'EXRT',
    //   logoURI: 'https://tokens.1inch.io/0xb20043f149817bff5322f1b928e89abfc65a9925.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa487bf43cf3b10dffc97a9a744cbb7036965d3b9',
    //   symbol: 'DERI',
    //   decimals: 18,
    //   name: 'Deri',
    //   logoURI: 'https://tokens.1inch.io/0xa487bf43cf3b10dffc97a9a744cbb7036965d3b9.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x14da7b27b2e0fedefe0a664118b0c9bc68e2e9af',
    //   symbol: 'BCUG',
    //   decimals: 18,
    //   name: 'Blockchain Cuties Universe Governance Token',
    //   logoURI: 'https://tokens.1inch.io/0x14da7b27b2e0fedefe0a664118b0c9bc68e2e9af.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8b0e42f366ba502d787bb134478adfae966c8798',
    //   symbol: 'LABS',
    //   decimals: 18,
    //   name: 'LABS Group',
    //   logoURI: 'https://tokens.1inch.io/0x8b0e42f366ba502d787bb134478adfae966c8798.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcbfef8fdd706cde6f208460f2bf39aa9c785f05d',
    //   symbol: 'KINE',
    //   decimals: 18,
    //   name: 'Kine Governance Token',
    //   logoURI: 'https://tokens.1inch.io/0xcbfef8fdd706cde6f208460f2bf39aa9c785f05d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54',
    //   symbol: 'HAPI',
    //   decimals: 18,
    //   name: 'HAPI',
    //   logoURI: 'https://tokens.1inch.io/0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc5bddf9843308380375a611c18b50fb9341f502a',
    //   symbol: 'yveCRV-DAO',
    //   decimals: 18,
    //   name: 'veCRV-DAO yVault',
    //   logoURI: 'https://tokens.1inch.io/0xc5bddf9843308380375a611c18b50fb9341f502a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb9d99c33ea2d86ec5ec6b8a4dd816ebba64404af',
    //   symbol: 'K21',
    //   decimals: 18,
    //   name: 'k21.kanon.art',
    //   logoURI: 'https://tokens.1inch.io/0xb9d99c33ea2d86ec5ec6b8a4dd816ebba64404af.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x226f7b842e0f0120b7e194d05432b3fd14773a9d',
    //   symbol: 'UNN',
    //   decimals: 18,
    //   name: 'UNION Protocol Governance Token',
    //   logoURI: 'https://tokens.1inch.io/0x226f7b842e0f0120b7e194d05432b3fd14773a9d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa42f266684ac2ad6ecb00df95b1c76efbb6f136c',
    //   symbol: 'CATE',
    //   decimals: 18,
    //   name: 'Cash Tech',
    //   logoURI: 'https://tokens.1inch.io/0xa42f266684ac2ad6ecb00df95b1c76efbb6f136c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x07bac35846e5ed502aa91adf6a9e7aa210f2dcbe',
    //   symbol: 'erowan',
    //   decimals: 18,
    //   name: 'erowan',
    //   logoURI: 'https://tokens.1inch.io/0x07bac35846e5ed502aa91adf6a9e7aa210f2dcbe.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc4c2614e694cf534d407ee49f8e44d125e4681c4',
    //   symbol: 'CHAIN',
    //   decimals: 18,
    //   name: 'Chain Games',
    //   logoURI: 'https://tokens.1inch.io/0xc4c2614e694cf534d407ee49f8e44d125e4681c4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9f9c8ec3534c3ce16f928381372bfbfbfb9f4d24',
    //   symbol: 'GLQ',
    //   decimals: 18,
    //   name: 'GraphLinq',
    //   logoURI: 'https://tokens.1inch.io/0x9f9c8ec3534c3ce16f928381372bfbfbfb9f4d24.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc690f7c7fcffa6a82b79fab7508c466fefdfc8c5',
    //   symbol: 'LYM',
    //   decimals: 18,
    //   name: 'Lympo tokens',
    //   logoURI: 'https://tokens.1inch.io/0xc690f7c7fcffa6a82b79fab7508c466fefdfc8c5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3d3d35bb9bec23b06ca00fe472b50e7a4c692c30',
    //   symbol: 'VIDYA',
    //   decimals: 18,
    //   name: 'Vidya',
    //   logoURI: 'https://tokens.1inch.io/0x3d3d35bb9bec23b06ca00fe472b50e7a4c692c30.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfc979087305a826c2b2a0056cfaba50aad3e6439',
    //   symbol: 'DAFI',
    //   decimals: 18,
    //   name: 'DAFI Token',
    //   logoURI: 'https://tokens.1inch.io/0xfc979087305a826c2b2a0056cfaba50aad3e6439.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcd2828fc4d8e8a0ede91bb38cf64b1a81de65bf6',
    //   symbol: 'ODDZ',
    //   decimals: 18,
    //   name: 'OddzToken',
    //   logoURI: 'https://tokens.1inch.io/0xcd2828fc4d8e8a0ede91bb38cf64b1a81de65bf6.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa92e7c82b11d10716ab534051b271d2f6aef7df5',
    //   symbol: 'ARA',
    //   decimals: 18,
    //   name: 'Ara Token',
    //   logoURI: 'https://tokens.1inch.io/0xa92e7c82b11d10716ab534051b271d2f6aef7df5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc3d088842dcf02c13699f936bb83dfbbc6f721ab',
    //   symbol: 'vETH',
    //   decimals: 18,
    //   name: 'Voucher Ethereum',
    //   logoURI: 'https://tokens.1inch.io/0xc3d088842dcf02c13699f936bb83dfbbc6f721ab.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xceb286c9604c542d3cc08b41aa6c9675b078a832',
    //   symbol: 'VTX',
    //   decimals: 18,
    //   name: 'Vortex DeFi',
    //   logoURI: 'https://tokens.1inch.io/0xceb286c9604c542d3cc08b41aa6c9675b078a832.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc7283b66eb1eb5fb86327f08e1b5816b0720212b',
    //   symbol: 'TRIBE',
    //   decimals: 18,
    //   name: 'Tribe',
    //   logoURI: 'https://tokens.1inch.io/0xc7283b66eb1eb5fb86327f08e1b5816b0720212b.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x956f47f50a910163d8bf957cf5846d573e7f87ca',
    //   symbol: 'FEI',
    //   decimals: 18,
    //   name: 'Fei USD',
    //   logoURI: 'https://tokens.1inch.io/0x956f47f50a910163d8bf957cf5846d573e7f87ca.png',
    //   eip2612: true,
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x70e8de73ce538da2beed35d14187f6959a8eca96',
    //   symbol: 'XSGD',
    //   decimals: 6,
    //   name: 'XSGD',
    //   logoURI: 'https://tokens.1inch.io/0x70e8de73ce538da2beed35d14187f6959a8eca96.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x626e8036deb333b408be468f951bdb42433cbf18',
    //   symbol: 'AIOZ',
    //   decimals: 18,
    //   name: 'AIOZ Network',
    //   logoURI: 'https://tokens.1inch.io/0x626e8036deb333b408be468f951bdb42433cbf18.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa0cf46eb152656c7090e769916eb44a138aaa406',
    //   symbol: 'SPH',
    //   decimals: 18,
    //   name: 'Spheroid',
    //   logoURI: 'https://tokens.1inch.io/0xa0cf46eb152656c7090e769916eb44a138aaa406.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1796ae0b0fa4862485106a0de9b654efe301d0b2',
    //   symbol: 'PMON',
    //   decimals: 18,
    //   name: 'Polkamon',
    //   logoURI: 'https://tokens.1inch.io/0x1796ae0b0fa4862485106a0de9b654efe301d0b2.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf293d23bf2cdc05411ca0eddd588eb1977e8dcd4',
    //   symbol: 'SYLO',
    //   decimals: 18,
    //   name: 'Sylo',
    //   logoURI: 'https://tokens.1inch.io/0xf293d23bf2cdc05411ca0eddd588eb1977e8dcd4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1735db6ab5baa19ea55d0adceed7bcdc008b3136',
    //   symbol: 'URQA',
    //   decimals: 18,
    //   name: 'UREEQA Token',
    //   logoURI: 'https://tokens.1inch.io/0x1735db6ab5baa19ea55d0adceed7bcdc008b3136.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x99295f1141d58a99e939f7be6bbe734916a875b8',
    //   symbol: 'LPL',
    //   decimals: 18,
    //   name: 'LinkPool',
    //   logoURI: 'https://tokens.1inch.io/0x99295f1141d58a99e939f7be6bbe734916a875b8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe1c7e30c42c24582888c758984f6e382096786bd',
    //   symbol: 'XCUR',
    //   decimals: 8,
    //   name: 'Curate',
    //   logoURI: 'https://tokens.1inch.io/0xe1c7e30c42c24582888c758984f6e382096786bd.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xff75ced57419bcaebe5f05254983b013b0646ef5',
    //   symbol: 'COOK',
    //   decimals: 18,
    //   name: 'Cook Token',
    //   logoURI: 'https://tokens.1inch.io/0xff75ced57419bcaebe5f05254983b013b0646ef5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x26c8afbbfe1ebaca03c2bb082e69d0476bffe099',
    //   symbol: 'CELL',
    //   decimals: 18,
    //   name: 'Cellframe Token',
    //   logoURI: 'https://tokens.1inch.io/0x26c8afbbfe1ebaca03c2bb082e69d0476bffe099.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5b09a0371c1da44a8e24d36bf5deb1141a84d875',
    //   symbol: 'MAD',
    //   decimals: 18,
    //   name: 'MADToken',
    //   logoURI: 'https://tokens.1inch.io/0x5b09a0371c1da44a8e24d36bf5deb1141a84d875.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc834fa996fa3bec7aad3693af486ae53d8aa8b50',
    //   symbol: 'CONV',
    //   decimals: 18,
    //   name: 'Convergence',
    //   logoURI: 'https://tokens.1inch.io/0xc834fa996fa3bec7aad3693af486ae53d8aa8b50.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x106538cc16f938776c7c180186975bca23875287',
    //   symbol: 'BASv2',
    //   decimals: 18,
    //   name: 'BASv2',
    //   logoURI: 'https://tokens.1inch.io/0x106538cc16f938776c7c180186975bca23875287.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3505f494c3f0fed0b594e01fa41dd3967645ca39',
    //   symbol: 'SWM',
    //   decimals: 18,
    //   name: 'SWARM',
    //   logoURI: 'https://tokens.1inch.io/0x3505f494c3f0fed0b594e01fa41dd3967645ca39.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfbbe9b1142c699512545f47937ee6fae0e4b0aa9',
    //   symbol: 'EDDA',
    //   decimals: 18,
    //   name: 'EDDA',
    //   logoURI: 'https://tokens.1inch.io/0xfbbe9b1142c699512545f47937ee6fae0e4b0aa9.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x29cbd0510eec0327992cd6006e63f9fa8e7f33b7',
    //   symbol: 'TIDAL',
    //   decimals: 18,
    //   name: 'Tidal Token',
    //   logoURI: 'https://tokens.1inch.io/0x29cbd0510eec0327992cd6006e63f9fa8e7f33b7.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb78b3320493a4efaa1028130c5ba26f0b6085ef8',
    //   symbol: 'DRC_2',
    //   decimals: 18,
    //   name: 'Dracula Token',
    //   logoURI: 'https://tokens.1inch.io/0xb78b3320493a4efaa1028130c5ba26f0b6085ef8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x55296f69f40ea6d20e478533c15a6b08b654e758',
    //   symbol: 'XYO',
    //   decimals: 18,
    //   name: 'XY Oracle',
    //   logoURI: 'https://tokens.1inch.io/0x55296f69f40ea6d20e478533c15a6b08b654e758.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0f71b8de197a1c84d31de0f1fa7926c365f052b3',
    //   symbol: 'ARCONA',
    //   decimals: 18,
    //   name: 'Arcona Distribution Contract',
    //   logoURI: 'https://tokens.1inch.io/0x0f71b8de197a1c84d31de0f1fa7926c365f052b3.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf3ae5d769e153ef72b4e3591ac004e89f48107a1',
    //   symbol: 'DPR',
    //   decimals: 18,
    //   name: 'Deeper Network',
    //   logoURI: 'https://tokens.1inch.io/0xf3ae5d769e153ef72b4e3591ac004e89f48107a1.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5f98805a4e8be255a32880fdec7f6728c6568ba0',
    //   symbol: 'LUSD',
    //   decimals: 18,
    //   name: 'LUSD Stablecoin',
    //   logoURI: 'https://tokens.1inch.io/0x5f98805a4e8be255a32880fdec7f6728c6568ba0.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe0b9a2c3e9f40cf74b2c7f591b2b0cca055c3112',
    //   symbol: 'GS',
    //   decimals: 18,
    //   name: 'Gen Shards',
    //   logoURI: 'https://tokens.1inch.io/0xe0b9a2c3e9f40cf74b2c7f591b2b0cca055c3112.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x88acdd2a6425c3faae4bc9650fd7e27e0bebb7ab',
    //   symbol: 'MIST',
    //   decimals: 18,
    //   name: 'Alchemist',
    //   logoURI: 'https://tokens.1inch.io/0x88acdd2a6425c3faae4bc9650fd7e27e0bebb7ab.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3d6f0dea3ac3c607b3998e6ce14b6350721752d9',
    //   symbol: 'CARDS',
    //   decimals: 18,
    //   name: 'CARD.STARTER',
    //   logoURI: 'https://tokens.1inch.io/0x3d6f0dea3ac3c607b3998e6ce14b6350721752d9.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x33840024177a7daca3468912363bed8b425015c5',
    //   symbol: 'EBOX',
    //   decimals: 18,
    //   name: 'ethbox Token',
    //   logoURI: 'https://tokens.1inch.io/0x33840024177a7daca3468912363bed8b425015c5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xec213f83defb583af3a000b1c0ada660b1902a0f',
    //   symbol: 'PRE',
    //   decimals: 18,
    //   name: 'Presearch',
    //   logoURI: 'https://tokens.1inch.io/0xec213f83defb583af3a000b1c0ada660b1902a0f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf418588522d5dd018b425e472991e52ebbeeeeee',
    //   symbol: 'PUSH',
    //   decimals: 18,
    //   name: 'Ethereum Push Notification Service',
    //   logoURI: 'https://tokens.1inch.io/0xf418588522d5dd018b425e472991e52ebbeeeeee.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc477d038d5420c6a9e0b031712f61c5120090de9',
    //   symbol: 'BOSON',
    //   decimals: 18,
    //   name: 'Boson Token',
    //   logoURI: 'https://tokens.1inch.io/0xc477d038d5420c6a9e0b031712f61c5120090de9.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdd1ad9a21ce722c151a836373babe42c868ce9a4',
    //   symbol: 'UBI',
    //   decimals: 18,
    //   name: 'Universal Basic Income',
    //   logoURI: 'https://tokens.1inch.io/0xdd1ad9a21ce722c151a836373babe42c868ce9a4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x53c8395465a84955c95159814461466053dedede',
    //   symbol: 'DG',
    //   decimals: 18,
    //   name: 'DeGate Token',
    //   logoURI: 'https://tokens.1inch.io/0x53c8395465a84955c95159814461466053dedede.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x16c52ceece2ed57dad87319d91b5e3637d50afa4',
    //   symbol: 'TCAP',
    //   decimals: 18,
    //   name: 'TCAP Token',
    //   logoURI: 'https://tokens.1inch.io/0x16c52ceece2ed57dad87319d91b5e3637d50afa4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x44564d0bd94343f72e3c8a0d22308b7fa71db0bb',
    //   symbol: 'BASK',
    //   decimals: 18,
    //   name: 'BasketDAO Gov',
    //   logoURI: 'https://tokens.1inch.io/0x44564d0bd94343f72e3c8a0d22308b7fa71db0bb.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5cf04716ba20127f1e2297addcf4b5035000c9eb',
    //   symbol: 'NKN',
    //   decimals: 18,
    //   name: 'NKN',
    //   logoURI: 'https://tokens.1inch.io/0x5cf04716ba20127f1e2297addcf4b5035000c9eb.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4730fb1463a6f1f44aeb45f6c5c422427f37f4d0',
    //   symbol: 'FOUR',
    //   decimals: 18,
    //   name: 'The 4th Pillar Token',
    //   logoURI: 'https://tokens.1inch.io/0x4730fb1463a6f1f44aeb45f6c5c422427f37f4d0.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x77fba179c79de5b7653f68b5039af940ada60ce0',
    //   symbol: 'FORTH',
    //   decimals: 18,
    //   name: 'Ampleforth Governance',
    //   logoURI: 'https://tokens.1inch.io/0x77fba179c79de5b7653f68b5039af940ada60ce0.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe796d6ca1ceb1b022ece5296226bf784110031cd',
    //   symbol: 'BLES',
    //   decimals: 18,
    //   name: 'Blind Boxes Token',
    //   logoURI: 'https://tokens.1inch.io/0xe796d6ca1ceb1b022ece5296226bf784110031cd.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x48c3399719b582dd63eb5aadf12a40b4c3f52fa2',
    //   symbol: 'SWISE',
    //   decimals: 18,
    //   name: 'StakeWise',
    //   logoURI: 'https://tokens.1inch.io/0x48c3399719b582dd63eb5aadf12a40b4c3f52fa2.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202',
    //   symbol: 'KNC',
    //   decimals: 18,
    //   name: 'Kyber Network Crystal v2',
    //   logoURI: 'https://tokens.1inch.io/0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xff56cc6b1e6ded347aa0b7676c85ab0b3d08b0fa',
    //   symbol: 'ORBS',
    //   decimals: 18,
    //   name: 'Orbs',
    //   logoURI: 'https://tokens.1inch.io/0xff56cc6b1e6ded347aa0b7676c85ab0b3d08b0fa.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa02120696c7b8fe16c09c749e4598819b2b0e915',
    //   symbol: 'WXT',
    //   decimals: 18,
    //   name: 'Wirex Token',
    //   logoURI: 'https://tokens.1inch.io/0xa02120696c7b8fe16c09c749e4598819b2b0e915.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2f109021afe75b949429fe30523ee7c0d5b27207',
    //   symbol: 'OCC',
    //   decimals: 18,
    //   name: 'OCC',
    //   logoURI: 'https://tokens.1inch.io/0x2f109021afe75b949429fe30523ee7c0d5b27207.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x767fe9edc9e0df98e07454847909b5e959d7ca0e',
    //   symbol: 'ILV',
    //   decimals: 18,
    //   name: 'Illuvium',
    //   logoURI: 'https://tokens.1inch.io/0x767fe9edc9e0df98e07454847909b5e959d7ca0e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5218e472cfcfe0b64a064f055b43b4cdc9efd3a6',
    //   symbol: 'eRSDL',
    //   decimals: 18,
    //   name: 'UnFederalReserveToken',
    //   logoURI: 'https://tokens.1inch.io/0x5218e472cfcfe0b64a064f055b43b4cdc9efd3a6.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf16e81dce15b08f326220742020379b855b87df9',
    //   symbol: 'ICE',
    //   decimals: 18,
    //   name: 'IceToken',
    //   logoURI: 'https://tokens.1inch.io/0xf16e81dce15b08f326220742020379b855b87df9.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe4cfe9eaa8cdb0942a80b7bc68fd8ab0f6d44903',
    //   symbol: 'XEND',
    //   decimals: 18,
    //   name: 'XEND',
    //   logoURI: 'https://tokens.1inch.io/0xe4cfe9eaa8cdb0942a80b7bc68fd8ab0f6d44903.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37',
    //   symbol: 'MARSH',
    //   decimals: 18,
    //   name: 'UnmarshalToken',
    //   logoURI: 'https://tokens.1inch.io/0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3d658390460295fb963f54dc0899cfb1c30776df',
    //   symbol: 'Coval',
    //   decimals: 8,
    //   name: 'CircuitsOfValue',
    //   logoURI: 'https://tokens.1inch.io/0x3d658390460295fb963f54dc0899cfb1c30776df.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429',
    //   symbol: 'GLM',
    //   decimals: 18,
    //   name: 'Golem Network Token',
    //   logoURI: 'https://tokens.1inch.io/0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x467bccd9d29f223bce8043b84e8c8b282827790f',
    //   symbol: 'TEL',
    //   decimals: 2,
    //   name: 'Telcoin',
    //   logoURI: 'https://tokens.1inch.io/0x467bccd9d29f223bce8043b84e8c8b282827790f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x90de74265a416e1393a450752175aed98fe11517',
    //   symbol: 'UDT',
    //   decimals: 18,
    //   name: 'Unlock Discount Token',
    //   logoURI: 'https://tokens.1inch.io/0x90de74265a416e1393a450752175aed98fe11517.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x808507121b80c02388fad14726482e061b8da827',
    //   symbol: 'PENDLE',
    //   decimals: 18,
    //   name: 'Pendle',
    //   logoURI: 'https://tokens.1inch.io/0x808507121b80c02388fad14726482e061b8da827.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7a2bc711e19ba6aff6ce8246c546e8c4b4944dfd',
    //   symbol: 'WAXE',
    //   decimals: 8,
    //   name: 'WAX Economic Token',
    //   logoURI: 'https://tokens.1inch.io/0x7a2bc711e19ba6aff6ce8246c546e8c4b4944dfd.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x15b543e986b8c34074dfc9901136d9355a537e7e',
    //   symbol: 'STC',
    //   decimals: 18,
    //   name: 'Student Coin',
    //   logoURI: 'https://tokens.1inch.io/0x15b543e986b8c34074dfc9901136d9355a537e7e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4',
    //   symbol: 'ROUTE',
    //   decimals: 18,
    //   name: 'Route',
    //   logoURI: 'https://tokens.1inch.io/0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7c8155909cd385f120a56ef90728dd50f9ccbe52',
    //   symbol: 'NII',
    //   decimals: 15,
    //   name: 'Nahmii',
    //   logoURI: 'https://tokens.1inch.io/0x7c8155909cd385f120a56ef90728dd50f9ccbe52.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1614f18fc94f47967a3fbe5ffcd46d4e7da3d787',
    //   symbol: 'PAID',
    //   decimals: 18,
    //   name: 'PAID Network',
    //   logoURI: 'https://tokens.1inch.io/0x1614f18fc94f47967a3fbe5ffcd46d4e7da3d787.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x182f4c4c97cd1c24e1df8fc4c053e5c47bf53bef',
    //   symbol: 'TANGO',
    //   decimals: 18,
    //   name: 'keyTango Token',
    //   logoURI: 'https://tokens.1inch.io/0x182f4c4c97cd1c24e1df8fc4c053e5c47bf53bef.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7bef710a5759d197ec0bf621c3df802c2d60d848',
    //   symbol: 'SHOPX',
    //   decimals: 18,
    //   name: 'SPLYT SHOPX',
    //   logoURI: 'https://tokens.1inch.io/0x7bef710a5759d197ec0bf621c3df802c2d60d848.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x358aa737e033f34df7c54306960a38d09aabd523',
    //   symbol: 'ARES',
    //   decimals: 18,
    //   name: 'Ares Protocol',
    //   logoURI: 'https://tokens.1inch.io/0x358aa737e033f34df7c54306960a38d09aabd523.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x321c2fe4446c7c963dc41dd58879af648838f98d',
    //   symbol: 'CTX',
    //   decimals: 18,
    //   name: 'Cryptex',
    //   logoURI: 'https://tokens.1inch.io/0x321c2fe4446c7c963dc41dd58879af648838f98d.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2d94aa3e47d9d5024503ca8491fce9a2fb4da198',
    //   symbol: 'BANK',
    //   decimals: 18,
    //   name: 'Bankless Token',
    //   logoURI: 'https://tokens.1inch.io/0x2d94aa3e47d9d5024503ca8491fce9a2fb4da198.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x841fb148863454a3b3570f515414759be9091465',
    //   symbol: 'SHIH',
    //   decimals: 18,
    //   name: 'Shih Tzu',
    //   logoURI: 'https://tokens.1inch.io/0x841fb148863454a3b3570f515414759be9091465.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa2b4c0af19cc16a6cfacce81f192b024d625817d',
    //   symbol: 'KISHU',
    //   decimals: 9,
    //   name: 'Kishu Inu',
    //   logoURI: 'https://tokens.1inch.io/0xa2b4c0af19cc16a6cfacce81f192b024d625817d_1.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xee9801669c6138e84bd50deb500827b776777d28',
    //   symbol: 'O3',
    //   decimals: 18,
    //   name: 'O3 Swap Token',
    //   logoURI: 'https://tokens.1inch.io/0xee9801669c6138e84bd50deb500827b776777d28.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa4cf2afd3b165975afffbf7e487cdd40c894ab6b',
    //   symbol: 'SHIBAKEN',
    //   decimals: 0,
    //   name: 'ShibaKen.Finance',
    //   logoURI: 'https://tokens.1inch.io/0xa4cf2afd3b165975afffbf7e487cdd40c894ab6b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb1191f691a355b43542bea9b8847bc73e7abb137',
    //   symbol: 'KIRO',
    //   decimals: 18,
    //   name: 'Kirobo',
    //   logoURI: 'https://tokens.1inch.io/0xb1191f691a355b43542bea9b8847bc73e7abb137.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b',
    //   symbol: 'CVX',
    //   decimals: 18,
    //   name: 'Convex Token',
    //   logoURI: 'https://tokens.1inch.io/0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa3c4dc4a9ce2a6b40b57f25f8b50decc2c64dec2',
    //   symbol: 'SNFT',
    //   decimals: 18,
    //   name: 'SeedSwap Token',
    //   logoURI: 'https://tokens.1inch.io/0xa3c4dc4a9ce2a6b40b57f25f8b50decc2c64dec2.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xde30da39c46104798bb5aa3fe8b9e0e1f348163f',
    //   symbol: 'GTC',
    //   decimals: 18,
    //   name: 'Gitcoin',
    //   logoURI: 'https://tokens.1inch.io/0xde30da39c46104798bb5aa3fe8b9e0e1f348163f.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xbc6da0fe9ad5f3b0d58160288917aa56653660e9',
    //   symbol: 'alUSD',
    //   decimals: 18,
    //   name: 'Alchemix USD',
    //   logoURI: 'https://tokens.1inch.io/0xbc6da0fe9ad5f3b0d58160288917aa56653660e9.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7697b462a7c4ff5f8b55bdbc2f4076c2af9cf51a',
    //   symbol: 'SARCO',
    //   decimals: 18,
    //   name: 'Sarcophagus',
    //   logoURI: 'https://tokens.1inch.io/0x7697b462a7c4ff5f8b55bdbc2f4076c2af9cf51a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x677ddbd918637e5f2c79e164d402454de7da8619',
    //   symbol: 'VUSD',
    //   decimals: 18,
    //   name: 'VUSD',
    //   logoURI: 'https://tokens.1inch.io/0x677ddbd918637e5f2c79e164d402454de7da8619.png',
    //   eip2612: true,
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3',
    //   symbol: 'ELON',
    //   decimals: 18,
    //   name: 'Dogelon',
    //   logoURI: 'https://tokens.1inch.io/0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x06a01a4d579479dd5d884ebf61a31727a3d8d442',
    //   symbol: 'Skey',
    //   decimals: 8,
    //   name: 'SmartKey',
    //   logoURI: 'https://tokens.1inch.io/0x06a01a4d579479dd5d884ebf61a31727a3d8d442.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xeb58343b36c7528f23caae63a150240241310049',
    //   symbol: 'NBU',
    //   decimals: 18,
    //   name: 'Nimbus',
    //   logoURI: 'https://tokens.1inch.io/0xeb58343b36c7528f23caae63a150240241310049.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x27c70cd1946795b66be9d954418546998b546634',
    //   symbol: 'LEASH',
    //   decimals: 18,
    //   name: 'DOGE KILLER',
    //   logoURI: 'https://tokens.1inch.io/0x27c70cd1946795b66be9d954418546998b546634.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0abdace70d3790235af448c88547603b945604ea',
    //   symbol: 'DNT',
    //   decimals: 18,
    //   name: 'district0x Network Token',
    //   logoURI: 'https://tokens.1inch.io/0x0abdace70d3790235af448c88547603b945604ea.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9695e0114e12c0d3a3636fab5a18e6b737529023',
    //   symbol: 'DFYN',
    //   decimals: 18,
    //   name: 'DFYN Token',
    //   logoURI: 'https://tokens.1inch.io/0x9695e0114e12c0d3a3636fab5a18e6b737529023.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x72e364f2abdc788b7e918bc238b21f109cd634d7',
    //   symbol: 'MVI',
    //   decimals: 18,
    //   name: 'Metaverse Index',
    //   logoURI: 'https://tokens.1inch.io/0x72e364f2abdc788b7e918bc238b21f109cd634d7.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3301ee63fb29f863f2333bd4466acb46cd8323e6',
    //   symbol: 'AKITA',
    //   decimals: 18,
    //   name: 'Akita Inu',
    //   logoURI: 'https://tokens.1inch.io/0x3301ee63fb29f863f2333bd4466acb46cd8323e6.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d',
    //   symbol: 'LQTY',
    //   decimals: 18,
    //   name: 'LQTY',
    //   logoURI: 'https://tokens.1inch.io/0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x87de305311d5788e8da38d19bb427645b09cb4e5',
    //   symbol: 'VRX',
    //   decimals: 18,
    //   name: 'Verox',
    //   logoURI: 'https://tokens.1inch.io/0x87de305311d5788e8da38d19bb427645b09cb4e5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf65b5c5104c4fafd4b709d9d60a185eae063276c',
    //   symbol: 'TRU_Truebit',
    //   decimals: 18,
    //   name: 'Truebit',
    //   logoURI: 'https://tokens.1inch.io/0xf65b5c5104c4fafd4b709d9d60a185eae063276c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9be89d2a4cd102d8fecc6bf9da793be995c22541',
    //   symbol: 'BBTC',
    //   decimals: 8,
    //   name: 'Binance Wrapped BTC',
    //   logoURI: 'https://tokens.1inch.io/0x9be89d2a4cd102d8fecc6bf9da793be995c22541.png',
    //   tags: ['PEG:BTC', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x944eee930933be5e23b690c8589021ec8619a301',
    //   symbol: 'MUNCH',
    //   decimals: 9,
    //   name: 'MUNCH Token',
    //   logoURI: 'https://tokens.1inch.io/0x944eee930933be5e23b690c8589021ec8619a301.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdc349913d53b446485e98b76800b6254f43df695',
    //   symbol: 'BEZOGE',
    //   decimals: 9,
    //   name: 'Bezoge Earth',
    //   logoURI: 'https://tokens.1inch.io/0xdc349913d53b446485e98b76800b6254f43df695.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1681bcb589b3cfcf0c0616b0ce9b19b240643dc1',
    //   symbol: 'ISLE',
    //   decimals: 9,
    //   name: 'Island',
    //   logoURI: 'https://tokens.1inch.io/0x1681bcb589b3cfcf0c0616b0ce9b19b240643dc1.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa150db9b1fa65b44799d4dd949d922c0a33ee606',
    //   symbol: 'DRC_1',
    //   decimals: 0,
    //   name: 'Digital Reserve Currency',
    //   logoURI: 'https://tokens.1inch.io/0xa150db9b1fa65b44799d4dd949d922c0a33ee606.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x15874d65e649880c2614e7a480cb7c9a55787ff6',
    //   symbol: 'eMax',
    //   decimals: 18,
    //   name: 'EthereumMax',
    //   logoURI: 'https://tokens.1inch.io/0x15874d65e649880c2614e7a480cb7c9a55787ff6.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6f40d4a6237c257fff2db00fa0510deeecd303eb',
    //   symbol: 'INST',
    //   decimals: 18,
    //   name: 'Instadapp',
    //   logoURI: 'https://tokens.1inch.io/0x6f40d4a6237c257fff2db00fa0510deeecd303eb.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x456d8f0d25a4e787ee60c401f8b963a465148f70',
    //   symbol: 'CAVA',
    //   decimals: 9,
    //   name: 'Cavapoo',
    //   logoURI: 'https://tokens.1inch.io/0x456d8f0d25a4e787ee60c401f8b963a465148f70.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8cb924583681cbfe487a62140a994a49f833c244',
    //   symbol: 'SWAPP',
    //   decimals: 18,
    //   name: 'Swapp Token',
    //   logoURI: 'https://tokens.1inch.io/0x8cb924583681cbfe487a62140a994a49f833c244.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x10633216e7e8281e33c86f02bf8e565a635d9770',
    //   symbol: 'DVI',
    //   decimals: 18,
    //   name: 'Dvision',
    //   logoURI: 'https://tokens.1inch.io/0x10633216e7e8281e33c86f02bf8e565a635d9770.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcadc0acd4b445166f12d2c07eac6e2544fbe2eef',
    //   symbol: 'CADC',
    //   decimals: 18,
    //   name: 'CAD Coin',
    //   logoURI: 'https://tokens.1inch.io/0xcadc0acd4b445166f12d2c07eac6e2544fbe2eef.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1321f1f1aa541a56c31682c57b80ecfccd9bb288',
    //   symbol: 'ARCX',
    //   decimals: 18,
    //   name: 'ARCx Governance Token',
    //   logoURI: 'https://tokens.1inch.io/0xed30dd7e50edf3581ad970efc5d9379ce2614adb.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8798249c2e607446efb7ad49ec89dd1865ff4272',
    //   symbol: 'xSUSHI',
    //   decimals: 18,
    //   name: 'SushiBar',
    //   logoURI: 'https://tokens.1inch.io/0x8798249c2e607446efb7ad49ec89dd1865ff4272.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa9b1eb5908cfc3cdf91f9b8b3a74108598009096',
    //   symbol: 'Auction',
    //   decimals: 18,
    //   name: 'Bounce Token',
    //   logoURI: 'https://tokens.1inch.io/0xa9b1eb5908cfc3cdf91f9b8b3a74108598009096.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5b7533812759b45c2b44c19e320ba2cd2681b542',
    //   symbol: 'AGIX',
    //   decimals: 8,
    //   name: 'SingularityNET Token',
    //   logoURI: 'https://tokens.1inch.io/0x5b7533812759b45c2b44c19e320ba2cd2681b542.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6595b8fd9c920c81500dca94e53cdc712513fb1f',
    //   symbol: 'OLY',
    //   decimals: 18,
    //   name: 'Olyseum',
    //   logoURI: 'https://tokens.1inch.io/0x6595b8fd9c920c81500dca94e53cdc712513fb1f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x474021845c4643113458ea4414bdb7fb74a01a77',
    //   symbol: 'UNO',
    //   decimals: 18,
    //   name: 'UnoRe',
    //   logoURI: 'https://tokens.1inch.io/0x474021845c4643113458ea4414bdb7fb74a01a77.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x33349b282065b0284d756f0577fb39c158f935e6',
    //   symbol: 'MPL',
    //   decimals: 18,
    //   name: 'Maple Token',
    //   logoURI: 'https://tokens.1inch.io/0x33349b282065b0284d756f0577fb39c158f935e6.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6243d8cea23066d098a15582d81a598b4e8391f4',
    //   symbol: 'FLX_1',
    //   decimals: 18,
    //   name: 'Flex Ungovernance Token',
    //   logoURI: 'https://tokens.1inch.io/0x6243d8cea23066d098a15582d81a598b4e8391f4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc53342fd7575f572b0ff4569e31941a5b821ac76',
    //   symbol: 'ETHV',
    //   decimals: 18,
    //   name: 'ETH Volatility Index',
    //   logoURI: 'https://tokens.1inch.io/0xc53342fd7575f572b0ff4569e31941a5b821ac76.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x69fa8e7f6bf1ca1fb0de61e1366f7412b827cc51',
    //   symbol: 'NRCH',
    //   decimals: 9,
    //   name: 'EnreachDAO',
    //   logoURI: 'https://tokens.1inch.io/0x69fa8e7f6bf1ca1fb0de61e1366f7412b827cc51.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfb7b4564402e5500db5bb6d63ae671302777c75a',
    //   symbol: 'DEXT',
    //   decimals: 18,
    //   name: 'DEXTools',
    //   logoURI: 'https://tokens.1inch.io/0x26ce25148832c04f3d7f26f32478a9fe55197166.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x853bb55c1f469902f088a629db8c8803a9be3857',
    //   symbol: 'one1INCH',
    //   decimals: 18,
    //   name: 'Stable 1INCH',
    //   logoURI: 'https://tokens.1inch.io/0x853bb55c1f469902f088a629db8c8803a9be3857.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdddddd4301a082e62e84e43f474f044423921918',
    //   symbol: 'DVF',
    //   decimals: 18,
    //   name: 'DeversiFi Token',
    //   logoURI: 'https://tokens.1inch.io/0xdddddd4301a082e62e84e43f474f044423921918.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6b4c7a5e3f0b99fcd83e9c089bddd6c7fce5c611',
    //   symbol: 'MM_2',
    //   decimals: 18,
    //   name: 'Million',
    //   logoURI: 'https://tokens.1inch.io/0x6b4c7a5e3f0b99fcd83e9c089bddd6c7fce5c611.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc581b735a1688071a1746c968e0798d642ede491',
    //   symbol: 'EURT',
    //   decimals: 6,
    //   name: 'Euro Tether',
    //   logoURI: 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
    //   tags: ['PEG:EUR', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x72e5390edb7727e3d4e3436451dadaff675dbcc0',
    //   symbol: 'HANU',
    //   decimals: 12,
    //   name: 'Hanu Yokia',
    //   logoURI: 'https://tokens.1inch.io/0x72e5390edb7727e3d4e3436451dadaff675dbcc0.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x76417e660df3e5c90c0361674c192da152a806e4',
    //   symbol: 'zUSD',
    //   decimals: 18,
    //   name: 'Zerogoki USD',
    //   logoURI: 'https://tokens.1inch.io/0x76417e660df3e5c90c0361674c192da152a806e4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8e6cd950ad6ba651f6dd608dc70e5886b1aa6b24',
    //   symbol: 'STARL',
    //   decimals: 18,
    //   name: 'StarLink',
    //   logoURI: 'https://tokens.1inch.io/0x8e6cd950ad6ba651f6dd608dc70e5886b1aa6b24.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1559fa1b8f28238fd5d76d9f434ad86fd20d1559',
    //   symbol: 'EDEN',
    //   decimals: 18,
    //   name: 'Eden',
    //   logoURI: 'https://tokens.1inch.io/0x1559fa1b8f28238fd5d76d9f434ad86fd20d1559.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x515d7e9d75e2b76db60f8a051cd890eba23286bc',
    //   symbol: 'GDAO',
    //   decimals: 18,
    //   name: 'Governor',
    //   logoURI: 'https://tokens.1inch.io/0x515d7e9d75e2b76db60f8a051cd890eba23286bc.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb26631c6dda06ad89b93c71400d25692de89c068',
    //   symbol: 'MINDS',
    //   decimals: 18,
    //   name: 'Minds',
    //   logoURI: 'https://tokens.1inch.io/0xb26631c6dda06ad89b93c71400d25692de89c068.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x06f3c323f0238c72bf35011071f2b5b7f43a054c',
    //   symbol: 'MASQ',
    //   decimals: 18,
    //   name: 'MASQ',
    //   logoURI: 'https://tokens.1inch.io/0x06f3c323f0238c72bf35011071f2b5b7f43a054c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x106552c11272420aad5d7e94f8acab9095a6c952',
    //   symbol: 'KEANU',
    //   decimals: 9,
    //   name: 'Keanu Inu',
    //   logoURI: 'https://tokens.1inch.io/0x106552c11272420aad5d7e94f8acab9095a6c952.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xddb3422497e61e13543bea06989c0789117555c5',
    //   symbol: 'COTI',
    //   decimals: 18,
    //   name: 'COTI Token',
    //   logoURI: 'https://tokens.1inch.io/0xddb3422497e61e13543bea06989c0789117555c5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x92d6c1e31e14520e676a687f0a93788b716beff5',
    //   symbol: 'DYDX',
    //   decimals: 18,
    //   name: 'dYdX',
    //   logoURI: 'https://assets.coingecko.com/coins/images/17500/large/hjnIm9bV.jpg?1628009360',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa4ef4b0b23c1fc81d3f9ecf93510e64f58a4a016',
    //   symbol: '1MIL',
    //   decimals: 18,
    //   name: '1MILNFT',
    //   logoURI: 'https://tokens.1inch.io/0xa4ef4b0b23c1fc81d3f9ecf93510e64f58a4a016.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x25e1474170c4c0aa64fa98123bdc8db49d7802fa',
    //   symbol: 'BID',
    //   decimals: 18,
    //   name: 'Bidao',
    //   logoURI: 'https://tokens.1inch.io/0x25e1474170c4c0aa64fa98123bdc8db49d7802fa.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x738865301a9b7dd80dc3666dd48cf034ec42bdda',
    //   symbol: 'AGRS',
    //   decimals: 8,
    //   name: 'Agoras Token',
    //   logoURI: 'https://tokens.1inch.io/0x738865301a9b7dd80dc3666dd48cf034ec42bdda.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0af55d5ff28a3269d69b98680fd034f115dd53ac',
    //   symbol: 'BSL',
    //   decimals: 8,
    //   name: 'BankSocial',
    //   logoURI: 'https://tokens.1inch.io/0x0af55d5ff28a3269d69b98680fd034f115dd53ac.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd85ad783cc94bd04196a13dc042a3054a9b52210',
    //   symbol: 'HAKA',
    //   decimals: 18,
    //   name: 'TribeOne',
    //   logoURI: 'https://tokens.1inch.io/0xd85ad783cc94bd04196a13dc042a3054a9b52210.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x62dc4817588d53a056cbbd18231d91ffccd34b2a',
    //   symbol: 'DHV',
    //   decimals: 18,
    //   name: 'DeHive.finance',
    //   logoURI: 'https://tokens.1inch.io/0x62dc4817588d53a056cbbd18231d91ffccd34b2a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x19042021329fddcfbea5f934fb5b2670c91f7d20',
    //   symbol: 'TMM',
    //   decimals: 6,
    //   name: 'Take My Muffin',
    //   logoURI: 'https://tokens.1inch.io/0x19042021329fddcfbea5f934fb5b2670c91f7d20.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x15b7c0c907e4c6b9adaaaabc300c08991d6cea05',
    //   symbol: 'GEL',
    //   decimals: 18,
    //   name: 'Gelato Network Token',
    //   logoURI: 'https://tokens.1inch.io/0x15b7c0c907e4c6b9adaaaabc300c08991d6cea05.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa64dfe8d86963151e6496bee513e366f6e42ed79',
    //   symbol: 'GOKU',
    //   decimals: 9,
    //   name: 'Goku Inu',
    //   logoURI: 'https://tokens.1inch.io/0xa64dfe8d86963151e6496bee513e366f6e42ed79.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3',
    //   symbol: 'MIM',
    //   decimals: 18,
    //   name: 'Magic Internet Money',
    //   logoURI: 'https://tokens.1inch.io/0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x090185f2135308bad17527004364ebcc2d37e5f6',
    //   symbol: 'SPELL',
    //   decimals: 18,
    //   name: 'Spell Token',
    //   logoURI: 'https://tokens.1inch.io/0x090185f2135308bad17527004364ebcc2d37e5f6.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xde5ed76e7c05ec5e4572cfc88d1acea165109e44',
    //   symbol: 'DEUS',
    //   decimals: 18,
    //   name: 'DEUS',
    //   logoURI: 'https://tokens.1inch.io/0xde5ed76e7c05ec5e4572cfc88d1acea165109e44.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc221b7e65ffc80de234bbb6667abdd46593d34f0',
    //   symbol: 'wCFG',
    //   decimals: 18,
    //   name: 'Wrapped Centrifuge',
    //   logoURI: 'https://tokens.1inch.io/0xc221b7e65ffc80de234bbb6667abdd46593d34f0.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0',
    //   symbol: 'wstETH',
    //   decimals: 18,
    //   name: 'Wrapped liquid staked Ether 2.0',
    //   logoURI: 'https://tokens.1inch.io/0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0.png',
    //   eip2612: true,
    //   tags: ['PEG:ETH', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf50a07e4ff052a14f3f608da8936d8ae0ed5be50',
    //   symbol: 'FLOKIPUP',
    //   decimals: 9,
    //   name: 'Floki Pup',
    //   logoURI: 'https://tokens.1inch.io/0xf50a07e4ff052a14f3f608da8936d8ae0ed5be50.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xaecc217a749c2405b5ebc9857a16d58bdc1c367f',
    //   symbol: 'PAWTH',
    //   decimals: 9,
    //   name: 'Pawthereum',
    //   logoURI: 'https://tokens.1inch.io/0xaecc217a749c2405b5ebc9857a16d58bdc1c367f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x949d48eca67b17269629c7194f4b727d4ef9e5d6',
    //   symbol: 'MC',
    //   decimals: 18,
    //   name: 'Merit Circle',
    //   logoURI: 'https://tokens.1inch.io/0x949d48eca67b17269629c7194f4b727d4ef9e5d6.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6',
    //   symbol: 'sBTC',
    //   decimals: 18,
    //   name: 'Synth sBTC',
    //   logoURI: 'https://tokens.1inch.io/0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6.png',
    //   tags: ['PEG:BTC', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb',
    //   symbol: 'sETH',
    //   decimals: 18,
    //   name: 'Synth sETH',
    //   logoURI: 'https://tokens.1inch.io/0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb.png',
    //   tags: ['PEG:ETH', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc50ef449171a51fbeafd7c562b064b6471c36caa',
    //   symbol: 'ZINU',
    //   decimals: 9,
    //   name: 'Zombie Inu',
    //   logoURI: 'https://tokens.1inch.io/0xc50ef449171a51fbeafd7c562b064b6471c36caa.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0c3685559af6f3d20c501b1076a8056a0a14426a',
    //   symbol: 'miniSAITAMA',
    //   decimals: 9,
    //   name: 'mini SAITAMA',
    //   logoURI: 'https://tokens.1inch.io/0x0c3685559af6f3d20c501b1076a8056a0a14426a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x24e89bdf2f65326b94e36978a7edeac63623dafa',
    //   symbol: 'TKING',
    //   decimals: 18,
    //   name: 'Tiger King',
    //   logoURI: 'https://tokens.1inch.io/0x24e89bdf2f65326b94e36978a7edeac63623dafa.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xaaaaaa20d9e0e2461697782ef11675f668207961',
    //   symbol: 'AURORA',
    //   decimals: 18,
    //   name: 'Aurora',
    //   logoURI: 'https://tokens.1inch.io/0xaaaaaa20d9e0e2461697782ef11675f668207961.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xed0889f7e1c7c7267407222be277e1f1ef4d4892',
    //   symbol: 'MEL',
    //   decimals: 18,
    //   name: 'Melalie',
    //   logoURI: 'https://tokens.1inch.io/0xed0889f7e1c7c7267407222be277e1f1ef4d4892.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc18360217d8f7ab5e7c516566761ea12ce7f9d72',
    //   symbol: 'ENS',
    //   decimals: 18,
    //   name: 'Ethereum Name Service',
    //   logoURI: 'https://tokens.1inch.io/0xc18360217d8f7ab5e7c516566761ea12ce7f9d72.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfb40e79e56cc7d406707b66c4fd175e07eb2ae3c',
    //   symbol: 'ROTTS',
    //   decimals: 9,
    //   name: 'ROTTSCHILD.com',
    //   logoURI: 'https://tokens.1inch.io/0xfb40e79e56cc7d406707b66c4fd175e07eb2ae3c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x28c5805b64d163588a909012a628b5a03c1041f9',
    //   symbol: 'CHOPPER',
    //   decimals: 9,
    //   name: 'CHOPPER INU',
    //   logoURI: 'https://tokens.1inch.io/0x28c5805b64d163588a909012a628b5a03c1041f9.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x37fe0f067fa808ffbdd12891c0858532cfe7361d',
    //   symbol: 'CIV',
    //   decimals: 18,
    //   name: 'Civilization',
    //   logoURI: 'https://tokens.1inch.io/0x37fe0f067fa808ffbdd12891c0858532cfe7361d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf32aa187d5bc16a2c02a6afb7df1459d0d107574',
    //   symbol: 'Inu',
    //   decimals: 18,
    //   name: 'Hachiko Inu',
    //   logoURI: 'https://tokens.1inch.io/0xf32aa187d5bc16a2c02a6afb7df1459d0d107574.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc08512927d12348f6620a698105e1baac6ecd911',
    //   symbol: 'GYEN',
    //   decimals: 6,
    //   name: 'GMO JPY',
    //   logoURI: 'https://tokens.1inch.io/0xc08512927d12348f6620a698105e1baac6ecd911.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcafe001067cdef266afb7eb5a286dcfd277f3de5',
    //   symbol: 'PSP',
    //   decimals: 18,
    //   name: 'ParaSwap',
    //   logoURI: 'https://tokens.1inch.io/0xcafe001067cdef266afb7eb5a286dcfd277f3de5.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xbb1ee07d6c7baeb702949904080eb61f5d5e7732',
    //   symbol: 'DINU',
    //   decimals: 18,
    //   name: 'Dogey-Inu',
    //   logoURI: 'https://tokens.1inch.io/0xbb1ee07d6c7baeb702949904080eb61f5d5e7732.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x582d872a1b094fc48f5de31d3b73f2d9be47def1',
    //   symbol: 'TONCOIN',
    //   decimals: 9,
    //   name: 'Wrapped TON Coin',
    //   logoURI: 'https://tokens.1inch.io/0x582d872a1b094fc48f5de31d3b73f2d9be47def1.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe87e15b9c7d989474cb6d8c56b3db4efad5b21e8',
    //   symbol: 'HOKK',
    //   decimals: 18,
    //   name: 'Hokkaido Inu',
    //   logoURI: 'https://tokens.1inch.io/0xe87e15b9c7d989474cb6d8c56b3db4efad5b21e8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4104b135dbc9609fc1a9490e61369036497660c8',
    //   symbol: 'APW',
    //   decimals: 18,
    //   name: 'APWine Token',
    //   logoURI: 'https://tokens.1inch.io/0x4104b135dbc9609fc1a9490e61369036497660c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xae78736cd615f374d3085123a210448e74fc6393',
    //   symbol: 'rETH',
    //   decimals: 18,
    //   name: 'Rocket Pool ETH',
    //   logoURI: 'https://tokens.1inch.io/0xae78736cd615f374d3085123a210448e74fc6393.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1fbd3df007eb8a7477a1eab2c63483dcc24effd6',
    //   symbol: 'SCA',
    //   decimals: 18,
    //   name: 'ScaleSwapToken',
    //   logoURI: 'https://tokens.1inch.io/0x1fbd3df007eb8a7477a1eab2c63483dcc24effd6.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb1a88c33091490218965787919fcc9862c1798ee',
    //   symbol: 'SHIBLI',
    //   decimals: 9,
    //   name: 'Studio Shibli',
    //   logoURI: 'https://tokens.1inch.io/0xb1a88c33091490218965787919fcc9862c1798ee.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcfeaead4947f0705a14ec42ac3d44129e1ef3ed5',
    //   symbol: 'NOTE',
    //   decimals: 8,
    //   name: 'Notional',
    //   logoURI: 'https://tokens.1inch.io/0xcfeaead4947f0705a14ec42ac3d44129e1ef3ed5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x41a3dba3d677e573636ba691a70ff2d606c29666',
    //   symbol: 'BLANK',
    //   decimals: 18,
    //   name: 'GoBlank Token',
    //   logoURI: 'https://tokens.1inch.io/0xaec7e1f531bb09115103c53ba76829910ec48966.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8254e26e453eb5abd29b3c37ac9e8da32e5d3299',
    //   symbol: 'RBX',
    //   decimals: 18,
    //   name: 'RBX',
    //   logoURI: 'https://tokens.1inch.io/0x8254e26e453eb5abd29b3c37ac9e8da32e5d3299.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3b484b82567a09e2588a13d54d032153f0c0aee0',
    //   symbol: 'SOS',
    //   decimals: 18,
    //   name: 'SOS',
    //   logoURI: 'https://tokens.1inch.io/0x3b484b82567a09e2588a13d54d032153f0c0aee0.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x16cc8367055ae7e9157dbcb9d86fd6ce82522b31',
    //   symbol: 'VXL',
    //   decimals: 18,
    //   name: 'Voxel X Network',
    //   logoURI: 'https://tokens.1inch.io/0x16cc8367055ae7e9157dbcb9d86fd6ce82522b31.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x44709a920fccf795fbc57baa433cc3dd53c44dbe',
    //   symbol: 'RADAR',
    //   decimals: 18,
    //   name: 'DappRadar',
    //   logoURI: 'https://tokens.1inch.io/0x44709a920fccf795fbc57baa433cc3dd53c44dbe.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb6ed7644c69416d67b522e20bc294a9a9b405b31',
    //   symbol: '0xBTC',
    //   decimals: 8,
    //   name: '0xBitcoin Token',
    //   logoURI: 'https://tokens.1inch.io/0xb6ed7644c69416d67b522e20bc294a9a9b405b31.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xbc19712feb3a26080ebf6f2f7849b417fdd792ca',
    //   symbol: 'BORING',
    //   decimals: 18,
    //   name: 'BoringDAO',
    //   logoURI: 'https://tokens.1inch.io/0xbc19712feb3a26080ebf6f2f7849b417fdd792ca.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa68dd8cb83097765263adad881af6eed479c4a33',
    //   symbol: 'WTF',
    //   decimals: 18,
    //   name: 'fees.wtf',
    //   logoURI: 'https://tokens.1inch.io/0xa68dd8cb83097765263adad881af6eed479c4a33.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb56a1f3310578f23120182fb2e58c087efe6e147',
    //   symbol: 'ACYC',
    //   decimals: 18,
    //   name: 'AllCoinsYieldCapital',
    //   logoURI: 'https://tokens.1inch.io/0xb56a1f3310578f23120182fb2e58c087efe6e147.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x39fbbabf11738317a448031930706cd3e612e1b9',
    //   symbol: 'WXRP',
    //   decimals: 18,
    //   name: 'Wrapped XRP',
    //   logoURI: 'https://tokens.1inch.io/0x39fbbabf11738317a448031930706cd3e612e1b9_1.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcdf7028ceab81fa0c6971208e83fa7872994bee5',
    //   symbol: 'T',
    //   decimals: 18,
    //   name: 'Threshold Network Token',
    //   logoURI: 'https://tokens.1inch.io/0xcdf7028ceab81fa0c6971208e83fa7872994bee5.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcf0c122c6b73ff809c693db761e7baebe62b6a2e',
    //   symbol: 'FLOKI',
    //   decimals: 9,
    //   name: 'FLOKI',
    //   logoURI: 'https://tokens.1inch.io/0xcf0c122c6b73ff809c693db761e7baebe62b6a2e_1.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x73d7c860998ca3c01ce8c808f5577d94d545d1b4',
    //   symbol: 'IXS',
    //   decimals: 18,
    //   name: 'Ixs Token',
    //   logoURI: 'https://tokens.1inch.io/0x73d7c860998ca3c01ce8c808f5577d94d545d1b4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1a7e4e63778b4f12a199c062f3efdd288afcbce8',
    //   symbol: 'agEUR',
    //   decimals: 18,
    //   name: 'agEUR',
    //   logoURI: 'https://tokens.1inch.io/0x1a7e4e63778b4f12a199c062f3efdd288afcbce8.png',
    //   eip2612: true,
    //   tags: ['PEG:EUR', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf4d2888d29d722226fafa5d9b24f9164c092421e',
    //   symbol: 'LOOKS',
    //   decimals: 18,
    //   name: 'LooksRare Token',
    //   logoURI: 'https://tokens.1inch.io/0xf4d2888d29d722226fafa5d9b24f9164c092421e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x916c5de09cf63f6602d1e1793fb41f6437814a62',
    //   symbol: 'JACY',
    //   decimals: 9,
    //   name: 'JACY',
    //   logoURI: 'https://tokens.1inch.io/0x916c5de09cf63f6602d1e1793fb41f6437814a62.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x31429d1856ad1377a8a0079410b297e1a9e214c2',
    //   symbol: 'ANGLE',
    //   decimals: 18,
    //   name: 'ANGLE',
    //   logoURI: 'https://tokens.1inch.io/0x31429d1856ad1377a8a0079410b297e1a9e214c2.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x618679df9efcd19694bb1daa8d00718eacfa2883',
    //   symbol: 'XYZ',
    //   decimals: 18,
    //   name: 'XYZ Governance Token',
    //   logoURI: 'https://tokens.1inch.io/0x618679df9efcd19694bb1daa8d00718eacfa2883_1.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9e32b13ce7f2e80a01932b42553652e053d6ed8e',
    //   symbol: 'Metis',
    //   decimals: 18,
    //   name: 'Metis Token',
    //   logoURI: 'https://tokens.1inch.io/0x9e32b13ce7f2e80a01932b42553652e053d6ed8e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd38bb40815d2b0c2d2c866e0c72c5728ffc76dd9',
    //   symbol: 'SIS',
    //   decimals: 18,
    //   name: 'Symbiosis',
    //   logoURI: 'https://tokens.1inch.io/0xd38bb40815d2b0c2d2c866e0c72c5728ffc76dd9.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1e4ede388cbc9f4b5c79681b7f94d36a11abebc9',
    //   symbol: 'X2Y2',
    //   decimals: 18,
    //   name: 'X2Y2Token',
    //   logoURI: 'https://tokens.1inch.io/0x1e4ede388cbc9f4b5c79681b7f94d36a11abebc9.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd5d86fc8d5c0ea1ac1ac5dfab6e529c9967a45e9',
    //   symbol: 'WRLD',
    //   decimals: 18,
    //   name: 'NFT Worlds',
    //   logoURI: 'https://tokens.1inch.io/0xd5d86fc8d5c0ea1ac1ac5dfab6e529c9967a45e9.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2602278ee1882889b946eb11dc0e810075650983',
    //   symbol: 'VADER',
    //   decimals: 18,
    //   name: 'Vader',
    //   logoURI: 'https://tokens.1inch.io/0x2602278ee1882889b946eb11dc0e810075650983.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x249e38ea4102d0cf8264d3701f1a0e39c4f2dc3b',
    //   symbol: 'UFO',
    //   decimals: 18,
    //   name: 'THE TRUTH',
    //   logoURI: 'https://tokens.1inch.io/0x249e38ea4102d0cf8264d3701f1a0e39c4f2dc3b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf57e7e7c23978c3caec3c3548e3d615c346e79ff',
    //   symbol: 'IMX',
    //   decimals: 18,
    //   name: 'Immutable X',
    //   logoURI: 'https://tokens.1inch.io/0xf57e7e7c23978c3caec3c3548e3d615c346e79ff.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2e9d63788249371f1dfc918a52f8d799f4a38c94',
    //   symbol: 'TOKE',
    //   decimals: 18,
    //   name: 'Tokemak',
    //   logoURI: 'https://tokens.1inch.io/0x2e9d63788249371f1dfc918a52f8d799f4a38c94.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdb5c3c46e28b53a39c255aa39a411dd64e5fed9c',
    //   symbol: 'NCR',
    //   decimals: 18,
    //   name: 'Neos Credits',
    //   logoURI: 'https://tokens.1inch.io/0xdb5c3c46e28b53a39c255aa39a411dd64e5fed9c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0f2d719407fdbeff09d87557abb7232601fd9f29',
    //   symbol: 'SYN',
    //   decimals: 18,
    //   name: 'Synapse',
    //   logoURI: 'https://tokens.1inch.io/0x0f2d719407fdbeff09d87557abb7232601fd9f29.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5c147e74d63b1d31aa3fd78eb229b65161983b2b',
    //   symbol: 'WFLOW',
    //   decimals: 18,
    //   name: 'Wrapped Flow',
    //   logoURI: 'https://tokens.1inch.io/0x5c147e74d63b1d31aa3fd78eb229b65161983b2b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x509a38b7a1cc0dcd83aa9d06214663d9ec7c7f4a',
    //   symbol: 'BST',
    //   decimals: 18,
    //   name: 'BlocksquareToken',
    //   logoURI: 'https://tokens.1inch.io/0x509a38b7a1cc0dcd83aa9d06214663d9ec7c7f4a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x44017598f2af1bd733f9d87b5017b4e7c1b28dde',
    //   symbol: 'stkATOM',
    //   decimals: 6,
    //   name: 'pSTAKE Staked ATOM',
    //   logoURI: 'https://tokens.1inch.io/0x44017598f2af1bd733f9d87b5017b4e7c1b28dde.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa5f2211b9b8170f694421f2046281775e8468044',
    //   symbol: 'THOR',
    //   decimals: 18,
    //   name: 'THORSwap Token',
    //   logoURI: 'https://tokens.1inch.io/0xa5f2211b9b8170f694421f2046281775e8468044.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4c2e59d098df7b6cbae0848d66de2f8a4889b9c3',
    //   symbol: 'FODL',
    //   decimals: 18,
    //   name: 'Fodl',
    //   logoURI: 'https://tokens.1inch.io/0x4c2e59d098df7b6cbae0848d66de2f8a4889b9c3.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4d224452801aced8b2f0aebe155379bb5d594381',
    //   symbol: 'APE',
    //   decimals: 18,
    //   name: 'ApeCoin',
    //   logoURI: 'https://tokens.1inch.io/0x4d224452801aced8b2f0aebe155379bb5d594381.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xaf5191b0de278c7286d6c7cc6ab6bb8a73ba2cd6',
    //   symbol: 'STG',
    //   decimals: 18,
    //   name: 'StargateToken',
    //   logoURI: 'https://tokens.1inch.io/0xaf5191b0de278c7286d6c7cc6ab6bb8a73ba2cd6.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25',
    //   symbol: 'SLP',
    //   decimals: 0,
    //   name: 'Smooth Love Potion',
    //   logoURI: 'https://tokens.1inch.io/0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x64aa3364f17a4d01c6f1751fd97c2bd3d7e7f1d5',
    //   symbol: 'OHM',
    //   decimals: 9,
    //   name: 'Olympus',
    //   logoURI: 'https://tokens.1inch.io/0x64aa3364f17a4d01c6f1751fd97c2bd3d7e7f1d5.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdef1ca1fb7fbcdc777520aa7f396b4e015f497ab',
    //   symbol: 'COW',
    //   decimals: 18,
    //   name: 'CoW Protocol Token',
    //   logoURI: 'https://tokens.1inch.io/0xdef1ca1fb7fbcdc777520aa7f396b4e015f497ab.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3541a5c1b04adaba0b83f161747815cd7b1516bc',
    //   symbol: 'KNIGHT',
    //   decimals: 18,
    //   name: 'CitaDAO',
    //   logoURI: 'https://tokens.1inch.io/0x3541a5c1b04adaba0b83f161747815cd7b1516bc.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf0f9d895aca5c8678f706fb8216fa22957685a13',
    //   symbol: 'CULT',
    //   decimals: 18,
    //   name: 'Cult DAO',
    //   logoURI: 'https://tokens.1inch.io/0xf0f9d895aca5c8678f706fb8216fa22957685a13.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9506d37f70eb4c3d79c398d326c871abbf10521d',
    //   symbol: 'MLT',
    //   decimals: 18,
    //   name: 'Media Licensing Token',
    //   logoURI: 'https://tokens.1inch.io/0x9506d37f70eb4c3d79c398d326c871abbf10521d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4f640f2529ee0cf119a2881485845fa8e61a782a',
    //   symbol: 'ORE',
    //   decimals: 18,
    //   name: 'pTokens ORE',
    //   logoURI: 'https://tokens.1inch.io/0x4f640f2529ee0cf119a2881485845fa8e61a782a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7f280dac515121dcda3eac69eb4c13a52392cace',
    //   symbol: 'FNC',
    //   decimals: 18,
    //   name: 'Fancy Games',
    //   logoURI: 'https://tokens.1inch.io/0x7f280dac515121dcda3eac69eb4c13a52392cace.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7c07f7abe10ce8e33dc6c5ad68fe033085256a84',
    //   symbol: 'icETH',
    //   decimals: 18,
    //   name: 'Interest Compounding ETH Index',
    //   logoURI: 'https://tokens.1inch.io/0x7c07f7abe10ce8e33dc6c5ad68fe033085256a84.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xab2a7b5876d707e0126b3a75ef7781c77c8877ee',
    //   symbol: 'QUAD',
    //   decimals: 18,
    //   name: 'Quadency Token',
    //   logoURI: 'https://tokens.1inch.io/0xab2a7b5876d707e0126b3a75ef7781c77c8877ee.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x299698b4b44bd6d023981a7317798dee12860834',
    //   symbol: 'NFP',
    //   decimals: 9,
    //   name: 'New Frontier Presents',
    //   logoURI: 'https://tokens.1inch.io/0x299698b4b44bd6d023981a7317798dee12860834.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9fa69536d1cda4a04cfb50688294de75b505a9ae',
    //   symbol: 'DERC',
    //   decimals: 18,
    //   name: 'DeRace Token',
    //   logoURI: 'https://tokens.1inch.io/0x9fa69536d1cda4a04cfb50688294de75b505a9ae.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0ab87046fbb341d058f17cbc4c1133f25a20a52f',
    //   symbol: 'gOHM',
    //   decimals: 18,
    //   name: 'Governance OHM',
    //   logoURI: 'https://tokens.1inch.io/0x0ab87046fbb341d058f17cbc4c1133f25a20a52f.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3b9be07d622accaed78f479bc0edabfd6397e320',
    //   symbol: 'LSS',
    //   decimals: 18,
    //   name: 'Lossless Token',
    //   logoURI: 'https://tokens.1inch.io/0x3b9be07d622accaed78f479bc0edabfd6397e320.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa67e9f021b9d208f7e3365b2a155e3c55b27de71',
    //   symbol: 'KLEE',
    //   decimals: 9,
    //   name: 'KleeKai',
    //   logoURI: 'https://tokens.1inch.io/0xa67e9f021b9d208f7e3365b2a155e3c55b27de71.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8c543aed163909142695f2d2acd0d55791a9edb9',
    //   symbol: 'VLX',
    //   decimals: 18,
    //   name: 'VLX',
    //   logoURI: 'https://tokens.1inch.io/0x8c543aed163909142695f2d2acd0d55791a9edb9.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8f693ca8d21b157107184d29d398a8d082b38b76',
    //   symbol: 'DATA_1',
    //   decimals: 18,
    //   name: 'Streamr',
    //   logoURI: 'https://tokens.1inch.io/0x8f693ca8d21b157107184d29d398a8d082b38b76.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2ba8349123de45e931a8c8264c332e6e9cf593f9',
    //   symbol: 'BCMC',
    //   decimals: 18,
    //   name: 'Blockchain Monster Coin',
    //   logoURI: 'https://tokens.1inch.io/0x2ba8349123de45e931a8c8264c332e6e9cf593f9.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd9016a907dc0ecfa3ca425ab20b6b785b42f2373',
    //   symbol: 'GMEE',
    //   decimals: 18,
    //   name: 'GAMEE',
    //   logoURI: 'https://tokens.1inch.io/0xd9016a907dc0ecfa3ca425ab20b6b785b42f2373.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa693b19d2931d498c5b318df961919bb4aee87a5',
    //   symbol: 'UST_1',
    //   decimals: 6,
    //   name: 'UST (Wormhole)',
    //   logoURI: 'https://tokens.1inch.io/0xa693b19d2931d498c5b318df961919bb4aee87a5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf406f7a9046793267bc276908778b29563323996',
    //   symbol: 'VISION',
    //   decimals: 18,
    //   name: 'Vision Token',
    //   logoURI: 'https://tokens.1inch.io/0xf406f7a9046793267bc276908778b29563323996.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfc98e825a2264d890f9a1e68ed50e1526abccacd',
    //   symbol: 'MCO2',
    //   decimals: 18,
    //   name: 'Moss Carbon Credit',
    //   logoURI: 'https://tokens.1inch.io/0xfc98e825a2264d890f9a1e68ed50e1526abccacd.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x656c00e1bcd96f256f224ad9112ff426ef053733',
    //   symbol: 'EFI',
    //   decimals: 18,
    //   name: 'Efinity Token',
    //   logoURI: 'https://tokens.1inch.io/0x656c00e1bcd96f256f224ad9112ff426ef053733.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe7f58a92476056627f9fdb92286778abd83b285f',
    //   symbol: 'DWEB',
    //   decimals: 18,
    //   name: 'DecentraWeb',
    //   logoURI: 'https://tokens.1inch.io/0xe7f58a92476056627f9fdb92286778abd83b285f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7815bda662050d84718b988735218cffd32f75ea',
    //   symbol: 'YEL',
    //   decimals: 18,
    //   name: 'YEL Token',
    //   logoURI: 'https://tokens.1inch.io/0x7815bda662050d84718b988735218cffd32f75ea.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4cf89ca06ad997bc732dc876ed2a7f26a9e7f361',
    //   symbol: 'MYST',
    //   decimals: 18,
    //   name: 'Mysterium',
    //   logoURI: 'https://tokens.1inch.io/0x4cf89ca06ad997bc732dc876ed2a7f26a9e7f361.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x68037790a0229e9ce6eaa8a99ea92964106c4703',
    //   symbol: 'PAR',
    //   decimals: 18,
    //   name: 'PAR Stablecoin',
    //   logoURI: 'https://tokens.1inch.io/0x68037790a0229e9ce6eaa8a99ea92964106c4703.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x232fb065d9d24c34708eedbf03724f2e95abe768',
    //   symbol: 'SHEESHA',
    //   decimals: 18,
    //   name: 'Sheesha Finance',
    //   logoURI: 'https://tokens.1inch.io/0x232fb065d9d24c34708eedbf03724f2e95abe768.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0c10bf8fcb7bf5412187a595ab97a3609160b5c6',
    //   symbol: 'USDD',
    //   decimals: 18,
    //   name: 'Decentralized USD',
    //   logoURI: 'https://tokens.1inch.io/0x0c10bf8fcb7bf5412187a595ab97a3609160b5c6.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc',
    //   symbol: 'HOP',
    //   decimals: 18,
    //   name: 'Hop',
    //   logoURI: 'https://tokens.1inch.io/0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x632806bf5c8f062932dd121244c9fbe7becb8b48',
    //   symbol: 'PDI',
    //   decimals: 18,
    //   name: 'Phuture DeFi Index',
    //   logoURI: 'https://tokens.1inch.io/0x632806bf5c8f062932dd121244c9fbe7becb8b48.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6c3f90f043a72fa612cbac8115ee7e52bde6e490',
    //   symbol: '3Crv',
    //   decimals: 18,
    //   name: 'Curve.fi DAI/USDC/USDT',
    //   logoURI: 'https://tokens.1inch.io/0x6c3f90f043a72fa612cbac8115ee7e52bde6e490.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7659ce147d0e714454073a5dd7003544234b6aa0',
    //   symbol: 'XCAD',
    //   decimals: 18,
    //   name: 'XCAD Token',
    //   logoURI: 'https://tokens.1inch.io/0x7659ce147d0e714454073a5dd7003544234b6aa0.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xddd6a0ecc3c6f6c102e5ea3d8af7b801d1a77ac8',
    //   symbol: 'UNIX',
    //   decimals: 18,
    //   name: 'UniX Gaming',
    //   logoURI: 'https://tokens.1inch.io/0xddd6a0ecc3c6f6c102e5ea3d8af7b801d1a77ac8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x579cea1889991f68acc35ff5c3dd0621ff29b0c9',
    //   symbol: 'IQ',
    //   decimals: 18,
    //   name: 'Everipedia IQ',
    //   logoURI: 'https://tokens.1inch.io/0x579cea1889991f68acc35ff5c3dd0621ff29b0c9.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x48f07301e9e29c3c38a80ae8d9ae771f224f1054',
    //   symbol: 'XZAR',
    //   decimals: 18,
    //   name: 'South African Tether',
    //   logoURI: 'https://tokens.1inch.io/0x48f07301e9e29c3c38a80ae8d9ae771f224f1054.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa52bffad02b1fe3f86a543a4e81962d3b3bb01a7',
    //   symbol: 'DUCKER',
    //   decimals: 18,
    //   name: 'Duckereum',
    //   logoURI: 'https://tokens.1inch.io/0xa52bffad02b1fe3f86a543a4e81962d3b3bb01a7.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1abaea1f7c830bd89acc67ec4af516284b1bc33c',
    //   symbol: 'EURC',
    //   decimals: 6,
    //   name: 'Euro Coin',
    //   logoURI: 'https://tokens.1inch.io/0x1abaea1f7c830bd89acc67ec4af516284b1bc33c.png',
    //   eip2612: true,
    //   tags: ['PEG:EUR', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4b520c812e8430659fc9f12f6d0c39026c83588d',
    //   symbol: 'DG_2',
    //   decimals: 18,
    //   name: 'Decentral Games',
    //   logoURI: 'https://tokens.1inch.io/0x4b520c812e8430659fc9f12f6d0c39026c83588d.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcfcecfe2bd2fed07a9145222e8a7ad9cf1ccd22a',
    //   symbol: 'ADS',
    //   decimals: 11,
    //   name: 'Adshares',
    //   logoURI: 'https://tokens.1inch.io/0xcfcecfe2bd2fed07a9145222e8a7ad9cf1ccd22a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6fb3e0a217407efff7ca062d46c26e5d60a14d69',
    //   symbol: 'IOTX',
    //   decimals: 18,
    //   name: 'IoTeX Network',
    //   logoURI: 'https://tokens.1inch.io/0x6fb3e0a217407efff7ca062d46c26e5d60a14d69.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd0cd466b34a24fcb2f87676278af2005ca8a78c4',
    //   symbol: 'POP',
    //   decimals: 18,
    //   name: 'Popcorn',
    //   logoURI: 'https://tokens.1inch.io/0xd0cd466b34a24fcb2f87676278af2005ca8a78c4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2af1df3ab0ab157e1e2ad8f88a7d04fbea0c7dc6',
    //   symbol: 'BED',
    //   decimals: 18,
    //   name: 'Bankless BED Index',
    //   logoURI: 'https://tokens.1inch.io/0x2af1df3ab0ab157e1e2ad8f88a7d04fbea0c7dc6.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf063fe1ab7a291c5d06a86e14730b00bf24cb589',
    //   symbol: 'SALE',
    //   decimals: 18,
    //   name: 'DxSale.Network',
    //   logoURI: 'https://tokens.1inch.io/0xf063fe1ab7a291c5d06a86e14730b00bf24cb589.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb29130cbcc3f791f077eade0266168e808e5151e',
    //   symbol: 'a1INCH',
    //   decimals: 18,
    //   name: 'Aave interest bearing 1INCH',
    //   logoURI: 'https://tokens.1inch.io/0xb29130cbcc3f791f077eade0266168e808e5151e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xce3f08e664693ca792cace4af1364d5e220827b2',
    //   symbol: 'SAITAMA',
    //   decimals: 9,
    //   name: 'SAITAMA',
    //   logoURI: 'https://tokens.1inch.io/0xce3f08e664693ca792cace4af1364d5e220827b2.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd33526068d116ce69f19a9ee46f0bd304f21a51f',
    //   symbol: 'RPL',
    //   decimals: 18,
    //   name: 'Rocket Pool Protocol',
    //   logoURI: 'https://tokens.1inch.io/0xd33526068d116ce69f19a9ee46f0bd304f21a51f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6bea7cfef803d1e3d5f7c0103f7ded065644e197',
    //   symbol: 'GAMMA',
    //   decimals: 18,
    //   name: 'Gamma',
    //   logoURI: 'https://tokens.1inch.io/0x6bea7cfef803d1e3d5f7c0103f7ded065644e197.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7db5af2b9624e1b3b4bb69d6debd9ad1016a58ac',
    //   symbol: 'VOLT',
    //   decimals: 9,
    //   name: 'Volt Inu',
    //   logoURI: 'https://tokens.1inch.io/0x7db5af2b9624e1b3b4bb69d6debd9ad1016a58ac.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x111111517e4929d3dcbdfa7cce55d30d4b6bc4d6',
    //   symbol: 'ICHI',
    //   decimals: 18,
    //   name: 'ICHI',
    //   logoURI: 'https://tokens.1inch.io/0x111111517e4929d3dcbdfa7cce55d30d4b6bc4d6.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3aada3e213abf8529606924d8d1c55cbdc70bf74',
    //   symbol: 'XMON',
    //   decimals: 18,
    //   name: 'XMON',
    //   logoURI: 'https://tokens.1inch.io/0x3aada3e213abf8529606924d8d1c55cbdc70bf74.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb4b9dc1c77bdbb135ea907fd5a08094d98883a35',
    //   symbol: 'SWEAT',
    //   decimals: 18,
    //   name: 'SWEAT',
    //   logoURI: 'https://tokens.1inch.io/0xb4b9dc1c77bdbb135ea907fd5a08094d98883a35.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4fd51cb87ffefdf1711112b5bd8ab682e54988ea',
    //   symbol: 'WPT',
    //   decimals: 18,
    //   name: 'WPT Investing Corp',
    //   logoURI: 'https://tokens.1inch.io/0x4fd51cb87ffefdf1711112b5bd8ab682e54988ea.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x799ebfabe77a6e34311eeee9825190b9ece32824',
    //   symbol: 'BTRST',
    //   decimals: 18,
    //   name: 'BTRST',
    //   logoURI: 'https://tokens.1inch.io/0x799ebfabe77a6e34311eeee9825190b9ece32824.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd084944d3c05cd115c09d072b9f44ba3e0e45921',
    //   symbol: 'FOLD',
    //   decimals: 18,
    //   name: 'Manifold Finance',
    //   logoURI: 'https://tokens.1inch.io/0xd084944d3c05cd115c09d072b9f44ba3e0e45921.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b',
    //   symbol: 'BOB',
    //   decimals: 18,
    //   name: 'BOB',
    //   logoURI: 'https://tokens.1inch.io/0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4b13006980acb09645131b91d259eaa111eaf5ba',
    //   symbol: 'MYC',
    //   decimals: 18,
    //   name: 'Mycelium',
    //   logoURI: 'https://tokens.1inch.io/0x4b13006980acb09645131b91d259eaa111eaf5ba.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9c354503c38481a7a7a51629142963f98ecc12d0',
    //   symbol: 'OGV',
    //   decimals: 18,
    //   name: 'Origin Dollar Governance',
    //   logoURI: 'https://tokens.1inch.io/0x9c354503c38481a7a7a51629142963f98ecc12d0.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x865377367054516e17014ccded1e7d814edc9ce4',
    //   symbol: 'DOLA',
    //   decimals: 18,
    //   name: 'Dola USD Stablecoin',
    //   logoURI: 'https://tokens.1inch.io/0x865377367054516e17014ccded1e7d814edc9ce4.png',
    //   eip2612: true,
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3af33bef05c2dcb3c7288b77fe1c8d2aeba4d789',
    //   symbol: 'KROM',
    //   decimals: 18,
    //   name: 'Kromatika',
    //   logoURI: 'https://tokens.1inch.io/0x3af33bef05c2dcb3c7288b77fe1c8d2aeba4d789.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7420b4b9a0110cdc71fb720908340c03f9bc03ec',
    //   symbol: 'JASMY',
    //   decimals: 18,
    //   name: 'JasmyCoin',
    //   logoURI: 'https://tokens.1inch.io/0x7420b4b9a0110cdc71fb720908340c03f9bc03ec.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6dca182ac5e3f99985bc4ee0f726d6472ab1ec55',
    //   symbol: 'USHI',
    //   decimals: 18,
    //   name: 'Ushi',
    //   logoURI: 'https://tokens.1inch.io/0x6dca182ac5e3f99985bc4ee0f726d6472ab1ec55.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x52662717e448be36cb54588499d5a8328bd95292',
    //   symbol: 'TENSHI',
    //   decimals: 18,
    //   name: 'TENSHI v2',
    //   logoURI: 'https://tokens.1inch.io/0x52662717e448be36cb54588499d5a8328bd95292.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb3999f658c0391d94a37f7ff328f3fec942bcadc',
    //   symbol: 'HFT',
    //   decimals: 18,
    //   name: 'Hashflow',
    //   logoURI: 'https://tokens.1inch.io/0xb3999f658c0391d94a37f7ff328f3fec942bcadc.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x03be5c903c727ee2c8c4e9bc0acc860cca4715e2',
    //   symbol: 'CAPS',
    //   decimals: 18,
    //   name: 'Capsule Coin',
    //   logoURI: 'https://tokens.1inch.io/0x03be5c903c727ee2c8c4e9bc0acc860cca4715e2.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfe2e637202056d30016725477c5da089ab0a043a',
    //   symbol: 'sETH2',
    //   decimals: 18,
    //   name: 'StakeWise Staked ETH2',
    //   logoURI: 'https://tokens.1inch.io/0xfe2e637202056d30016725477c5da089ab0a043a.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfd09911130e6930bf87f2b0554c44f400bd80d3e',
    //   symbol: 'ETHIX',
    //   decimals: 18,
    //   name: 'Ethix',
    //   logoURI: 'https://tokens.1inch.io/0xfd09911130e6930bf87f2b0554c44f400bd80d3e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfc0d6cf33e38bce7ca7d89c0e292274031b7157a',
    //   symbol: 'NTVRK',
    //   decimals: 18,
    //   name: 'NETVRK',
    //   logoURI: 'https://tokens.1inch.io/0xfc0d6cf33e38bce7ca7d89c0e292274031b7157a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x72b886d09c117654ab7da13a14d603001de0b777',
    //   symbol: 'XDEFI',
    //   decimals: 18,
    //   name: 'XDEFI',
    //   logoURI: 'https://tokens.1inch.io/0x72b886d09c117654ab7da13a14d603001de0b777.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfb782396c9b20e564a64896181c7ac8d8979d5f4',
    //   symbol: 'DIVER',
    //   decimals: 18,
    //   name: 'DivergenceProtocol',
    //   logoURI: 'https://tokens.1inch.io/0xfb782396c9b20e564a64896181c7ac8d8979d5f4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfa14fa6958401314851a17d6c5360ca29f74b57b',
    //   symbol: 'SAITO',
    //   decimals: 18,
    //   name: 'SAITO',
    //   logoURI: 'https://tokens.1inch.io/0xfa14fa6958401314851a17d6c5360ca29f74b57b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf8e9f10c22840b613cda05a0c5fdb59a4d6cd7ef',
    //   symbol: 'DOE',
    //   decimals: 18,
    //   name: 'Dogs Of Elon',
    //   logoURI: 'https://tokens.1inch.io/0xf8e9f10c22840b613cda05a0c5fdb59a4d6cd7ef.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf87c4b9c0c1528147cac4e05b7ac349a9ab23a12',
    //   symbol: 'WMLK',
    //   decimals: 8,
    //   name: 'Wrapped MiL.k',
    //   logoURI: 'https://tokens.1inch.io/0xf87c4b9c0c1528147cac4e05b7ac349a9ab23a12.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf5cfbc74057c610c8ef151a439252680ac68c6dc',
    //   symbol: 'OCT',
    //   decimals: 18,
    //   name: 'Octopus Network Token',
    //   logoURI: 'https://tokens.1inch.io/0xf5cfbc74057c610c8ef151a439252680ac68c6dc.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf3b9569f82b18aef890de263b84189bd33ebe452',
    //   symbol: 'CAW',
    //   decimals: 18,
    //   name: 'A Hunters Dream',
    //   logoURI: 'https://tokens.1inch.io/0xf3b9569f82b18aef890de263b84189bd33ebe452.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1ea48b9965bb5086f3b468e50ed93888a661fc17',
    //   symbol: 'MON',
    //   decimals: 18,
    //   name: 'Moneta',
    //   logoURI: 'https://tokens.1inch.io/0x1ea48b9965bb5086f3b468e50ed93888a661fc17.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x045da4bfe02b320f4403674b3b7d121737727a36',
    //   symbol: 'DCHF',
    //   decimals: 18,
    //   name: 'Defi Franc',
    //   logoURI: 'https://tokens.1inch.io/0x045da4bfe02b320f4403674b3b7d121737727a36.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf203ca1769ca8e9e8fe1da9d147db68b6c919817',
    //   symbol: 'WNCG',
    //   decimals: 18,
    //   name: 'Wrapped NCG',
    //   logoURI: 'https://tokens.1inch.io/0xf203ca1769ca8e9e8fe1da9d147db68b6c919817.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcfeb09c3c5f0f78ad72166d55f9e6e9a60e96eec',
    //   symbol: 'VEMP',
    //   decimals: 18,
    //   name: 'vEmpire Gamer Token',
    //   logoURI: 'https://tokens.1inch.io/0xcfeb09c3c5f0f78ad72166d55f9e6e9a60e96eec.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xedf6568618a00c6f0908bf7758a16f76b6e04af9',
    //   symbol: 'ARIA20',
    //   decimals: 18,
    //   name: 'ARIANEE',
    //   logoURI: 'https://tokens.1inch.io/0xedf6568618a00c6f0908bf7758a16f76b6e04af9.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xeb953eda0dc65e3246f43dc8fa13f35623bdd5ed',
    //   symbol: 'RAINI',
    //   decimals: 18,
    //   name: 'Rainicorn',
    //   logoURI: 'https://tokens.1inch.io/0xeb953eda0dc65e3246f43dc8fa13f35623bdd5ed.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9393fdc77090f31c7db989390d43f454b1a6e7f3',
    //   symbol: 'DEC',
    //   decimals: 3,
    //   name: 'DarkEnergyCrystals',
    //   logoURI: 'https://tokens.1inch.io/0x9393fdc77090f31c7db989390d43f454b1a6e7f3.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe76c6c83af64e4c60245d8c7de953df673a7a33d',
    //   symbol: 'RAIL',
    //   decimals: 18,
    //   name: 'Rail',
    //   logoURI: 'https://tokens.1inch.io/0xe76c6c83af64e4c60245d8c7de953df673a7a33d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4740735aa98dc8aa232bd049f8f0210458e7fca3',
    //   symbol: 'RDT',
    //   decimals: 18,
    //   name: 'Ridotto',
    //   logoURI: 'https://tokens.1inch.io/0x4740735aa98dc8aa232bd049f8f0210458e7fca3.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd31a59c85ae9d8edefec411d448f90841571b89c',
    //   symbol: 'SOL',
    //   decimals: 9,
    //   name: 'Wrapped SOL',
    //   logoURI: 'https://tokens.1inch.io/0xd31a59c85ae9d8edefec411d448f90841571b89c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe0a189c975e4928222978a74517442239a0b86ff',
    //   symbol: 'KEYS',
    //   decimals: 9,
    //   name: 'Keys',
    //   logoURI: 'https://tokens.1inch.io/0xe0a189c975e4928222978a74517442239a0b86ff.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdf801468a808a32656d2ed2d2d80b72a129739f4',
    //   symbol: 'CUBE',
    //   decimals: 8,
    //   name: 'Somnium Space Cubes',
    //   logoURI: 'https://tokens.1inch.io/0xdf801468a808a32656d2ed2d2d80b72a129739f4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdc8af07a7861bedd104b8093ae3e9376fc8596d2',
    //   symbol: 'RVF',
    //   decimals: 18,
    //   name: 'Rocket Vault',
    //   logoURI: 'https://tokens.1inch.io/0xdc8af07a7861bedd104b8093ae3e9376fc8596d2.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5aa158404fed6b4730c13f49d3a7f820e14a636f',
    //   symbol: 'ULX',
    //   decimals: 18,
    //   name: 'Ultron',
    //   logoURI: 'https://tokens.1inch.io/0x5aa158404fed6b4730c13f49d3a7f820e14a636f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc6dddb5bc6e61e0841c54f3e723ae1f3a807260b',
    //   symbol: 'URUS',
    //   decimals: 18,
    //   name: 'Aurox Token',
    //   logoURI: 'https://tokens.1inch.io/0xc6dddb5bc6e61e0841c54f3e723ae1f3a807260b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x58fcaa970339a9b1f8c0a5b4f3fcd7af2ba3075e',
    //   symbol: 'POLAR',
    //   decimals: 18,
    //   name: 'PolarSync',
    //   logoURI: 'https://tokens.1inch.io/0x58fcaa970339a9b1f8c0a5b4f3fcd7af2ba3075e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xbe9895146f7af43049ca1c1ae358b0541ea49704',
    //   symbol: 'cbETH',
    //   decimals: 18,
    //   name: 'Coinbase Wrapped Staked ETH',
    //   logoURI: 'https://tokens.1inch.io/0xbe9895146f7af43049ca1c1ae358b0541ea49704.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd417144312dbf50465b1c641d016962017ef6240',
    //   symbol: 'CQT',
    //   decimals: 18,
    //   name: 'Covalent Query Token',
    //   logoURI: 'https://tokens.1inch.io/0xd417144312dbf50465b1c641d016962017ef6240.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd3e4ba569045546d09cf021ecc5dfe42b1d7f6e4',
    //   symbol: 'MNW',
    //   decimals: 18,
    //   name: 'Morpheus.Network',
    //   logoURI: 'https://tokens.1inch.io/0xd3e4ba569045546d09cf021ecc5dfe42b1d7f6e4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd3c51de3e6dd9b53d7f37699afb3ee3bf9b9b3f4',
    //   symbol: 'MCONTENT',
    //   decimals: 6,
    //   name: 'MContent',
    //   logoURI: 'https://tokens.1inch.io/0xd3c51de3e6dd9b53d7f37699afb3ee3bf9b9b3f4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcd7492db29e2ab436e819b249452ee1bbdf52214',
    //   symbol: 'SMI',
    //   decimals: 8,
    //   name: 'SafeMoon Inu',
    //   logoURI: 'https://tokens.1inch.io/0xcd7492db29e2ab436e819b249452ee1bbdf52214.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcb84d72e61e383767c4dfeb2d8ff7f4fb89abc6e',
    //   symbol: 'VEGA',
    //   decimals: 18,
    //   name: 'VEGA',
    //   logoURI: 'https://tokens.1inch.io/0xcb84d72e61e383767c4dfeb2d8ff7f4fb89abc6e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc98d64da73a6616c42117b582e832812e7b8d57f',
    //   symbol: 'RSS3',
    //   decimals: 18,
    //   name: 'RSS3',
    //   logoURI: 'https://tokens.1inch.io/0xc98d64da73a6616c42117b582e832812e7b8d57f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc91a71a1ffa3d8b22ba615ba1b9c01b2bbbf55ad',
    //   symbol: 'ZZ',
    //   decimals: 18,
    //   name: 'ZigZag',
    //   logoURI: 'https://tokens.1inch.io/0xc91a71a1ffa3d8b22ba615ba1b9c01b2bbbf55ad.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc82e3db60a52cf7529253b4ec688f631aad9e7c2',
    //   symbol: 'ARC',
    //   decimals: 18,
    //   name: 'ARC',
    //   logoURI: 'https://tokens.1inch.io/0xc82e3db60a52cf7529253b4ec688f631aad9e7c2.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xac57de9c1a09fec648e93eb98875b212db0d460b',
    //   symbol: 'BabyDoge',
    //   decimals: 9,
    //   name: 'Baby Doge Coin',
    //   logoURI: 'https://tokens.1inch.io/0xac57de9c1a09fec648e93eb98875b212db0d460b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc669928185dbce49d2230cc9b0979be6dc797957',
    //   symbol: 'BTT',
    //   decimals: 18,
    //   name: 'BitTorrent',
    //   logoURI: 'https://tokens.1inch.io/0xc669928185dbce49d2230cc9b0979be6dc797957.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc55c2175e90a46602fd42e931f62b3acc1a013ca',
    //   symbol: 'STARS',
    //   decimals: 18,
    //   name: 'Mogul Stars',
    //   logoURI: 'https://tokens.1inch.io/0xc55c2175e90a46602fd42e931f62b3acc1a013ca.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc55126051b22ebb829d00368f4b12bde432de5da',
    //   symbol: 'BTRFLY',
    //   decimals: 18,
    //   name: 'BTRFLY',
    //   logoURI: 'https://tokens.1inch.io/0xc55126051b22ebb829d00368f4b12bde432de5da.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc52c326331e9ce41f04484d3b5e5648158028804',
    //   symbol: 'ZCX',
    //   decimals: 18,
    //   name: 'ZEN Exchange Token',
    //   logoURI: 'https://tokens.1inch.io/0xc52c326331e9ce41f04484d3b5e5648158028804.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc4f6e93aeddc11dc22268488465babcaf09399ac',
    //   symbol: 'HI',
    //   decimals: 18,
    //   name: 'hi Dollar',
    //   logoURI: 'https://tokens.1inch.io/0xc4f6e93aeddc11dc22268488465babcaf09399ac.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc17c30e98541188614df99239cabd40280810ca3',
    //   symbol: 'RISE',
    //   decimals: 18,
    //   name: 'EverRise',
    //   logoURI: 'https://tokens.1inch.io/0xc17c30e98541188614df99239cabd40280810ca3.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc0c293ce456ff0ed870add98a0828dd4d2903dbf',
    //   symbol: 'AURA',
    //   decimals: 18,
    //   name: 'Aura',
    //   logoURI: 'https://tokens.1inch.io/0xc0c293ce456ff0ed870add98a0828dd4d2903dbf.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xba8a621b4a54e61c442f5ec623687e2a942225ef',
    //   symbol: 'QUARTZ',
    //   decimals: 18,
    //   name: 'Sandclock',
    //   logoURI: 'https://tokens.1inch.io/0xba8a621b4a54e61c442f5ec623687e2a942225ef.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xba5bde662c17e2adff1075610382b9b691296350',
    //   symbol: 'RARE',
    //   decimals: 18,
    //   name: 'SuperRare',
    //   logoURI: 'https://tokens.1inch.io/0xba5bde662c17e2adff1075610382b9b691296350.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb620be8a1949aa9532e6a3510132864ef9bc3f82',
    //   symbol: 'LFT',
    //   decimals: 18,
    //   name: 'LendFlare DAO Token',
    //   logoURI: 'https://tokens.1inch.io/0xb620be8a1949aa9532e6a3510132864ef9bc3f82.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb26c4b3ca601136daf98593feaeff9e0ca702a8d',
    //   symbol: 'ALD',
    //   decimals: 18,
    //   name: 'Aladdin Token',
    //   logoURI: 'https://tokens.1inch.io/0xb26c4b3ca601136daf98593feaeff9e0ca702a8d.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xaa8330fb2b4d5d07abfe7a72262752a8505c6b37',
    //   symbol: 'POLC',
    //   decimals: 18,
    //   name: 'Polka City',
    //   logoURI: 'https://tokens.1inch.io/0xaa8330fb2b4d5d07abfe7a72262752a8505c6b37.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xba3335588d9403515223f109edc4eb7269a9ab5d',
    //   symbol: 'GEAR',
    //   decimals: 18,
    //   name: 'Gearbox',
    //   logoURI: 'https://tokens.1inch.io/0xba3335588d9403515223f109edc4eb7269a9ab5d.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7825e833d495f3d1c28872415a4aee339d26ac88',
    //   symbol: 'TLOS',
    //   decimals: 18,
    //   name: 'pTokens TLOS',
    //   logoURI: 'https://tokens.1inch.io/0x7825e833d495f3d1c28872415a4aee339d26ac88.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x967fb0d760ed3ce53afe2f0a071674cccae73550',
    //   symbol: 'XETA',
    //   decimals: 18,
    //   name: 'XANA',
    //   logoURI: 'https://tokens.1inch.io/0x967fb0d760ed3ce53afe2f0a071674cccae73550.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0100546f2cd4c9d97f798ffc9755e47865ff7ee6',
    //   symbol: 'alETH',
    //   decimals: 18,
    //   name: 'Alchemix ETH',
    //   logoURI: 'https://tokens.1inch.io/0x0100546f2cd4c9d97f798ffc9755e47865ff7ee6.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe5f7ef61443fc36ae040650aa585b0395aef77c8',
    //   symbol: 'REALTOKEN-9943-MARLOWE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken 9943 Marlowe Street Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xed42cedcadbfbcaa3e6f411b09567c2c0b5ad28f',
    //   symbol: 'REALTOKEN-9336-PATTON-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken 9336 Patton Street Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x395c47a421c254ae42253764a7f56e0ee0cddac5',
    //   symbol: 'REALTOKEN-20200-LESURE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken 20200 Lesure Street Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x43688910273f199b8ae2ca018c13918fb3d37b58',
    //   symbol: 'REALTOKEN-5942-AUDUBON-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken 5942 Audubon Road Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x22c8ecf727c23422f47093b562ec53c139805301',
    //   symbol: 'REALTOKEN-16200-FULLERTON-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken 16200 Fullerton Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6fd016ccc4611f7bab1dd3267334cb0216ef47f9',
    //   symbol: 'REALTOKEN-8342-SCHAEFER-HWY-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken 8342 Schaefer Hwy Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfc89f1b932079b462ef9c8757de5a28e387b847b',
    //   symbol: 'REALTOKEN-18276-APPOLINE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken 18276 Appoline Street Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x74d2cb65b1158300c3e6bea149d68509c7b2425d',
    //   symbol: 'REALTOKEN-25097-ANDOVER-DR-DEARBORN-MI',
    //   decimals: 18,
    //   name: 'RealToken 25097 Andover Drive Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xbecaea7aa3629d4b7ddccf3a973bef09ff34d4b6',
    //   symbol: 'REALTOKEN-15634-LIBERAL-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken 15634 Liberal Street Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x22cabb38295eaeccfede4e99af508052e3b74ca0',
    //   symbol: 'REALTOKEN-18900-MANSFIELD-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken 18900 Mansfield Street Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe9eace1313913888c364d8504ffc3b8d991c67c6',
    //   symbol: 'REALTOKEN-272-NE-42ND-CT-DEERFIELDBEACH-FL',
    //   decimals: 18,
    //   name: 'RealToken 272 NE 42nd CT Deerfield Beach FL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x67f2014293d641468161bbb0e6bd088fc0b8c381',
    //   symbol: 'REALTOKEN-15048-FREELAND-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken 15048 Freeland Street Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4236f8aaf2b1f3a28420eb15b8e0ddf63201a95e',
    //   symbol: 'BMDA',
    //   decimals: 18,
    //   name: 'Bermuda',
    //   logoURI: 'https://tokens.1inch.io/0x4236f8aaf2b1f3a28420eb15b8e0ddf63201a95e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xbff3a3d79d0f9165cfcc1b369ee41f3c5c9ae398',
    //   symbol: 'REALTOKEN-S-18433-FAUST-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 18433 Faust ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc1af55156b64da1d484fb13b1afdb1da8efa7733',
    //   symbol: 'REALTOKEN-S-9165-KENSINGTON-AVE-DETROIT-MI ',
    //   decimals: 18,
    //   name: 'RealToken S 9165 Kensington Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x67bc218f5ea919ff9003b28c1702d468312af302',
    //   symbol: 'REALTOKEN-D-10048-GRAYTON-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken D 10048 Grayton St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xac1682ad8893ed96e7ec3379f3a212dc50f06d23',
    //   symbol: 'REALTOKEN-S-10084-GRAYTON-ST-DETROIT-MI ',
    //   decimals: 18,
    //   name: 'RealToken S 10084 Grayton St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfd510a3c53666035900ceb4d4743bd869d98d57a',
    //   symbol: 'REALTOKEN-S-9166-DEVONSHIRE-RD-DETROIT-MI ',
    //   decimals: 18,
    //   name: 'RealToken S 9166 Devonshire Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd4203d4f0f2c3ae21ce93f04ab00517262f65aa9',
    //   symbol: 'REALTOKEN-S-10612-SOMERSET-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10612 Somerset Ave Detroit-MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa9e8a9d9729e766a72763253f2afd1b1cf9053a0',
    //   symbol: 'REALTOKEN-S-9309-COURVILLE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken 9309 Courville St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x53993d04758ee89bbe190e15a81c411688543aba',
    //   symbol: 'REALTOKEN-S-10616-McKINNEY-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10616 McKinney Street Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x273a160eb5df613c8c99869f5ae4941f65bf94cb',
    //   symbol: 'REALTOKEN-S-9169-BOLEYN-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 9169 Boleyn Street Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xec2d495f20adf65cd26e9e250c8cd5d863609afd',
    //   symbol: 'REALTOKEN-S-3432-HARDING-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 3432 Harding Street Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x033b186321fa88603e3ecc98821fb0932b2c0760',
    //   symbol: 'REALTOKEN-S-12334-LANSDOWNE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 12334 Lansdowne St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x87645f94f6ea37f9f2f56d4521315e9c1ed89aa4',
    //   symbol: 'REALTOKEN-S-10974-WORDEN-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10974 Worden St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4475ad655d6fa73db81cc52a5cf4585faa34a1dd',
    //   symbol: 'REALTOKEN-S-13991-WARWICK-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 13991 Warwick st Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2830209f6573f10c481d946ba18b446429f30360',
    //   symbol: 'REALTOKEN-S-6923-GREEVIEW-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 6923 Greenview ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdbb13d3f745a64995ca76069f2cebf9a2d7b18c0',
    //   symbol: 'REALTOKEN-S-1000-FLORIDA-AVE-AKRON-OH',
    //   decimals: 18,
    //   name: 'RealToken S 1000 Florida ave Akron OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xee9a08fc54bf53353398f946db4cb2447276f850',
    //   symbol: 'REALTOKEN-S-4340-EAST-71-CLEVELAND-OH',
    //   decimals: 18,
    //   name: 'RealToken S 4340 East-71 Cleveland OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xec069eaa5c83763f288106506fecbd5dbe74d047',
    //   symbol: 'REALTOKEN-S-9133-DEVONSHIRE-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 9133 Devonshire st Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc4cb613947890ea300fedc509ac19f8efa0cdd14',
    //   symbol: ' REALTOKEN-S-10604-SOMERSET-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10604 Somerset Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/00xe5f7ef61443fc36ae040650aa585b0395aef77c8png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8a41b6b6177f35bfa6d677447d3fe0d5a0cec45e',
    //   symbol: 'REALTOKEN-S-587-JEFFERSON-AVE-ROCHESTER-NY_1',
    //   decimals: 18,
    //   name: 'RealToken S 581-587 Jefferson Ave Rochester NY',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1105c20ac6f4de989faf05d17ab3f950963b75ad',
    //   symbol: 'REALTOKEN-S-15778-MANOR-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15778 Manor st Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x438f9de51f51692a4b83696413062a040cc5cbd5',
    //   symbol: 'REALTOKEN-S-15784-MONTEVISTA-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15784 Monte vista st Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x434e3a92c43a98ff508ab44e023ea7638952ad21',
    //   symbol: 'REALTOKEN-S-13114-GLENFIELD-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 13114 Glenfield Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xba0d050bbb662c190bf99c61708b42ff9d8750e0',
    //   symbol: 'REALTOKEN-S-13116-KILBOURNE-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 13116 Kilbourne Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x419f97e6dcfbf89a70ea898b7f44472f75bf6137',
    //   symbol: 'REALTOKEN-S-19317-GABLE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19317 Gable st Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfe17c3c0b6f38cf3bd8ba872bee7a18ab16b43fb',
    //   symbol: 'REALTOKEN-S-15777-ARDMORE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15777 Ardmore st Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x41599149f1b52035392402f9e311b1edb0c9f699',
    //   symbol: 'REALTOKEN-S-14319-ROSEMARY-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14319 Rosemary st Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x315699f1ba88383cff2f2f30fcad187adb2e4d72',
    //   symbol: 'REALTOKEN-S-14078-CARLISLE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14078 Carlisle st Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6f442da588232dc57bf0096e8de48d6961d5cc83',
    //   symbol: 'REALTOKEN-S-13895-SARATOGA-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 13895 Saratoga st Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x96700ffae33c651bc329c3f3fbfe56e1f291f117',
    //   symbol: 'REALTOKEN-S-4380-BEACONSFIELD-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 4380 Beaconsfield st Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x499a6c19f5537dd6005e2b5c6e1263103f558ba4',
    //   symbol: 'REALTOKEN-S-17813-BRADFORD-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 17813 Bradford st Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb3d3c1bbcef737204aadb4fa6d90e974bc262197',
    //   symbol: 'REALTOKEN-S-15796-HARTWELL-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15796 Hartwell st Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x73bde888664df8ddfd156b52e6999eeabab57c94',
    //   symbol: 'REALTOKEN-S-9717-EVERTS-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 9717 Everts St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x830b0e9a5ecf36d0a886d21e1c20043cd2d16515',
    //   symbol: 'REALTOKEN-S-19201-WESTPHALIA-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19201 Westphalia St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x18084fba666a33d37592fa2633fd49a74dd93a88',
    //   symbol: 'tBTC',
    //   decimals: 18,
    //   name: 'tBTC v2',
    //   logoURI: 'https://tokens.1inch.io/0x18084fba666a33d37592fa2633fd49a74dd93a88.png',
    //   eip2612: true,
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4cc53ee5ef306a95d407321d4b4acc30814c04ee',
    //   symbol: 'REALTOKEN-S-19163-MITCHELL-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19163 Mitchell St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd9e89bfebae447b42c1fa85c590716ec8820f737',
    //   symbol: 'REALTOKEN-S-4061-GRAND-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 4061 Grand St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xefe82d6baf0db71f92889eb9d00721bd49121316',
    //   symbol: 'REALTOKEN-S-4680-BUCKINGHAM-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 4680 Buckingham Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8a9f904b4ead6a97f3ab304d0d2196f5c602c807',
    //   symbol: 'REALTOKEN-S-19311-KEYSTONE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19311 Keystone St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7e95b310724334ff74537dc08bfd3377d25e65ce',
    //   symbol: 'REALTOKEN-S-15039-WARD-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15039 Ward Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x75f06b482adbfb04b877d8ee683e2fcdf18ad153',
    //   symbol: 'REALTOKEN-S-18481-WESTPHALIA-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 18481 Westphalia St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3150f0ebc0efee280b5348b9c8c271ad44eb8b13',
    //   symbol: 'REALTOKEN-S-19596-GOULBURN-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19596 Goulburn St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x94fa7f8cb8453ad57cd133363b3012044647078c',
    //   symbol: 'REALTOKEN-S-1244-S.AVERS -ST-CHICAGO-IL',
    //   decimals: 18,
    //   name: 'RealToken S 1244 S. Avers Chicago IL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x175cbd54d38f58b530785e01471a2ec0d4596eb5',
    //   symbol: 'REALTOKEN-S-15770-PREST-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15770 Prest St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xce111a198eb04f388aceb78c40ced6daf1b0514a',
    //   symbol: 'REALTOKEN-272-NE42nd-COURT-DEERFIELDBEACH-FL',
    //   decimals: 18,
    //   name: 'RealToken S 272 N.E. 42nd Court Deerfield Beach FL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa29ae272bc89e5f315b2793925f700045f845d82',
    //   symbol: 'REALTOKEN-S-587-JEFFERSON-AVE-ROCHESTER-NY',
    //   decimals: 18,
    //   name: 'RealToken S 581-587 Jefferson Ave Rochester NY',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x34ed9e71449529e034d0326cfbb3b5ccdca00cbc',
    //   symbol: 'REALTOKEN-S-19314-GABLE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19317 Gable St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb5d30c28f87acf675ed5b9f343e5fff39ec9942c',
    //   symbol: 'REALTOKEN-10024-28-APPOLINE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10024-28 Appoline St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1eb16ec378f0ce8f81449120629f52ba28961d47',
    //   symbol: 'REALTOKEN-S-1000-FLORIDA-AVE-AKRON-OH_1',
    //   decimals: 18,
    //   name: 'RealToken S 1000 Florida Ave Akron OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4e98493920b16dd6642e9d48497c8d0a49150f6f',
    //   symbol: 'REALTOKEN-S-13991-WARWICK-ST-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 13991 Warwick St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9a99f283e1f6c3b7f24901995624ef7b78e94471',
    //   symbol: 'REALTOKEN-S-18466-FIELDING-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 18466 Fielding St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9fef44fc4c571010bccd5b63e1cdc807d3b347bf',
    //   symbol: 'REALTOKEN-S-15095-HARTWELL-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15095 Hartwell St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9856c5ca15a4ac9c65aac090c38a9f39eb3b5eec',
    //   symbol: 'REALTOKEN-S-18273-MONTEVISTA-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 18273 Monte Vista St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe3902e329ef2d3fd7666022c139d75bcc984b7a5',
    //   symbol: 'REALTOKEN-15048-FREELAND-ST-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 15048 Freeland St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7f940b5509a22e81d29167581bdeea3fa5a0abee',
    //   symbol: 'REALTOKEN-S-15784-MONTEVISTA-ST-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 15784 Monte Vista St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd08d2b199e9e5df407427d4085877d1fdff3b1d6',
    //   symbol: 'REALTOKEN-S-6923-GREEVIEW-AVE-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 6923 Greenview Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9f923653a19537b5a1b003854a1920fe67a8ffeb',
    //   symbol: 'REALTOKEN-S-13114-GLENFIELD-AVE-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 13114 Glenfield Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa68b7779504b0ae372ddcc109f8786db9b91e93e',
    //   symbol: 'REALTOKEN-S-4340-EAST-71-CLEVELAND-OH_1',
    //   decimals: 18,
    //   name: 'RealToken S 4338-4340 East 71 Cleveland OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8626b38267e4fc0d8c92e0bb86f97acab3f6aa05',
    //   symbol: 'REALTOKEN-S-10604-SOMERSET-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10604 Somerset Ave MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x76dbeb740ecd1f3b052a9afa302abc7eb4fb5390',
    //   symbol: 'REALTOKEN-S-9133-DEVONSHIRE-RD-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 9133 Devonshire Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x46f8a600337dec5cab03aa9b8f67f1d5b788ce28',
    //   symbol: 'REALTOKEN-S-18433-FAUST-AVE-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 18433 Faust Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe5ce63ac9a08c1eb160889151cd84855f16c94d2',
    //   symbol: 'REALTOKEN-S-10616-McKINNEY-ST-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 10616 McKinney Street Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x67a83b28f6dd8c07301495ee2c6f83b73fd21092',
    //   symbol: 'REALTOKEN-S-9309-COURVILLE-ST-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 9309 Courville St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3c56d5e887d8fa7ae1ba65bf7eccc25ec09eaf18',
    //   symbol: 'REALTOKEN-S-9165-KENSINGTON-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 9165 Kensington St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x42b387cdf0951a0e08336d35651544c47cd05c95',
    //   symbol: 'REALTOKEN-S-10974-WORDEN-ST-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 10974 Worden Street Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf23b80216a10e6f0c0d3b5ad5c9349e9425cad40',
    //   symbol: 'REALTOKEN-S-15778-MANOR-ST-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 15778 Manor St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa69d7d4ddf397f3d1e7ebaf108555d1107b3b117',
    //   symbol: 'REALTOKEN-S-9166-DEVONSHIRE-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 9166 Devonshire Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x806690b7a093d2cf6419a515abedb7f28595bc5e',
    //   symbol: 'REALTOKEN-S-9169-BOLEYN-ST-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 9169 Boleyn Street Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x662b67d00a13faf93254714dd601f5ed49ef2f51',
    //   symbol: 'ORC',
    //   decimals: 18,
    //   name: 'Orbit Chain',
    //   logoURI: 'https://tokens.1inch.io/0x662b67d00a13faf93254714dd601f5ed49ef2f51.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xee2f2212a64ec3f6bc0f7580e10c53cb38b57508',
    //   symbol: 'REALTOKEN-S-12334-LANSDOWNE-ST-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 12334 Lansdowne Street Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x92161385c9de8798ad5fb01c0be99ffcbc84dfd8',
    //   symbol: 'REALTOKEN-S-10084-GRAYTON-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10084 Grayton St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x400b5716b0c23b6f1f0f2a5fdb038949962b803e',
    //   symbol: 'REALTOKEN-S-3432-HARDING-ST-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 3432 Harding Street Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x57eadd2a542cfe9f00a37f55df4d5062f857c0e8',
    //   symbol: 'REALTOKEN-9943-MARLOWE-ST-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 9943 Marlowe St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x21f1af3e751317a2f7de7df31d5d092e6a907bde',
    //   symbol: 'REALTOKEN-18276-APPOLINE-ST-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 18276 Appoline St Detroit MI 48235',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9eb90ec3faafc22092c9b91559fddde538042093',
    //   symbol: 'REALTOKEN-18900-MANSFIELD-ST-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 18900 Mansfield St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf18cffb528eca0ea31d1d6b28bc80d2eca34d14d',
    //   symbol: 'REALTOKEN-25097-ANDOVER-DR-DEARBORN-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 25097 Andover Drive Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6db6d540f5614e6bab7475af3f430f46a0b083e2',
    //   symbol: 'REALTOKEN-5942-AUDUBON-RD-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 5942 Audubon Road Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x741857c07b100c9c0c1272d95845dddc4f1b67cb',
    //   symbol: 'REALTOKEN-8342-SCHAEFER-HWY-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 8342 Schaefer Hwy Detroit MI 48228',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x23684569c0636c9aea246551879d457d0a0e6f58',
    //   symbol: 'REALTOKEN-9336-PATTON-ST-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 9336 Patton St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xeedc2f5f4d1226759b1acf9efa23a99661de6663',
    //   symbol: 'REALTOKEN-20200-LESURE-ST-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 20200 Lesure St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa81f77e8988b28fb74243b907ace3c83353dc80a',
    //   symbol: 'REALTOKEN-15634-LIBERAL-ST-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 15634 Liberal St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x021bb23a45e9fc824260435e670fc383b7b8cbbb',
    //   symbol: 'REALTOKEN-16200-FULLERTON-AVE-DETROIT-MI_1',
    //   decimals: 18,
    //   name: 'RealToken S 16200 Fullerton Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x280e9ed3b20c580a2f4219657bb9332d80bc01f1',
    //   symbol: 'REALTOKEN-S-19020-ROSEMONT-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19020 Rosemont Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x33722ea778df197f1b7b1cf0b124d7a962181d65',
    //   symbol: 'REALTOKEN-S-19136-TRACEY-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19136 Tracey St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x750fa12ae51d1515c893c1aaabe2c135937a2c8b',
    //   symbol: 'REALTOKEN-S-9465-BEACONSFIELD-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 9465 Beaconsfield St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd2f69dcd1e26f51fbbce48f61426c724e65acf91',
    //   symbol: 'REALTOKEN-S-1115-S.TROY-ST-CHICAGO-IL',
    //   decimals: 18,
    //   name: 'RealToken S 1115 S. Troy St Chicago IL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc731eca970979cd2da2a1094a808f49894070d35',
    //   symbol: 'REALTOKEN-S-19218-HOUGHTON-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19218 Houghton St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x06d0e5aee443093ac5635b709c8a01342e59df19',
    //   symbol: 'REALTOKEN-S-10617-HATHAWAY-AVE-CLEVELAND-OH',
    //   decimals: 18,
    //   name: 'RealToken S 10617 Hathaway Ave Cleveland OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4d0da4e75d40bd7d9c4f7a292bf883bcdf38c45d',
    //   symbol: 'REALTOKEN-S-15373-PARKSIDE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15373 Parkside St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9528a7402c0fe85b817aa6e106eafa03a02924c4',
    //   symbol: 'REALTOKEN-S-14231-STRATHMOOR-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14231 Strathmoor St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xda47bd33e8f5d17bb81b8752784bfb46c1c44b2a',
    //   symbol: 'REALTOKEN-S-15350-GREYDALE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15350 Greydale St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb5dd2b6e0a0422e069e1d2cc3ed16533488a05e3',
    //   symbol: 'REALTOKEN-S-12866-LAUDER-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 12866 Lauder St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x92d31e19f88597f368825ba16410f263a844527a',
    //   symbol: 'REALTOKEN-S-8181-BLISS-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 8181 Bliss St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x24293ab20159cfc0f3d7c8727cd827fba63d4f64',
    //   symbol: 'REALTOKEN-S-19333-MOENART-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19333 Moenart St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5e2a09064b2dca8c44aad8a5b69a69bb1854fe72',
    //   symbol: 'REALTOKEN-S-11201-COLLEGE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 11201 College St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa9f30c907321718e655b74463ca665b690b78894',
    //   symbol: 'REALTOKEN-S-15860-HARTWELL-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15860 Hartwell St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb8403b7730368942a5bfe5aac04a31b44015b1cc',
    //   symbol: 'REALTOKEN-S-11078-WAYBURN-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 11078 Wayburn St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x43fed9f9bf7deedcb314b432a8e38219dd62ce9e',
    //   symbol: 'REALTOKEN-S-14825-WILFRIED-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14825 Wilfried St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x31aa5fa895fd186fde12347a6fcaf540875b6434',
    //   symbol: 'REALTOKEN-S-14882-TROESTER-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14882 Troester St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xba07997f594a52df179620284b52b50a4e66227d',
    //   symbol: 'REALTOKEN-S-17809-CHAREST-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 17809 Charest St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2c6f00a020b613c9419bb6c1ac220036dbe9e622',
    //   symbol: 'REALTOKEN-S-18776-SUNDERLAND-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 18776 Sunderland Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb09850e2b93aa3aaa1476bf0c007cfc960e2de79',
    //   symbol: 'REALTOKEN-S-14229-WILSHIRE-DR-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14229 Wilshire St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2b683f8cc61de593f089bdddc01431c0d7ca2ee2',
    //   symbol: 'REALTOKEN-S-601-MILTON-ST-TOLEDO-OH',
    //   decimals: 18,
    //   name: 'RealToken S 601 Milton St Toledo OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa137d82197ea4cdfd5f008a91ba816b8324f59e1',
    //   symbol: 'REALTOKEN-S-5601-S.WOOD-ST-CHICAGO-IL',
    //   decimals: 18,
    //   name: 'RealToken S 5601 S Wood St Chicago IL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1e001730a23c7ebaff35bc8bc90da5a9b20804a4',
    //   symbol: 'REALTOKEN-S-9481-WAYBURN-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 9481 Wayburn St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9b5b4886033b4abc5eb0552fff7c15a67c3c47c7',
    //   symbol: 'REALTOKEN-S-11300-ROXBURY-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 11300 Roxbury St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd5d1adf54fbf73a00b197ddcfdad7cc27d93002f',
    //   symbol: 'REALTOKEN-S-10629-MCKINNEY-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10629 McKinney St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa2b2ae397492c7ed8a4c1e751ac72d2b59947e6b',
    //   symbol: 'REALTOKEN-S-9920-BISHOP-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 9920 Bishop St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4a99cc509f7facf58d7b67e99236db5e0921ef81',
    //   symbol: 'REALTOKEN-S-10639-STRATMAN-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10639 Stratman St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5600e25b4f24c63afa655c3bd96e3c178b654fa1',
    //   symbol: 'REALTOKEN-S-13045-WADE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 13045 Wade St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2adc1cfa726a45264a328d9d2e2c692ceac97458',
    //   symbol: 'REALTOKEN-S-14494-CHELSEA-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14494 Chelsea Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xad91999f534f4075b00ba4231c018e57bdebb342',
    //   symbol: 'REALTOKEN-S-18983-ALCOY-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 18983 Alcoy Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x211618fa0934910666f2c2731101f5a3ac013fd8',
    //   symbol: 'REALTOKEN-S-19200-STRASBURG-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19200 Strasburg St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x31820af2d43c08bd82bd94b08974062482bd98d3',
    //   symbol: 'REALTOKEN-S-11653-NOTTINGHAM-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 11653 Nottingham Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8d1090df790ffafdaccda03015c05df3b4cc9c21',
    //   symbol: 'REALTOKEN-S-15753-HARTWELL-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15753 Hartwell St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1fdb4015fd5e031c5641752c1e03b973ad5ea168',
    //   symbol: 'REALTOKEN-S-17500-EVERGREEN-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 17500 Evergreen Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe82cbb7c29d00a4296ee505d12a473c26cd9c423',
    //   symbol: 'REALTOKEN-S-10700-WHITTIER-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10700 Whittier Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x63a1849b47ef5913ccc5adb0e2708b11a4ba6972',
    //   symbol: 'REALTOKEN-S-14918-JOY-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14918 Joy Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe255caf8893382465368b8e1cd4ef8436acf0ade',
    //   symbol: 'REALTOKEN-S-402-S.KOSTNER-AVE-CHICAGO-IL',
    //   decimals: 18,
    //   name: 'RealToken S 402 S Kostner Ave Chicago IL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x69d1b42b20f3ded07bec322253d0140b04cbb6f5',
    //   symbol: 'REALTOKEN-S-1542-S.RIDGEWAY-AVE-CHICAGO-IL',
    //   decimals: 18,
    //   name: 'RealToken S 1542 S Ridgeway Ave Chicago IL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd5fc0c4c4c5ff316e1e91494d963ff1d52ba25ff',
    //   symbol: 'REALTOKEN-S-11078-LONGVIEW-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 11078 Longview St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x311fc485f1fea0c8cc9b5c783e79f4313ddfa720',
    //   symbol: 'REALTOKEN-S-19996-JOANN-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19996 Joann Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x804f6baa10615c85e4b4a5bc4efe516d9f7a4365',
    //   symbol: 'REALTOKEN-S-13606-WINTHROP-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 13606 Winthrop St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x969d42ad7008e6651e1fd52742153f8743225d98',
    //   symbol: 'REALTOKEN-S-12409-WHITEHILL-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 12409 Whitehill St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe7b6de709ffc3bd237c2f2c800e1002f97a760f3',
    //   symbol: 'REALTOKEN-S-4852-4854-W.CORTEZ-ST-CHICAGO-IL',
    //   decimals: 18,
    //   name: 'RealToken S 4852-4854 W Cortez St Chicago IL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9d918ee39a356be8ef99734599c7e70160db4db6',
    //   symbol: 'REALTOKEN-S-12405-SANTA-ROSA-DR-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 12405 Santa Rosa Dr Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdd833d0eef6d5d7cec781b03c19f3b425f3039df',
    //   symbol: 'REALTOKEN-S-11957-OLGA-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 11957 Olga St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8fcb39a25e639c8fbd28e8a018227d6570e02352',
    //   symbol: 'REALTOKEN-S-1815-S.AVERS-AVE-CHICAGO-IL',
    //   decimals: 18,
    //   name: 'RealToken S 1815 S.Avers Ave Chicago IL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf4657ab08681214bcb1893aa8e9c7613459250ec',
    //   symbol: 'REALTOKEN-S-1617-S.AVERS-AVE-CHICAGO-IL',
    //   decimals: 18,
    //   name: 'RealToken S 1617 S.Avers Ave Chicago IL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe887dc4fcb5240c0c080aeab8870421d3ebd0b28',
    //   symbol: 'REALTOKEN-S-14066-SANTA-ROSA-DR-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14066 Santa Rosa Dr Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5b690b010944bdfa8b26116967fe3fb3c38cfaac',
    //   symbol: 'REALTOKEN-S-13370-WILSHIRE-DR-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 13370 Wilshire Dr Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x38de2858be53d603b1104f16aa67cf180002465d',
    //   symbol: 'REALTOKEN-S-738-742-E.87TH-PL-CHICAGO-IL',
    //   decimals: 18,
    //   name: 'RealToken S 738-742 E 87th Pl Chicago IL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x78a9013b53d2d255935bbc43112d0dd3f475f3d3',
    //   symbol: 'REALTOKEN-S-15203-PARK-GROVE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15203 Park Grove St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0954682ff1b512d3927d06c591942f50917e16a0',
    //   symbol: 'REALTOKEN-S-10021-GRAYTON-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10021 Grayton St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcb061ae1f9b618c44ac10a47a672bf438da01fd8',
    //   symbol: 'REALTOKEN-S-893-895-W.PHILADELPHIA-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 893-895 W Philadelphia St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf5aeab9d9c707b56311066e5172239686ab88110',
    //   symbol: 'REALTOKEN-S-2318-2324-W.MARQUETTE-RD-CHICAGO-IL',
    //   decimals: 18,
    //   name: 'RealToken S 2318-2324 W Marquette Rd Chicago IL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9b091105b9a9eb118f4e0da06a090d6d95463357',
    //   symbol: 'REALTOKEN-S-11217-GREENWICH-AVE-CLEVELAND-OH',
    //   decimals: 18,
    //   name: 'RealToken S 11217 Greenwich Ave Cleveland OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4471962eeffec57a33fa4e0793efeec07684dffb',
    //   symbol: 'REALTOKEN-S-10003-PINEHURST-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10003 Pinehurst St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x584967356bad1499c10a8695522983f2fb7d88f3',
    //   symbol: 'REALTOKEN-S-19191-BRADFORD-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19191 Bradford Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8c1c77c549a3f233fa8f8dea133ff5415d9bae11',
    //   symbol: 'REALTOKEN-S-1521-1523-S.DRAKE-AVE-CHICAGO-IL',
    //   decimals: 18,
    //   name: 'RealToken S 1521-1523 S.Drake Ave Chicago IL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcdf955df8a7ae1264f3b4f8ee5fa68507e8528aa',
    //   symbol: 'REALTOKEN-S-5278-5280-DREXEL-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 5278-5280 Drexel St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x394d59797495848934acf61e680c1739a2cd8cfd',
    //   symbol: 'REALTOKEN-S-5772-5774-CHALMERS-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 5772-5774 Chalmers St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x950bc24510274163aabf83339424e7b49bf6a0c0',
    //   symbol: 'REALTOKEN-S-7430-NETT-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 7430 Nett St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x26ccc79ceeec918e01bbd5c04a64767919f9ec1a',
    //   symbol: 'REALTOKEN-S-7109-7111-PILGRIM-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 7109-7111 Pilgrim St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xca4e38439d5d86554431e15eced03b8bcf2abddd',
    //   symbol: 'REALTOKEN-S-15864-EASTBURN-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15864 Eastburn St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6bd094e39d0b839689e2f900bfdd180b10df62d7',
    //   symbol: 'REALTOKEN-S-12410-HAMBURG-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 12410 Hamburg St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x81cea1a7c83d5caed483dd4da59bfe98f24ef687',
    //   symbol: 'REALTOKEN-S-15379-PATTON-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15379 Patton St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x328249efca026ae8596e9afe913c5f8775ef60ae',
    //   symbol: 'REALTOKEN-S-14884-WARD-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14884 Ward Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x46b00b4bf04c2c94ae67576004a3a247b9400ade',
    //   symbol: 'REALTOKEN-S-116-MONTEREY-ST-HIGHLAND-PARK-MI',
    //   decimals: 18,
    //   name: 'RealToken S 116 Monterey St Highland Park MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x10c2c7a5342988818eb6726fae369299d8fb6328',
    //   symbol: 'REALTOKEN-S-14839-WISCONSIN-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14839 Wisconsin St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0c12f2b2c3ad5150d344b6d3abb901b4795d72d9',
    //   symbol: 'REALTOKEN-S-14215-HAMPSHIRE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14215 Hampshire St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8c3761c5d489ee5a5c30f874b5220c769a7c5a16',
    //   symbol: 'REALTOKEN-S-15841-CORAM-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15841 Coram St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd3f7130940c7746298d9778f79e7bba4c552f176',
    //   symbol: 'REALTOKEN-S-11758-CHRISTY-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 11758 Christy St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xef2b6234e376c3b152c5febe47e1ca3c73cdaa9f',
    //   symbol: 'REALTOKEN-S-15208-BRINGARD-DR-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15208 Bringard Dr Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa5c16ae5fd75f4f079f3e33f0124899bacf567f9',
    //   symbol: 'REALTOKEN-S-882-884-PINGREE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 882-884 Pingree St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1d5da20522b1b94e3b7d983c954075da429bbae1',
    //   symbol: 'REALTOKEN-S-19154-SHERWOOD-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19154 Sherwood St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x23bb1314b73aaaa888800b177ad5d9719a51195b',
    //   symbol: 'REALTOKEN-S-14432-WILSHIRE-DR-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14432 Wilshire Dr Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc363ea8a468b3970ef93140d5d4ad9d124178f6e',
    //   symbol: 'REALTOKEN-S-20418-ANDOVER-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 20418 Andover St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7389ef988fae6b3bcf520000c535e1e2d94c5427',
    //   symbol: 'REALTOKEN-S-18949-FENMORE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 18949 Fenmore St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd1095b31f41d3bdbb66a52b94a737b2d7ac17635',
    //   symbol: 'REALTOKEN-S-14263-OHIO-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14263 Ohio St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa8ab830bfd0d91bc017cdec98a2a198b9938ea8d',
    //   symbol: 'REALTOKEN-S-12747-12749-NASHVILLE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 12747-12749 Nashville St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xafd76591d02462cce1aa5b6c6430854aa9a17e56',
    //   symbol: 'REALTOKEN-S-2550-GREENWAY-ST-TOLEDO-OH',
    //   decimals: 18,
    //   name: 'RealToken S 2550 Greenway St Toledo OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x690602eb0bf5607e3586f1d3e4c4601ef6e4a89f',
    //   symbol: 'REALTOKEN-S-8531-INTERVALE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 8531 Inervale St Detroit MI ',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x185e39d860cf86fbecf4a7c341bd1545ea3a41b9',
    //   symbol: 'REALTOKEN-S-20552-WALTHAM-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 20552 Waltham St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x24a2558d0b0b2247a64eab7cf09d7244cb4c9597',
    //   symbol: 'REALTOKEN-S-17616-BELAND-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 17616 Beland St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcd7dc5e034b631331bc0cfc4ea71d2dc7b53c338',
    //   symbol: 'REALTOKEN-S-2950-2952-MONTEREY-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 2950-2952 Monterey St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2360fca74ed948ff4f962e369080a64a40a1300d',
    //   symbol: 'REALTOKEN-S-8366-SCHAEFER-HWY-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 8366 Schaefer Hwy Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc7785a2575606d444cefbc8a22591600ae5aa9b4',
    //   symbol: 'REALTOKEN-S-13041-HAYES-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 13041 Hayes St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5162d60b699a44b9f09b5fbfd8e6343cde9d7b22',
    //   symbol: 'REALTOKEN-S-128-E.WEBER-ST-TOLEDO-OH',
    //   decimals: 18,
    //   name: 'RealToken S 128 E Weber St Toledo OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x83b16b1dcaaeb59caa13b96da260d8b15671822a',
    //   symbol: 'REALTOKEN-S-1204-E-MANHATTAN-BLVD-TOLEDO-OH',
    //   decimals: 18,
    //   name: 'RealToken S 1204 E Manhattan Blvd Toledo OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xde9122799c313d5cc5c4385984156ad068cde331',
    //   symbol: 'REALTOKEN-S-1612-DENVER-AVE-TOLEDO-OH',
    //   decimals: 18,
    //   name: 'RealToken S 1612 Denver Ave Toledo OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9d19b4d771ef67ea1da64699a388133b44eb434c',
    //   symbol: 'REALTOKEN-S-19962-WALTHAM-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19962 Waltham St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4505f5bff6bada5a20b1a008c6db3cd9545027a4',
    //   symbol: 'REALTOKEN-S-338-WILLARD-ST-TOLEDO-OH',
    //   decimals: 18,
    //   name: 'RealToken S 338 Willard St Toledo OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x79e18a519d60c2ef7e18aac08d60ba0d4eee2511',
    //   symbol: 'REALTOKEN-S-20039-BLOOM-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 20039 Bloom St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x18e55343ecfc135e21916fcdb9788accb5b53caf',
    //   symbol: 'REALTOKEN-S-9171-WHITTIER-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 9171 Whittier Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb2960e73b260812e4326723c7136e7b1988a036a',
    //   symbol: 'REALTOKEN-S-8060-DREAMSICLE-DR-KISSIMMEE-FL',
    //   decimals: 18,
    //   name: 'RealToken S 8060 Dreamsicle Dr Kissimmee FL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe23a5fc3502976de653cb64e5c27dc01d700db36',
    //   symbol: 'REALTOKEN-S-19144-RIOPELLE-ST-HIGHLAND-PARK-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19144 Riopelle St Highland Park MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x945d833927380e25f402350667c6cd2d2615e7c2',
    //   symbol: 'REALTOKEN-S-18668-SAINT-LOUIS-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 18668 Saint Louis St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x96510c0bafc5b3305d2b468063f7b3f8e8389802',
    //   symbol: 'REALTOKEN-S-12779-STRATHMOOR-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 12779 Strathmoor St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x062f0732a7daca652c3bd7d8ad51c3a920b25962',
    //   symbol: 'REALTOKEN-S-5846-CRANE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 5846 Crane St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5e29f1b62c15658e76671e199a7f16afddc9ad76',
    //   symbol: 'REALTOKEN-S-2661-2663-CORTLAND-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 2661-2663 Cortland St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x529ff4d9b07acd6366d0c3eb077ebaa2b06e71c4',
    //   symbol: 'REALTOKEN-S-20160-CONANT-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 20160 Conant St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6133a54e3895f478ee6aae582d7dbc7bbb086b7d',
    //   symbol: 'REALTOKEN-S-5517-5519-ELMHURST-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 5517-5519 Elmhurst St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8c60eccab34ffce7b54e985fc8b7ba280b45701d',
    //   symbol: 'REALTOKEN-S-8017-8019-S-PAULINA-ST-CHICAGO-IL',
    //   decimals: 18,
    //   name: 'RealToken S 8017-8019 S Paulina St Chicago IL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb890b3cc0f2874b15b0dbf6377d39c106ca29fbf',
    //   symbol: 'REALTOKEN-S-2626-2638-WREFORD-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 2626-2638 Wreford St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x934e4bed6f85295581697002fadd816b07c03406',
    //   symbol: 'REALTOKEN-S-4000-TAYLOR-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 4000 Taylor St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x744ca59499be33f6a112eed3acca533954da1050',
    //   symbol: 'REALTOKEN-S-9135-YORKSHIRE-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 9135 Yorkshire Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x20170890ef210e402578f97d6b179784c45f3a1b',
    //   symbol: 'REALTOKEN-S-7337-S-YALE-AVE-CHICAGO-IL',
    //   decimals: 18,
    //   name: 'RealToken S 7337 S Yale Ave Chicago IL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xccca5323052435aa4b0ec94c1d0255f17e4f495c',
    //   symbol: 'REALTOKEN-S-11750-MORANG-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 11750 Morang Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x306044777e68ec5c323889468baacba6d2705994',
    //   symbol: 'REALTOKEN-S-15440-15444-ST-CLAIR-AVE-CLEVELAND-OH',
    //   decimals: 18,
    //   name: 'RealToken S 15440-15444 St Clair Ave Cleveland OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x052ad78e3aa0b0f2d3912fd3b50a9a289cf2f7aa',
    //   symbol: 'REALTOKEN-S-13245-MONICA-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 13245 Monica St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8a25f83819fda66ff0fc6567c8327a5f154c2ec6',
    //   symbol: 'REALTOKEN-S-425-STEWARD-AVE-JACKSON-MI',
    //   decimals: 18,
    //   name: 'RealToken S 425 Steward Ave Jackson MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xbdc1f8ccd117fe4e34f6c78f5293cd126b0c0474',
    //   symbol: 'REALTOKEN-S-10518-WOODLAND-AVE-CLEVELAND-OH',
    //   decimals: 18,
    //   name: 'RealToken S 10518 Woodland Ave Cleveland OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x009bab289f104699ae87e576294d18ed505faa61',
    //   symbol: 'REALTOKEN-S-3323-WAVERLY-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 3323 Waverly St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe447e8ec034c9100c1ff9bc401b5cd2fe15c9dcc',
    //   symbol: 'REALTOKEN-S-8065-LOST-SHAKER-LN-KISSIMMEE-FL',
    //   decimals: 18,
    //   name: 'RealToken S 8065 Lost Shaker Ln Kissimmee FL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x062c208073c9b439d1973262cfe0ddd88f38afa8',
    //   symbol: 'REALTOKEN-S-19268-EUREKA-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19268 Eureka St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2fb7eeeece8498af2bf5b00ea29ca03005c35956',
    //   symbol: 'REALTOKEN-S-1389-BIRD-AVE-BIRMINGHAM-MI',
    //   decimals: 18,
    //   name: 'RealToken S 1389 Bird Ave Birmingham MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcfe61eff2cde5c5885ba3a649ab092f56bd2830f',
    //   symbol: 'REALTOKEN-S-3747-SCOVEL-PL-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 3747 Scovel Pl Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa6004ca1fab428152f27135355f21d6bcdf355bd',
    //   symbol: 'REALTOKEN-S-16767-GREENFIELD-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 16767 Greenfield Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0d5d0b74c690170a82bf52e5d16388fc4fa29082',
    //   symbol: 'REALTOKEN-S-10435-10445-CADIEUX-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10435-10445 Cadieux Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa1bbc683d5f401c3969469c77a3aa1dd3a0ee016',
    //   symbol: 'REALTOKEN-S-212-N-PARK-AVE-JACKSON-MI',
    //   decimals: 18,
    //   name: 'RealToken S 212 N Park Ave Jackson MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdf7a80778a8be812e654291ffab21d61e9c21323',
    //   symbol: 'REALTOKEN-S-10030-CADIEUX-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10030 Cadieux Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2e1a7d86e4591ebe5662ce6b5e3601f19a626c22',
    //   symbol: 'REALTOKEN-S-3344-E-140TH-ST-CLEVELAND-OH',
    //   decimals: 18,
    //   name: 'RealToken S 3344 E 140th St Cleveland OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x960e61f5c4107d71bd8936b010416738c250f91c',
    //   symbol: 'REALTOKEN-S-16851-16881-GREENFIELD-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 16851-16881 Greenfield Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1707a9bad232d728afded75faced38ec90eaa41e',
    //   symbol: 'REALTOKEN-S-1354-W-64TH-ST-CHICAGO-IL',
    //   decimals: 18,
    //   name: 'RealToken S 1354 W 64th St Chicago IL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x07da3cdae2396aa826387a48deba5868d7deb7bc',
    //   symbol: 'REALTOKEN-S-10411-10421-CADIEUX-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10411-10421 Cadieux Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf13d215776dac65c9c1e80d8f3daf6d91cc062d5',
    //   symbol: 'REALTOKEN-S-16007-NELACREST-RD-EAST-CLEVELAND-OH',
    //   decimals: 18,
    //   name: 'RealToken S 16007 Nelacrest Rd East Cleveland OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xee2dacc1e9264cbcb19c46863373fbff4ba7a9bd',
    //   symbol: 'REALTOKEN-S-4342-4344-CORTLAND-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 4342-4344 Cortland St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5d0436f003aef56990194f79a242bc185843aea5',
    //   symbol: 'REALTOKEN-S-19750-MARX-ST-HIGHLAND-PARK-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19750 Marx St Highland Park MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5d9eb5fc910176b08efa5f8d13812b85fdf3394b',
    //   symbol: 'REALTOKEN-S-13835-LA-SALLE-BLVD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 13835 La Salle Blvd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xac0d5dbfed881b3ffaebc7152bc2bc23464fe0cc',
    //   symbol: 'REALTOKEN-S-19751-MARX-ST-HIGHLAND-PARK-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19751 Marx St Highland Park MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd88e8873e90f734c9d3e3519e9e87345478c1df2',
    //   symbol: 'REALTOKEN-S-1610-E-STATE-FAIR-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 1610 E State Fair Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe919df5f4eff1e6e6c6fe7c82471a5e6ce3437f1',
    //   symbol: 'REALTOKEN-S-1630-E-STATE-FAIR-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 1630 E State Fair Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x994c698175e5dbe405a46df94fbd54999a3676c2',
    //   symbol: 'REALTOKEN-S-2703-2705-W-GRAND-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 2703-2705 W Grand St Detroit MI ',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x65d81bf81a65b177012b323f14970071c5099226',
    //   symbol: 'REALTOKEN-S-9415-9417-RAVENSWOOD-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 9415-9417 Ravenswood St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc8ed28ce508811216030b96e2dc70883abad5408',
    //   symbol: 'REALTOKEN-S-7519-7521-WYKES-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 7519-7521 Wykes St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3839ab8550a64940964e0ecb02ed301005a96ffc',
    //   symbol: 'REALTOKEN-S-18980-FENTON-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 18980 Fenton St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf7412e264fa85ae5e79ac3a4b64ce4669e32b98f',
    //   symbol: 'REALTOKEN-S-1900-FENTON-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19000 Fenton St Detroit M',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xafa816f7fff6f252e5cdbd40b80d9ef77b7e289b',
    //   symbol: 'REALTOKEN-S-19041-LENORE-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19041 Lenore Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2089b1b815a2fd0187a48a1c66c511da828a8128',
    //   symbol: 'REALTOKEN-S-3310-3312-STURTEVANT-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 3310-3312 Sturtevant St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf793d1ba72e2914525205ca592dec2142e700cb4',
    //   symbol: 'REALTOKEN-S-10040-CADIEUX-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10040 Cadieux Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xef0c14c5d7da4d0447c28da7a9c8145d0a5a6f61',
    //   symbol: 'REALTOKEN-S-9795-9797-CHENLOT-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 9795-9797 Chenlot St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x03cbe3dda83908ad48643d6a1b5b13d11acaf845',
    //   symbol: 'REALTOKEN-S-324-PIPER-BLVD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 324 Piper Blvd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7a684f6d9a34175d642eedb31fa545604d8d3acf',
    //   symbol: 'REALTOKEN-S-2911-STURTEVANT-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 2911 Sturtevant St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe2fbdeadc82c71c1b8bff9ca4f9e7666224a362c',
    //   symbol: 'REALTOKEN-S-16085-E-SEVEN-MILE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 16085 E Seven Mile Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5b571d103e670848ce8cde37f93821c9c93c7c43',
    //   symbol: 'REALTOKEN-S-14409-LINNHURST-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14409 Linnhurst St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4b293baa4703c5e7d480498bf3ccd8dc3d1297cd',
    //   symbol: 'REALTOKEN-S-10147-SOMERSET-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10147 Somerset Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3113bb5e8bf9dc44ecdb111e5c4ab0818015a2e1',
    //   symbol: 'REALTOKEN-S-16520-ILENE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 16520 Ilene St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6f5258feb5862b661829315841b0718d6e56ca2c',
    //   symbol: 'REALTOKEN-S-20257-MONICA-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 20257 Monica St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xc1c1031e4a44b98707203480029e6576cb3267e3',
    //   symbol: 'REALTOKEN-S-11898-LAING-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 11898 Laing St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x19a6a39b746c4647a01a3bf80751155969ddb15a',
    //   symbol: 'REALTOKEN-S-616-E-131ST-ST-CLEVELAND-OH',
    //   decimals: 18,
    //   name: 'RealToken S 616 E 131st St Cleveland OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4b9c173c81ea2b6804b99ac91846c62bdb74fe72',
    //   symbol: 'REALTOKEN-S-10163-DUPREY-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10163 Duprey St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7909090541d646a262a3062639298772a2c79daa',
    //   symbol: 'REALTOKEN-S-3510-EWALD-CIR-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 3510 Ewald Cir Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x304bee450c2d116696d8b442981e7a300dfdf1cb',
    //   symbol: 'REALTOKEN-S-11965-LAKEPOINTE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 11965 Lakepointe St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x64d69276cc78adbb6d5fea95d3e6370b4844dc76',
    //   symbol: 'REALTOKEN-S-10645-STRATMAN-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10645 Stratman St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdaf6b273691372e4eb2fe353624f8f4f768c2099',
    //   symbol: 'REALTOKEN-S-618-E-79TH-ST-CHICAGO-IL',
    //   decimals: 18,
    //   name: 'RealToken S 618 E 79th St Chicago IL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x53cebce6bd24f8e14da85ba0627a92336bd4f515',
    //   symbol: 'REALTOKEN-S-9200-HARVARD-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 9200 Harvard Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb80173756781960761aa59c9eeebe191f049f4c8',
    //   symbol: 'REALTOKEN-S-19391-GRANDVIEW-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19391 Grandview St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0fd357ea71ad533c6b8a62520321ed286ed9a0c2',
    //   symbol: 'REALTOKEN-S-17204-BRADFORD-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 17204 Bradford Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa984e4c759bea433bef9239736d5f9a9af0e7389',
    //   symbol: 'REALTOKEN-S-10703-MCKINNEY-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10703 McKinney St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf549b95362e6062999bdfe6c61e75e4547b8116d',
    //   symbol: 'REALTOKEN-S-11117-WORDEN-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 11117 Worden St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x034ecb6dc0608b73765f2965771a003d940ee8b2',
    //   symbol: 'REALTOKEN-S-22233-LYNDON-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 22233 Lyndon St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcaf963cd253cf81664ecbcad71d043c47edf77fb',
    //   symbol: 'REALTOKEN-S-11217-BEACONSFIELD-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 11217 Beaconsfield St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf98be7ddab511622b5de91039c48f1f2e37d0bc1',
    //   symbol: 'REALTOKEN-S-9624-ABINGTON-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 9624 Abington Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9abff92ddd896f3f7664ec232bb0a5db027d6724',
    //   symbol: 'REALTOKEN-S-11310-ABINGTON-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 11310 Abington Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7dfeee178fc1f929a88ad69e4e8d493600dd26d0',
    //   symbol: 'REALTOKEN-S-14117-MANNING-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14117 Manning St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x219c820c2d25d8937601d8713235b556c4a37f62',
    //   symbol: 'REALTOKEN-S-9585-ABINGTON-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 9585 Abington Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xbb32de53a057f29280ad889a92fe99d69d470ef0',
    //   symbol: 'REALTOKEN-S-14439-LONGVIEW-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14439 Longview St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xfc5073816fe9671859ef1e6936efd23bb7814274',
    //   symbol: 'REALTOKEN-S-1418-W-MARQUETTE-RD-CHICAGO-IL',
    //   decimals: 18,
    //   name: 'RealToken S 1418 W Marquette Rd Chicago IL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe5b46b4c5c7f974e55ded0b0099c02774f0023c1',
    //   symbol: 'REALTOKEN-S-14329-STRATHMOOR-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14329 Strathmoor St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3b22445411b429f665d12f7a55dd16dfac22c6ae',
    //   symbol: 'REALTOKEN-S-19535-FAIRPORT-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19535 Fairport St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7ad92301442b73bed8d9696d704ac24a0fef3b69',
    //   symbol: 'REALTOKEN-S-8056-E-HOLLYWOOD-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 8056 E Hollywood St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2f3640655d076b45d1fa10da0f6d880329c9d553',
    //   symbol: 'REALTOKEN-S-15240-EDMORE-DR-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15240 Edmore Dr Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xdcfe90e59b574839e028ec28e78018b84b6fcdab',
    //   symbol: 'REALTOKEN-S-14622-HUBBELL-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14622 Hubbell Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x27c30545dc2ba4b3bfdcb9f807a0214f4b06f034',
    //   symbol: 'REALTOKEN-S-8003-S-INGLESIDE-AVE-CHICAGO-IL',
    //   decimals: 18,
    //   name: 'RealToken S 8003 S Ingleside Ave Chicago IL',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9ea9b45a500dd4346163ce7483dea2294ae88d1d',
    //   symbol: 'REALTOKEN-S-10099-GREENSBORO-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10099 Greensboro St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7facb63afb928ac5a4650acc6eab701d7a8becb5',
    //   symbol: 'REALTOKEN-S-15894-TACOMA-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15894 Tacoma St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x43ab521302ff36039be6b425ddaea5ec30a0f8b5',
    //   symbol: 'REALTOKEN-S-5760-LAKEPOINTE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 5760 Lakepointe St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa14d2507a2b96adc13cb19642802fd4eaf9a8a32',
    //   symbol: 'REALTOKEN-S-16810-BRINGARD-DR-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 16810 Bringard Dr Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa3bb6ea63c0fbf259e16ffe0586d84b2c83d7229',
    //   symbol: 'REALTOKEN-S-14511-HOUSTON-WHITTIER-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14511 Houston-Whittier St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x10cde9d1e1a2d3b703f037a6788e04bc139cdf6f',
    //   symbol: 'REALTOKEN-S-19530-HICKORY-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19530 Hickory St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xea91a67a23943984bc8017e20c9fd2e40fd38c3d',
    //   symbol: 'REALTOKEN-S-19539-HICKORY-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19539 Hickory St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1106d8755ffafb1f1820b3668e354336d9085a12',
    //   symbol: 'REALTOKEN-S-9201-LONGACRE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 9201 Longacre St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7085c30b97f1af202f8dfec174f1e07d8f8f887d',
    //   symbol: 'REALTOKEN-S-13430-TACOMA-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 13430 Tacoma St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4c0c0fe9ca9ad4bc748eb8f596ddcfd8707a5cd4',
    //   symbol: 'REALTOKEN-S-13445-TACOMA-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 13445 Tacoma St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe1c4610477da28f6852ced5666aa6f3ccbd73b02',
    //   symbol: 'REALTOKEN-S-13628-TACOMA-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 13628 Tacoma St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xbf1a3181167e4979fd121ef247d9af4fc884cc46',
    //   symbol: 'REALTOKEN-S-19400-HICKORY-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 19400 Hickory St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x940b60023484d593b8391521704abb063b5cccf0',
    //   symbol: 'REALTOKEN-S-18515-18517-KELLY-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 18515-18517 Kelly Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7051faed0775f664a0286af4f75ef5ed74e02754',
    //   symbol: 'CHANGE',
    //   decimals: 18,
    //   name: 'ChangeX',
    //   logoURI: 'https://tokens.1inch.io/0x7051faed0775f664a0286af4f75ef5ed74e02754.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd84d9c58a93e3d4b0acc5ab5a5aa5e2fa6b7ab75',
    //   symbol: 'REALTOKEN-S-18527-18529-KELLY-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 18527-18529 Kelly Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa48ddf8d4f9b03c63d6340b5e0d2af293ab516ea',
    //   symbol: 'REALTOKEN-S-18608-18612-MOROSS-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 18608-18612 Moross Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2f4974a3be7355cf8915ab34099f35ed44293128',
    //   symbol: 'REALTOKEN-S-15611-E-SEVEN-MILE-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 15611 E Seven Mile Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2e1db155a7b812f3fcb0dbc8ed3164b4705213b4',
    //   symbol: 'REALTOKEN-S-10610-BALFOUR-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10610 Balfour Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2a7c9f7719367006e9bd20d0555025efb86d7d5d',
    //   symbol: 'REALTOKEN-S-8809-BECKMAN-AVE-CLEVELAND-OH',
    //   decimals: 18,
    //   name: 'RealToken S 8809 Beckman Ave Cleveland OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x06cc12368fa6a3d4dc0872c60331156a21cdcc9c',
    //   symbol: 'REALTOKEN-S-12318-IMPERIAL-AVE-CLEVELAND-OH',
    //   decimals: 18,
    //   name: 'RealToken S 12318 Imperial Ave Cleveland OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x06246100ba403608b98adfb006d82a7484f5d9ff',
    //   symbol: 'REALTOKEN-S-22512-W-MCNICHOLS-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 22512 W McNichols Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x3c5654362fa005393ad9c793fe0fa74f0cd95bbb',
    //   symbol: 'REALTOKEN-S-8744-HOMER-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 8744 Homer St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x377d14927bbc274771daa9ffab0722935dafaffa',
    //   symbol: 'REALTOKEN-S-13523-GLENSIDE-RD-CLEVELAND-OH',
    //   decimals: 18,
    //   name: 'RealToken S 13523 Glenside Rd Cleveland OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf3c4c10ab96f9b6d7719de63f4219f69078df976',
    //   symbol: 'REALTOKEN-S-14881-GREENFIELD-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14881 Greenfield Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa12a334f563c1a9fef29cf11fac7e148fd54463b',
    //   symbol: 'REALTOKEN-S-11830-CAMDEN-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 11830 Camden Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9642a2379bc359c8b10ead2e06c4a4156bbb3f57',
    //   symbol: 'REALTOKEN-S-14745-PLYMOUTH-RD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 14745 Plymouth Rd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xedcddc374c78bb8596352a28faf1eba9874aa2e1',
    //   symbol: 'REALTOKEN-S-11701-MORANG-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 11701 Morang Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4e2976a2fbaae5877a1272e57343460c25d9d408',
    //   symbol: 'REALTOKEN-S-11845-CAMDEN-AVE-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 11845 Camden Ave Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x87b2fb660f790b936e42093036e0b519f0b50b24',
    //   symbol: 'REALTOKEN-S-3588-OAKMAN-BLVD-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 3588 Oakman Blvd Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd79e63912dbeb409ea350138503a9eb29d73728f',
    //   symbol: 'REALTOKEN-S-10862-MARNE-ST-DETROIT-MI',
    //   decimals: 18,
    //   name: 'RealToken S 10862 Marne St Detroit MI',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xbabc257b4958556bda696e7705cf535f400d8b09',
    //   symbol: 'REALTOKEN-S-10717-10719-BRYANT-AVE-CLEVELAND-OH',
    //   decimals: 18,
    //   name: 'RealToken S 10717-10719 Bryant Ave Cleveland OH',
    //   logoURI: 'https://tokens.1inch.io/0xe5f7ef61443fc36ae040650aa585b0395aef77c8.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9a0c8ff858d273f57072d714bca7411d717501d7',
    //   symbol: 'st1INCH',
    //   decimals: 18,
    //   name: 'Staking 1INCH v2',
    //   logoURI: 'https://tokens.1inch.io/0x9a0c8ff858d273f57072d714bca7411d717501d7.png',
    //   tags: ['staking'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xaccfac2339e16dc80c50d2fa81b5c2b049b4f947',
    //   symbol: 'dst1INCH',
    //   decimals: 18,
    //   name: 'Delegated st1INCH',
    //   logoURI: 'https://tokens.1inch.io/0xaccfac2339e16dc80c50d2fa81b5c2b049b4f947.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9813037ee2218799597d83d4a5b6f3b6778218d9',
    //   symbol: 'BONE',
    //   decimals: 18,
    //   name: 'BONE SHIBASWAP',
    //   logoURI: 'https://tokens.1inch.io/0x9813037ee2218799597d83d4a5b6f3b6778218d9.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9625ce7753ace1fa1865a47aae2c5c2ce4418569',
    //   symbol: 'KAP',
    //   decimals: 18,
    //   name: 'Kapital DAO Token',
    //   logoURI: 'https://tokens.1inch.io/0x9625ce7753ace1fa1865a47aae2c5c2ce4418569.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x9ab7bb7fdc60f4357ecfef43986818a2a3569c62',
    //   symbol: 'GOG',
    //   decimals: 18,
    //   name: 'Guild of Guardians',
    //   logoURI: 'https://tokens.1inch.io/0x9ab7bb7fdc60f4357ecfef43986818a2a3569c62.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x993864e43caa7f7f12953ad6feb1d1ca635b875f',
    //   symbol: 'SDAO',
    //   decimals: 18,
    //   name: 'Singularity Dao',
    //   logoURI: 'https://tokens.1inch.io/0x993864e43caa7f7f12953ad6feb1d1ca635b875f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x98585dfc8d9e7d48f0b1ae47ce33332cf4237d96',
    //   symbol: 'NEWO',
    //   decimals: 18,
    //   name: 'New Order',
    //   logoURI: 'https://tokens.1inch.io/0x98585dfc8d9e7d48f0b1ae47ce33332cf4237d96.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x954b890704693af242613edef1b603825afcd708',
    //   symbol: 'CARD',
    //   decimals: 18,
    //   name: 'Cardstack',
    //   logoURI: 'https://tokens.1inch.io/0x954b890704693af242613edef1b603825afcd708.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8fc8f8269ebca376d046ce292dc7eac40c8d358a',
    //   symbol: 'DFI_2',
    //   decimals: 8,
    //   name: 'DeFiChain Token',
    //   logoURI: 'https://tokens.1inch.io/0x8fc8f8269ebca376d046ce292dc7eac40c8d358a.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x8db1d28ee0d822367af8d220c0dc7cb6fe9dc442',
    //   symbol: 'ETHPAD',
    //   decimals: 18,
    //   name: 'ETHPAD.network',
    //   logoURI: 'https://tokens.1inch.io/0x8db1d28ee0d822367af8d220c0dc7cb6fe9dc442.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1a4b46696b2bb4794eb3d4c26f1c55f9170fa4c5',
    //   symbol: 'BIT',
    //   decimals: 18,
    //   name: 'BitDAO',
    //   logoURI: 'https://tokens.1inch.io/0x1a4b46696b2bb4794eb3d4c26f1c55f9170fa4c5.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x83249c6794bca5a77eb8c0af9f1a86e055459cea',
    //   symbol: 'GIGA',
    //   decimals: 9,
    //   name: 'GigaSwap',
    //   logoURI: 'https://tokens.1inch.io/0x83249c6794bca5a77eb8c0af9f1a86e055459cea.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x823556202e86763853b40e9cde725f412e294689',
    //   symbol: 'ASTO',
    //   decimals: 18,
    //   name: 'Altered State Machine Utility Token',
    //   logoURI: 'https://tokens.1inch.io/0x823556202e86763853b40e9cde725f412e294689.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7d29a64504629172a429e64183d6673b9dacbfce',
    //   symbol: 'VXV',
    //   decimals: 18,
    //   name: 'VectorspaceAI',
    //   logoURI: 'https://tokens.1inch.io/0x7d29a64504629172a429e64183d6673b9dacbfce.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x777e2ae845272a2f540ebf6a3d03734a5a8f618e',
    //   symbol: 'RYOSHI',
    //   decimals: 18,
    //   name: 'Ryoshis Vision',
    //   logoURI: 'https://tokens.1inch.io/0x777e2ae845272a2f540ebf6a3d03734a5a8f618e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x721a1b990699ee9d90b6327faad0a3e840ae8335',
    //   symbol: 'LOOT',
    //   decimals: 18,
    //   name: 'LOOT Token',
    //   logoURI: 'https://tokens.1inch.io/0x721a1b990699ee9d90b6327faad0a3e840ae8335.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x7105e64bf67eca3ae9b123f0e5ca2b83b2ef2da0',
    //   symbol: 'X7DAO',
    //   decimals: 18,
    //   name: 'X7DAO',
    //   logoURI: 'https://tokens.1inch.io/0x7105e64bf67eca3ae9b123f0e5ca2b83b2ef2da0.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x70008f18fc58928dce982b0a69c2c21ff80dca54',
    //   symbol: 'X7R',
    //   decimals: 18,
    //   name: 'X7R',
    //   logoURI: 'https://tokens.1inch.io/0x70008f18fc58928dce982b0a69c2c21ff80dca54.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x65ef703f5594d2573eb71aaf55bc0cb548492df4',
    //   symbol: 'MULTI',
    //   decimals: 18,
    //   name: 'Multichain',
    //   logoURI: 'https://tokens.1inch.io/0x65ef703f5594d2573eb71aaf55bc0cb548492df4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6368e1e18c4c419ddfc608a0bed1ccb87b9250fc',
    //   symbol: 'XTP',
    //   decimals: 18,
    //   name: 'Tap',
    //   logoURI: 'https://tokens.1inch.io/0x6368e1e18c4c419ddfc608a0bed1ccb87b9250fc.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x62b9c7356a2dc64a1969e19c23e4f579f9810aa7',
    //   symbol: 'cvxCRV',
    //   decimals: 18,
    //   name: 'Convex CRV',
    //   logoURI: 'https://tokens.1inch.io/0x62b9c7356a2dc64a1969e19c23e4f579f9810aa7.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6123b0049f904d730db3c36a31167d9d4121fa6b',
    //   symbol: 'RBN',
    //   decimals: 18,
    //   name: 'Ribbon',
    //   logoURI: 'https://tokens.1inch.io/0x6123b0049f904d730db3c36a31167d9d4121fa6b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5d285f735998f36631f678ff41fb56a10a4d0429',
    //   symbol: 'MIX',
    //   decimals: 18,
    //   name: 'MixMarvel Token',
    //   logoURI: 'https://tokens.1inch.io/0x5d285f735998f36631f678ff41fb56a10a4d0429.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5283d291dbcf85356a21ba090e6db59121208b44',
    //   symbol: 'BLUR',
    //   decimals: 18,
    //   name: 'Blur',
    //   logoURI: 'https://tokens.1inch.io/0x5283d291dbcf85356a21ba090e6db59121208b44.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5de8ab7e27f6e7a1fff3e5b337584aa43961beef',
    //   symbol: 'SDEX',
    //   decimals: 18,
    //   name: 'SmarDex',
    //   logoURI: 'https://tokens.1inch.io/0x5de8ab7e27f6e7a1fff3e5b337584aa43961beef.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5919dea604631016c15c805e3d948a0384879892',
    //   symbol: 'PEEP$',
    //   decimals: 9,
    //   name: 'The Peoples Coin',
    //   logoURI: 'https://tokens.1inch.io/0x5919dea604631016c15c805e3d948a0384879892.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb50721bcf8d664c30412cfbc6cf7a15145234ad1',
    //   symbol: 'ARB',
    //   decimals: 18,
    //   name: 'Arbitrum',
    //   logoURI: 'https://tokens.1inch.io/0xb50721bcf8d664c30412cfbc6cf7a15145234ad1.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x83f20f44975d03b1b09e64809b757c47f942beea',
    //   symbol: 'sDAI',
    //   decimals: 18,
    //   name: 'Savings Dai',
    //   logoURI: 'https://tokens.1inch.io/0x83f20f44975d03b1b09e64809b757c47f942beea.png',
    //   tags: ['PEG:USD', 'tokens'],
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb755506531786c8ac63b756bab1ac387bacb0c04',
    //   symbol: 'ZARP',
    //   decimals: 18,
    //   name: 'ZARP Stablecoin',
    //   logoURI: 'https://tokens.1inch.io/0xb755506531786c8ac63b756bab1ac387bacb0c04.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf951e335afb289353dc249e82926178eac7ded78',
    //   symbol: 'SWETH',
    //   decimals: 18,
    //   name: 'swETH',
    //   logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/25147.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6982508145454ce325ddbe47a25d4ec3d2311933',
    //   symbol: 'PEPE',
    //   decimals: 18,
    //   name: 'Pepe',
    //   logoURI: 'https://tokens.1inch.io/0x6982508145454ce325ddbe47a25d4ec3d2311933.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x5026f006b85729a8b14553fae6af249ad16c9aab',
    //   symbol: 'WOJAK',
    //   decimals: 18,
    //   name: 'Wojak Coin',
    //   logoURI: 'https://tokens.1inch.io/0x5026f006b85729a8b14553fae6af249ad16c9aab.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x856c4efb76c1d1ae02e20ceb03a2a6a08b0b8dc3',
    //   symbol: 'OETH',
    //   decimals: 18,
    //   name: 'Origin Ether',
    //   logoURI: 'https://tokens.1inch.io/0x856c4efb76c1d1ae02e20ceb03a2a6a08b0b8dc3.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa2e3356610840701bdf5611a53974510ae27e2e1',
    //   symbol: 'wBETH',
    //   decimals: 18,
    //   name: 'Wrapped Binance Beacon ETH',
    //   logoURI: 'https://tokens.1inch.io/0xa2e3356610840701bdf5611a53974510ae27e2e1.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xd1d2eb1b1e90b638588728b4130137d262c87cae',
    //   symbol: 'GALA',
    //   decimals: 8,
    //   name: 'Gala',
    //   logoURI: 'https://tokens.1inch.io/0xd1d2eb1b1e90b638588728b4130137d262c87cae.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x32b86b99441480a7e5bd3a26c124ec2373e3f015',
    //   symbol: 'BAD',
    //   decimals: 18,
    //   name: 'BAD IDEA AI',
    //   logoURI: 'https://tokens.1inch.io/0x32b86b99441480a7e5bd3a26c124ec2373e3f015.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xf939e0a03fb07f59a73314e73794be0e57ac1b4e',
    //   symbol: 'crvUSD',
    //   decimals: 18,
    //   name: 'Curve.Fi USD Stablecoin',
    //   logoURI: 'https://tokens.1inch.io/0xf939e0a03fb07f59a73314e73794be0e57ac1b4e.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x57f12fe6a4e5fe819eec699fadf9db2d06606bb4',
    //   symbol: 'NPM',
    //   decimals: 18,
    //   name: 'Neptune Mutual',
    //   logoURI: 'https://tokens.1inch.io/0x57f12fe6a4e5fe819eec699fadf9db2d06606bb4.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xea81dab2e0ecbc6b5c4172de4c22b6ef6e55bd8f',
    //   symbol: 'PLEB',
    //   decimals: 18,
    //   name: 'Plebbit',
    //   logoURI: 'https://assets.coingecko.com/coins/images/31903/large/WgMdNjT4_400x400.png?1696530713',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x78a0a62fba6fb21a83fe8a3433d44c73a4017a6f',
    //   symbol: 'OX_OLD',
    //   decimals: 18,
    //   name: 'Open Exchange Token OLD',
    //   logoURI: 'https://tokens.1inch.io/0x78a0a62fba6fb21a83fe8a3433d44c73a4017a6f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb2e96a63479c2edd2fd62b382c89d5ca79f572d3',
    //   symbol: 'wZNN',
    //   decimals: 8,
    //   name: 'Wrapped ZNN',
    //   logoURI: 'https://tokens.1inch.io/0xb2e96a63479c2edd2fd62b382c89d5ca79f572d3.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xa35b1b31ce002fbf2058d22f30f95d405200a15b',
    //   symbol: 'ETHX',
    //   decimals: 18,
    //   name: 'Stader ETHx',
    //   logoURI: 'https://tokens.1inch.io/0xa35b1b31ce002fbf2058d22f30f95d405200a15b.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x6e2a43be0b1d33b726f0ca3b8de60b3482b8b050',
    //   symbol: 'ARKM',
    //   decimals: 18,
    //   name: 'Arkham',
    //   logoURI: 'https://assets.coingecko.com/coins/images/30929/large/Arkham_Logo_CG.png?1689672102',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x40d16fc0246ad3160ccc09b8d0d3a2cd28ae6c2f',
    //   symbol: 'GHO',
    //   decimals: 18,
    //   name: 'Gho Token',
    //   logoURI: 'https://tokens.1inch.io/0x40d16fc0246ad3160ccc09b8d0d3a2cd28ae6c2f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
    //   symbol: 'HEX',
    //   decimals: 8,
    //   name: 'HEX',
    //   logoURI: 'https://tokens.1inch.io/0x2b591e99afe9f32eaa6214f7b7629768c40eeb39.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xe063f04f280c60aeca68b38341c2eecbec703ae2',
    //   symbol: 'xETH',
    //   decimals: 18,
    //   name: 'Leveraged ETH',
    //   logoURI: 'https://tokens.1inch.io/0xe063f04f280c60aeca68b38341c2eecbec703ae2.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x06ddb3a8bc0abc14f85e974cf1a93a6f8d4909d9',
    //   symbol: '8PAY v2',
    //   decimals: 18,
    //   name: '8PAY Network v2',
    //   logoURI: 'https://tokens.1inch.io/0x06ddb3a8bc0abc14f85e974cf1a93a6f8d4909d9.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb1f1ee126e9c96231cc3d3fad7c08b4cf873b1f1',
    //   symbol: 'BIFI',
    //   decimals: 18,
    //   name: 'Beefy',
    //   logoURI: 'https://tokens.1inch.io/0xb1f1ee126e9c96231cc3d3fad7c08b4cf873b1f1.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xed1167b6dc64e8a366db86f2e952a482d0981ebd',
    //   symbol: 'LBR',
    //   decimals: 18,
    //   name: 'Lybra Finance',
    //   logoURI: 'https://tokens.1inch.io/0xed1167b6dc64e8a366db86f2e952a482d0981ebd.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x776280f68ad33c4d49e6846507b7dbaf7811c89f',
    //   symbol: 'ZETH',
    //   decimals: 18,
    //   name: 'ZeroLiquid ETH',
    //   logoURI: 'https://tokens.1inch.io/0x776280f68ad33c4d49e6846507b7dbaf7811c89f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xda47862a83dac0c112ba89c6abc2159b95afd71c',
    //   symbol: 'PRISMA',
    //   decimals: 18,
    //   name: 'Prisma Finance',
    //   logoURI: 'https://tokens.1inch.io/0xda47862a83dac0c112ba89c6abc2159b95afd71c.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x056c1d42fb1326f57da7f19ebb7dda4673f1ff55',
    //   symbol: 'GAINS',
    //   decimals: 18,
    //   name: 'Gains',
    //   logoURI: 'https://tokens.1inch.io/0x056c1d42fb1326f57da7f19ebb7dda4673f1ff55.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x365accfca291e7d3914637abf1f7635db165bb09',
    //   symbol: 'FXN',
    //   decimals: 18,
    //   name: 'FXN Token',
    //   logoURI: 'https://tokens.1inch.io/0x365accfca291e7d3914637abf1f7635db165bb09.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb504035a11e672e12a099f32b1672b9c4a78b22f',
    //   symbol: 'SAFEREUM',
    //   decimals: 18,
    //   name: 'SAFEREUM',
    //   logoURI: 'https://tokens.1inch.io/0xb504035a11e672e12a099f32b1672b9c4a78b22f.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x0e573ce2736dd9637a0b21058352e1667925c7a8',
    //   symbol: 'USDV',
    //   decimals: 6,
    //   name: 'Verified USD',
    //   logoURI: 'https://tokens.1inch.io/0x0e573ce2736dd9637a0b21058352e1667925c7a8.png',
    // },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x455e53cbb86018ac2b8092fdcd39d8444affc3f6',
      symbol: 'POL',
      decimals: 18,
      name: 'Polygon Ecosystem Token',
      logoURI: 'https://tokens.1inch.io/0x455e53cbb86018ac2b8092fdcd39d8444affc3f6.png',
    },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xb131f4a55907b10d1f0a50d8ab8fa09ec342cd74',
    //   symbol: 'MEME',
    //   decimals: 18,
    //   name: 'Memecoin',
    //   logoURI: 'https://tokens.1inch.io/0xb131f4a55907b10d1f0a50d8ab8fa09ec342cd74.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x4507cef57c46789ef8d1a19ea45f4216bae2b528',
    //   symbol: 'TOKEN',
    //   decimals: 9,
    //   name: 'TokenFi',
    //   logoURI: 'https://tokens.1inch.io/0x4507cef57c46789ef8d1a19ea45f4216bae2b528.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x1a2eb478fa07125c9935a77b3c03a82470801e30',
    //   symbol: 'AMO',
    //   decimals: 18,
    //   name: 'Amino',
    //   logoURI: 'https://tokens.1inch.io/0x1a2eb478fa07125c9935a77b3c03a82470801e30.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee',
    //   symbol: 'weETH',
    //   decimals: 18,
    //   name: 'Wrapped eETH',
    //   logoURI: 'https://tokens.1inch.io/0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee.png',
    // },
    // {
    //   chainId: ChainId.ETHEREUM,
    //   address: '0x35fa164735182de50811e8e2e824cfb9b6118ac2',
    //   symbol: 'EETH',
    //   decimals: 18,
    //   name: 'ether fi',
    //   logoURI: 'https://tokens.1inch.io/0x35fa164735182de50811e8e2e824cfb9b6118ac2.png',
    // },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xde342a3e269056fc3305f9e315f4c40d917ba521',
      symbol: 'BYTE',
      decimals: 9,
      name: 'Byte',
      logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/28664.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x8457ca5040ad67fdebbcc8edce889a335bc0fbfb',
      symbol: 'ALT',
      decimals: 18,
      name: 'AltLayer Token',
      logoURI: 'https://tokens.1inch.io/0x8457ca5040ad67fdebbcc8edce889a335bc0fbfb.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x15e6e0d4ebeac120f9a97e71faa6a0235b85ed12',
      symbol: 'SAVM',
      decimals: 18,
      name: 'SatoshiVM',
      logoURI: 'https://tokens.1inch.io/0x15e6e0d4ebeac120f9a97e71faa6a0235b85ed12.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0x3429d03c6f7521aec737a0bbf2e5ddcef2c3ae31',
      symbol: 'PIXEL',
      decimals: 18,
      name: 'Pixels',
      logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/29335.png',
    },
    {
      chainId: ChainId.ETHEREUM,
      address: '0xba0dda8762c24da9487f5fa026a9b64b695a07ea',
      symbol: 'OX',
      decimals: 18,
      name: 'OX Coin',
    },
  ],
}
