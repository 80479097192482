import { transparentize } from 'polished'
import React, { useMemo } from 'react'
import { useMedia } from 'react-use'
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme,
} from 'styled-components'
import { useIsDarkMode } from '../state/user/hooks'
import { Text, TextProps } from 'rebass'
import { Colors } from './styled'

export * from './components'

const MEDIA_WIDTHS = {
  upToExtra2Small: 320,
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1200,
  upToExtraLarge: 1440,
}

export const useMediaWith = () => {
  return {
    upToExtra2Small: useMedia(`(max-width: ${MEDIA_WIDTHS.upToExtra2Small}px)`),
    upToExtraSmall: useMedia(`(max-width: ${MEDIA_WIDTHS.upToExtraSmall}px)`),
    upToSmall: useMedia(`(max-width: ${MEDIA_WIDTHS.upToSmall}px)`),
    upToMedium: useMedia(`(max-width: ${MEDIA_WIDTHS.upToMedium}px)`),
    upToLarge: useMedia(`(max-width: ${MEDIA_WIDTHS.upToLarge}px)`),
    upToExtraLarge: useMedia(`(max-width: ${MEDIA_WIDTHS.upToExtraLarge}px)`),
  }
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

export function colors(darkMode: boolean): Colors {
  return {
    // base
    white,
    black,

    // text
    text1: '#39404E',
    text2: darkMode ? '#C7C7C7' : '#FFFFFF',
    text3: 'rgba(255, 255, 255, 0.3)',
    text4: '#C3C5CB',
    text5: darkMode ? '#373F4C' : '#FFFFFF',
    text6: darkMode ? '#727272' : '#000000',
    landingText: darkMode ? '#FFF0CD' : '#0000AE',
    disabledTextColor: darkMode ? '#676767' : '#C4C4C4',
    appBg: darkMode ? '#090D1A' : 'radial-gradient(100% 100% at 0% 0%, #79A5C6 0%, #2A4874 100%)',
    reversedBg: darkMode
      ? 'radial-gradient(38.53% 139% at 100% 100%, #1B3255 0%, rgba(27, 50, 85, 0) 100%), #1F2841;'
      : 'radial-gradient(47.62% 170.64% at 100% 100%, #7EA6C5 0%, rgba(110, 151, 186, 0) 100%), #34547E;',
    fixedPanelBg: darkMode
      ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 49.48%, rgba(211, 211, 211, 0.08) 69.79%, rgba(255, 255, 255, 0.08) 100%)'
      : 'linear-gradient(180deg, rgba(54, 61, 77, 0.25) 0%, rgba(49, 56, 69, 0.25) 49.48%, rgba(43, 48, 60, 0.25) 69.79%, rgba(28, 30, 39, 0.25) 100%)',
    // backgrounds / greys
    bg1: darkMode
      ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 49.48%, rgba(211, 211, 211, 0.08) 69.79%, rgba(255, 255, 255, 0.08) 100%)'
      : 'linear-gradient(180deg, rgba(54, 61, 77, 0.25) 0%, rgba(49, 56, 69, 0.25) 49.48%, rgba(43, 48, 60, 0.25) 69.79%, rgba(28, 30, 39, 0.25) 100%)',
    bg2: darkMode ? '#333333' : '#FFFFFF',
    bg3: '#FFFFFF',
    bg4: '#CED0D9',
    bg5: '#888D9B',
    bg6: darkMode ? 'rgba(255, 255, 255, 0.1)' : '#E5E5E5',

    componentBg1: darkMode
      ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 49.48%, rgba(211, 211, 211, 0.08) 69.79%, rgba(255, 255, 255, 0.08) 100%)'
      : 'linear-gradient(180deg, rgba(54, 61, 77, 0.25) 0%, rgba(49, 56, 69, 0.25) 49.48%, rgba(43, 48, 60, 0.25) 69.79%, rgba(28, 30, 39, 0.25) 100%)',
    componentBg2: 'rgba(255, 255, 255, 0.15)',
    componentBg3: 'rgba(255, 255, 255, 0.8)',
    componentBg4: darkMode
      ? 'linear-gradient(180deg, rgba(54, 61, 77, 0.25) 0%, rgba(49, 56, 69, 0.25) 49.48%, rgba(43, 48, 60, 0.25) 69.79%, rgba(54, 61, 77, 0.25) 100%)'
      : 'linear-gradient(180deg,rgba(54,61,77,0.25) 0%,rgba(49,56,69,0.25) 49.48%,rgba(43,48,60,0.25) 69.79%,rgba(28,30,39,0.25) 100%)',
    componentBg5: 'rgba(255, 255, 255, 0.05)',
    accountPanelBg: darkMode
      ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 49.48%, rgba(211, 211, 211, 0.08) 69.79%, rgba(255, 255, 255, 0.08) 100%)'
      : ' linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.25) 49.48%, rgba(255, 255, 255, 0.25) 69.79%, rgba(255, 255, 255, 0.25) 100%)',
    dropDownBg: darkMode
      ? '#1a243f'
      : 'linear-gradient(180deg, rgba(54, 61, 77, 0.25) 0%, rgba(49, 56, 69, 0.25) 49.48%, rgba(43, 48, 60, 0.25) 69.79%, rgba(28, 30, 39, 0.25) 100%)',
    componentBg2Hover: 'rgba(255, 255, 255, 0.3)',

    inputBg: '#FFFFFF',
    inputShadow:
      '0px 38px 94px rgba(0, 0, 0, 0.09), 0px 19.2375px 40.9781px rgba(0, 0, 0, 0.06075), 0px 7.6px 15.275px rgba(0, 0, 0, 0.045), 0px 1.6625px 5.43437px rgba(0, 0, 0, 0.02925)',

    buttonBg1: darkMode ? '#3c3c3c' : '#CED0D9',
    buttonBg1Hover: darkMode ? '#535353' : '#b6b7ba',
    buttonBg2: darkMode ? '#474747' : '#CED0D9',
    buttonBg2Hover: darkMode ? '#595959' : '#b7b8be',
    buttonBg3: darkMode ? '#333333' : '#F1F1F1',
    buttonGradientBg: 'linear-gradient(-45deg, #BE7F34, #DA3768, #2C67B5, #3EBFA0)',
    buttonGradientHover: 'linear-gradient(to right, #5E36BE 0%, #EC9AE1 50%, #5E36BE 100%)',

    //specialty colors
    modalOverlay: darkMode ? 'rgba(0, 4, 16, 0.6)' : 'rgba(0, 7, 30, 0.6)',
    modalBG: darkMode
      ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 49.48%, rgba(211, 211, 211, 0.08) 69.79%, rgba(255, 255, 255, 0.08) 100%)'
      : 'linear-gradient(180deg, rgba(54, 61, 77, 0.25) 0%, rgba(49, 56, 69, 0.25) 49.48%, rgba(43, 48, 60, 0.25) 69.79%, rgba(28, 30, 39, 0.25) 100%)',
    advancedBG: '#F1F1F1',
    grayBG: darkMode ? '#333333' : '#F1F1F1',

    //primary colors
    primary1: '#D5B78B',
    primary2: darkMode ? 'rgba(237, 237, 237, 0.1)' : 'rgba(17, 17, 17, 0.1)',
    primary3: darkMode ? '#3838F1' : '#0000AE',
    primary4: darkMode ? '#3838F1' : '#0000AE',
    primary5: darkMode ? '#6c6c6c' : '#EBEBEB',

    // color text
    primaryText1: '#FFFFFF',
    secondaryText1: 'rgba(255, 255, 255, 0.75)',
    secondaryText2: darkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(17, 17, 17, 0.6)',
    secondaryText3: darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(17, 17, 17, 0.3)',
    footerText: darkMode ? '#FFF0CD' : '#0000AE',
    confirmedText: '#787878',
    fixedPanelText: 'rgba(255, 255, 255, 0.5)',

    // other
    red1: '#E2726B',
    red2: '#FF837B',
    red3: '#D60000',
    green1: darkMode ? '#81dab1' : '#7FD0B3',
    green2: '#0D9295',
    yellow1: '#FFE270',
    yellow2: '#FF9D42',

    accent: 'radial-gradient(100% 100% at 0% 0%, #79A5C6 0%, #2A4874 100%)',
    accent2: '#fff',
    accentText: '#C7C7C7',
    accentHover: '',
    accent2Hover: '#e4ce61',

    link: '#FFFFFF',

    borderRadius: '10px',

    activeTabBg: 'linear-gradient(91.08deg, #39404E 1.23%, #373F4C 100%)', /////////////
    activeGreyBg: '#4E4E4E',

    accent2Border: darkMode ? '' : '#D5B78B',
    tabsBorder: darkMode ? '#C7C7C7' : '#FFFFFF',
    border1: 'rgba(255, 255, 255, 0.15)',
    border2: 'rgba(255, 255, 255, 0.45)',
    border3: 'rgba(204, 177, 117, 0.2)',
    border2Active: darkMode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(17, 17, 17, 0.3)',

    mixedAccentColor: darkMode ? '#D5B78B' : '#FFFFFF',

    tableRowShadow: darkMode
      ? '0px 12.16px 14.7936px rgba(0, 0, 0, 0.04384), 0px 2.72px 6.4032px rgba(0, 0, 0, 0.02768)'
      : '0px 10.125px 17.4375px rgba(159, 151, 151, 0.0945), 0px 0.875px 2.3125px rgba(159, 151, 151, 0.0455)',
    menuBoxShadow: darkMode
      ? '0px 12.16px 14.7936px rgba(0, 0, 0, 0.04384), 0px 2.72px 6.4032px rgba(0, 0, 0, 0.02768)'
      : '0px 12.16px 14.7936px rgba(0, 0, 0, 0.04384), 0px 2.72px 6.4032px rgba(0, 0, 0, 0.02768)',

    mobileBackdropBg: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 76.53%, #c1c1c1 100%)',
    mobileBackdropFilter:
      'drop-shadow(0px 100px 80px rgba(255, 255, 255, 0.03)), drop-shadow(0px 50.625px 34.875px rgba(255, 255, 255, 0.02025)), drop-shadow(0px 20px 13px rgba(255, 255, 255, 0.015)), drop-shadow(0px 4.375px 4.625px rgba(255, 255, 255, 0.00975))',
    modalBoxShadow: '0px 38px 54px rgba(0, 0, 0, 0.13), 0px 7.6px 8.775px rgba(0, 0, 0, 0.065)',
    footerBg: darkMode
      ? 'linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111111 100%)'
      : 'linear-gradient(180deg, rgba(241, 241, 241, 0) 0%, #F1F1F1 100%)',
    footerLandingBg: darkMode
      ? 'linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111111 100%)'
      : 'linear-gradient(180deg, rgba(241, 241, 241, 0) 0%, #F1F1F1 100%)',
    headerLandingBg: darkMode
      ? 'linear-gradient(270deg, rgba(56, 56, 241, 0.2) 0.43%, rgba(56, 56, 241, 0) 87.85%), rgba(29, 29, 29, 0.6)'
      : 'linear-gradient(270deg, rgba(56, 56, 241, 0.2) 0.43%, rgba(56, 56, 241, 0) 87.85%), rgba(255, 255, 255, 0.8)',
    statusOpen: '#83B691',
    statusFilled: '#83B691',
    statusPartFilled: 'rgba(131, 182, 145, 0.5)',
    statusCanceled: 'rgba(17, 17, 17, 0.3)',

    contrastShadow:
      '0px 20px 40px rgba(0, 0, 0, 0.14), 0px 10.125px 17.4375px rgba(0, 0, 0, 0.0945), 0px 4px 6.5px rgba(0, 0, 0, 0.07), 0px 0.875px 2.3125px rgba(0, 0, 0, 0.0455)',
    contrastElementShadow: '0px 0px 3px rgba(0, 0, 0, 0.1)',

    sectionsBoxShadow: darkMode
      ? '0px 60px 123px rgba(0, 0, 47, 0.05), 0px 30.375px 53.6203px rgba(0, 0, 47, 0.03375), 0px 12px 19.9875px rgba(0, 0, 47, 0.025), 0px 2.625px 7.11094px rgba(0, 0, 47, 0.01625)'
      : '0px 60px 123px rgba(0, 0, 47, 0.05), 0px 30.375px 53.6203px rgba(0, 0, 47, 0.03375), 0px 12px 19.9875px rgba(0, 0, 47, 0.025), 0px 2.625px 7.11094px rgba(0, 0, 47, 0.01625)',

    scrollbarTrackColor: '#FFFFFF',
    scrollbarThumbColor:
      'linear-gradient(180deg, rgba(54, 61, 77, 0.25) 0%, rgba(49, 56, 69, 0.25) 49.48%, rgba(43, 48, 60, 0.25) 69.79%, rgba(28, 30, 39, 0.25) 100%)',

    chartsPreloaderGradient:
      'linear-gradient(90deg,rgb(60, 136, 109, 0.23) 0%,rgba(127, 208, 179, 0.16) 30%,rgb(60, 136, 109, 0.23) 75%,rgba(127, 208, 179, 0.16) 100%)',
    sidebarMenuItemGradient: 'linear-gradient(88.3deg,#FFFFFF,#0000AE 100%),#2f2f2f',

    toggleBackground: 'radial-gradient(100% 100% at 0% 0%, #79A5C6 0%, #2A4874 100%)',
    // dont wanna forget these blue yet
    // blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
  }
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    darkMode,

    //shadows
    shadow1: '#2F80ED',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode()

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={400} color={'primaryText1'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={400} color={'primary1'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={400} color={'primaryText1'} {...props} />
  },
  white(props: TextProps) {
    return <TextWrapper fontWeight={400} color={'white'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={12} color={'primaryText1'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  small(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={11} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={400} color={'primaryText1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={400} color={'yellow1'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={400} color={'secondaryText1'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={400} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={400} color={error ? 'red1' : 'text2'} {...props} />
  },
}

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {
  font-family: 'IBM Plex Sans', sans-serif;
}


html,
body {
  margin: 0;
  padding: 0;
}

body.no-scroll {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
}

button {
  user-select: none;
}

button, a {
  transition: opacity 0.3s;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  
  &:active {
    opacity: 0.4;
  }
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on; 
}

.ReactModalPortal .ReactModal__Content {
  top: 50% !important;
  left: 10px !important;
  right: 10px !important;
  bottom: unset !important;
}

.ReactModalPortal .ReactModal__Overlay {
  opacity: 0;
	transition: opacity 150ms ease-in-out;
}

.ReactModalPortal .ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close{
  opacity: 0;
}

.Drawer .ReactModal__Content {
  top: 0 !important;
  left: unset !important;
  right: 0 !important;
  bottom: 0 !important;
  margin-left: auto !important;
  transform: translateX(100%);
  transition: transform 150ms ease-in-out;

  &:focus-visible {
    outline: none;
  }
}

.Drawer .ReactModal__Overlay {
  opacity: 0;
	transition: opacity 150ms ease-in-out;
}

.Drawer .ReactModal__Overlay--after-open{
  opacity: 1;
}

.Drawer .ReactModal__Overlay--before-close{
  opacity: 0;
}

.Drawer .ReactModal__Content--after-open{
  transform: translateX(0%);
}

.Drawer .ReactModal__Content--before-close{
  transform: translateX(100%);
}
`

export const ThemedGlobalStyle = createGlobalStyle<{ isMobile: boolean }>`
html {
  color: ${({ theme }) => theme.primaryText1};
  background: ${({ theme }) => theme.appBg};
}

body {
  min-height: 100vh;
}

a {
  color: ${({ theme }) => theme.link}; 
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: ${({ theme }) => theme.scrollbarTrackColor}; 
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: ${({ theme }) => theme.scrollbarThumbColor}; 
}

// transitions

.confirmed-button-enter{
  width: 48%;
}
.confirmed-button-enter-active{
  width: 60px;
}
.confirmed-button-enter-active{
  transition: width 500ms ease-out;
}
`
