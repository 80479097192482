import { useActiveWeb3React, useChainId, useUserWalletConnection } from '../../hooks'
import { Box, shortenAddress } from 'shared'
import { CheckCircle, Copy, ExternalLink } from 'react-feather'
import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk'
import {
  AccountPanelAction,
  AccountPanelBox,
  AccountPanelChainStatus,
  AccountPanelCol,
  AccountPanelHr,
  AccountPanelIconAction,
  AccountPanelIconRow,
  AccountPanelLabel,
  AccountPanelPurchase,
  AccountPanelRow,
  AccountPanelText,
  AccountPanelTitle,
  DisconnectButton,
} from './styles'
import { TransactionsIcon } from 'icons/TransactionsIcon'
import { Identicon } from 'components/Identicon'
import { DebankIcon } from 'icons/DebankIcon'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { ChainId } from 'sdk'
import { chains } from 'components/ChainToggle/constants'
import { CardIcon } from 'icons/CardIcon'
import { useTotalNetworth } from 'hooks/useTotalNetworth'
import { formatDollarAmount } from 'utils/chartUtils'
import Skeleton from 'react-loading-skeleton'
import { getExplorerLink } from 'utils'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  selectedChainId: ChainId
}

export const AccountPanel = ({ selectedChainId }: Props) => {
  const { account } = useActiveWeb3React()
  const { deactivate } = useWeb3React()
  const chainId = useChainId()
  const [isCopied, setCopied] = useCopyClipboard()
  const { totalNetworth, walletTokensInfoIsLoading } = useTotalNetworth()
  const { setUserWalletConnection } = useUserWalletConnection()
  const supportedDefaultAssets = {
    [ChainId.FANTOM]: 'FANTOM_FTM',
    [ChainId.BSC]: 'BSC_BNB',
    [ChainId.POLYGON]: 'MATIC_MATIC',
  }
  const rampInstance = new RampInstantSDK({
    hostAppName: 'Lif3',
    hostLogoUrl: 'https://assets.lif3.com/wallet/ramp/Lif3.com-dark.svg',
    hostApiKey: 'k4jnfagsmjemjbfaxocxsnbtq2g76wfyvkdv42cs',
    defaultAsset: supportedDefaultAssets[chainId],
    enabledFlows: ['ONRAMP', 'OFFRAMP'],
    defaultFlow: 'ONRAMP',
    userAddress: account ?? '',
  })
  const handleRampShow = () => rampInstance.show()

  const getScanUrl = () => {
    switch (chainId) {
      case ChainId.FANTOM:
        return `https://ftmscan.com/address/${account}`
      case ChainId.POLYGON:
        return `https://polygonscan.com/address/${account}`
      case ChainId.BSC:
        return `https://bscscan.com/address/${account}`
      case ChainId.TOMB:
        return `https://tombscout.com/address/${account}`
      default:
        return ''
    }
  }

  return (
    <AccountPanelBox>
      <AccountPanelRow>
        <AccountPanelTitle>Account</AccountPanelTitle>
        <DisconnectButton
          onClick={() => {
            deactivate()
            setUserWalletConnection(false)
          }}
        >
          Disconnect
        </DisconnectButton>
      </AccountPanelRow>
      {account && (
        <>
          <AccountPanelRow>
            <Identicon size={30} />
            <AccountPanelCol>
              <AccountPanelTitle>{shortenAddress(account)}</AccountPanelTitle>
              <AccountPanelText>MetaMask</AccountPanelText>
            </AccountPanelCol>
            <AccountPanelIconRow>
              <AccountPanelIconAction href={`https://debank.com/profile/${account}`} target="_blank" rel="noreferrer">
                <DebankIcon />
              </AccountPanelIconAction>
              <AccountPanelIconAction as="button" onClick={() => setCopied(account)}>
                {isCopied ? <CheckCircle /> : <Copy />}
              </AccountPanelIconAction>
              <AccountPanelIconAction href={getScanUrl()} target="_blank" rel="noreferrer">
                <ExternalLink />
              </AccountPanelIconAction>
            </AccountPanelIconRow>
          </AccountPanelRow>
          <AccountPanelHr />
        </>
      )}
      <AccountPanelAction as={Link} to="/portfolio">
        <AccountPanelTitle>Portfolio</AccountPanelTitle>
        {walletTokensInfoIsLoading ? (
          <Skeleton baseColor="#090D1A" width={40} />
        ) : (
          <AccountPanelText>{formatDollarAmount(Number(totalNetworth.toString()), 2)}</AccountPanelText>
        )}
      </AccountPanelAction>
      <AccountPanelHr />
      <AccountPanelRow>
        <AccountPanelCol>
          <AccountPanelLabel>Network</AccountPanelLabel>
          <Box display="flex" alignItems="center">
            {chainId === ChainId.FANTOM && <AccountPanelChainStatus></AccountPanelChainStatus>}
            <AccountPanelText>{chains[selectedChainId]?.name}</AccountPanelText>
          </Box>
        </AccountPanelCol>
      </AccountPanelRow>
      <AccountPanelHr />
      <AccountPanelRow>
        <AccountPanelPurchase onClick={handleRampShow}>
          <CardIcon />
          Buy Crypto
        </AccountPanelPurchase>
      </AccountPanelRow>
      <AccountPanelHr />
      <AccountPanelAction href={getExplorerLink(account as any, 'address', chainId)} target="_blank" rel="noreferrer">
        <AccountPanelText>Transactions</AccountPanelText>
        <Box width="14px" height="14px">
          <TransactionsIcon />
        </Box>
      </AccountPanelAction>
    </AccountPanelBox>
  )
}
