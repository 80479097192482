import { CurrencyAmount, JSBI, TokenAmount, Trade, Currency, NATIVE_CURRENCY } from 'sdk'
import { Box, setupNetwork } from 'shared'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import AddressInputPanel from 'components/AddressInputPanel'
import { ButtonError, ButtonPrimary, ButtonConfirmed, ConfirmedIcon } from 'components/Button'
import { GreyCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import ConfirmSwapModal from 'components/swap/ConfirmSwapModal'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import Row, { AutoRow, RowBetween } from 'components/Row'
import AdvancedSwapDetailsSection from 'components/swap/AdvancedSwapDetailsSection'
import confirmPriceImpactWithoutFee from 'components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, BottomInfo, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import TradePrice from 'components/swap/TradePrice'

import { INITIAL_ALLOWED_SLIPPAGE } from '../../../../constants'
import { useActiveWeb3React, useChainId } from 'hooks'
import { ApprovalState, useApproveCallbackFromTrade } from 'hooks/useApproveCallback'
import { useSwapCallback } from 'hooks/useSwapCallback'
import { useAddPopup, useToggleSettingsMenu, useWalletModalToggle } from 'state/application/hooks'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSelectedCurrencies,
  useSwapActionHandlers,
  useSwapState,
} from 'state/swap/hooks'
import { useUserSlippageTolerance, useUserSingleHopOnly } from 'state/user/hooks'
import { LinkStyledButton, TYPE } from 'theme'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from 'utils/prices'
import { ClickableText } from 'pages/Pool/styleds'
import Loader from 'components/Loader'
import { CurrencyDirection } from 'enums/common'
import { ArrowDownIcon } from 'icons/ArrowDownIcon'
import QuestionHelper from 'components/QuestionHelper'
import { halfAmountSpend } from 'utils/halfAmountSpend'
import { waitAndFinalizeTransaction } from 'utils/waitAndFinalizeTransaction'
import { AcceptIcon } from 'icons/AcceptIcon'
import { LoadingDotsIcon } from 'ui/LoadingDotsIcon'
import { useLPorCurrenciesBalanceQuery, useLPorCurrencyBalanceQuery } from 'state/wallet/hooks'
import { usePairsReservesQuery } from 'hooks/queries/usePairsReservesQuery'
import { useReservesTokensMap } from 'data/Reserves'
import { useAllPairCombinations } from 'hooks/Trades'
import { SimplexSection } from '../components/SimplexSection'
import { useGetSimplexQuoteQuery } from 'hooks/queries/useGetSimplexQuoteQuery'
import { SUPPORTED_NETWORKS_IDS } from 'connectors'
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback'
import { SwapCurrenciesIcon } from 'icons/SwapCurrenciesIcon'
import { createNumberForPopUp, getPopupText } from 'shared/utils/getPopupText'

type SwapProps = {
  didChainChange: boolean
}

export default function SwapMarket({ didChainChange }: SwapProps) {
  useDefaultsFromURLSearch(didChainChange)

  const { account } = useActiveWeb3React()
  const chainId = useChainId()
  const theme = useContext(ThemeContext)
  const addPopup = useAddPopup()

  const { inputCurrency, wrappedInputCurrency, wrappedOutputCurrency } = useSelectedCurrencies()
  const allPairCombinations = useAllPairCombinations(wrappedInputCurrency, wrappedOutputCurrency)
  const tokens = useReservesTokensMap(allPairCombinations)
  const pairsWithReservesQuery = usePairsReservesQuery(tokens)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const toggleSettings = useToggleSettingsMenu()
  // const [isExpertMode] = useExpertModeManager()
  const isExpertMode = false

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  const getSimplexQuoteQuery = useGetSimplexQuoteQuery()

  // swap state
  const { independentField, typedValue, recipient, limitValue } = useSwapState()
  const { v2Trade, currencyBalances, parsedAmount, currencies, inputError: swapInputError } = useDerivedSwapInfo()

  const trade = v2Trade

  const refetchBalances = async () => {
    await Promise.all([
      updateBalances.refetch(),
      updateInputCurrencyBalanceQuery.refetch(),
      updateOutputCurrencyBalanceQuery.refetch(),
      pairsWithReservesQuery.refetch(),
    ])
  }

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(
    currencies[CurrencyDirection.INPUT],
    currencies[CurrencyDirection.OUTPUT],
    typedValue,
    refetchBalances
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE

  const parsedAmounts = showWrap
    ? {
        [CurrencyDirection.INPUT]: parsedAmount,
        [CurrencyDirection.OUTPUT]: parsedAmount,
      }
    : {
        [CurrencyDirection.INPUT]: independentField === CurrencyDirection.INPUT ? parsedAmount : trade?.inputAmount,
        [CurrencyDirection.OUTPUT]: independentField === CurrencyDirection.OUTPUT ? parsedAmount : trade?.outputAmount,
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient, onUserLimitInput } =
    useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: CurrencyDirection =
    independentField === CurrencyDirection.INPUT ? CurrencyDirection.OUTPUT : CurrencyDirection.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(CurrencyDirection.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(CurrencyDirection.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts =
    currencies.INPUT === Currency.USD_CARD
      ? {
          [independentField]: typedValue,
          [dependentField]: getSimplexQuoteQuery.data?.digitalMoney?.amount
            ? String(getSimplexQuoteQuery.data?.digitalMoney?.amount)
            : '0',
        }
      : {
          [independentField]: typedValue,
          [dependentField]: showWrap
            ? parsedAmounts[independentField]?.toExact() ?? ''
            : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
        }

  const route = trade?.route

  const userHasSpecifiedInputOutput = Boolean(
    currencies[CurrencyDirection.INPUT] &&
      currencies[CurrencyDirection.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(chainId, currencyBalances[CurrencyDirection.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[CurrencyDirection.INPUT]?.equalTo(maxAmountInput))

  const halfAmountInput: TokenAmount | undefined = halfAmountSpend(chainId, currencyBalances[CurrencyDirection.INPUT])
  const atHalfAmountInput = Boolean(
    halfAmountInput && parsedAmounts[CurrencyDirection.INPUT]?.equalTo(JSBI.BigInt(halfAmountInput.raw))
  )

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade, chainId)
  const updateBalances = useLPorCurrenciesBalanceQuery()
  const updateInputCurrencyBalanceQuery = useLPorCurrencyBalanceQuery(currencies[CurrencyDirection.INPUT] ?? undefined)
  const updateOutputCurrencyBalanceQuery = useLPorCurrencyBalanceQuery(
    currencies[CurrencyDirection.OUTPUT] ?? undefined
  )

  const [singleHopOnly] = useUserSingleHopOnly()

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then(async (response) => {
        await waitAndFinalizeTransaction(response.hash, chainId)
        addPopup(
          {
            txn: {
              hash: response.hash,
              success: true,
              summary: getPopupText(
                {
                  inputAmount: createNumberForPopUp(+formattedAmounts[CurrencyDirection.INPUT]),
                  outputAmount: createNumberForPopUp(+formattedAmounts[CurrencyDirection.OUTPUT]),
                  inputSymbol: currencies[CurrencyDirection.INPUT]?.getSymbol() ?? '',
                  outputSymbol: currencies[CurrencyDirection.OUTPUT]?.getSymbol() ?? '',
                },
                'V3'
              ),
            },
          },
          response.hash
        )
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: undefined,
          txHash: response.hash,
        })
        handleTypeInput('')
        await refetchBalances()
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        })

        setTimeout(() => {
          setSwapState({
            showConfirm: false,
            tradeToConfirm: undefined,
            attemptingTxn: false,
            swapErrorMessage: undefined,
            txHash: undefined,
          })
        }, 5000)
      })
  }, [
    priceImpactWithoutFee,
    swapCallback,
    tradeToConfirm,
    showConfirm,
    recipient,
    account,
    trade,
    singleHopOnly,
    chainId,
  ])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(CurrencyDirection.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(CurrencyDirection.INPUT, inputCurrency)
    },
    [onCurrencySelection, chainId]
  )

  const handleOutputSelect = useCallback(
    (outputCurrency) => onCurrencySelection(CurrencyDirection.OUTPUT, outputCurrency),
    [onCurrencySelection, chainId]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(CurrencyDirection.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const handleHalfInput = useCallback(() => {
    halfAmountInput && onUserInput(CurrencyDirection.INPUT, halfAmountInput.toExact())
  }, [halfAmountInput, onUserInput])

  const isWrongAppNetwork = !SUPPORTED_NETWORKS_IDS.includes(chainId ?? 0)

  const limitOrderOutputAmount = limitValue
    ? (Number(formattedAmounts[CurrencyDirection.INPUT]) * Number(limitValue)).toFixed(2)
    : formattedAmounts[CurrencyDirection.OUTPUT]

  const renderBottomGrouping = () => {
    if (currencies[CurrencyDirection.INPUT] === Currency.USD_CARD) {
      return <SimplexSection usdAmount={formattedAmounts[CurrencyDirection.INPUT]} />
    }

    return (
      <>
        <BottomGrouping style={{ marginBottom: '16px' }}>
          {!account || isWrongAppNetwork ? (
            <ButtonPrimary onClick={isWrongAppNetwork ? () => setupNetwork(chainId) : toggleWalletModal}>
              Connect Wallet
            </ButtonPrimary>
          ) : showWrap ? (
            <ButtonPrimary disabled={Boolean(wrapInputError)} className="btn" onClick={onWrap}>
              {wrapInputError ?? (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
            </ButtonPrimary>
          ) : noRoute && userHasSpecifiedInputOutput ? (
            <GreyCard style={{ textAlign: 'center' }}>
              <TYPE.main mb="4px">Insufficient liquidity for this trade.</TYPE.main>
              {singleHopOnly && <TYPE.main mb="4px">Try enabling multi-hop trades.</TYPE.main>}
            </GreyCard>
          ) : showApproveFlow ? (
            <RowBetween gap="16px">
              <ButtonConfirmed
                onClick={approveCallback}
                disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                confirmed={approval === ApprovalState.APPROVED}
              >
                {approval === ApprovalState.PENDING ? (
                  <AutoRow gap="6px" justify="center">
                    Approving <Loader stroke="#ffffff" />
                  </AutoRow>
                ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                  <ConfirmedIcon>
                    <AcceptIcon />
                  </ConfirmedIcon>
                ) : (
                  'Approve ' + currencies[CurrencyDirection.INPUT]?.getSymbol()
                )}
              </ButtonConfirmed>
              <ButtonError
                onClick={handleSwap}
                disabled={!isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)}
                error={isValid && priceImpactSeverity > 2}
              >
                <Text fontSize={16} fontWeight={500}>
                  {priceImpactSeverity > 3 && !isExpertMode
                    ? `Price Impact High`
                    : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                </Text>
              </ButtonError>
            </RowBetween>
          ) : (
            <ButtonError
              onClick={handleSwap}
              disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError || attemptingTxn}
              error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
            >
              <Text fontSize={15} fontWeight={500}>
                {swapInputError
                  ? swapInputError
                  : priceImpactSeverity > 3 && !isExpertMode
                  ? `Price Impact Too High`
                  : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
              </Text>
              {attemptingTxn ? (
                <Box ml="10px" width="20px" height="20px">
                  <LoadingDotsIcon size={20} color="black" />
                </Box>
              ) : null}
            </ButtonError>
          )}
          {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
        </BottomGrouping>
        {showWrap ? null : (
          <BottomInfo>
            {
              <Box mb="4px">
                <AutoColumn gap="4px">
                  {Boolean(trade) && (
                    <RowBetween align="center">
                      <Text fontWeight={500} fontSize={13} color={theme.secondaryText1}>
                        Price
                      </Text>
                      <Row gap="8px" width="auto">
                        {/* {inputCurrency !== Currency.USD_CARD && <ActionButtons />} */}
                        <TradePrice
                          price={trade?.executionPrice}
                          showInverted={showInverted}
                          setShowInverted={setShowInverted}
                        />
                      </Row>
                    </RowBetween>
                  )}
                  {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                    <RowBetween align="center">
                      <ClickableText
                        fontWeight={400}
                        fontSize={13}
                        color={theme.secondaryText1}
                        onClick={toggleSettings}
                      >
                        Slippage Tolerance
                      </ClickableText>
                      <Box display="flex" alignItems="center">
                        <ClickableText
                          fontWeight={400}
                          fontSize={13}
                          color={theme.primaryText1}
                          onClick={toggleSettings}
                        >
                          {allowedSlippage / 100}%
                        </ClickableText>
                        <QuestionHelper text="Your transaction will revert if the price changes unfavorably by more than this percentage." />
                      </Box>
                    </RowBetween>
                  )}
                </AutoColumn>
              </Box>
            }
            <AdvancedSwapDetailsSection trade={trade} />
          </BottomInfo>
        )}
      </>
    )
  }

  return (
    <>
      <Wrapper>
        <ConfirmSwapModal
          isOpen={showConfirm}
          trade={trade}
          originalTrade={tradeToConfirm}
          onAcceptChanges={handleAcceptChanges}
          attemptingTxn={attemptingTxn}
          txHash={txHash}
          recipient={recipient}
          allowedSlippage={allowedSlippage}
          onConfirm={handleSwap}
          swapErrorMessage={swapErrorMessage}
          onDismiss={handleConfirmDismiss}
        />
        <AutoColumn gap={isExpertMode ? '6px' : '5px'}>
          <CurrencyInputPanel
            label={independentField === CurrencyDirection.OUTPUT && trade && !showWrap ? 'From (estimated)' : 'From'}
            value={formattedAmounts[CurrencyDirection.INPUT]}
            currency={currencies[CurrencyDirection.INPUT]}
            onUserInput={handleTypeInput}
            showMaxButton={!atMaxAmountInput}
            onMax={handleMaxInput}
            showHalfButton={!atHalfAmountInput}
            onHalf={handleHalfInput}
            onCurrencySelect={handleInputSelect}
            otherCurrency={currencies[CurrencyDirection.OUTPUT]}
            cornerRadiusBottomNone={isExpertMode ? false : true}
            currencyDirection={CurrencyDirection.INPUT}
            //containerBackground={'#101b31'}
          />
          <ArrowWrapper clickable>
            <Box
              width="24px"
              onClick={() => {
                setApprovalSubmitted(false) // reset 2 step UI for approvals
                onSwitchTokens()
              }}
            >
              <SwapCurrenciesIcon />
            </Box>
          </ArrowWrapper>
          <CurrencyInputPanel
            value={limitOrderOutputAmount}
            onUserInput={handleTypeOutput}
            label={independentField === CurrencyDirection.INPUT && trade && !showWrap ? 'To (estimated)' : 'To'}
            showMaxButton={false}
            currency={currencies[CurrencyDirection.OUTPUT]}
            onCurrencySelect={handleOutputSelect}
            otherCurrency={currencies[CurrencyDirection.INPUT]}
            cornerRadiusTopNone={isExpertMode ? false : true}
            currencyDirection={CurrencyDirection.OUTPUT}
            inputDisabled={currencies[CurrencyDirection.INPUT] === Currency.USD_CARD}
            //containerBackground={'#16182b'}
          />
          {recipient !== null && !showWrap ? (
            <>
              <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                <ArrowWrapper clickable={false}>
                  <ArrowDownIcon />
                </ArrowWrapper>

                <LinkStyledButton color={theme.red1} onClick={() => onChangeRecipient(null)}>
                  - Remove send
                </LinkStyledButton>
              </AutoRow>
              <AddressInputPanel value={recipient} onChange={onChangeRecipient} />
            </>
          ) : null}
          {renderBottomGrouping()}
        </AutoColumn>
      </Wrapper>
    </>
  )
}
