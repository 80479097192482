import JSBI from 'jsbi'

import { ChainId, SolidityType } from '../constants'
import { validateSolidityTypeInstance } from '../utils'

/**
 * A currency is any fungible financial instrument on Ethereum, including Ether and all ERC20 tokens.
 *
 * The only instance of the base class `Currency` is Ether.
 */
export class Currency {
  public readonly decimals: number
  public readonly symbol?: string
  public readonly name?: string
  public readonly address?: string

  public static readonly ETHER: Currency = new Currency(
    18,
    'ETH',
    'Ethereum',
    '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
  )

  public static readonly BNB: Currency = new Currency(18, 'BNB', 'Binance Coin')

  public static readonly FTM: Currency = new Currency(18, 'FTM', 'Fantom')

  public static readonly POL: Currency = new Currency(18, 'POL', 'Polygon')

  public static readonly XDAI: Currency = new Currency(18, 'XDAI', 'xDai')

  public static readonly GLMR: Currency = new Currency(18, 'GLMR', 'Glimmer')

  public static readonly AVAX: Currency = new Currency(18, 'AVAX', 'Avalanche')

  public static readonly HT: Currency = new Currency(18, 'HT', 'Heco Token')

  public static readonly METIS: Currency = new Currency(18, 'FTM', 'Tomb')

  public static readonly USD_CARD: Currency = new Currency(6, 'USD', 'Visa / Master Card')

  public static readonly LIF3: Currency = new Currency(18, 'LIF3', 'Lif3')

  public static readonly NATIVE = Currency.FTM

  /**
   * Constructs an instance of the base class `Currency`. The only instance of the base class `Currency` is `Currency.ETHER`.
   * @param decimals decimals of the currency
   * @param symbol symbol of the currency
   * @param name of the currency
   */
  protected constructor(decimals: number, symbol?: string, name?: string, address?: string) {
    validateSolidityTypeInstance(JSBI.BigInt(decimals), SolidityType.uint8)

    this.decimals = decimals
    this.symbol = symbol
    this.name = name
    this.address = address
  }

  public static getNativeCurrencySymbol() {
    return this.NATIVE.symbol
  }

  public static getNativeCurrencyName() {
    return this.NATIVE.name
  }

  public getSymbol() {
    if (this?.symbol === 'FTM') {
      return Currency.getNativeCurrencySymbol()
    }

    // if (this?.symbol === 'WETH') {
    //   return `W${Currency.getNativeCurrencySymbol(chainId)}`
    // }

    return this?.symbol
  }

  public getName() {
    if (this?.name === 'FTM') {
      return Currency.getNativeCurrencyName()
    }

    return this?.name
  }
}

export const NATIVE_CURRENCY = {
  [ChainId.FANTOM]: Currency.FTM,
  [ChainId.BSC]: Currency.BNB,
  [ChainId.POLYGON]: Currency.POL,
  [ChainId.AMOY_TESTNET]: Currency.POL,
  [ChainId.ETHEREUM]: Currency.ETHER,
  [ChainId.OPTIMISM]: Currency.ETHER,
  [ChainId.ARBITRUM]: Currency.ETHER,
  [ChainId.BASE]: Currency.ETHER,
  [ChainId.LIF3CHAIN_TESTNET]: Currency.LIF3,
}

const FTM = Currency.FTM

export { FTM }
