import React, { useMemo } from 'react'
import { useChainId } from 'hooks'
import { useMediaWith } from 'theme'
import { Text } from 'rebass'
import {
  EarnDropdownBox,
  EarnDropdownCol,
  EarnDropdownTitle,
  EarnDropdownList,
  EarnDropdownListItem,
  EarnDropdownListItemCol,
  EarnDropdownListItemTitle,
  EarnDropdownListItemText,
  ArrowRightStyledIcon,
  GardenStyledIcon,
  DropStyledIcon,
  GreenhouseStyledIcon,
  FountainStyledIcon,
  Lif3StyledIcon,
  TerraceStyledIcon,
  PoolsStyledIcon,
} from './styles'
import {
  CHAIND_IDS_WITHOUT_SUITE,
  CHAIN_IDS_FOR_POOLS,
  SUPPORTED_CHAIN_IDS_FOR_DEFI_FEATURES,
  CHAIN_IDS_FOR_NURSERY,
} from 'components/Header/constants'
import { ChainId } from 'sdk/constants'
import terraceIcon from 'assets/images/terrace-icon.png'

export const EarnDropdown = ({ toggleIcon }: { toggleIcon?: (state: boolean) => void }) => {
  const chainId = useChainId()
  const chainIdOnlySearchParams = useMemo(() => new URLSearchParams(`chainId=${chainId}`), [chainId])
  const fantomSearchParams = useMemo(() => new URLSearchParams(`chainId=${ChainId.FANTOM}`), [chainId])
  const allowedChain = CHAIND_IDS_WITHOUT_SUITE.includes(chainId)
  const { upToMedium } = useMediaWith()

  const isEthereum = chainId === ChainId.ETHEREUM
  const isLif3Testnet = chainId === ChainId.LIF3CHAIN_TESTNET
  const isFantom = chainId === ChainId.FANTOM
  const isPolygon = chainId === ChainId.POLYGON
  const isPolygonAmoy = chainId === ChainId.AMOY_TESTNET
  const isChainAllowedForPools = CHAIN_IDS_FOR_POOLS.includes(chainId)
  const isChainAllowedForDefiFeatures = SUPPORTED_CHAIN_IDS_FOR_DEFI_FEATURES.includes(chainId)
  const isChainAllowedForNursery = CHAIN_IDS_FOR_NURSERY.includes(chainId)

  return (
    <EarnDropdownBox onMouseEnter={() => toggleIcon?.(true)} onMouseLeave={() => toggleIcon?.(false)}>
      <EarnDropdownCol>
        <EarnDropdownTitle>Earn</EarnDropdownTitle>
        <EarnDropdownList>
          {isChainAllowedForPools && !upToMedium && (
            <EarnDropdownListItem
              to={{
                pathname: '/pools',
                search: chainIdOnlySearchParams.toString(),
              }}
            >
              <PoolsStyledIcon />
              <EarnDropdownListItemCol>
                <EarnDropdownListItemTitle>V3 Pools</EarnDropdownListItemTitle>
                <EarnDropdownListItemText>
                  Provide liquidity and earn swap fees & $LSHARE incentives.
                </EarnDropdownListItemText>
              </EarnDropdownListItemCol>
              <ArrowRightStyledIcon />
            </EarnDropdownListItem>
          )}
          {isChainAllowedForDefiFeatures && (
            <EarnDropdownListItem
              to={{
                pathname: '/pool',
                search: chainIdOnlySearchParams.toString(),
              }}
            >
              <DropStyledIcon />
              <EarnDropdownListItemCol>
                <EarnDropdownListItemTitle>V2 Pools</EarnDropdownListItemTitle>
                <EarnDropdownListItemText>Provide liquidity and earn swap fees.</EarnDropdownListItemText>
              </EarnDropdownListItemCol>
              <ArrowRightStyledIcon />
            </EarnDropdownListItem>
          )}
          {isChainAllowedForDefiFeatures && (
            <Text fontSize="13px" color="#FFFFFF80" paddingLeft="15px">
              {'Legacy (Ended)'}
            </Text>
          )}
          {isChainAllowedForDefiFeatures && (
            <EarnDropdownListItem
              to={{
                pathname: '/garden',
                search: allowedChain ? fantomSearchParams.toString() : chainIdOnlySearchParams.toString(),
              }}
            >
              <GardenStyledIcon />
              <EarnDropdownListItemCol>
                <EarnDropdownListItemTitle>The Garden</EarnDropdownListItemTitle>
                {/* <EarnDropdownListItemText>
                  Stake your liquidity tokens here to start earning LSHARE rewards.
                </EarnDropdownListItemText> */}
              </EarnDropdownListItemCol>
              <ArrowRightStyledIcon />
            </EarnDropdownListItem>
          )}

          {/* TODO: temporary on hold */}
          {/* <EarnDropdownListItem to={{ pathname: 'https://trade.lif3.com/earn' }} target="_blank">
            <Lif3StyledIcon />
            <EarnDropdownListItemCol>
              <EarnDropdownListItemTitle>Stake Lif3</EarnDropdownListItemTitle>
              <EarnDropdownListItemText>Stake LIF3 and to earn rewards.</EarnDropdownListItemText>
            </EarnDropdownListItemCol>
            <ArrowRightStyledIcon />
          </EarnDropdownListItem> */}
          {isChainAllowedForDefiFeatures && (
            <EarnDropdownListItem
              to={{
                pathname: '/staking',
                search: allowedChain ? fantomSearchParams.toString() : chainIdOnlySearchParams.toString(),
              }}
            >
              <GreenhouseStyledIcon />
              <EarnDropdownListItemCol>
                <EarnDropdownListItemTitle>Greenhouse</EarnDropdownListItemTitle>
                {/* <EarnDropdownListItemText>Single-stake your tokens and earn by lending.</EarnDropdownListItemText> */}
              </EarnDropdownListItemCol>
              <ArrowRightStyledIcon />
            </EarnDropdownListItem>
          )}
          {isChainAllowedForDefiFeatures && (
            <EarnDropdownListItem
              to={{
                pathname: '/terrace',
                search: allowedChain ? fantomSearchParams.toString() : chainIdOnlySearchParams.toString(),
              }}
            >
              <TerraceStyledIcon src={terraceIcon} alt="Terrace" />
              <EarnDropdownListItemCol>
                <EarnDropdownListItemTitle>Terrace</EarnDropdownListItemTitle>
                {/* <EarnDropdownListItemText>Leverage your liquidity, lend and borrow.</EarnDropdownListItemText> */}
              </EarnDropdownListItemCol>
              <ArrowRightStyledIcon />
            </EarnDropdownListItem>
          )}
          {isFantom && (
            <EarnDropdownListItem
              to={{
                pathname: '/fountain',
                // search: fantomSearchParams.toString(),
              }}
            >
              <FountainStyledIcon />
              <EarnDropdownListItemCol>
                <EarnDropdownListItemTitle>Fountain</EarnDropdownListItemTitle>
                {/* <EarnDropdownListItemText>
                  Lif3 rewards are automatically staked in the Greenhouse to generate additional yield.
                </EarnDropdownListItemText> */}
              </EarnDropdownListItemCol>
              <ArrowRightStyledIcon />
            </EarnDropdownListItem>
          )}
          {isChainAllowedForNursery && (
            <EarnDropdownListItem
              to={{
                pathname: '/nursery',
                search: isEthereum
                  ? chainIdOnlySearchParams.toString()
                  : allowedChain
                  ? fantomSearchParams.toString()
                  : chainIdOnlySearchParams.toString(),
              }}
            >
              <Lif3StyledIcon />
              <EarnDropdownListItemCol>
                <EarnDropdownListItemTitle>The Nursery</EarnDropdownListItemTitle>
                <EarnDropdownListItemText>
                  {isEthereum ? 'Deposit $ETH, $USDT or $BTC and earn $LIF3!' : ''}
                </EarnDropdownListItemText>
              </EarnDropdownListItemCol>
              <ArrowRightStyledIcon />
            </EarnDropdownListItem>
          )}
        </EarnDropdownList>
      </EarnDropdownCol>
    </EarnDropdownBox>
  )
}
