import { ChainId } from 'sdk'

export const V3_SUBGRAPH_URL = {
  [ChainId.FANTOM]: 'https://api.studio.thegraph.com/query/72851/lif3-v3/v0.0.1',
  [ChainId.ETHEREUM]: 'https://api.studio.thegraph.com/query/72851/lif3-v3-mainnet/v0.0.1',
  [ChainId.POLYGON]: 'https://api.studio.thegraph.com/query/72851/lif3-v3-polygon/v0.0.1',
  [ChainId.LIF3CHAIN_TESTNET]: 'https://testnet-graph-node.lif3chain.com/subgraphs/name/lifev3',
  [ChainId.BSC]: 'https://api.studio.thegraph.com/query/72851/lif3-v3-bnb/v0.0.1',
}

export const STAKING_REWARDS_V3_SUBGRAPH_URL = {
  [ChainId.FANTOM]: 'https://api.studio.thegraph.com/query/69426/lif3-v3-staking-fantom/0.0.1',
  [ChainId.ETHEREUM]: 'https://api.studio.thegraph.com/query/72851/lif3v3-staking-mainnet/v0.0.1',
  [ChainId.POLYGON]: 'https://api.studio.thegraph.com/query/72851/lif3v3-staking-polygon/0.0.1',
  [ChainId.BSC]: 'https://api.studio.thegraph.com/query/72851/lif3v3-staking-bsc/0.0.2',
}
