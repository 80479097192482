import { Currency, NATIVE_CURRENCY, Token } from 'sdk'
import { Token as TokenV3, Currency as CurrencyV3 } from 'sdk/v3'
import { useMemo } from 'react'
import { isAddress } from '../utils'
import { getContract } from 'shared'
import { useQuery } from 'react-query'
import { currencyEquals } from 'sdk'
import { useUserAddedTokens } from 'state/user/hooks'
import { listedTokensList } from '../constants/aggregatorTokensList'
import { ERC20_ABI } from 'constants/abis/erc20'
import { PROVIDERS_BY_CHAIN } from 'connectors'
import { farmTokens } from 'pages/Farms/config/constants/tokens'
import { useChainId } from 'hooks'
import { getAddress } from '@ethersproject/address'
import { useSwapState } from 'state/swap/hooks'
import { swapTokensList } from 'constants/swapTokensList'
import { SwapType } from 'state/swap/reducer'
import { v3TokensList } from 'constants/v3/v3TokenList'
import { Ether } from 'sdk/v3'
import { marketTokenList } from 'constants/marketTokenList'

export const getCurrencyAddress = (currencyId: string | undefined, chainId: number) => {
  if (!currencyId) return
  if (currencyId === NATIVE_CURRENCY[chainId].symbol) return NATIVE_CURRENCY[chainId].symbol

  return currencyId
}

type TokenInfo = {
  address: string
  decimals: number
  level?: number
  logoURI: string
  name: string
  symbol: string
  website?: string
  slippage?: number
  socials?: Record<string, string>
}

// reduce token map into standard address <-> Token mapping, optionally include user added tokens
export function useMapTokensFromArray(tokensArray: Array<TokenInfo>): Record<string, Token> {
  return useMemo(() => {
    return tokensArray?.reduce((newMap, token) => {
      newMap[getAddress(token.address)] = new Token({
        address: getAddress(token.address),
        decimals: token.decimals,
        symbol: token.symbol,
        name: token.name,
        logo: token.logoURI,
        level: token.level,
        website: token.website,
        slippage: token.slippage,
        socials: token.socials,
      })

      return newMap
    }, {})
  }, [tokensArray])
}

export function useListedTokens(type?: SwapType) {
  const chainId = useChainId()
  const { selectedSwapType } = useSwapState()

  const getTokensList = () => {
    const typeValue = type ?? selectedSwapType

    switch (typeValue) {
      case 'Aggregator':
      case 'CrossChain':
        return listedTokensList[chainId]
      case 'Market':
        return marketTokenList[chainId]

      case 'V3':
        return v3TokensList[chainId]
      case 'Swap':
        return swapTokensList[chainId]

      default:
        return swapTokensList[chainId]
    }
  }

  return useMapTokensFromArray(getTokensList())
}

export const useFarmTokens = () => {
  const chainId = useChainId()

  const processedFarmTokens = Object.values(farmTokens).map((token) => {
    return {
      address: token.address[chainId],
      name: token.name,
      symbol: token.symbol,
      decimals: token.decimals,
      logoURI: token.logoURI,
    }
  })

  return useMapTokensFromArray(processedFarmTokens)
}

export const useUserAddedTokensMap = () => {
  const tokens = useUserAddedTokens()
  const tokensMap = tokens.reduce<Record<string, Token>>(
    (acc, token) => ({
      ...acc,
      [token.address]: token,
    }),
    {}
  )

  return tokensMap
}

// Check if currency is included in custom list from user storage
export function useIsUserAddedToken(currency: Currency | undefined | null): boolean {
  const userAddedTokens = useUserAddedTokens()

  if (!currency) {
    return false
  }

  return Boolean(userAddedTokens.find((token) => currencyEquals(currency, token)))
}

export function useIsTokenActive(token: Token | undefined | null): boolean {
  const listedTokens = useListedTokens()

  if (!listedTokens || !token) {
    return false
  }

  return Boolean(listedTokens[token.address])
}

// parse a name or symbol from a token response
const BYTES32_REGEX = /^0x[a-fA-F0-9]{64}$/

function parseString(str: string | undefined, defaultValue: string): string {
  return str && str.length > 0 ? str : defaultValue
}

export function useToken(tokenAddress?: string, swapType?: SwapType): Token | undefined | null {
  const listedTokens = useListedTokens(swapType)
  const userAddedTokens = useUserAddedTokens()
  const address = isAddress(tokenAddress)
  const chainId = useChainId()
  const token: Token | undefined = address && listedTokens ? listedTokens[getAddress(address)] : undefined

  const tokenInfoQuery = useQuery(
    ['get-token-info-use-token', tokenAddress, chainId],
    async () => {
      const tokenInfo = userAddedTokens.find((v) => v.address == address)
      let tokenName: string | undefined
      let symbol: string | undefined
      let decimals: number | undefined
      try {
        if (tokenInfo == undefined) {
          const tokenContract = getContract(address, ERC20_ABI, PROVIDERS_BY_CHAIN[chainId])
          tokenName = await tokenContract.name()
          symbol = await tokenContract.symbol()
          decimals = await tokenContract.decimals()
        } else {
          tokenName = tokenInfo?.name
          symbol = tokenInfo?.symbol
          decimals = tokenInfo?.decimals
        }
      } catch (error) {
        console.error(error)
        throw error
      }
      return { tokenName, symbol, decimals }
    },
    { enabled: !token && Boolean(tokenAddress) && Boolean(address), refetchOnMount: false }
  )

  if (token) return token
  if (!address) return undefined
  if (tokenInfoQuery.isLoading) return null
  const tokenData = tokenInfoQuery.data

  if (tokenData) {
    return new Token({
      address: address,
      decimals: tokenData.decimals ?? 18,
      symbol: parseString(tokenData.symbol, 'UNKNOWN'),
      name: parseString(tokenData.tokenName, 'Unknown Token'),
      logo: token?.logoURI,
    })
  }
  return undefined
}

export function useCurrency(currencyId: string | undefined, swapType?: SwapType): Token | Currency | null | undefined {
  const chainId = useChainId()
  const currencyAddress = getCurrencyAddress(currencyId, chainId)
  const token = useToken(currencyAddress, swapType)

  if (currencyAddress === 'USD') {
    return Currency.USD_CARD
  }

  return currencyAddress === NATIVE_CURRENCY[chainId].symbol ? NATIVE_CURRENCY[chainId] : token
}

export function useCurrencyV3(currencyId: string | undefined | null): CurrencyV3 | undefined {
  const chainId = useChainId()
  const currencyAddress = getCurrencyAddress(currencyId ?? '', chainId)
  const token = useToken(currencyAddress, 'V3')

  const lif3Token = listedTokensList[chainId].find((item: CurrencyV3) => item.symbol === currencyAddress)

  return currencyAddress === NATIVE_CURRENCY[chainId].symbol
    ? Ether.onChain(chainId)
    : currencyAddress === 'LIF3'
    ? new TokenV3(chainId, lif3Token.address, lif3Token.decimals, lif3Token.symbol, lif3Token.name)
    : token
    ? new TokenV3(chainId, token.address, token?.decimals, token.symbol, token.name)
    : undefined
}

export const useLiquidityCurrency = (currencyId: string | undefined): Currency | null | undefined => {
  const chainId = useChainId()
  const currencyAddress = getCurrencyAddress(currencyId, chainId)
  const token = useToken(currencyAddress)

  return currencyAddress === NATIVE_CURRENCY[chainId].symbol ? NATIVE_CURRENCY[chainId] : token
}
