import { ChainId } from 'sdk'

export const ORDER_BOOK_CONTRACT = '0xFeD154b53d92f2DcdcA49f8D48917008Bc6A8CC3'

export const PROJECT_TOKEN_ADDRESS = {
  [ChainId.TOMBCHAINTESTNET]: '0x0E1E4Ee9B9443D179f5C78eFF2Da3402C9819b6f',
  [ChainId.FANTOM]: '0x5e074C28149cC35c1e5fbC79d8A6ea688dba2314',
  [ChainId.BSC]: '0x336f374198c872Ba760e85af9c6331C3c5A535d3',
  [ChainId.POLYGON]: '0x110B25D2B21eE73eB401F3aE7833f7072912A0Bf',
  [ChainId.ETHEREUM]: '0x7138Eb0d563f3F6722500936A11DcAe99D738A2c',
}

export const LIFE_CONTRACT = '0xbf60e7414EF09026733c1E7de72E7393888C64DA'

export const USDC_CONTRACT = '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75'

export const L3USD_CONTRACT = '0x5f0456F728E2D59028b4f5B8Ad8C604100724C6A'

export const LSHARE_CONTRACT = '0xCbE0CA46399Af916784cADF5bCC3aED2052D6C45'

export const SETTLEMENT_CONTRACT = '0x8649c8B62CbaE9a86d097D3E85Cf69aAC8035B3c'

export const METIS_ADDRESS = '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83'

export const WFTM_ADDRESS = {
  [ChainId.FANTOM]: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
  [ChainId.BSC]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  [ChainId.POLYGON]: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
}

export const tombContracts = {
  treasury: '0xF50c6dAAAEC271B56FCddFBC38F0b56cA45E6f0d',
  tbond: '0x24248CD1747348bDC971a5395f4b3cd7feE94ea0',
}

export const L3USD_fUSDT_USDC_CURVE_LP_ADDRESS = '0x6E6c999e1893dc2E68b4193108bEC307b6C42f96'
export const DAI_CURVE_LP_ADDRESS = '0x78d51eb71a62c081550efcc0a9f9ea94b2ef081c'

export const CEMETERY_MASTERCHEF = '0x1f832dfba15346d25438cf7ac683b013ed03e32f'

export const fountainContracts = {
  treasury: {
    [ChainId.FANTOM]: '0xc626b744E12517a2891CA324CDCFf03a2C24372C',
    [ChainId.POLYGON]: '0xE947A4bCd52771e20787191bce21bF65010a0Dda',
    [ChainId.AMOY_TESTNET]: '0xeD08F1cA02ac88bdAC71fa2D62fB248Db5472002',
  },
  masonry: {
    [ChainId.FANTOM]: '0xB23AC6CbADef580c4825f128763F6c7DDAa0E0CE',
    [ChainId.POLYGON]: '0x3AFE1891BF84F6AB1D0085aE9ECe798Dc354D2CF',
    [ChainId.AMOY_TESTNET]: '0x2a783573888eD316f809C6E51a3644762B2e3AFb',
  },
}
