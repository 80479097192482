import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right-icon.svg'
import { ReactComponent as Lif3TokenIcon } from 'assets/images/logos/lif-logo.svg'
import { ReactComponent as LshareTokenIcon } from 'assets/images/logos/lshare.svg'
import { ReactComponent as L3usdIcon } from 'assets/images/logos/l3usd.svg'
import { ReactComponent as CreditCardIcon } from 'assets/svg/credit-card-icon.svg'
import { ReactComponent as BitmartIcon } from 'assets/svg/bitmart-logo.svg'
import { ReactComponent as BitfinexIcon } from 'assets/svg/bitfinext-logo.svg'
import { ReactComponent as MEXCIcon } from 'assets/svg/mexc-logo.svg'
import { ReactComponent as HowToBuyIcon } from 'assets/images/how-to-buy.svg'

export const BuyDropdownBox = styled.div`
  position: absolute;
  top: calc(100% + 15px);
  left: 0;
  transform-origin: 50px 0px;
  transform: translateX(0) scale(0.8);
  max-width: 400px;

  display: flex;
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
  padding: 30px;
  width: max-content;
  border-radius: 12px;
  background: linear-gradient(
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0.08) 49.48%,
    rgba(211, 211, 211, 0.08) 69.79%,
    rgba(255, 255, 255, 0.08) 100%
  );
  -webkit-backdrop-filter: blur(60px);
  backdrop-filter: blur(60px);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 20px 40px, rgba(0, 0, 0, 0.094) 0px 10.125px 17.4375px,
    rgba(0, 0, 0, 0.07) 0px 4px 6.5px, rgba(0, 0, 0, 0.047) 0px 0.875px 2.3125px;
  visibility: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 20px;
    position: absolute;
    top: -17px;
    left: 0;
  }

  @media screen and (max-width: 1510px) {
    transform: translateX(0) scale(0.8);
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    right: 0;
    transform: translateX(20%);
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    bottom: 10px;
    top: auto;
    left: 11px;
    right: 11px;
    transform: translate(0, 100%);

    width: calc(100% - 22px);
    max-width: 100%;
    padding: 20px;

    background: ${({ theme }) => theme.componentBg4};
    backdrop-filter: blur(30px);
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 15px 20px;
  `}

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 15px 10px;
  `}
`

export const BuyDropdownCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `}
`

export const BuyDropdownTitle = styled.div`
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #97a3b7;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`

export const BuyDropdownList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}
`

export const ArrowRightStyledIcon = styled(ArrowRight)`
  width: 6px;
  height: 10px;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
  margin-left: auto;
`

export const Lif3TokenStyledIcon = styled(Lif3TokenIcon)`
  width: 30px;
  height: 30px;
  transition: color 0.3s;
`

export const LshareTokenStyledIcon = styled(LshareTokenIcon)`
  width: 30px;
  height: 30px;
  transition: color 0.3s;
`

export const L3usdTokenStyledIcon = styled(L3usdIcon)`
  width: 30px;
  height: 30px;
  transition: color 0.3s;
`

export const CreditCardStyledIcon = styled(CreditCardIcon)`
  width: 30px;
  height: 22px;
  transition: color 0.3s;
`

export const HowToBuyStyledIcon = styled(HowToBuyIcon)`
  width: 32px;
  height: 32px;
  transition: color 0.3s;
`

export const BitmartStyledIcon = styled(BitmartIcon)`
  width: 27px;
  height: 30px;
  transition: color 0.3s;
`

export const BitfinexStyledIcon = styled(BitfinexIcon)`
  width: 27px;
  height: 30px;
  transition: color 0.3s;
`

export const MEXCStyledIcon = styled(MEXCIcon)`
  width: 30px;
  height: 18px;
  transition: color 0.3s;
`

export const BuyDropdownListItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 12px 15px;
  gap: 16px;
  text-decoration: none;
  border-radius: 10px;
  color: ${({ theme }) => theme.primaryText1};

  &:hover {
    background: #ffffff0d;
  }

  & > svg {
    flex: 0 0 auto;
    color: ${({ theme }) => theme.primaryText1};
  }

  &:hover > svg {
    color: ${({ theme }) => theme.secondaryText1};
  }

  &:hover > ${ArrowRightStyledIcon} {
    opacity: 1;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px 15px;
  `}

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 10px 3px;
  `}
`

export const BuyDropdownListItemCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const BuyDropdownListItemTitle = styled.span`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 15px;
  `}
`

export const BuyDropdownListItemText = styled.span`
  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: ${({ theme }) => theme.secondaryText1};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `}
`
