import { ChainId, CurrencyAmount, JSBI, NATIVE_CURRENCY, TokenAmount } from 'sdk'
import { useWeb3React } from '@web3-react/core'
import { Box, setupNetwork } from 'shared'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import AddressInputPanel from 'components/AddressInputPanel'
import { ButtonError, ButtonPrimary, ButtonPrimaryGradient } from 'components/Button'
import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import Row, { AutoRow, RowBetween } from 'components/Row'
import AdvancedSwapDetailsSection from 'components/swapAggregator/AdvancedSwapDetailsSection'
import { ArrowWrapper, BottomGrouping, BottomInfo, Wrapper } from 'components/swapAggregator/styleds'
import { Link } from 'react-router-dom'
import { useChainId } from 'hooks'
import { useAddPopup, useWalletModalToggle } from 'state/application/hooks'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapAggregatorInfo,
  useSwapODOS,
  useSelectedCurrencies,
  useSwapActionHandlers,
  useSwapState,
} from 'state/swap/hooks'
import { LinkStyledButton } from 'theme'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { getSigner } from 'utils'
import { formatByDecimals, formatToCurrency } from 'utils/formatters'

import { CurrencyDirection } from 'enums/common'
import { SwapCurrenciesIcon } from 'icons/SwapCurrenciesIcon'
import { ArrowDownIcon } from 'icons/ArrowDownIcon'
import { halfAmountSpend } from 'utils/halfAmountSpend'
import { LoadingDotsIcon } from 'ui/LoadingDotsIcon'
import { useBalances, useLPorCurrencyBalanceQuery } from 'state/wallet/hooks'

import { SUPPORTED_NETWORKS_IDS } from 'connectors'
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback'

import { BottomGroupingFooter, SwapCurrenciesIconWrapper } from './styles'
import { useODOSTokensPrices } from 'hooks/ODOS/useODOSTokensPrices'
import { formatAmount } from 'utils/formatAmount'
import { createNumberForPopUp, getPopupText } from 'shared/utils/getPopupText'
import { ROUTER_ADDRESSES_ODOS_V2 } from 'pages/Lending/config/web3/contracts/routers'
import { ZERO_ADDRESS } from 'constants/v3'
import { odosBaseUrl, polygonReferralCode } from 'sdk/constants'
import axios from 'axios'
import { useUserSlippageTolerance } from 'state/user/hooks'

type Props = {
  didChainChange: boolean
  disabled?: boolean
}

export default function Swap(props: Props) {
  useDefaultsFromURLSearch(props.didChainChange)

  const [swapError, setSwapError] = useState<string>('')
  const [price, setPrice] = useState('')
  const [isPriceFetching, setIsPriceFetching] = useState<boolean>()
  const addPopup = useAddPopup()
  const { account, library } = useWeb3React()
  const chainId = useChainId()
  const theme = useContext(ThemeContext)
  const { typedValue, recipient } = useSwapState()
  const { inputCurrency, wrappedInputCurrency, outputCurrency, wrappedOutputCurrency } = useSelectedCurrencies()
  const [isFetchingQuote, setIsFetchingQuote] = useState<boolean>(false)
  const [quoteResponse, setQuoteResponse] = useState<any>()

  const [formattedAmount, setFormattedAmount] = useState<string>()
  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // const [isExpertMode] = useExpertModeManager()
  const isExpertMode = false

  // get custom setting values for user
  const { currencyBalances, parsedAmount, currencies, inputError: swapInputError } = useDerivedSwapAggregatorInfo()
  const selectedCurrencyBalanceQuery = useLPorCurrencyBalanceQuery(currencies[CurrencyDirection.INPUT])
  const selectedOutputCurrencyBalanceQuery = useLPorCurrencyBalanceQuery(currencies[CurrencyDirection.OUTPUT])
  const { fetchSelectedTokensPrices } = useODOSTokensPrices()
  const [allowedSlippage] = useUserSlippageTolerance()

  const refetchBalances = async () => {
    await Promise.all([
      updateBalances.refetch(),
      updateInputCurrencyBalanceQuery.refetch(),
      updateOutputCurrencyBalanceQuery.refetch(),
    ])
  }

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(
    currencies[CurrencyDirection.INPUT],
    currencies[CurrencyDirection.OUTPUT],
    typedValue,
    refetchBalances
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE

  const parsedAmounts = showWrap
    ? {
        [CurrencyDirection.INPUT]: parsedAmount,
        [CurrencyDirection.OUTPUT]: parsedAmount,
      }
    : {
        [CurrencyDirection.INPUT]: parsedAmount,
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()

  const handleSwitchTokens = () => {
    if (isPriceFetching) {
      return
    }
    onSwitchTokens()
    handleTypeInput('')
    setFormattedAmount('')
  }

  const notifyInvalidInputAmount = () => {
    addPopup({
      notification: {
        success: false,
        text: 'The number of digits after the decimal point is too high.',
      },
    })
  }

  const handleTypeInput = (value: string) => {
    if (value.split('.')[1]?.length > 18) {
      return notifyInvalidInputAmount()
    }
    onUserInput(CurrencyDirection.INPUT, value)
  }

  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(CurrencyDirection.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ attemptingTxn }, setSwapState] = useState<{
    attemptingTxn: boolean
  }>({
    attemptingTxn: false,
  })

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(chainId, currencyBalances[CurrencyDirection.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[CurrencyDirection.INPUT]?.equalTo(maxAmountInput))

  const halfAmountInput: TokenAmount | undefined = halfAmountSpend(chainId, currencyBalances[CurrencyDirection.INPUT])
  const atHalfAmountInput = Boolean(
    halfAmountInput && parsedAmounts[CurrencyDirection.INPUT]?.equalTo(JSBI.BigInt(halfAmountInput.raw))
  )

  const updateBalances = useBalances()
  const updateInputCurrencyBalanceQuery = useLPorCurrencyBalanceQuery(currencies[CurrencyDirection.INPUT] ?? undefined)
  const updateOutputCurrencyBalanceQuery = useLPorCurrencyBalanceQuery(
    currencies[CurrencyDirection.OUTPUT] ?? undefined
  )

  const [symbol0, symbol0Postfix] = currencies[CurrencyDirection.INPUT]?.symbol?.split(' ') ?? []
  const [symbol1, symbol1Postfix] = currencies[CurrencyDirection.OUTPUT]?.symbol?.split(' ') ?? []

  const showConvert = symbol0 === symbol1 && (symbol0Postfix === '(Old)' || symbol1Postfix === '(Old)')

  const convertPrice = async () => {
    setIsPriceFetching(true)
    const price = await fetchSelectedTokensPrices()
    setIsPriceFetching(false)
    if (!price) {
      return
    }
    setPrice(price.toString())
  }
  const fetchQuote = useCallback(
    async (amount: string) => {
      if (!amount || Number(amount) <= 0 || !inputCurrency || !outputCurrency) {
        return
      }
      const fromAmountInWei = formatByDecimals(Number(amount), inputCurrency?.decimals as number)
      const quoteUrl = `${odosBaseUrl}/sor/quote/v2`

      const from = (inputCurrency === NATIVE_CURRENCY[chainId] ? ZERO_ADDRESS : wrappedInputCurrency?.address) ?? ''
      const to = (outputCurrency === NATIVE_CURRENCY[chainId] ? ZERO_ADDRESS : wrappedOutputCurrency?.address) ?? ''

      const quoteRequestBody = {
        chainId,
        compact: true,
        inputTokens: [
          {
            amount: fromAmountInWei.toString(),
            tokenAddress: from,
          },
        ],
        outputTokens: [
          {
            proportion: 1,
            tokenAddress: to,
          },
        ],
        referralCode: chainId === ChainId.POLYGON ? polygonReferralCode : 0,
        slippageLimitPercent: allowedSlippage / 100,
        sourceBlacklist: [],
        sourceWhitelist: [],
        userAddr: account,
      }
      const headers = { 'Content-Type': 'application/json' }
      setIsFetchingQuote(true)
      const quoteResponse = await axios.post(quoteUrl, quoteRequestBody, {
        headers,
      })
      setIsFetchingQuote(false)
      const parsedAmount = Number(quoteResponse.data.outAmounts[0]) / Math.pow(10, outputCurrency?.decimals)
      const formattedAmount = parsedAmount.toFixed(outputCurrency?.decimals || 2)

      setFormattedAmount(String(formattedAmount))
      setQuoteResponse(quoteResponse)
    },
    [chainId, inputCurrency, outputCurrency, allowedSlippage, account]
  )

  useEffect(() => {
    setFormattedAmount('')
    const handler = setTimeout(() => {
      if (!typedValue) {
        setQuoteResponse(null)
        setIsFetchingQuote(false)
        return
      }
      checkAllowance()
      fetchQuote(typedValue)
    }, 2000)

    return () => clearTimeout(handler)
  }, [typedValue])

  useEffect(() => {
    convertPrice()
  }, [currencies[(CurrencyDirection.INPUT, CurrencyDirection.OUTPUT)]])

  const { getCurrentAllowance, approveToken, simpleSwap } = useSwapODOS()

  const [allowanceNumber, setAllowanceNumber] = useState<string | undefined>()

  const checkAllowance = async () => {
    if (inputCurrency === NATIVE_CURRENCY[chainId]) {
      setAllowanceNumber(undefined)
      return
    }
    const fromAddress = wrappedInputCurrency?.address ?? ''

    const signer = getSigner(library, account ?? '')
    const allowanceNumber = await getCurrentAllowance(fromAddress, ROUTER_ADDRESSES_ODOS_V2[chainId], signer)
    setAllowanceNumber(allowanceNumber)
  }

  useEffect(() => {
    setFormattedAmount('0')
  }, [chainId])

  useEffect(() => {
    setSwapError(swapInputError ?? '')
  }, [swapInputError])

  const handleSwap = async () => {
    try {
      const typedValueNumber = Number(typedValue)
      setSwapState({
        attemptingTxn: true,
      })
      if (allowanceNumber !== undefined) {
        const fromAddress =
          (inputCurrency === NATIVE_CURRENCY[chainId] ? ZERO_ADDRESS : wrappedInputCurrency?.address) ?? ''

        const signer = getSigner(library, account ?? '')
        if (signer && typedValueNumber > Number(allowanceNumber)) {
          const fromAmountInWei = formatByDecimals(Number(typedValue), inputCurrency?.decimals as number)

          const approveTokenState = await approveToken(
            fromAddress,
            ROUTER_ADDRESSES_ODOS_V2[chainId],
            fromAmountInWei,
            signer
          )
          if (approveTokenState && approveTokenState?.status) {
            await checkAllowance()
          }
          setSwapState({
            attemptingTxn: false,
          })

          return
        }
      }
      const transaction = await simpleSwap(quoteResponse)

      if (transaction.error) {
        throw transaction.error
      }
      const hash = transaction?.transactionHash
      const from = currencies[CurrencyDirection.INPUT]?.symbol ?? ''
      const to = currencies[CurrencyDirection.OUTPUT]?.symbol ?? ''
      const fromAmount = Number(typedValue) ?? 0
      const toAmount = Number(formattedAmount) ?? 0

      addPopup({
        txn: {
          hash,
          success: true,
          summary: getPopupText(
            {
              inputAmount: formatToCurrency(Number(createNumberForPopUp(fromAmount))),
              outputAmount: formatToCurrency(Number(createNumberForPopUp(toAmount))),
              inputSymbol: from,
              outputSymbol: to,
            },
            'V3'
          ),
        },
      })
      handleTypeInput('')
      setFormattedAmount('')
      onUserInput(CurrencyDirection.INPUT, '')
      setSwapState({
        attemptingTxn: false,
      })
    } catch (error: any) {
      setSwapState({
        attemptingTxn: false,
      })
      // custom handle error message for large decimals
      if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') {
        addPopup(
          {
            notification: { success: false, text: 'User rejected the transaction' },
          },
          undefined
        )
      } else if (error.code === 1001) {
        addPopup({ notification: { success: false, text: `Error: Invalid "From" value` } })
      } else {
        addPopup({ notification: { success: false, text: `${error.name}: ${error.htmlMessage || error.message}` } })
      }
    }
  }

  // errors
  const getBalanceLabel = (): number => {
    const selectedCurrencyBalance = selectedCurrencyBalanceQuery.data
    const currency = currencies[CurrencyDirection.INPUT]
    if (!!currency && selectedCurrencyBalance) {
      return Number(selectedCurrencyBalance.toExact())
    }
    return 0
  }

  const getOutputBalanceLabel = (): number => {
    const selectedCurrencyBalance = selectedOutputCurrencyBalanceQuery.data
    const currency = currencies[CurrencyDirection.OUTPUT]

    if (!!currency && selectedCurrencyBalance) {
      return Number(selectedCurrencyBalance.toExact())
    }
    return 0
  }

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      onUserInput(CurrencyDirection.INPUT, '')
      onCurrencySelection(CurrencyDirection.INPUT, inputCurrency)
      convertPrice()
    },
    [onCurrencySelection, chainId]
  )

  const handleMaxInput = useCallback(async () => {
    const balance = getBalanceLabel()
    if (balance && currencies.INPUT && currencies.OUTPUT) {
      onUserInput(CurrencyDirection.INPUT, formatAmount(balance))
    }
  }, [maxAmountInput, onUserInput])

  const handleMaxOutput = useCallback(async () => {
    const price = await fetchSelectedTokensPrices()
    const balance = getOutputBalanceLabel()

    if (balance && currencies.INPUT && currencies.OUTPUT && price) {
      const value = Number(balance) / price ?? 0
      onUserInput(CurrencyDirection.INPUT, formatAmount(value))
      setFormattedAmount(formatAmount(balance))
    }
  }, [onUserInput, setFormattedAmount, selectedOutputCurrencyBalanceQuery])

  const handleHalfInput = useCallback(() => {
    const balance = getBalanceLabel()
    if (balance && currencies.INPUT && currencies.OUTPUT) {
      onUserInput(CurrencyDirection.INPUT, formatAmount(balance / 2))
    }
  }, [halfAmountInput, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency) => {
      onUserInput(CurrencyDirection.INPUT, '')
      onCurrencySelection(CurrencyDirection.OUTPUT, outputCurrency)
      convertPrice()
    },
    [onCurrencySelection, chainId]
  )

  const isWrongAppNetwork = !SUPPORTED_NETWORKS_IDS.includes(chainId ?? 0)

  const renderBottomGrouping = () => {
    let isAllowance = false
    if (allowanceNumber !== undefined) {
      if (Number(typedValue) > Number(allowanceNumber)) {
        isAllowance = true
      }
    }

    if (showConvert) {
      return (
        <BottomGrouping style={{ marginBottom: '16px' }}>
          <Box mb={3} display="flex" justifyContent="center" textAlign="center">
            Convert your {symbol0} (Old) to {symbol1} (New) 1:1 on the convert page.
          </Box>
          <Link style={{ textDecoration: 'none' }} to="/convert">
            <ButtonPrimary>Convert</ButtonPrimary>
          </Link>
        </BottomGrouping>
      )
    }

    return (
      <>
        <BottomGrouping style={{ marginBottom: '16px' }}>
          {!account || isWrongAppNetwork ? (
            <ButtonPrimaryGradient onClick={isWrongAppNetwork ? () => setupNetwork(chainId) : toggleWalletModal}>
              <Text>Connect Wallet</Text>
            </ButtonPrimaryGradient>
          ) : showWrap ? (
            <ButtonPrimary disabled={Boolean(wrapInputError)} className="btn" onClick={onWrap}>
              {wrapInputError ?? (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
            </ButtonPrimary>
          ) : (
            <ButtonError
              onClick={handleSwap}
              disabled={
                attemptingTxn ||
                !!swapError ||
                typedValue.split('.')[1]?.length > 18 ||
                isFetchingQuote ||
                !formattedAmount
              }
              error={false}
            >
              <Text fontSize={15} fontWeight={500}>
                {isAllowance ? `Approve ${inputCurrency?.symbol}` : swapInputError || 'Swap'}{' '}
              </Text>
              {attemptingTxn || isFetchingQuote ? (
                <Box ml="10px" width="20px" height="20px">
                  <LoadingDotsIcon size={20} color="black" />
                </Box>
              ) : null}
            </ButtonError>
          )}
        </BottomGrouping>
        {showWrap ? null : (
          <Box>
            <BottomInfo>
              {
                <Box mb="4px">
                  <AutoColumn gap="4px">
                    {Boolean(price) && (
                      <RowBetween align="center">
                        <Text fontWeight={500} fontSize={13} color={theme.secondaryText1}>
                          Price
                        </Text>
                        <Row gap="8px" width="auto">
                          <Text
                            fontSize={13}
                            color={theme.primaryText1}
                            style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                          >
                            {formatToCurrency(Number(parseFloat(price).toFixed(6)))}
                          </Text>
                        </Row>
                      </RowBetween>
                    )}
                  </AutoColumn>
                </Box>
              }
              <AdvancedSwapDetailsSection
                inputAmount={typedValue}
                outputAmount={String(formattedAmount)}
                currency={currencies[CurrencyDirection.OUTPUT]}
                gasPrice={quoteResponse?.data?.gasEstimateValue ?? 0}
              />
            </BottomInfo>
            <BottomGroupingFooter>
              <Text fontSize="15px" fontWeight={400}>
                Powered by
              </Text>
              <img width={70} src="https://assets.odos.xyz/logo_white_transparent.png"></img>
            </BottomGroupingFooter>
          </Box>
        )}
      </>
    )
  }

  return (
    <>
      <Wrapper
        style={{
          opacity: props.disabled ? '0.75' : 'unset',
          filter: props.disabled ? 'grayscale(100%) saturate(20%)' : 'unset',
        }}
      >
        <AutoColumn gap={isExpertMode ? '6px' : '2px'}>
          <CurrencyInputPanel
            listType="Aggregator"
            label={'From'}
            value={typedValue}
            currency={currencies[CurrencyDirection.INPUT]}
            onUserInput={handleTypeInput}
            showMaxButton={!atMaxAmountInput}
            onMax={handleMaxInput}
            showHalfButton={!atHalfAmountInput}
            onHalf={handleHalfInput}
            onCurrencySelect={handleInputSelect}
            otherCurrency={currencies[CurrencyDirection.OUTPUT]}
            cornerRadiusBottomNone={isExpertMode ? false : true}
            currencyDirection={CurrencyDirection.INPUT}
            showRefresh={true}
          />
          <Box position="relative" height="0">
            <Box width="24px" height="auto" onClick={handleSwitchTokens}>
              <SwapCurrenciesIconWrapper>
                <SwapCurrenciesIcon />
              </SwapCurrenciesIconWrapper>
            </Box>
          </Box>
          {recipient === null && isExpertMode && !showWrap ? (
            <LinkStyledButton onClick={() => onChangeRecipient('')}>+ Add a send (optional)</LinkStyledButton>
          ) : null}
          <CurrencyInputPanel
            listType="Aggregator"
            value={!typedValue ? '' : String(formattedAmount)}
            onUserInput={handleTypeOutput}
            label={'To (estimated)'}
            onMax={handleMaxOutput}
            showMaxButton={false}
            currency={currencies[CurrencyDirection.OUTPUT]}
            onCurrencySelect={handleOutputSelect}
            otherCurrency={currencies[CurrencyDirection.INPUT]}
            cornerRadiusTopNone={isExpertMode ? false : true}
            currencyDirection={CurrencyDirection.OUTPUT}
            inputDisabled={true}
            isFetchinQuote={isFetchingQuote}
          />
          {recipient !== null && !showWrap ? (
            <>
              <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                <ArrowWrapper clickable={false}>
                  <ArrowDownIcon />
                </ArrowWrapper>

                <LinkStyledButton color={theme.red1} onClick={() => onChangeRecipient(null)}>
                  - Remove send
                </LinkStyledButton>
              </AutoRow>
              <AddressInputPanel value={recipient} onChange={onChangeRecipient} />
            </>
          ) : null}
          {props.disabled ? (
            <BottomGrouping
              style={{
                marginBottom: '16px',
              }}
            >
              <ButtonError disabled={true} error={false}>
                <Text fontSize={15} fontWeight={500}>
                  Temporarily disabled
                </Text>
              </ButtonError>
            </BottomGrouping>
          ) : (
            renderBottomGrouping()
          )}
        </AutoColumn>
      </Wrapper>
    </>
  )
}
