import { ChainId, Currency, NATIVE_CURRENCY, Token } from 'sdk'
import React from 'react'
import styled from 'styled-components'

import cardsIcon from '../../assets/images/logos/cards-icon.svg'

import Logo from '../Logo'
import { useChainId } from 'hooks'

export const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  object-fit: contain;
`

const NATIVE_LOGO = {
  [ChainId.FANTOM]: 'https://assets.lif3.com/swap/tokens/FTM.svg',
  [ChainId.BSC]: 'https://assets.lif3.com/swap/tokens/BNB.svg',
  [ChainId.POLYGON]: 'https://assets.lif3.com/swap/tokens/MATIC.svg',
  [ChainId.ETHEREUM]: 'https://assets.lif3.com/swap/tokens/ETH.svg',
  [ChainId.OPTIMISM]: 'https://assets.lif3.com/swap/tokens/ETH.svg',
  [ChainId.ARBITRUM]: 'https://assets.lif3.com/swap/tokens/ETH.svg',
  [ChainId.BASE]: 'https://assets.lif3.com/swap/tokens/ETH.svg',
  [ChainId.LIF3CHAIN_TESTNET]: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
}

const getSrc = (currency: Currency | undefined | any, chainId: number) => {
  if (currency === NATIVE_CURRENCY[chainId]) return NATIVE_LOGO[chainId]

  if (currency?.symbol === NATIVE_CURRENCY[chainId].symbol) return NATIVE_LOGO[chainId]

  if (currency === Currency.USD_CARD) {
    return cardsIcon
  }

  return currency?.logo && currency.symbol !== 'LIF3'
    ? currency?.logoURI
    : `https://assets.lif3.com/swap/tokens/${currency?.symbol}.svg`
}

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const chainId = useChainId()

  return (
    <StyledLogo
      style={style}
      size={size}
      src={getSrc(currency, chainId) || ''}
      alt={`${currency?.symbol ?? 'token'} logo`}
    />
  )
}
