import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { NetworkContextName } from '../../constants'
import { useWalletModalToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'

import { Identicon } from '../Identicon'
import WalletModal from '../WalletModal'
import { Box, setupNetwork, shortenAddress, useOnClickOutside, WalletIcon } from 'shared'
import { LoadingDotsIcon } from 'ui/LoadingDotsIcon'
import useTheme from 'hooks/useTheme'
import { useBridgeState } from 'pages/Bridge/BridgeForm/hooks/useBridgeState'
import { setupBridgeNetwork } from 'pages/Bridge/BridgeForm/hooks/setupBridgeNetwork'
import { ConnectIcon } from 'icons/ConnectIcon'
import { ChainId } from 'sdk/constants'
import { ConnectButton, NetworkIcon, StyledDropDown, StyledText, Web3StatusConnected, Web3StatusError } from './styles'
import { AccountPanel } from 'components/AccountPanel'
import { useChainId } from 'hooks'
import { isNetworkSupported } from 'utils'
import SubgraphProvider from 'pages/Lending/contexts/SubgraphProvider'

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

function Web3StatusInner({ selectedChainId }: { selectedChainId: ChainId }) {
  const { account, error, chainId: userChainId, library, active } = useWeb3React()
  const URLChainId = useChainId()
  const location = useLocation()
  const theme = useTheme()
  const allTransactions = useAllTransactions()
  const bridgeState = useBridgeState()
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length

  const toggleWalletModal = useWalletModalToggle()

  const isBridgePage = location.pathname.startsWith('/bridge')

  const [accountPanelOpen, setAccountPanelOpen] = useState(false)
  const accountRef = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(accountRef, accountPanelOpen ? () => setAccountPanelOpen(!accountPanelOpen) : undefined)

  const isWrongBridgeNetwork = () => {
    return false
  }

  const isWrongAppNetwork = () => {
    if (!isBridgePage && userChainId !== URLChainId) {
      return true
    }

    return !isNetworkSupported(URLChainId)
  }

  if (!active && !(error instanceof UnsupportedChainIdError)) {
    return (
      <ConnectButton onClick={toggleWalletModal}>
        <ConnectIcon />
        <StyledText>Connect Wallet</StyledText>
      </ConnectButton>
    )
  }

  if (account && library && isBridgePage && isWrongBridgeNetwork()) {
    return (
      <Web3StatusError
        onClick={() => {
          setupBridgeNetwork({
            library,
            account,
            chainId: bridgeState.chainFrom,
          })
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <NetworkIcon />
        </Box>
        <StyledText>Wrong Network</StyledText>
      </Web3StatusError>
    )
  } else if (isWrongAppNetwork() || error instanceof UnsupportedChainIdError) {
    return (
      <Web3StatusError
        onClick={() => {
          setupNetwork(selectedChainId)
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <NetworkIcon />
        </Box>
        <StyledText>Wrong Network</StyledText>
      </Web3StatusError>
    )
  } else if (account) {
    return (
      <Box position="relative" ref={accountRef} onClick={() => setAccountPanelOpen(!accountPanelOpen)}>
        <Web3StatusConnected pending={hasPendingTransactions}>
          <Box width="12px" height="12px" display="flex" justifyContent="center" alignItems="center">
            {hasPendingTransactions ? (
              <LoadingDotsIcon color={theme.primaryText1} size={12} />
            ) : (
              <Identicon size={12} />
            )}
          </Box>
          <StyledText>{shortenAddress(account)}</StyledText>
          <StyledDropDown selected={accountPanelOpen} />
        </Web3StatusConnected>
        {accountPanelOpen && (
          <AccountPanel selectedChainId={selectedChainId} isOpen={accountPanelOpen} setIsOpen={setAccountPanelOpen} />
        )}
      </Box>
    )
  } else if (error) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <NetworkIcon />
        </Box>
        <StyledText>{error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}</StyledText>
      </Web3StatusError>
    )
  } else {
    return null
  }
}

export default function Web3Status({ selectedChainId }: { selectedChainId: ChainId }) {
  const { active } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  if (!contextNetwork.active && !active) {
    return null
  }

  return (
    <SubgraphProvider>
      <Web3StatusInner selectedChainId={selectedChainId} />
      <WalletModal selectedChainId={selectedChainId} />
    </SubgraphProvider>
  )
}
