import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { rgba } from 'polished'
import { Text } from 'rebass'
import { Box } from 'shared'
import { useMediaWith } from 'theme'
import { RowBetween, RowFixed } from 'components/Row'
import { ButtonBordered, ButtonPrimaryWhite } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { useStakingMethods } from 'hooks/v3/useStakingMethods'
import Logo from 'components/Logo'
import { TextContent } from './styles'
import { useUserRewardInfo } from 'hooks/v3/useUserRewardInfo'
import { useIncentivizedPools } from 'hooks/v3/useIncentivizedPools'
import { useCurrentIncentiveApr } from 'hooks/v3/useCurrentIncentiveApr'
import { useStakingPositionByTokenIdQuery } from 'hooks/queries/v3/useStakingPositionByTokenIdQuery'
import QuestionHelper from 'components/QuestionHelper'
import { usePoolById } from 'hooks/queries/v3/usePoolById'
import { ZERO_ADDRESS } from 'constants/v3'

export type StakingSteps = null | 'isApproved' | 'isTransfered' | 'isStaked'

export type StakingProps = {
  tokenId: number
  poolId: string
}

export const Staking = ({ tokenId, poolId }: StakingProps) => {
  const { upToSmall } = useMediaWith()
  const theme = useTheme()
  const {
    isLoading: isLoadingPositions,
    approve,
    transfer,
    stake,
    unstake,
    withdraw,
    claim,
  } = useStakingMethods(tokenId, poolId)
  const {
    isLoading: isLoadingPositionByTokenId,
    error,
    data: positionByTokenIdData,
  } = useStakingPositionByTokenIdQuery(tokenId)
  const position = positionByTokenIdData?.positions[0]
  const { userReward, userRewardUSD, incentiveReward, incentiveRewardUSD, rewardTokenInfo, isStaked } =
    useUserRewardInfo({ tokenId, poolId })
  const pools = useIncentivizedPools()
  const [step, setStep] = useState<StakingSteps>(null)
  const graphPool = usePoolById(poolId.toLowerCase())
  const tvl = Number(graphPool.data?.totalValueLockedUSD) ?? 0
  const { apr } = useCurrentIncentiveApr({ poolId, tvl })

  function calculateTVLandAPR(pool) {
    if (!pool) return { tvl: 0, feeAPR: 0 }
    // Assuming TVL is already calculated in USD
    const tvl = parseFloat(pool.totalValueLockedUSD)

    // Total fees in USD collected over the observed period
    const feesUSD = parseFloat(pool.feesUSD)

    // Total volume in USD over the observed period
    const volumeUSD = parseFloat(pool.volumeUSD)

    // The period in seconds (e.g., one week = 604800 seconds)
    // Assuming the period of data collection is a year (365 days)
    // This is a simplification; ideally, you'd calculate the exact duration the pool has been active
    const periodInSeconds = 365 * 24 * 60 * 60

    // Convert createdAtTimestamp from string to number
    const poolCreationTimestamp = parseInt(pool.createdAtTimestamp)
    const currentTimestamp = Math.floor(Date.now() / 1000)
    const activePeriodInSeconds = currentTimestamp - poolCreationTimestamp

    // Calculate APR based on fees collected over the period
    // and extrapolate it to a yearly rate.
    const feeAPR = (feesUSD / tvl) * (periodInSeconds / activePeriodInSeconds) * 100

    return { tvl, feeAPR }
  }

  // const { tvl, feeAPR } = calculateTVLandAPR(graphPool.data)

  useEffect(() => {
    if (position?.approved) {
      setStep('isApproved')
    }
    if (position?.oldOwner === ZERO_ADDRESS) {
      setStep('isTransfered')
    }
    if (position?.staked) {
      setStep('isStaked')
    }
  }, [position])

  const getStakeButtons = () => {
    if (step === 'isStaked') {
      return (
        <RowFixed gap="10px">
          <ButtonPrimaryWhite
            width={100}
            padding="8px"
            style={{ fontSize: '13px', borderRadius: '5px' }}
            disabled={isLoadingPositions}
            onClick={() => claim?.(() => {})}
          >
            <Text>Claim</Text>
            <QuestionHelper
              color={theme.text1}
              text="Stake for the full duration of the incentive period to claim rewards"
            />
          </ButtonPrimaryWhite>
          <ButtonBordered
            width={100}
            padding="8px"
            style={{ fontSize: '13px', borderRadius: '5px' }}
            disabled={isLoadingPositions}
            onClick={() =>
              unstake?.(() => {
                setStep('isTransfered')
              })
            }
          >
            <Text>Unstake</Text>
          </ButtonBordered>
        </RowFixed>
      )
    }
    if (step === 'isTransfered') {
      return (
        <RowFixed gap="10px">
          <ButtonPrimaryWhite
            width={100}
            padding="8px"
            style={{ fontSize: '13px', borderRadius: '5px' }}
            disabled={isLoadingPositions}
            onClick={() =>
              stake?.(() => {
                setStep('isStaked')
              })
            }
          >
            <Text>Stake</Text>
          </ButtonPrimaryWhite>
          <ButtonBordered
            width={100}
            padding="8px"
            style={{ fontSize: '13px', borderRadius: '5px' }}
            disabled={isLoadingPositions}
            onClick={() => withdraw?.(() => setStep(null))}
          >
            <Text>Withdraw</Text>
          </ButtonBordered>
        </RowFixed>
      )
    }

    if (step === 'isApproved') {
      return (
        <RowFixed gap="10px">
          <ButtonPrimaryWhite
            width={100}
            padding="8px"
            style={{ fontSize: '13px', borderRadius: '5px' }}
            disabled={isLoadingPositions}
            onClick={() =>
              transfer?.(() => {
                setStep('isTransfered')
              })
            }
          >
            <Text>Stake NFT to Contract</Text>
          </ButtonPrimaryWhite>
        </RowFixed>
      )
    }

    return (
      <RowFixed gap="10px">
        <ButtonPrimaryWhite
          width={100}
          padding="8px"
          style={{ fontSize: '13px', borderRadius: '5px' }}
          disabled={isLoadingPositions}
          onClick={() =>
            approve?.(() => {
              setStep('isApproved')
            })
          }
        >
          <Text>Approve</Text>
        </ButtonPrimaryWhite>
      </RowFixed>
    )
  }

  return (
    <Box padding={upToSmall ? '0 10px 20px' : '0 20px 20px'}>
      <LightCard padding="15px">
        <AutoColumn gap="15px">
          <RowBetween>
            <Text fontSize="16px" fontWeight={500}>
              Stake
            </Text>
          </RowBetween>
          <AutoColumn gap="10px">
            <Box width="100%" backgroundColor={rgba(theme.white, 0.02)} padding="20px">
              <RowBetween>
                <Text
                  width={upToSmall ? 'auto' : '172px'}
                  fontWeight={400}
                  fontSize={14}
                  color={rgba(theme.white, 0.5)}
                >
                  APR
                </Text>
                <Text
                  width={upToSmall ? 'auto' : '172px'}
                  fontWeight={400}
                  fontSize={14}
                  color={rgba(theme.white, 0.5)}
                >
                  TVL
                </Text>
                <Text
                  width={upToSmall ? 'auto' : '172px'}
                  fontWeight={400}
                  fontSize={14}
                  color={rgba(theme.white, 0.5)}
                >
                  My Rewards
                </Text>
                <Box width={upToSmall ? 'auto' : '172px'} />
              </RowBetween>
            </Box>
            <Box width="100%" padding="16px 20px">
              <RowBetween>
                <Text width={upToSmall ? 'auto' : '110px'} fontWeight={400} fontSize={14} color={theme.white}>
                  <TextContent>{`${apr.toFixed(2)}%`}</TextContent>
                </Text>
                <Text width={upToSmall ? 'auto' : '110px'} fontWeight={400} fontSize={14} color={theme.white}>
                  {`$${Number(tvl?.toFixed(2) ?? '0').toLocaleString('en-US') ?? '-'}`}
                </Text>
                {isStaked ? (
                  <RowFixed gap="10px">
                    <Logo size="22px" src={rewardTokenInfo?.logo} />
                    <Text fontWeight={400} fontSize={14} color={theme.white}>
                      {userReward ?? '-'} {rewardTokenInfo?.name ?? ''}
                    </Text>
                    <Text fontWeight={400} fontSize={14} color={theme.accent}>
                      {`~$${Number(userRewardUSD ?? '0.00').toLocaleString('en-US')}`}
                    </Text>
                  </RowFixed>
                ) : (
                  // <RowFixed gap="10px">
                  //   <Text fontWeight={400} fontSize={14} color={theme.white}>
                  //     Stake your LPs
                  //     <br />
                  //     to earn up to:
                  //   </Text>
                  //   <AutoColumn>
                  //     <RowFixed gap="10px">
                  //       <Logo size="22px" src={rewardTokenInfo?.logo} />
                  //       <Text fontWeight={400} fontSize={14} color={theme.white}>
                  //         {incentiveReward ?? '-'} {rewardTokenInfo?.name ?? ''}
                  //       </Text>
                  //     </RowFixed>
                  //     <Text fontWeight={400} fontSize={14} color={theme.accent}>
                  //       {`~$${incentiveRewardUSD ?? '0.00'}`}
                  //     </Text>
                  //   </AutoColumn>
                  // </RowFixed>
                  <RowFixed gap="10px">
                    <Logo size="22px" src={rewardTokenInfo?.logo} />
                    <Text fontWeight={400} fontSize={14} color={theme.white}>
                      {userReward ?? '-'} {rewardTokenInfo?.name ?? ''}
                    </Text>
                    <Text fontWeight={400} fontSize={14} color={theme.accent}>
                      {`~$${userRewardUSD ?? '0.00'}`}
                    </Text>
                  </RowFixed>
                )}
                {getStakeButtons()}
              </RowBetween>
            </Box>
          </AutoColumn>
        </AutoColumn>
      </LightCard>
    </Box>
  )
}
