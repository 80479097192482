import {
  Currency,
  JSBI,
  Token,
  TokenAmount,
  Pair,
  CurrencyAmount,
  CURVEPOOL1,
  CURVEPOOL2,
  stlFTM,
  stlLIFE,
  stlUSDC,
  stlL3USD,
  stlTOMB,
  NATIVE_CURRENCY,
} from 'sdk'
import UniswapV2PairUpdatedAbi from '../../constants/abis/UniswapV2PairUpdatedAbi.json'

import { useActiveWeb3React, useChainId } from '../../hooks'
import { getContract, isAddress } from '../../utils'
import { useQuery, useQueryClient } from 'react-query'

import { PairState, usePairs } from 'data/Reserves'

import { useListedTokens, useUserAddedTokensMap } from 'hooks/Tokens'
import { multicallFailSafe } from 'shared'
import { WFTM_ADDRESS } from 'constants/contracts'
import { useLocation } from 'react-router'
import qs from 'qs'
import { PROVIDERS_BY_CHAIN } from 'connectors'
import { ERC20_ABI } from 'constants/abis/erc20'
import { BigNumber } from 'ethers'
import { useMemo } from 'react'
import axios1Inch from 'utils/1inch/axiosInstance'
import { create1InchProxyUrl } from 'utils/1inch/api'

function pairs(arr) {
  const res = [],
    l = arr.length
  for (let i = 0; i < l; ++i) for (let j = i + 1; j < l; ++j) res.push([arr[i], arr[j]])
  return res
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export const useLPorCurrencyBalanceQuery = (currency?: Currency) => {
  const { account, library } = useActiveWeb3React()
  const chainId = useChainId()

  const tokenAddress = currency?.symbol === NATIVE_CURRENCY[chainId].symbol ? WFTM_ADDRESS[chainId] : currency?.address

  return useQuery<TokenAmount | null | undefined>(
    [
      'token-balance',
      'single',
      currency instanceof Token ? currency?.address : currency?.symbol,
      account,
      chainId,
      currency?.symbol,
    ],
    async () => {
      try {
        if (currency === Currency.USD_CARD) return undefined

        let balanceResponse: any = null

        if (currency?.symbol === NATIVE_CURRENCY[chainId].symbol) {
          balanceResponse = await library?.getBalance(account as string)
        } else {
          const tokenContract = getContract(tokenAddress, ERC20_ABI, PROVIDERS_BY_CHAIN[chainId], account as string)
          balanceResponse = await tokenContract.balanceOf(account, { blockTag: 'pending' })
        }

        const amount = balanceResponse ? JSBI.BigInt(balanceResponse.toString()) : undefined

        if (amount && currency) {
          return new TokenAmount(currency as Token, amount.toString())
        }

        return undefined
      } catch (error) {
        console.error(error)
      }
    },
    {
      enabled: Boolean(account) && Boolean(currency),
      refetchInterval: 1000,
      staleTime: 1000,
      cacheTime: 1000,
    }
  )
}

export const useUpdateBalance = (address: string) => {
  const { account } = useActiveWeb3React()
  const chainId = useChainId()
  const queryClient = useQueryClient()

  const contract = address ? getContract(address, ERC20_ABI, PROVIDERS_BY_CHAIN[chainId]) : null

  return async () => {
    const newBalance = (await contract?.balanceOf(account)) as BigNumber

    queryClient.setQueryData(['token-balances', 'multicall', account, chainId], (oldBalances: any) => {
      const oldBalanceAmount = oldBalances.balances[address]

      const newBalanceAmount = new TokenAmount(oldBalanceAmount.token, newBalance.toString())

      return {
        ...oldBalances,
        balances: {
          ...oldBalances.balances,
          [address]: newBalanceAmount,
        },
      }
    })
  }
}

export const useBalances = () => {
  const { account, library } = useActiveWeb3React()
  const listedTokens = useListedTokens('Market')
  const chainId = useChainId()

  const balancesQuery = useQuery(
    ['listed-tokens-balances', 'multicall', account, chainId],
    async () => {
      try {
        const calls = Object.keys(listedTokens).map((tokenAddr) => ({
          address: tokenAddr,
          name: 'balanceOf',
          params: [account],
        }))

        const ftmBalanceResponse = await library?.getBalance(account ?? '', 'pending')

        const nativeCurrencyBalanceAmount =
          ftmBalanceResponse && CurrencyAmount.ether(ftmBalanceResponse?.toString(), chainId)

        const balances = await multicallFailSafe(UniswapV2PairUpdatedAbi, calls, PROVIDERS_BY_CHAIN[chainId], chainId)

        const finalBalances = balances.reduce((acc, balance, index) => {
          const amount = JSBI.BigInt(balance[0].toString())

          const token = Object.values(listedTokens)[index]

          if (amount && token) {
            acc[token.address] = new TokenAmount(token as Token, amount.toString())
          }

          return acc
        }, {})

        return { ...finalBalances, [NATIVE_CURRENCY[chainId].symbol]: nativeCurrencyBalanceAmount }
      } catch (error) {
        console.error(error)
      }
    },
    {
      enabled: Boolean(account),
      refetchOnMount: false,
      refetchOnWindowFocus: true,
      refetchInterval: 30000,
    }
  )

  return balancesQuery
}

export const useLPorCurrenciesBalanceQuery = () => {
  const { account, library } = useActiveWeb3React()
  const userAddedTokensMap = useUserAddedTokensMap()
  const listedTokens = useListedTokens('Market')

  const location = useLocation()
  const chainId = useChainId()
  const queryObject = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const derivedFromQueryString = {}

  queryObject &&
  queryObject.inputCurrency &&
  isAddress(queryObject.inputCurrency) &&
  !listedTokens[queryObject?.inputCurrency?.toString()] &&
  !userAddedTokensMap[queryObject?.inputCurrency?.toString()]
    ? (derivedFromQueryString[queryObject?.inputCurrency?.toString()] = new Token({
        address: queryObject.inputCurrency.toString(),
        decimals: 18,
        symbol: 'UNKNOWN',
        name: 'UNKNOWN',
      }))
    : 1

  queryObject &&
  queryObject.outputCurrency &&
  isAddress(queryObject.outputCurrency) &&
  !listedTokens[queryObject?.outputCurrency?.toString()] &&
  !userAddedTokensMap[queryObject?.outputCurrency?.toString()]
    ? (derivedFromQueryString[queryObject?.outputCurrency?.toString()] = new Token({
        address: queryObject.outputCurrency.toString(),
        decimals: 18,
        symbol: 'UNKNOWN',
        name: 'UNKNOWN',
      }))
    : 1

  const watchTokens = {
    [CURVEPOOL1.address]: CURVEPOOL1,
    [CURVEPOOL2.address]: CURVEPOOL2,
    [stlTOMB.address]: stlTOMB,
    [stlFTM.address]: stlFTM,
    [stlLIFE.address]: stlLIFE,
    [stlUSDC.address]: stlUSDC,
    [stlL3USD.address]: stlL3USD,
    ...userAddedTokensMap,
    ...listedTokens,
    ...derivedFromQueryString,
  }

  const result = useMemo(() => {
    const lp = {}

    const tokens = {
      [CURVEPOOL1.address]: CURVEPOOL1,
      [CURVEPOOL2.address]: CURVEPOOL2,
      [stlTOMB.address]: stlTOMB,
      [stlFTM.address]: stlFTM,
      [stlLIFE.address]: stlLIFE,
      [stlUSDC.address]: stlUSDC,
      [stlL3USD.address]: stlL3USD,
      ...userAddedTokensMap,
      ...listedTokens,
      ...derivedFromQueryString,
    }

    pairs(Object.values(tokens)).forEach(function (pair) {
      const liquidityToken = new Token({
        address: Pair.getAddress(pair[0], pair[1], chainId),
        decimals: 18,
        symbol: 'LIF3-LP',
        name: 'LIF3-LP',
      })

      lp[liquidityToken.address] = { liquidityToken: liquidityToken, tokens: [pair[0], pair[1]] }
      tokens[liquidityToken.address] = liquidityToken
    })

    return { lp, tokens }
  }, [Object.values(watchTokens).length, chainId])

  const balancesQuery = useQuery(
    ['token-balances', 'multicall', account, chainId],
    async () => {
      try {
        const calls = Object.keys(result.tokens).map((tokenAddr) => ({
          address: tokenAddr,
          name: 'balanceOf',
          params: [account],
        }))

        const ftmBalanceResponse = await library?.getBalance(account ?? '', 'pending')

        const nativeCurrencyBalanceAmount =
          ftmBalanceResponse && CurrencyAmount.ether(ftmBalanceResponse?.toString(), chainId)

        const balances = await multicallFailSafe(UniswapV2PairUpdatedAbi, calls, PROVIDERS_BY_CHAIN[chainId], chainId)

        const finalBalances = balances.reduce((acc, balance, index) => {
          const amount = JSBI.BigInt(balance[0].toString())

          const token = Object.values(result.tokens)[index]

          if (amount && token) {
            acc[token.address] = new TokenAmount(token as Token, amount.toString())
          }

          return acc
        }, {})

        return {
          balances: { ...finalBalances, [NATIVE_CURRENCY[chainId].symbol]: nativeCurrencyBalanceAmount },
          lpPairs: result.lp,
        }
      } catch (error) {
        console.error(error)
      }
    },
    {
      enabled: Boolean(account),
      refetchOnMount: false,
      refetchOnWindowFocus: true,
      refetchInterval: 30000,
    }
  )

  return balancesQuery
}

export function useLiquidityTokensBalance() {
  const liquidityTokensBalanceQuery = useLPorCurrenciesBalanceQuery()
  const tokensBalance = (liquidityTokensBalanceQuery.data as any)?.balances
  const pairs = (liquidityTokensBalanceQuery.data as any)?.lpPairs

  const liquidityTokensWithBalances = ((pairs && Object.values(pairs)) ?? []).filter((token) =>
    tokensBalance && token.liquidityToken.symbol == 'LIF3-LP'
      ? tokensBalance[token.liquidityToken.address]?.greaterThan('0')
      : false
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    liquidityTokensBalanceQuery?.isLoading ||
    v2Pairs?.length < liquidityTokensWithBalances.length ||
    v2Pairs.some(([state]) => state === PairState.LOADING)

  const result = v2Pairs
    .filter(([, pair]) => pair !== null)
    .map(([, pair]) => {
      return { pair, lpTokenBalance: tokensBalance?.[(pair as Pair).liquidityToken?.address] }
    })

  return { lpTokensWithBalances: result, isLoading: v2IsLoading } as {
    lpTokensWithBalances: Array<{
      pair: Pair
      lpTokenBalance: TokenAmount | undefined
    }>
    isLoading: boolean
  }
}
