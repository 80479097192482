import { Base } from 'components/Button'
import styled from 'styled-components'
import { ReactComponent as DropDown } from 'assets/images/dropdown.svg'
import { Box } from 'shared'

export const AccountPanelBox = styled.div`
  position: absolute;
  top: calc(100% + 20px);
  right: 50%;
  transform: translateX(50%);

  display: flex;
  flex-direction: column;
  gap: 15px;
  min-width: 275px;
  padding: 20px;
  border-radius: 8px;
  background: linear-gradient(
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0.08) 49.48%,
    rgba(211, 211, 211, 0.08) 69.79%,
    rgba(255, 255, 255, 0.08) 100%
  );
  -webkit-backdrop-filter: blur(60px);
  backdrop-filter: blur(60px);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 20px 40px, rgba(0, 0, 0, 0.094) 0px 10.125px 17.4375px,
    rgba(0, 0, 0, 0.07) 0px 4px 6.5px, rgba(0, 0, 0, 0.047) 0px 0.875px 2.3125px;
`

export const AccountPanelRow = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

export const AccountPanelIconRow = styled(AccountPanelRow)`
  gap: 8px;
  margin-top: 3px;
  align-self: flex-start;
  justify-content: flex-end;
  margin-left: auto;
`

export const AccountPanelCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const AccountPanelAction = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s;
  color: ${({ theme }) => theme.primaryText1};

  &:hover > * {
    color: ${({ theme }) => theme.secondaryText1};
    transition: color 0.3s;
  }
`

export const AccountPanelIconAction = styled.a`
  padding: 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  transition: opacity 0.3s;
  color: ${({ theme }) => (theme.darkMode ? theme.text1 : theme.white)};
  background-color: ${({ theme }) => (theme.darkMode ? theme.white : theme.text1)};

  &:hover,
  &:active {
    opacity: 0.7;
  }
`

export const AccountPanelTitle = styled.span`
  font-weight: 700;
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.primaryText1};
  text-align: left;
`

export const AccountPanelText = styled(AccountPanelTitle)`
  font-weight: 400;
`

export const AccountPanelLabel = styled(AccountPanelTitle)`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
`

export const DisconnectButton = styled(Base)`
  width: auto;
  background: transparent;
  color: ${({ theme }) => theme.primaryText1};
  border: 1px solid ${({ theme }) => theme.white};
  padding: 5px;
  border-radius: 5px;
  transition: all 0.3s;
  display: flex;
  font-weight: 400;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  transition: border-color 0.3s;

  &:hover {
    border-color: ${({ theme }) => theme.border1};
  }
`

export const AccountPanelHr = styled.hr`
  display: flex;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.border1};
  border: none;
  margin: 0;
`

export const AccountPanelChainStatus = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #118b64;
  margin-right: 5px;
`

export const AccountPanelPurchase = styled.a`
  width: 100%;
  background: transparent;
  color: ${({ theme }) => theme.primaryText1};
  border: 1px solid ${({ theme }) => theme.white};
  padding: 9px;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  transition: color 0.3s, border-color 0.3s;
  text-decoration: none;
  cursor: pointer;

  & > svg {
    width: 16px;
  }
  &:hover {
    border-color: ${({ theme }) => theme.secondaryText1};
    color: ${({ theme }) => theme.secondaryText1};
  }
`
